import React, { useState, useEffect, useContext } from 'react'
import ETH from '@/assets/icon/eth1.png'
import MaskBtn from '@/assets/img/maskbtn.png'
import AppContext from '@/hooks/useAppContext.js'
import contentEthereum from '@/hooks/contentEthereum.js'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction.js"

// import { Collapse, Typography, Button } from '@material-ui/core';
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Button,
  InputAdornment,
  Select,
  FormControl,
  MenuItem,
  useTheme,
  OutlinedInput,
  InputLabel,
  TextField,
  NativeSelect,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Typography
} from '@mui/material'
import PropTypes from 'prop-types'
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { globalApiSlice } from '@/features/global/globalSlice'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import axios from 'axios'
import utils from 'web3-utils'
import {
  arbitrum,
  mainnet,
  polygon,
  polygonMumbai,
  goerli,
  arbitrumGoerli
} from '@wagmi/chains'

import cfxTest from "@/cfxTest.js"
import cfx from "@/cfx.js"

// import { useDispatch } from 'react-redux';

// import { globalApiSlice } from '@/features/global/globalSlice.js'

// 时间戳转化成2023/07/21 18:12:45 格式时间
function formatDaTime(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  const m = addZero(date.getMonth() + 1)
  const d = addZero(date.getDate())
  const h = addZero(date.getHours())
  const mm = addZero(date.getMinutes())
  const s = addZero(date.getSeconds())
  return y + '/' + m + '/' + d + ' ' + h + ':' + mm + ':' + s
}
function addZero(num) {
  return num < 10 ? '0' + num : num
}
// 折叠
const TextExpandCollapse = ({ text, maxLength }) => {
  // console.log(text);
  if (text) {
    const [expanded, setExpanded] = useState(false)

    const handleExpandCollapse = () => {
      setExpanded(!expanded)
    }

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          {expanded
            ? text
            : `${text.slice(0, maxLength)}${text.length > maxLength ? '...' : ''
            }`}
        </Typography>
        {text.length > maxLength && (
          <div style={{ position: 'relative' }}>
            <span
              onClick={handleExpandCollapse}
              style={{
                color: 'rgba(36, 213, 186, 1)',
                fontSize: 14,
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                position: 'absolute',
                bottom: 0,
              }}
            >
              {expanded ? $t('sq') : $t('zk')}
            </span>
          </div>
        )}
      </div>
    )
  }
}
//减法函数，用来得到精确的减法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
//调用：Sub(arg1,arg2)
//返回值：arg1减去arg2的精确结果
const Sub = (arg1, arg2) => {
  arg1 = parseFloat(arg1)
  arg2 = parseFloat(arg2)
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((Mul(arg1, m) - Mul(arg2, m)) / m).toFixed(n)
}

//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：Mul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
const Mul = (arg1, arg2) => {
  arg1 = parseFloat(arg1)
  arg2 = parseFloat(arg2)
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString()
  try {
    m += s1.split(".")[1].length
  } catch (e) { }
  try {
    m += s2.split(".")[1].length
  } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

// 验证整数
const digits = (value) => {
  return /^\d+$/.test(value)
}

function PublishInfo() {
  // const isMobile = window.innerWidth <= 600; // 判断是否为移动端
  const theme = useTheme()
  const lt = useLocation()
  const navigate = useNavigate()
  // const dispatch = useDispatch();
  const id = lt.search.replace(/\?id=/, '')
  // console.log(sessionStorage.getItem('USER'),'USER');
  // console.log(sessionStorage.getItem('ULE'),'ULE');
  // console.log(sessionStorage.getItem('TOKEN'),'TOKEN');
  // console.log(id)

  const [state, setState] = useState({
    publishShow: {},
    activeIndex: 0, // 用于存储当前轮播项的索引
  })

  const handleSlideChange = (swiper) => {
    setState((v) => ({ ...v, activeIndex: swiper.activeIndex }))
  }

  const { usePublishShowQuery } = globalApiSlice

  const publishShow = usePublishShowQuery({ id })
  useEffect(() => {
    if (publishShow.data)
      setState((v) => ({ ...v, publishShow: publishShow.data }))
  }, [publishShow.data])

  const [open, setOpen] = React.useState(false)
  const [msg, setMsg] = React.useState('请连接钱包')
  let [respCastMessageFlag, setRespCastMessageFlag] = React.useState(false)
  let [discountMsgFlag, setDiscountMsgFlag] = React.useState(false)
  let [respCastMessage, setRespCastMessage] = React.useState({})
  // hashreturn参数
  let [hashReturn, setHashReturn] = React.useState({})
  // 标识
  let [hashReturnFlag, setHashReturnFlag] = React.useState(false)
  let [hashReturnMsg, setHashReturnMsg] = React.useState({})
  let [buyRespFlag, setBuyRespFlag] = React.useState(false)
  let [buyRespMessage, setBuyRespMessage] = React.useState({})
  const { store, setStore } = useContext(AppContext)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  // 点击外侧 - 立即铸造
  const create = async () => {
    let walletconnect
    // 未登录
    if (!sessionStorage.getItem('TOKEN')) {
      setMsg('请先登录!')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/login')
      }, 800)
      return
    }

    if (
      state.publishShow.product[state.activeIndex].now >=
      state.publishShow.product[state.activeIndex].first_at &&
      state.publishShow.product[state.activeIndex].now <
      state.publishShow.product[state.activeIndex].start_at &&
      !state.publishShow.product[state.activeIndex].white_info
    ) {
      // return uni.$u._toast(this.$t('bzbmdn'))
      setMsg($t('bzbmdn'))
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
    }

    if (
      +state.publishShow.product[state.activeIndex].sale >=
      state.publishShow.product[state.activeIndex].quantity
    ) {
      // return uni.$u._toast(this.$t('ysqs'))
      setMsg($t('ysqs'))
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
    }

    sessionStorage.setItem(
      'collectionInfo',
      JSON.stringify({
        ...state.publishShow.product[state.activeIndex],
        max_limit: state.publishShow.max_limit,
        pay_coin: state.publishShow.pay_coin,
        discount: state.publishShow.discount,
        white_info: state.publishShow.white_info,
        is_blindbox: state.publishShow.is_blindbox,
        user_bought_number: state.publishShow.user_bought_number, //用户已购买数量
        user_bought_number_white: state.publishShow.user_bought_number_white, //用户已购买消耗白名单数量
      }),
    )
    // 打开弹框
    await handleClickOpen()

    return

    if (window.ethereum.selectedAddress)
      walletconnect = window.ethereum.selectedAddress


    if (!walletconnect) {
      // uni.$u._toast(this.$t('pljqb'))
      setMsg('请连接钱包')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
      // this.$refs.connectWallet.showConnectWallet = true
    } else {
      if (
        state.publishShow.product[state.activeIndex].now >=
        state.publishShow.product[state.activeIndex].first_at &&
        state.publishShow.product[state.activeIndex].now <
        state.publishShow.product[state.activeIndex].start_at &&
        !state.publishShow.product[state.activeIndex].white_info
      ) {
        // return uni.$u._toast(this.$t('bzbmdn'))
        setMsg('不在白名单内')
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
      if (
        +state.publishShow.product[state.activeIndex].sale >=
        state.publishShow.product[state.activeIndex].quantity
      ) {
        // return uni.$u._toast(this.$t('ysqs'))
        setMsg('已售罄')
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
      // console.log(state.publishShow.product[state.activeIndex]);
      sessionStorage.setItem(
        'collectionInfo',
        JSON.stringify({
          ...state.publishShow.product[state.activeIndex],
          max_limit: state.publishShow.max_limit,
          pay_coin: state.publishShow.pay_coin,
          discount: state.publishShow.discount,
          white_info: state.publishShow.white_info,
          is_blindbox: state.publishShow.is_blindbox,
          user_bought_number: state.publishShow.user_bought_number, //用户已购买数量
          user_bought_number_white: state.publishShow.user_bought_number_white, //用户已购买消耗白名单数量
        }),
      )
      // 打开弹框
      await handleClickOpen()
      // console.log(JSON.parse(sessionStorage.getItem('collectionInfo')));
    }
  }
  let [detail, setDetail] = useState({})
  let [discount, setDiscount] = useState({})
  let [num, setNum] = useState('')

  const handleInputNumChange = (event) => {
    if (+event.target.value > 0) {
      // 处理合法的输入
      num = +event.target.value
      setNum(+event.target.value)
      console.log(event.target.value, 'event.target.value')
    } else {
      // 处理非法的输入
      event.target.value = 1
    }
    inputHand()
  }

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('collectionInfo'))) {
      setDetail(JSON.parse(sessionStorage.getItem('collectionInfo')))
      JSON.parse(sessionStorage.getItem('collectionInfo')).fungible == 0
        ? setNum(1)
        : setNum('')
      if (JSON.parse(sessionStorage.getItem('collectionInfo')).fungible == 0)
        inputHand()
    }
  }, [])

  // console.log(detail);

  const { usePublishDiscountQuery, usePublishBuyRespCastMessageQuery, useUserHashReturnQuery, usePublishBuyRespQuery } =
    globalApiSlice
  if (JSON.parse(sessionStorage.getItem('collectionInfo'))) {
    detail = JSON.parse(sessionStorage.getItem('collectionInfo'))
    // num = JSON.parse(sessionStorage.getItem('collectionInfo')).fungible==0 ? 1 : ''
    // if(JSON.parse(sessionStorage.getItem('collectionInfo')).fungible==0 ) inputHand()
  }
  let discountMsg = {}

  let respCastMessageResult = {}
  if (respCastMessageFlag) respCastMessageResult = usePublishBuyRespCastMessageQuery({
    order_id: detail.order?.id,
    number: num,
    ckR: !!respCastMessageFlag,
  })
  let tx_hash = ''
  let hashReturnResult = {}
  if (hashReturnFlag) hashReturnResult = useUserHashReturnQuery({ ...hashReturn, ckR: hashReturnFlag })
  // console.log(hashReturnMsg);
  if (tx_hash) buyRespMessage = usePublishBuyRespQuery({ tx_hash, ckR: buyRespFlag })
  useEffect(() => {
    if (respCastMessageResult.data) {
      setRespCastMessage(respCastMessageResult.data)
    } else {
      if (respCastMessageResult.error && respCastMessageResult.isError) {
        setMsg(respCastMessageResult.error)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
    }
    // setRespCastMessageFlag(false)

  }, [respCastMessageResult.data, respCastMessageResult.status])
  // console.log(respCastMessage,'===respCastMessage');
  useEffect(() => {
    if (hashReturnResult.data) {
      setHashReturnMsg(hashReturnResult.data)
    } else {
      if (hashReturnResult.error && hashReturnResult.isError) {
        setMsg(hashReturnResult.error)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
    }
    // setHashReturnFlag(false)

  }, [hashReturnResult.data, hashReturnResult.status])

  useEffect(() => {
    if (buyRespMessage.data) {
      setBuyRespMessage(buyRespMessage.data)
    } else {
      if (buyRespMessage.error && buyRespMessage.isError) {
        setMsg(buyRespMessage.error)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
    }
    // setHashReturnFlag(false)

  }, [buyRespMessage.data, buyRespMessage.status])

  useEffect(() => {
    // console.log(discountMsg);
    if (discountMsg.data) {
      setDiscount(discountMsg.data)
    } else {
      if (discountMsg.error && discountMsg.isError) {
        setMsg(discountMsg.error)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
        return
      }
    }
    setDiscountMsgFlag(false)
  }, [discountMsg.data, num, discountMsg.status, discountMsgFlag])
  // console.log(discount,'discount');
  // console.log(discountMsg,'discountMsg');


  const [openDialog, setOpenDialog] = React.useState(false)
  const handleClickClose = () => {
    setOpenDialog(false)
    setRespCastMessageFlag(false)
    setBuyRespFlag(false)
    setHashReturnFlag(false)
  }
  // 打开弹框
  const handleClickOpen = async () => {

    // detail.fungible==0? setNum(1):setNum('')
    // if (detail.fungible==0) {
    console.log(JSON.parse(sessionStorage.getItem('collectionInfo')))
    num =
      JSON.parse(sessionStorage.getItem('collectionInfo')).fungible == 0
        ? 1
        : ''
    const collectionInfo = JSON.parse(sessionStorage.getItem('collectionInfo'))
    const resut = await globalApiSlice.publishDiscountApi({
      amount: num,
      order_id: collectionInfo.order.id
    })
    setDiscount(resut)
    setOpenDialog(true)
    setDiscountMsgFlag(true)
    // inputHand()

    // }
  }
  const inputHand = async () => {
    console.log(num, 'num11111')
    setDiscountMsgFlag(true)

    // console.log(detail.fungible);
    if (!num) {
      return false
    }
    if (!discountMsg.data) {
      // setMsg(discountMsg.error)
      // setOpen(true)
      // setTimeout(() => {
      //   setOpen(false)
      // }, 1000)
      return
    }
    console.log(discount, 'input -discount')
    if (discount && discount.max_limit < num) {
      // uni.$u._toast(this.$t('ccgmxz'))
      setMsg('超出购买限制')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
    }
    // await fetchDiscount({order_id: detail.order.id,amount:num})
  }
  const chainsList = {
    '1030': cfx,
    '71': cfxTest,
    '42161': arbitrum,
    '421613': arbitrumGoerli,
    '1': mainnet,
    '5': goerli
  }
  // 发送交易
  const send_transaction = async (data) => {
    let walletconnect
    // 未登录
    if (!sessionStorage.getItem('TOKEN')) {
      setMsg('请先登录!')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/login')
      }, 800)
      return
    }
    //  链接钱包
    if (window.ethereum.selectedAddress)
      walletconnect = window.ethereum.selectedAddress

    if (!walletconnect) {
      // uni.$u._toast(this.$t('pljqb'))
      setMsg($t('pljqb'))
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
      // this.$refs.connectWallet.showConnectWallet = true
    }
    let chain_id = await window.ethereum.networkVersion
    console.log('chain_id:', chain_id, data.chain_id)

    if (data.chain_id && (data.chain_id != chain_id)) {
      let switchRes = await window.ethereum.request({
        "method": "wallet_switchEthereumChain",
        "params": [{
          "chainId": utils.numberToHex(data.chain_id)
        }]
      })
      // 4902	无法识别的链 ID。首先尝试使用“wallet_addEthereumChain”添加链。
      if (switchRes) {
        await window.ethereum.request({
          "method": "wallet_addEthereumChain",
          "params": [chainsList[data.chain_id]]
        })
      }
    }
    await new Promise(resolve => setTimeout(resolve, 1000))
    let estimateGas = 0
    let gas = {
      71: '200000',
      42161: '5000000'
    }
    // console.log(walletconnect,'===walletconnect');
    try {
      estimateGas = await window.ethereum.request({
        "method": "eth_estimateGas",
        "params": [{
          "from": walletconnect,
          "to": data.to,
          "data": data.data,
          "chainId": utils.numberToHex(data.chain_id),
          "value": data.value ? utils.numberToHex(+data.value) : '0x0',
        }, null]
      })
      // console.log(estimateGas,'===estimateGas')
      let result = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: walletconnect,
          to: data.to,
          value: data.value ? utils.numberToHex(data.value) : 0,
          data: data.data,
          gas: estimateGas
        }],
      })
      if (data.type) {
        setHashReturn({
          ids: [data.id],
          type: data.type,
          hash: result
        })
        // await hashReturn()
        // hashReturn触发标识
        setHashReturnFlag(true)
      }
      return result
    } catch (e) {
      console.log(e)
      let msg = ''
      if (typeof e == 'object') {
        msg = e && e.message
      } else {
        msg = e
      }
      setMsg(msg)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 800)
      return null
    }
  }
  // 确定铸造
  const ljzz = async () => {
    let eth = null
    if (store.ethereum?.address) {
      eth = store.ethereum
    } else {
      eth = await contentEthereum()
    }
    const collectionInfo = JSON.parse(sessionStorage.getItem('collectionInfo'))
    const res = await globalApiSlice.publishBuyRespCastMessageApi({
      number: num,
      order_id: collectionInfo.order.id
    })

    const signature = await ethereumSendTransaction({
      ...res,
      to: res.exchange_address,
      id: [res.trade_id],
      type: 1,
      money: +collectionInfo.price * num
    })

    if (signature) {
      globalApiSlice.publishBuyRespApi({ tx_hash: signature }).then(() => {
        _toast($t('zzcg'))
        handleClickClose()
      })
    }

    return
    console.log(11111111)
    let walletconnect
    // 未登录
    if (!sessionStorage.getItem('TOKEN')) {
      setMsg('请先登录!')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        navigate('/login')
      }, 800)
      return
    }
    //  链接钱包
    if (window.ethereum.selectedAddress)
      walletconnect = window.ethereum.selectedAddress

    if (!walletconnect) {
      // uni.$u._toast(this.$t('pljqb'))
      setMsg('请连接钱包')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
      // this.$refs.connectWallet.showConnectWallet = true
    }
    // console.log(num);
    console.log(2222222222222)

    if (!num || !digits(num)) {
      setMsg('请填写数量')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
      return
    }
    // 获取铸造事务编码
    console.log(3333333333333)
    setRespCastMessageFlag(true)
    // console.log(respCastMessageFlag)
    // console.log(respCastMessage,'====respCastMessage')
    if (respCastMessageResult.data) {
      // 获取到铸造编码
      // 发送交易
      console.log(44444444)
      console.log(respCastMessage, '====respCastMessage')
      tx_hash = await send_transaction({ ...respCastMessage, to: respCastMessage.exchange_address, id: respCastMessage.trade_id, type: 1, money: +detail.price * num })
      // console.log(tx_hash,'==tx_hash');
      if (tx_hash) {
        // 立即铸造-交易状态
        setBuyRespFlag(true)
        console.log(buyRespMessage)
        // data": 1 // 0:表示支付中或不存在记录, 1: 表示成功, 2:表示失败
        if (buyRespMessage == 0) {
          setMsg('支付中或不存在记录')
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 1000)
          return
        } else if (buyRespMessage == 1) {
          setMsg('成功')
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 1000)
        } else if (buyRespMessage == 2) {
          setMsg('失败')
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 1000)
          return
        }

        // 铸造成功-触发关闭方法
        handleClickClose()
      }
    }




    // await handleRequest({order_id: detail.order.id,number:num,ckR:true})
    // 交易状态查询
  }

  return (
    <Box sx={{ ...theme.components.layoutMainBox, ...theme.palette.warp }}>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '0 auto' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            margin: '0 auto 30px',
            '@media (max-width: 600px)': {
              marginBottom: '10px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '500px',
              '@media (max-width: 600px)': {
                height: '250px',
              },
              // borderRadius: '30px',
              // overflow: 'hidden',
              position: 'relative',
              background: `url(${state.publishShow.image})`,
              // backgroundRepeat: 'repeat',
              // backgroundSize: '100% 100%',
            }}
            className="media-whimg"
          >
            {/* <img
              style={{width:'100%',height:'250px'}}
              className=" "
              src={}
            /> */}
            <Box
              sx={{
                p: '10px',
                '@media (max-width: 600px)': {
                  fontSize: 12,
                  p: '5px',
                },
                bgcolor: 'rgba(0, 246, 208, .2)',
                borderRadius: '12px',
                position: 'absolute',
                top: '20px',
                left: '20px',
                color: '#00F6D0',
              }}
            >
              {(state.publishShow.now >= state.publishShow.start_at ||
                state.publishShow.now >= state.publishShow.first_at) &&
                state.publishShow.now <= state.publishShow.end_at ? (
                $t('issue.zzz')
              ) : (
                <></>
              )}
              {state.publishShow.now <
                (state.publishShow.first_at || state.publishShow.start_at) ? (
                $t('issue.jjks')
              ) : (
                <></>
              )}
              {state.publishShow.now > state.publishShow.end_at ? (
                $t('issue.yjs')
              ) : (
                <></>
              )}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                p: '0 45px 33px 22px',
                bottom: 0,
                left: 0,
                width: 1,
                // bgcolor:'black',
                backgroundImage:
                  'linear-gradient(0deg, rgba(0,0,0,.9), rgba(0,0,0,.1))',
                '@media (max-width: 600px)': {
                  display: 'none',
                },
              }}
            >
              <p style={{ fontSize: '28px', color: '#fff' }}>
                {state.publishShow.publish_name}
              </p>
              <p style={{ fontSize: '18px', color: '#D7D7D7' }}>
                {formatDaTime(state.publishShow.start_at)}-
                {formatDaTime(state.publishShow.end_at)}
              </p>
              <p
                style={{
                  fontSize: '18px',
                  color: '#fff',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {state.publishShow.publish_describe}
              </p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'none',
            }}
            className="container why-mediablock"
          >
            <span style={{ fontSize: '14px', display: 'block', color: '#666' }}>
              {formatDaTime(state.publishShow.start_at)}-
              {formatDaTime(state.publishShow.end_at)}
            </span>
            <span style={{ fontSize: '20px', display: 'block' }}>
              {state.publishShow.publish_name}
            </span>
            <span>{state.publishShow.publish_describe}</span>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{}} className="container">
          {/* 这里是一个轮播图 */}
          <Box
            sx={{
              pr: 7,
              position: 'relative',
              '@media (max-width: 600px)': {
                pr: 0,
              },
              height: '350px',
              width: 'auto',
            }}
          >
            <Swiper
              spaceBetween={0}
              // slidesPerView={1}
              onSlideChange={handleSlideChange}
            >
              {state.publishShow.product ? (
                state.publishShow.product.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.sourcefile_url}
                      style={{ width: '100%', height: '350px' }}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <></>
              )}
            </Swiper>
            {state.publishShow.product?.length > 1 ? (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    zIndex: '99',
                  }}
                >
                  {state.publishShow.product.map((item, index) => (
                    <img
                      key={index}
                      style={{
                        width: '60px',
                        height: '60px',
                        border:
                          state.activeIndex == index
                            ? '2px solid #888'
                            : 'none',
                        boxShadow: '2px 2px 5px 0px rgb(151 151 151 / 100%)',
                        borderRadius: '5px',
                        marginRight: '10px',
                        '@media (max-width: 600px)': {
                          display: 'none',
                        },
                      }}
                      src={item.sourcefile_url}
                    />
                  ))}
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
          {/* <Box
            sx={{
              width:370 ,
              // width: { xs: '100%', md: 370 },
              // height: { xs: '100%', md: 390 },
              height:390,
              background: '#24D5BA',
            }}
          ></Box> */}
        </Grid>

        {state.publishShow.product ? (
          <Grid item xs={12} md={8} className="container">
            <Box
              sx={{
                ...theme.palette.mainText,
                ...{
                  color: '#666',
                  fontSize: 22,
                  display: 'flex',
                  justifyContent: 'space-between',
                },
                '@media (max-width: 600px)': {
                  fontSize: 18,
                },
              }}
            >
              <span>
                {state.publishShow.product[state.activeIndex].product_name}
              </span>
              <span>
                <img
                  src={ETH}
                  style={{ marginRight: '10px', verticalAlign: 'bottom' }}
                />
                {state.publishShow.product[state.activeIndex].price}{' '}
                {state.publishShow.pay_coin}
              </span>
            </Box>
            {/* <p style={{ fontSize: 16 }}>
            <span style={{ color: '#888888', marginRight: '5px' }}>已铸造</span>
            {state.publishShow.product[state.activeIndex].sold}/{state.publishShow.product[state.activeIndex].quantity}
          </p> */}
            <Box>
              <p style={{}}>{$t('collection.jbxx')}</p>
              <Box
                sx={{
                  display: 'flex',
                  mb: 3,
                  '@media (max-width: 600px)': {
                    // marginRight: '0',
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Box
                  sx={{
                    marginRight: '50px',
                    '@media (max-width: 600px)': {
                      marginRight: '0',
                    },
                    ...theme.typobox.flexCenterCenterByColumn,
                  }}
                >
                  <span style={{ fontSize: 14, color: '#888' }}>{$t('public.network')}</span>
                  <span
                    style={{
                      fontSize: 16,
                      '@media (max-width: 600px)': {
                        fontSize: 14,
                      },
                    }}
                  >
                    {state.publishShow.chain_name}
                  </span>
                </Box>
                <Box
                  sx={{
                    marginRight: '50px',
                    ...theme.typobox.flexCenterCenterByColumn,
                    '@media (max-width: 600px)': {
                      marginRight: '0',
                    },
                  }}
                >
                  <span style={{ fontSize: 14, color: '#888' }}>{$t('public.tokenStandards')}</span>
                  <span
                    style={{
                      fontSize: 16,
                      '@media (max-width: 600px)': {
                        fontSize: 14,
                      },
                    }}
                  >
                    {state.publishShow.protocol}
                  </span>
                </Box>
                <Box
                  sx={{
                    marginRight: '50px',
                    ...theme.typobox.flexCenterCenterByColumn,
                    '@media (max-width: 600px)': {
                      marginRight: '0',
                    },
                  }}
                >
                  <span style={{ fontSize: 14, color: '#888' }}>{$t('issue.yzz')}</span>
                  <span
                    style={{
                      fontSize: 16,
                      '@media (max-width: 600px)': {
                        fontSize: 14,
                      },
                    }}
                  >
                    <span
                      style={{
                        display:
                          state.publishShow.product.length > 0 &&
                            state.publishShow.is_blindbox != 1
                            ? 'block'
                            : 'none',
                      }}
                    >
                      {state.publishShow.product[state.activeIndex] &&
                        state.publishShow.product[state.activeIndex]['sold']}
                      /
                      {state.publishShow.product[state.activeIndex] &&
                        state.publishShow.product[state.activeIndex][
                        'quantity'
                        ]}
                    </span>
                    <span
                      style={{
                        display:
                          state.publishShow.product.length > 0 &&
                            state.publishShow.is_blindbox != 1
                            ? 'none'
                            : 'block',
                      }}
                    >
                      {state.publishShow['sale']}/
                      {state.publishShow['quantity']}
                    </span>
                    {/* {state.publishShow.product[state.activeIndex].sold}/
                    {state.publishShow.product[state.activeIndex].quantity} */}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                '@media (max-width: 600px)': {
                  width: '100%',
                  justifyContent: 'center',
                },
              }}
            >
              <Box>
                <img
                  src={MaskBtn}
                  style={{
                    marginRight: '30px',
                    verticalAlign: 'bottom',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    navigate(
                      `/market?keyword=${state.publishShow.product[state.activeIndex] &&
                      state.publishShow.product[state.activeIndex][
                      'product_name'
                      ]
                      }`,
                    )
                  }
                />
              </Box>
              <button
                style={{
                  position: 'relative',
                  width: 150,
                  height: 48,
                  background: 'linear-gradient(90deg, #00E2D6, #0083F1)',
                  borderRadius: '24px',
                  color: '#fff',
                  ...theme.typobox.flexCenterCenter,
                  cursor: 'pointer',
                }}
                className="mr-btn"
                onClick={() => create()}
                disabled={
                  !state.publishShow.id ||
                  state.publishShow.now <
                  (state.publishShow.first_at ||
                    state.publishShow.start_at) ||
                  state.publishShow.now > state.publishShow.end_at
                }
              >
                {$t('issue.ljzz')}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor:
                      !state.publishShow.id ||
                        state.publishShow.now <
                        (state.publishShow.first_at ||
                          state.publishShow.start_at) ||
                        state.publishShow.now > state.publishShow.end_at
                        ? 'rgba(0,0,0,.3)'
                        : 'rgba(0,0,0,0)',
                    // 'rgba(0,0,0,.4)',
                    borderRadius: '24px',
                  }}
                ></div>
              </button>
            </Box>
            <Box>
              <p>{$t('public.collectionIntroduction')}</p>
              <div>
                {/* <TextExpandCollapse
              
              text={
                state.publishShow.product[state.activeIndex].product_describe
              }
              maxLength={80}
            /> */}
                {/* <span style={{fontSize:'16px'}}>{state.publishShow.product[state.activeIndex].product_describe}</span> */}

                <span style={{ fontSize: '16px', color: '#666' }}>
                  {
                    state.publishShow.product[state.activeIndex]
                      .product_describe
                  }
                </span>
              </div>
            </Box>
          </Grid>
        ) : (
          <></>
        )}

        <Grid
          item
          xs={12}
          sx={{ margin: '30px auto 50px', width: 1 }}
          className="container"
        >
          <Box
            sx={{
              display: 'flex',
              '@media (max-width: 600px)': {
                justifyContent: 'space-between',
                marginBottom: '20px',
              },
            }}
          >
            <Box>
              <Avatar
                src={state.publishShow.avatar}
                sx={{
                  width: 120,
                  height: 120,
                  marginRight: '38px',
                  '@media (max-width: 600px)': {
                    width: '60px',
                    height: '60px',
                    marginRight: '10px',
                    // marginRight: '0px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                '@media (max-width: 600px)': {
                  flex: 1,
                },
              }}
            >
              <p>
                <span
                  className="publisher-name"
                  style={{
                    fontSize: '22px',
                  }}
                >
                  {state.publishShow.publisher}
                </span>
                <span
                  className="publisher-icon"
                  style={{
                    padding: '8px 20px',
                    backgroundColor: 'rgba(36, 213, 186, .2)',
                    color: 'rgba(36, 213, 186, 1)',
                    marginLeft: '15px',
                    borderRadius: '5px',
                    fontSize: '18px',
                  }}
                >
                  {$t('public.issuer')}
                </span>
              </p>
              <div>
                {/* <TextExpandCollapse 
                text={state.publishShow.publisher_describe}
                maxLength={120}
              /> */}
                {/* <span style={{}}>{state.publishShow.publisher_describe}</span> */}
                <span
                  style={{ fontSize: '16px', color: '#666' }}
                  className=" media-title14"
                >
                  {state.publishShow.publisher_describe}
                </span>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: 300 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          message={msg}
        />
      </Box>
      {/* 立即铸造弹框pc */}
      <Dialog
        open={openDialog}
        className="why-medianone"
        onClose={handleClickClose}
        PaperProps={{ style: { width: '450px', fontSize: '18px' } }}
      >
        {/* <DialogTitle>铸造</DialogTitle> */}
        <DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
            style={{ float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={theme.typobox.flexCenterLeft}>
            <img
              src={
                detail.is_blindbox == 1
                  ? detail.product_sourcefile_url
                  : detail.sourcefile_url
              }
              alt=""
              style={{ width: '80px', height: '80px', marginRight: '5px' }}
            />
            <span>{detail.product_name}</span>
          </div>
          <div style={{ ...theme.typobox.flexCenterLeft, margin: '10px 0' }}>
            <span>{$t('num')}：</span>
            <TextField
              size="small"
              autoFocus
              type="number"
              value={num}
              onChange={handleInputNumChange}
              disabled={detail.fungible == 0}
            />
          </div>
          <div style={{ fontSize: '16px', color: '#999' }}>
            <span>{$t('public.maxZZ')}：</span>
            <span>{detail.fungible == 0 ? 1 : discount.max_limit}</span>
          </div>
          <div style={{ margin: '10px 0' }}>
            <span>{$t('public.price')}：</span>
            <span>
              {+detail.price} {detail.pay_coin}
            </span>
          </div>
          {/* 折扣优惠 */}
          {/* 111 {discount?.discount_list} */}
          <div
            style={{
              display:
                discount?.discount_list && discount?.discount_list?.length > 0
                  ? 'block'
                  : 'none',
              fontSize: '16px',
            }}
          >
            <span style={{ color: '#24D5BA' }}>{$t('zkyh')}:</span>
            {discount?.discount_list ? (
              discount?.discount_list.map((item, index) => {
                {
                  /* { discount.discount_list.map((item,index)=>{ */
                }
                {
                  /* { [0,1].map((item,index)=>{ */
                }
                return (
                  <div key={index}>
                    <span
                      style={{
                        display: item.type == 'white' ? 'block' : 'none',
                      }}
                    >
                      {$t('bmdzk')}：
                    </span>
                    <span
                      style={{
                        display: item.type == 'normal' ? 'block' : 'none',
                      }}
                    >
                      {$t('fxzk')}：
                    </span>
                    {/* <span>白名单折扣</span>
                <span>发行折扣</span> */}
                    <span>
                      {discount?.discount_list && discount?.discount_list?.length > 0 ? Sub(Mul(item.unit_price, item.discount_quantity), item.discount_money) : '--'} {detail.pay_coin}
                    </span>
                  </div>
                )
              })
            ) : (
              <></>
            )}
            {/* }) } */}
          </div>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ fontSize: '16px' }}>
            <span>{$t('public.ygfy')}：</span>
            <span>
              {num ? discount?.total_money : '--'}
              {detail.pay_coin}
            </span>
          </div>
          <div>
            {/* <Button onClick={handleClickClose}>取消</Button> */}
            <Button
              onClick={() => ljzz()}
              style={{
                background: 'linear-gradient(to right, #00F6D0, #0077F5)',
                fontSize: '16px',
                color: '#fff',
                padding: '5px 30px',
                borderRadius: '20px',
              }}
            >
              {$t('issue.ljzz')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* h5 */}
      <Dialog
        open={openDialog}
        style={{ display: 'none' }}
        className="why-mediablock"
        fullScreen
        onClose={handleClickClose}
        PaperProps={{ style: { width: '100%' } }}
      >
        <DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={theme.typobox.flexCenterLeft}>
            <img
              src={
                detail.is_blindbox == 1
                  ? detail.product_sourcefile_url
                  : detail.sourcefile_url
              }
              alt=""
              style={{ width: '60px', height: '60px', marginRight: '10px' }}
            />
            <span>{detail.product_name}</span>
          </div>
          <div style={{ margin: '15px 0' }}>
            <div style={{ marginBottom: '15px' }}>{$t('num')}：</div>
            <TextField
              size="small"
              fullWidth
              autoFocus
              type="number"
              value={num}
              onChange={handleInputNumChange}
              disabled={detail.fungible == 0}
            />
          </div>
          <div style={{ color: '#999' }}>
            <span>{$t('public.maxZZ')}：</span>
            <span>{detail.fungible == 0 ? 1 : discount.max_limit}</span>
          </div>
          <div style={{ margin: '15px 0' }}>
            <span>{$t('public.price')}：</span>
            <span>
              {+detail.price} {detail.pay_coin}
            </span>
          </div>
          {/* 折扣优惠 */}
          <div
            style={{
              display:
                discount?.discount_list && discount?.discount_list?.length > 0
                  ? 'block'
                  : 'none',
              fontSize: '16px',
            }}
          >
            <span style={{ color: '#24D5BA' }}>{$t('zkyh')}:</span>
            {discount?.discount_list ? (
              discount?.discount_list.map((item, index) => {
                {
                  /* { discount.discount_list.map((item,index)=>{ */
                }
                {
                  /* { [0,1].map((item,index)=>{ */
                }
                return (
                  <div key={index}>
                    <span
                      style={{
                        display: item.type == 'white' ? 'block' : 'none',
                      }}
                    >
                      {$t('bmdzk')}：
                    </span>
                    <span
                      style={{
                        display: item.type == 'normal' ? 'block' : 'none',
                      }}
                    >
                      {$t('fxzk')}：
                    </span>
                    {/* <span>白名单折扣</span>
                <span>发行折扣</span> */}
                    <span>
                      {Sub(Mul(item.unit_price, item.discount_quantity), item.discount_money)} {detail.pay_coin}
                    </span>
                  </div>
                )
              })
            ) : (
              <></>
            )}
            {/* }) } */}
          </div>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ fontSize: '16px' }}>
            <span style={{ color: '#888' }}>{$t('public.ygfy')}：</span>
            <div>
              {num ? discount?.total_money : '--'}
              {detail.pay_coin}
            </div>
          </div>
          <div>
            {/* <Button onClick={handleClickClose}>取消</Button> */}
            <div
              onClick={() => ljzz()}
              style={{
                background: 'linear-gradient(to right, #00F6D0, #0077F5)',
                fontSize: '14px',
                color: '#fff',
                padding: '10px 50px',
                borderRadius: '20px',
              }}
            >
              {$t('issue.ljzz')}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default PublishInfo
