import {
  Box,
  Grid,
  useTheme,
  Avatar,
  Typography,
  Button,
  Tab,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ListItemText,
  Checkbox,
  Divider,
  Collapse,
  ListItemButton,
  List,
  ListItem,
  IconButton,
  Drawer,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
  Tabs
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { globalApiSlice } from '@/features/global/globalSlice'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ReadyOrder from '@/components/ReadyOrder/index.jsx'
import React, { useState, useRef, useEffect, useContext, createRef } from 'react'
import icon1 from '@/assets/icon/collection1.svg'
import icon2 from '@/assets/icon/discord.png'
import icon3 from '@/assets/icon/twitter.png'
import icon4 from '@/assets/icon/wz.png'

import Activity from 'components/Activity/index.jsx'
import { Empty } from '@/components/Mine/list/index'
import { Data } from '@/components/CollectionHome/index'

function Project() {
  const theme = useTheme()
  const lt = useLocation()
  const [value, setValue] = useState(1)
  const [activityVal, setActivityVal] = useState(1)
  const n = useNavigate()
  const handleChange = (e, v) => {
    setValue(v)
    console.log('v', v)
    postData({ value: v })
  }
  const [activitys, setActivitys] = useState({
    s: [],
    e: [],
    n: []
  })
  const [detail, setDetail] = useState({})
  const [data, setData] = useState({})
  const [series, setSeries] = useState([])
  const [seriesId, setSeriesId] = useState(0)
  const id = lt.search.replace(/\?id=/, '')

  const postData = async options => {
    const parent = { ...detail, ...options }
    if (!parent.id) return
    if (parent.value === 1) {
    }
    if (parent.value === 5) {
      const result = await globalApiSlice.articleNoticeApi({ "category": "project", "project_id": id, "page": 1, "per_page": 9999, "home_show": 0 })
      setData(result.data)
    }
  }

  useEffect(() => {
    globalApiSlice.projectDetailApi({ id }).then(detail => {
      setDetail(detail)
      globalApiSlice.projectSeriesApi({ id: detail.id, page: 1, per_page: 9999 }).then(result => {
        setSeries(result.data)
        if (result.data.length) setSeriesId(result.data[0].id)
      })
    })
  }, [])

  useEffect(() => {
    globalApiSlice.mergeApi({
      page: 1,
      per_page: 100,
      status: 1,
      project_id: id
    }).then(res => {
      setActivitys(v => ({ ...v, s: res.data }))
    })
    globalApiSlice.mergeApi({
      page: 1,
      per_page: 100,
      status: -1,
      project_id: id
    }).then(res => {
      setActivitys(v => ({ ...v, e: res.data }))
    })
    globalApiSlice.mergeApi({
      page: 1,
      per_page: 100,
      status: 0,
      project_id: id
    }).then(res => {
      setActivitys(v => ({ ...v, n: res.data }))
    })
  }, [])

  return (
    <Box className="container global-content-auto"
      onScroll={e => _.throttle(() => _.scrollFactory({
        dom: e.target,
        bottomFnc: () => { }
      }))}>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '20px auto' }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 1,
            ...theme.palette.boxShadow,
          }}
        >
          <Hidden smUp>
            <div
              style={{
                height: '158px',
                // backgroundColor: 'pink',
                width: '100%',
                backgroundImage: `url(${detail.background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            ></div>
          </Hidden>
          <Hidden smDown>
            <div
              style={{
                height: '158px',
                // backgroundColor: 'pink',
                width: '100%',
                backgroundImage: `url(${detail.background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            ></div>
          </Hidden>
          <Box
            sx={{
              position: 'absolute',
              height: 140,
              top: '88px',
              left: '58px',
              display: 'flex',
              '@media (max-width: 600px)': {
                left: '10px'
              }
            }}
          >
            <Avatar src={detail.avatar} sx={{ width: 90, height: 90 }} />
            <Box sx={{ ...theme.typobox.flexCenterLeft }}>
              <a href={detail.chain_browser} target="_blank" style={{ display: detail.chain_browser ? 'block' : 'none' }}>
                <img src={detail.chain_image_url} style={{ width: '25px', height: '25px', marginRight: '8px', borderRadius: '50%' }} />
              </a>
              <a href={detail.web_url} target="_blank" style={{ display: detail.web_url ? 'block' : 'none' }}>
                <img src={icon4} style={{ width: '25px', height: '25px', marginRight: '8px' }} />
              </a>
              <a href={detail.twitter} target="_blank" style={{ display: detail.twitter ? 'block' : 'none' }}>
                <img src={icon3} style={{ width: '25px', height: '25px', marginRight: '8px' }} />
              </a>
              <a href={detail.discord} target="_blank" style={{ display: detail.discord ? 'block' : 'none' }}>
                <img
                  src={icon2}
                  style={{ width: '25px', height: '25px', marginRight: '8px' }}
                />
              </a>
            </Box>
          </Box>
          <Box sx={{ padding: '20px 48px 22px', fontSize: 16 }} className='txt-p10'>
            <Box>
              <Box sx={{
                ...theme.palette.mainText,
                fontSize: { xs: 20, sm: 22 },
                mt: 2,
                mb: 1,
                ...theme.typobox.flexCenterLeft
              }}>
                {detail.name}
              </Box>
              <p className='why-medianone'>
                <span className="txt-color1">NFTs</span>{detail.data?.total_supply}{' '}
                <span className="txt-color">{$t('collection.cyz')}</span>{detail.data?.total_holders}{' '}
                <span className="txt-color">{$t('series.cyzfsd')}</span>{(detail.data?.total_holders_dispersion * 100).toFixed(2)}%
                <span className="txt-color">{$t('series.sjs')}</span>{detail.data?.total_market}
                <span className="txt-color">{$t('series.sjl')}</span>{(detail.data?.total_market_rate * 100).toFixed(2)}%
              </p>
              <Box
                className="global-text-nowrap-2"
                sx={{
                  position: 'relative',
                  ...theme.palette.text,
                  mb: 1,
                  textAlign: 'justify',
                  fontSize: { xs: 14, sm: 16 }
                }}>
                {detail.describe}
                <span onClick={() => _alert({ message: detail.describe })} style={{
                  color: 'rgba(36, 213, 186, 1)',
                  fontSize: 14,
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  fontWeight: 'bold'
                }}>...{$t('gd')}</span>
              </Box>
              <Box className='why-mediablock' style={{ display: 'none', backgroundColor: theme.palette.grey.bg, padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, mb: 2 }}>
                  <Box sx={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{detail.data?.total_supply}</span><span style={{ fontSize: '14px', color: '#888' }}>NFTs</span></Box>
                  <Box sx={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}><span>{detail.data?.total_holders}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('collection.cyz')}</span></Box>
                  <Box sx={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{(detail.data?.total_holders_dispersion * 100).toFixed(2)}%</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.cyzfsd')}</span></Box>
                </Box>
                <Box sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
                  <Box sx={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{detail.data?.total_market}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.sjs')}</span></Box>
                  <Box sx={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{(detail.data?.total_market_rate * 100).toFixed(2)}%</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.sjl')}</span></Box>
                  <Box sx={{ opacity: 0, flex: 1 }}> <span>{(detail.data?.total_market_rate * 100).toFixed(2)}%</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.sjl')}</span></Box>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '20px auto' }}
      >
        <Box className="tabs" style={{ width: '100%' }}>
          <TabContext value={value}>
            <Box>
              <Hidden smDown>
                <TabList
                  variant="scrollable"
                  aria-label="visible arrows tabs example"
                  onChange={handleChange} >
                  <Tab label={$t('public.hj')} value={1} />
                  <Tab label={$t('seriesTab[1].name')} value={2} />
                  <Tab label={$t('seriesTab[3].name')} value={5} />
                  <Tab label={$t('seriesTab[4].name')} value={4} />
                </TabList>
              </Hidden>
              <Hidden smUp>
                <TabList
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  allowScrollButtonsMobile
                  onChange={handleChange} >
                  <Tab label={$t('public.hj')} value={1} />
                  <Tab label={$t('seriesTab[1].name')} value={2} />
                  <Tab label={$t('seriesTab[3].name')} value={5} />
                  <Tab label={$t('seriesTab[4].name')} value={4} />
                </TabList>
              </Hidden>
            </Box>
            <TabPanel value={1} sx={{ padding: '10px 0', overflow: 'auto' }}>
              <Grid container item xs={12}>
                {[
                  {
                    label: '',
                    row: 1
                  },
                  {
                    label: $t('public.hj'),
                    row: 4
                  },
                  {
                    label: $t('home.dbj'),
                    row: 3.5
                  },
                  {
                    label: $t('home.jyl'),
                    row: 3.5
                  }
                ].map((v, i) =>
                  <Grid item sx={{
                    ...theme.typobox.flexCenterCenter,
                    ...{ fontSize: 14, margin: '20px 0' },
                    ...theme.palette.text,
                  }} xs={v.row} key={v.label}>{v.label}</Grid>
                )}
              </Grid>
              {
                Array.isArray(series) && value == 1 && series.length ?
                  series.map((v, i) =>
                    <Grid container key={i} item xs={12}
                      onClick={() => n('/collection-home?id=' + v.id)}
                      sx={{
                        ...theme.typobox.flexCenterCenter,
                        textAlign: 'center',
                        fontSize: { xs: 16, sm: 22 },
                        mb: 1
                      }}
                    >
                      <Grid item xs={1} >
                        {i + 1}
                      </Grid>
                      <Grid item xs={4} sx={{ ...theme.typobox.flexCenterCenter }} >
                        <Box sx={{
                          width: { xs: 40, sm: 60 },
                          height: { xs: 40, sm: 60 },
                          mr: 1,
                          backgroundImage: `url(${v.icon_url})`,
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}></Box>
                        <Box>{v.name}</Box>
                      </Grid>
                      <Grid item xs={3.5} >
                        <Box>{+v.statistics.floor_price}{v.currency}</Box>
                        <Box>{+v.statistics.change_price == 0 ? '--' : `${(v.statistics.change_price * 100).toFixed(2)}%`}</Box>
                      </Grid>
                      <Grid item xs={3.5} >
                        <Box>{+v.statistics.tx_amount}{v.currency}</Box>
                        <Box>{+v.statistics.change_amount == 0 ? '--' : `${(v.statistics.change_amount * 100).toFixed(2)}%`}</Box>
                      </Grid>
                    </Grid>
                  ) :
                  <Empty />
              }
            </TabPanel>
            <TabPanel value={2} sx={{ padding: '24px 0' }} className='media-padding5'>
              <Grid container item xs={12}>
                {
                  series.map(v => <Box
                    onClick={() => setSeriesId(v.id)}
                    sx={{
                      mr: { xs: 1, sm: 2 },
                      ...theme.typobox.flexCenterCenterByColumn
                    }}
                    key={v.id}>
                    <Box sx={{
                      width: { xs: 40, sm: 60 },
                      height: { xs: 40, sm: 60 },
                      mb: 1,
                      mt: 1,
                      backgroundImage: `url(${v.icon_url})`,
                      borderRadius: '50%',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}></Box>
                    <Box
                      sx={{
                        color: seriesId == v.id ? '#24D5BA' : ''
                      }}
                    >{v.name}</Box>
                  </Box>)
                }
              </Grid>
              {
                value == 2 ?
                  <Data key={seriesId} {...{ id: seriesId }} /> :
                  ''
              }
            </TabPanel>
            <TabPanel value={3} sx={{ padding: '24px 0' }}>
              3
            </TabPanel>
            <TabPanel value={4} sx={{ padding: '24px 0' }}>
              <Grid container item xs={12} >
                <Grid className="tabs" style={{ width: '100%' }} item xs={12}>
                  <TabContext value={activityVal}>
                    <Grid item xs={12} >
                      <TabList onChange={(e, v) => setActivityVal(v)}>
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.jxz')} value={1} />
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.wks')} value={2} />
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.yjs')} value={3} />
                      </TabList>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                      <TabPanel value={1} sx={{ padding: '24px 0' }}>
                        {activitys.s.map(v => <Activity key={v.id} {...v} project_id={id} />)}
                        {!activitys.s.length ? <Empty /> : ''}
                      </TabPanel>
                      <TabPanel value={2} sx={{ padding: '24px 0' }}>
                        {activitys.n.map(v => <Activity key={v.id} {...v} project_id={id} />)}
                        {!activitys.n.length ? <Empty /> : ''}

                      </TabPanel>
                      <TabPanel value={3} sx={{ padding: '24px 0' }}>
                        {activitys.e.map(v => <Activity key={v.id} {...v} project_id={id} />)}
                        {!activitys.e.length ? <Empty /> : ''}
                      </TabPanel>
                    </Grid>

                  </TabContext>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={5} sx={{ padding: '24px 0' }}>
              {
                value === 5 && Array.isArray(data) && data.length ?
                  data.map(v => <Grid
                    sx={{
                      p: 1,
                      borderRadius: 2,
                      ...theme.palette.warp.box
                    }}
                    item container xs={12} key={v.id}>
                    <Grid item xs={12} sx={{ fontSize: { xs: 18, sm: 22 }, mb: 1 }}>{v.title}</Grid>
                    <Grid container item xs={12} >
                      <Grid item xs={9} sx={{ ...theme.typobox.flexCenterLeft }}>
                        {v.series.slice(0, 2).map(s =>
                          <Box sx={{ ...theme.typobox.flexCenterLeft, mr: 1 }}>
                            <Box sx={{
                              width: { xs: 20, sm: 40 },
                              height: { xs: 20, sm: 40 },
                              mr: 1,
                              backgroundImage: `url(${s.info.icon_url})`,
                              borderRadius: '50%',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }}></Box>
                            <Box sx={{
                              fontSize: { xs: 14, sm: 16 }
                            }}>{s.info.name}</Box>
                          </Box>)}
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: 'right', ...theme.palette.text, fontSize: { xs: 14, sm: 16 } }}>
                        {m.unix(v.publish_time).format('MM/DD HH:mm')}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>{v.summary}</Grid>
                  </Grid>)
                  : <Empty />
              }
            </TabPanel>
          </TabContext>
        </Box>
      </Grid >
    </Box >
  )
}

export default Project
