import { useEffect, useState, useContext, createRef } from 'react'
import {
  Box, Grid, InputAdornment, Select, FormControl, MenuItem, useTheme,
  OutlinedInput, InputLabel, TextField, Button, NativeSelect, Hidden, Skeleton
} from '@mui/material'
import { globalApiSlice } from '@/features/global/globalSlice'
import { useNavigate, useLocation } from 'react-router-dom'
import utils from 'web3-utils'
import AppContext from '@/hooks/useAppContext'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import EventNoteIcon from '@mui/icons-material/EventNote'
import ethereumSendTransaction, { handleMsg } from '@/hooks/ethereumSendTransaction'
import {
  arbitrum,
  mainnet,
  polygon,
  polygonMumbai,
  goerli,
  arbitrumGoerli
} from '@wagmi/chains'
import cfxTest from "@/cfxTest.js"
import cfx from "@/cfx.js"
import * as echarts from 'echarts'
import ReadyOrder from '@/components/ReadyOrder/index.jsx'

const OPENWALLET = 1 // 开启交易
const PAYWALLET = 2 // 进行交易
const SUCCEEWALLET = 3 // 交易成功


function Line(p) {
  const theme = useTheme()
  const { useProductShowQuery, useProductDealLineQuery, useUserHashReturnQuery, useProductSellOrderQuery, useCollectionDynamicQuery, useProductBuyMessageQuery } = globalApiSlice
  const lt = useLocation()
  const id = lt.search.replace(/\?id=/, '')
  const [day, setDay] = useState(1)
  const d = useProductDealLineQuery({ id, day })
  useEffect(() => {
    if (d.data) {
      const chart = echarts.init(document.getElementById('main'))
      const o = {
        color: ['#91CC75',],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          confine: true,
          formatter: (params) => {
            return `${m.unix(params[0].name).format('YYYY/MM/DD HH:mm')}\n ${params[0].seriesName}：${params[0].data || '0'}`
          }
        },
        legend: {
          data: $t('pjjg'),
          show: false,
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 15,
          top: 40,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            formatter: (value, index) => {
              return m.unix(value).format(day == 1 ? 'HH:mm' : 'mm:ss')
            }
          }
        },
        yAxis: [{
          type: 'value',
          interval: 150,
          min: 0,
          max: 600,
          splitNumber: 5,
          axisLabel: {
            formatter: '{value}'
          }
        },],
        series: [{
          name: $t('pjjg'),
          data: [],
          type: 'line',
          tooltip: {
            valueFormatter: (value) => {
              return `${+value}${p?.price_message?.currency}`
            }
          },
        },

        ]
      }

      o.xAxis['data'] = d.data?.data?.map(v => v.time) || []
      o.series[0]['data'] = d.data?.data?.map(v => v.average) || []

      chart.setOption(o)
    }
  }, [d])

  return (
    <Grid container item xs={12}>
      <Grid xs={12} item sx={{ ...theme.typobox.flexCenterCenter, ...theme.palette.text }}>
        <Grid xs={6} item sx={{ ...theme.palette.mainText, mb: 2 }} className="global-font-weight-bold">{$t('collection.lscjj')}</Grid>
        <Grid xs={6} item sx={{ ...theme.typobox.flexCenterRight, ...theme.palette.text, fontSize: 16 }}>
          <Hidden smDown>
            {$('timeType').map(v =>
              <Grid className={`global-pointer ${v.key === day ? 'global-font-weight-bold' : ''}`} onClick={e => setDay(d => v.key)} sx={{ marginRight: 1.2, ...v.key == day ? theme.palette.mainText : {} }} key={v.name}>{v.name}</Grid>)}
          </Hidden>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', height: { xs: 350, sm: 500 } }} id="main"></Box>
    </Grid>
  )
}


function Collection() {

  const theme = useTheme()
  const [index, setIndex] = useState(3)
  const lt = useLocation()
  const n = useNavigate()
  const id = lt.search.replace(/\?id=/, '')
  const [ld, setLd] = useState(true)

  const { useProductShowQuery, useProductDealLineQuery, useProductBulkBuyMessageQuery, useUserHashReturnQuery, useProductSellOrderQuery, useCollectionDynamicQuery, useProductBuyMessageQuery } = globalApiSlice

  const [s, setS] = useState({
    p: {}, o: {}, d: {}, c: {}, day: 1, pg: 1
  })
  const [show, setShow] = useState(false)
  const [zyShow, setZyShow] = useState(false)
  const [item, setItem] = useState({})
  const [zyNum, setZyNum] = useState(1)
  const [sellNum, setSellNum] = useState(1)
  const [add, setAdd] = useState('')
  const [status, setStatus] = useState(0)
  const [sign, setSign] = useState('')
  const { store } = useContext(AppContext)
  const readyOrder = createRef()

  const p = useProductShowQuery({ id, address: store.ethereum?.address })
  const o = useProductSellOrderQuery({ id, page: 1 })
  const d = useProductDealLineQuery({ id, day: 1 })
  const c = useCollectionDynamicQuery({ id, id_type: 2, type: 0, page: 1 })
  const offer = globalApiSlice.useOfferOrdersQuery({ product_id: id, per_page: 9999, series_id: p?.data?.series_id, ckR: !!p?.data?.series_id })
  const PBM =
    (() => item.sign_type == 0 ? useProductBuyMessageQuery : useProductBulkBuyMessageQuery)()(
      item.sign_type == 0 ? {
        order_id: item.id,
        number: sellNum,
        ckR: !!(item.id && sellNum)
      } : {
        orders: [
          {
            order_id: item.id,
            number: sellNum,
          }
        ],
        ckR: !!(item.id && sellNum)
      })


  useUserHashReturnQuery({
    ids: PBM?.data?.trade_ids,
    type: 1,
    hash: sign,
    ckR: sign && status == 3
  })

  const succeeWallet = () => {
    _toast($t('public.success'))
    setShow(false)
    setStatus(0)
    console.log('s.o', _.clone(s.o))
    setS(v => (_.clone({ ...v, ...{ o: s.o?.data?.filter(i => i.id !== item.id) } })))
  }

  const payWallet = () => {
    window.ethereum.request({
      "method": "eth_estimateGas",
      "params": [{
        "from": window.ethereum.selectedAddress,
        "to": PBM.data.exchange_address,
        "data": PBM.data.data,
        "chainId": utils.numberToHex(PBM.data.chain_id),
        "value": PBM.data.value ? utils.numberToHex(+PBM.data.value) : '0x0',
      }, null]
    }).then(res => {

      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: window.ethereum.selectedAddress,
          to: PBM.data.exchange_address,
          value: PBM.data.value ? utils.numberToHex(PBM.data.value) : 0,
          data: PBM.data.data,
          gas: res
        }]
      }).then(res => {
        setStatus(3)
        setSign(res)
      }).catch(e => {
        handleMsg(e)
        setStatus(0)
      })
    }).catch(e => {
      handleMsg(e)
      setStatus(0)
    })
  }

  const openWallet = () => {
    if (!window.ethereum) {
      _toast($t('qz'))
      setStatus(0)
    } else if (!window.ethereum.selectedAddress) {
      _toast($t('pljqb'))
      setStatus(0)
    } else if (sellNum == 0) {
      _toast($t('public.qsrsl'))
      setStatus(0)
    } else if (PBM.data) {
      if (PBM.data.chain_id && (PBM.data.chain_id !== window.ethereum.networkVersion)) {
        window.ethereum.request({
          "method": "wallet_switchEthereumChain",
          "params": [{
            "chainId": utils.numberToHex(PBM.data.chain_id)
          }]
        }).then(res => { setStatus(2) }).catch((err) => {
          if (err.code == 4902) {
            window.ethereum.request({
              "method": "wallet_addEthereumChain",
              "params": [{
                '1030': cfx,
                '71': cfxTest,
                '42161': arbitrum,
                '421613': arbitrumGoerli,
                '1': mainnet,
                '5': goerli
              }[PBM.data.chain_id]]
            })
          }
          setStatus(0)
        })
      }
    } else if (!PBM.data && PBM.error) {
      _toast(PBM.error.message || PBM.error)
      setItem(v => ({}))
      setStatus(0)
      setShow(false)
    }
  }


  useEffect(() => {
    const handlePayByWallet = (type, callback) => {
      if (type === OPENWALLET) return openWallet() // 开启交易
      if (type === PAYWALLET) return payWallet() // 进行交易
      if (type === SUCCEEWALLET) return succeeWallet() // 交易成功
      return false
    }

    if (p.data && o.data) setS(v => (_.clone({ ...v, ...{ p: { ...p.data }, o: { ...o.data } } })))
    if (p.data && o.data && d.data) setS(v => (_.clone({ ...v, ...{ p: { ...p.data }, o: { ...o.data }, d: { ...d.data } } })))
    if (c.data) setS(v => _.clone({ ...v, ...{ c: c.data } }))

    handlePayByWallet(status)

    if (![p, o, d, c].filter(v => !v.data).length && ld) setLd(false)
  }, [p.data, o.data, d.data, c.data, status, PBM])

  const handleItem = v => {
    if (v.action.indexOf(5) > -1) {
      setItem(v)
      setShow(true)
    }
  }

  const zy = async () => {
    let eth = null
    if (store.ethereum?.address) {
      eth = store.ethereum
    } else {
      eth = await contentEthereum()
    }
    if (!zyNum) {
      _toast($t('public.qsrsl'))
      return
    }
    if (!add) {
      _toast($t('mas.qtxdz'))
      return
    }
    const res = await globalApiSlice.productTransferMessageApi({
      product_id: id,
      to: eth.address,
      quantity: zyNum
    })

    const sign = await ethereumSendTransaction({
      id: [res.transfer_id],
      type: 3,
      to: res.nft_contract_address,
      data: res.data,
      chain_id: res.chain_id
    })
    if (sign) {
      _toast($t('zycg'))
      setZyNum(v => '')
      setZyShow(false)
    } else {
      _toast($t('转移失败'))
    }
  }

  const addShop = () => {
    console.log('333333')
  }

  const chujia = () => {
    const data = p.data
    const body = {
      id: data.id,
      max: data.offer.highest_price || '-',
      min: data.offer.floor_price || '-x',
      name: data.name,
      collection_name: data.collection_name,
      token_id: data.token_id,
      img: data.sourcefile_url,
      pay_coin: data.pay_coin,
      series_id: data.series_id,
      type: 2
    }
    readyOrder.current.open(body)
  }

  return (
    <Box className="global-position-relative" sx={{ ...theme.components.layoutMainBox, ...theme.palette.warp, overflowX: 'scroll' }}>
      <Grid container item xs={12} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: { xs: '100%', sm: 400 },
              height: { xs: 400, sm: 390 },
              ...theme.components.backgroundIcon
            }}>
            <Hidden smDown>
              <Box sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${s.p.sourcefile_url})`,
                ...theme.components.backgroundIcon,
                backgroundSize: { xs: '100%', sm: 'cover' }
              }}></Box>
            </Hidden>
            <Hidden smUp>
              <img className='global-img' src={s.p.sourcefile_url} />
            </Hidden>
          </Box>
        </Grid>
        <Grid item xs={11} sx={{ margin: '0 auto' }} md={7}>
          <Box sx={{ color: '#24D5BA', fontSize: { xs: 20, sm: 20 } }}>
            {s.p.name?.replace(`#${s.p.token_id}`, '') === s.p.collection_name ? '#' + s.p.token_id :
              s.p.name
            }
          </Box>
          <Box sx={{ ...theme.palette.mainText, ...{ color: theme.palette.text, fontSize: { xs: 12, sm: 14 }, textAlign: 'justify' } }}>{s.p.describe}</Box>
          <Box sx={theme.typobox.flexCenterLeft}>
            <Box sx={{ borderRadius: '50%', width: 22, height: 22, background: '#24D5BA', margin: '10px 0', backgroundImage: `url(${s.p.icon_url})`, marginRight: 1, ...theme.components.backgroundIcon }}></Box>
            <Box onClick={() => n('/collection-home?id=' + s.p.series_id)} sx={{ color: theme.mainText, fontSize: 16 }}>{s.p.collection_name}</Box>
          </Box>
          <Box sx={theme.typobox.flexCenterLeft}>
            <Box sx={{ ...theme.palette.text, fontSize: 16, marginRight: 1 }}>{$t('collection.cyz')}</Box>
            <Box sx={{ ...theme.palette.mainText, ...theme.typobox.flexCenterCenter }}>
              {s?.p?.holds?.substring(0, 6) + '***' + s?.p?.holds?.substring(s?.p?.holds.length - 4)}
              <ContentCopyRoundedIcon
                sx={{
                  fontSize: '20px',
                  color: '#5F6269',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => copyToClipboard(s?.p?.holds)}
              />
            </Box>
          </Box>
          <Box sx={{ ...{ height: 158, padding: '20px', margin: '20px 0', background: theme.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8', ...theme.palette.text } }}>
            <Grid container sx={{ ...theme.typobox.flexCenterSpaceBetween, ...{ fontSize: 14 } }}>
              <Grid lg={6} item sx={theme.typobox.flexCenterLeft}><Box sx={theme.typobox.flexCenterLeft}>{$t('zdsjlz')} <Box sx={{ borderRadius: '50%', width: 22, height: 22, ml: 1, background: '#24D5BA' }}><img className='global-img' src={s.p.chain_image_url} /></Box></Box></Grid>
              <Grid lg={5} sx={{ ...theme.palette.text, textAlign: 'center' }} item>{$t('public.time')}</Grid>
            </Grid>

            <Grid container
              className='global-font-weight-bold'
              sx={{
                ...theme.typobox.flexCenterSpaceBetween,
                ...{ fontSize: 14, borderBottom: `1px solid ${theme.palette.theme.color}`, padding: '5px 0' }
              }}>
              <Grid item lg={6} sx={{ fontSize: { xs: 24, sm: 28 } }}>{s.p?.price_message?.price ? `${s.p?.price_message?.price} ${s.p?.price_message?.currency}` : '-'}</Grid>
              <Grid item lg={5} sx={{ ...theme.palette.mainText, textAlign: 'center', fontSize: { xs: 12, sm: 16 } }}>{s.p?.price_message?.time ? m.unix(s.p?.price_message?.time).format('YYYY/MM/DD HH:mm:ss') : '-'}</Grid>
            </Grid>
            <Grid container sx={{ fontSize: 16, margin: '10px 0' }}>
              <Grid item sx={{ ...theme.palette.text, marginRight: 2 }}>{$t('collection.qbsjpjjg')}</Grid>
              <Grid item>{d.average || 0} {s.p.pay_coin}</Grid>
            </Grid>
          </Box>
          <Box sx={theme.typobox.flexCenterCenter}>
            {
              s.p?.actions?.indexOf(6) > -1 ? s.p?.actions?.indexOf(1) > -1 ?
                <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, width: '100%' }}>
                  <Box className="global-button" onClick={() => setZyShow(true)} sx={{ ...theme.palette.btn.login, width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('jyStatus.10')}</Box>
                  <Box className="global-button" onClick={() => chujia()} sx={{ ...theme.palette.btn.login, width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('cj')}</Box>
                </Box> :
                <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, width: '100%' }}>
                  <Box className="global-button" sx={{ background: '#666666', color: '#fff', width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('bkgm')}</Box>
                  <Box className="global-button" onClick={() => chujia()} sx={{ ...theme.palette.btn.login, width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('cj')}</Box>
                </Box> :
                <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, width: '100%' }}>
                  <Box className="global-button" onClick={() => setIndex(1) & $('html').animate({ scrollTop: $('#xxxxx')[0].offsetTop })} sx={{ ...theme.palette.btn.login, width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('collection.ckzsdd')}</Box>
                  <Box className="global-button" onClick={() => chujia()} sx={{ ...theme.palette.btn.login, width: '48%', height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('cj')}</Box>
                </Box>
            }
          </Box>
        </Grid >
      </Grid >
      <Grid id='xxxxx' container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '50px auto', mb: 5, fontSize: 14 }}>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, padding: '0px 0', borderBottom: `1px solid ${theme.palette.theme.color}` }}>
          <Box onClick={() => setIndex(0)} className={`${index == 0 ? 'global-font-weight-bold' : ''} global-position-relative`} sx={{ pb: 2, fontSize: index === 0 ? { xs: 16, sm: 18 } : { xs: 14, sm: 16 } }}>{$t('collection.tabList[0].name')}{index == 0 ? <Box sx={{ width: '100%', position: 'absolute', bottom: -1, height: 3, background: 'linear-gradient(90deg, #00F6D0, #0077F5);' }}></Box> : ''}</Box>
          <Box onClick={() => setIndex(1)} className={`${index == 1 ? 'global-font-weight-bold' : ''} global-position-relative`} sx={{ pb: 2, fontSize: index === 1 ? { xs: 16, sm: 18 } : { xs: 14, sm: 16 } }}>{$t('collection.tabList[1].name')}{index == 1 ? <Box sx={{ width: '100%', position: 'absolute', bottom: -1, height: 3, background: 'linear-gradient(90deg, #00F6D0, #0077F5);' }}></Box> : ''}</Box>
          <Box onClick={() => setIndex(3)} className={`${index == 3 ? 'global-font-weight-bold' : ''} global-position-relative`} sx={{ pb: 2, fontSize: index === 3 ? { xs: 16, sm: 18 } : { xs: 14, sm: 16 } }}>{$t('collection.tabList[3].name')}{index == 3 ? <Box sx={{ width: '100%', position: 'absolute', bottom: -1, height: 3, background: 'linear-gradient(90deg, #00F6D0, #0077F5);' }}></Box> : ''}</Box>
          <Box onClick={() => setIndex(2)} className={`${index == 2 ? 'global-font-weight-bold' : ''} global-position-relative`} sx={{ pb: 2, fontSize: index === 2 ? { xs: 16, sm: 18 } : { xs: 14, sm: 16 } }}>{$t('collection.tabList[2].name')}{index == 2 ? <Box sx={{ width: '100%', position: 'absolute', bottom: -1, height: 3, background: 'linear-gradient(90deg, #00F6D0, #0077F5);' }}></Box> : ''}</Box>
        </Grid>
        <Grid item xs={11} sm={12} sx={{ margin: { xs: '10px auto', sm: '50px auto' }, overflowX: { xs: 'auto', sm: 'hidden' } }}>
          {
            index === 0 ?
              (<Grid item xs={12}>
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, margin: '10px 0' }}>
                  <Box>{$t('collection.cyz')}</Box>
                  <Box sx={theme.typobox.flexCenterCenter}>
                    {s.p?.holds?.substring(0, 6) + '***' + s.p?.holds?.substring(s.p?.holds.length - 4)}
                    <ContentCopyRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#5F6269',
                        marginLeft: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard(s?.p?.holds)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, margin: '10px 0' }}>
                  <Box>{$t('collection.czz')}</Box>
                  <Box sx={theme.typobox.flexCenterCenter}>
                    {s.p?.user_address?.substring(0, 6) + '***' + s.p?.user_address?.substring(s.p?.user_address.length - 4)}
                    <ContentCopyRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#5F6269',
                        marginLeft: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard(s.p?.user_address)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, margin: '10px 0' }}>
                  {s.p?.attribute?.length ? <Box className="global-font-weight-bold">{$t('collection.sx')}</Box> : ''}
                </Grid>
                {s.p?.attribute?.map(v => <Grid key={v.id} item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.text, margin: '10px 0' }}>
                  <Box>{v.trait_type}</Box>
                  <Box sx={theme.palette.mainText}>{v.value}</Box>
                </Grid>)}
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, margin: '10px 0' }}>
                  <Box className="global-font-weight-bold">{$t('collection.jbxx')}</Box>
                </Grid>
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, margin: '10px 0', fontSize: { xs: 12, sm: 16 } }}>
                  <Grid sx={theme.typobox.flexCenterCenterByColumn} item>
                    <Grid sx={{ ...theme.palette.text, ...{ m: 1 } }} item>{$t('collection.hydz')}</Grid>
                    <Grid item sx={theme.typobox.flexCenterCenter}>
                      {s.p?.symbol_address?.substring(0, 6) + '***' + s.p?.symbol_address?.substring(s.p?.symbol_address.length - 4)}
                      <ContentCopyRoundedIcon
                        sx={{
                          fontSize: '20px',
                          color: '#5F6269',
                          marginLeft: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => copyToClipboard(s?.p?.symbol_address)}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={theme.typobox.flexCenterCenterByColumn} item>
                    <Grid sx={{ ...theme.palette.text, ...{ m: 1 } }} item>{$t('public.network')}</Grid>
                    <Grid item>{s.p.net_name}</Grid>
                  </Grid>
                  <Grid sx={theme.typobox.flexCenterCenterByColumn} item>
                    <Grid sx={{ ...theme.palette.text, ...{ m: 1 } }} item>{$t('collection.dbbh')}</Grid>
                    <Grid item>{s.p.token_id}</Grid>
                  </Grid>
                  <Grid sx={theme.typobox.flexCenterCenterByColumn} item>
                    <Grid sx={{ ...theme.palette.text, ...{ m: 1 } }} item>{$t('public.tokenStandards')}</Grid>
                    <Grid item>{s.p.protocol}</Grid>
                  </Grid>
                </Grid>
              </Grid>)
              : index === 1 ? (
                <Grid container item xs={12} sx={{ width: { xs: 700, sm: '100%' }, maxWidth: { xs: 'initial', sm: '100%' } }}>
                  {[$t('cp'), $t('price'), $t('collection.orderfrom'), $t('collection.from'), $t('collection.endtime'), $t('public.Status')].map((v, i) =>
                    <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0' }, ...theme.palette.text, ...(i === 5 ? theme.typobox.flexCenterRight : theme.typobox.flexCenterCenter) }} xs={2} key={v}>{v}</Grid>
                  )}
                  <Hidden smUp>
                    <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0', position: 'absolute', right: 20, zIndex: 2, width: 200, background: theme.palette.mode == 'dark' ? `#191919` : '#fff' }, ...theme.palette.text, ...theme.typobox.flexCenterRight }} xs={2}>{$t('public.Status')}</Grid>
                  </Hidden>
                  {s.o?.data?.map((v, i) =>
                    <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0', width: { xs: 700, sm: '100%' }, maxWidth: { xs: 'initial', sm: '100%' } }, ...theme.palette.text }} sm={12} key={v.id}>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterLeft }} xs={2} >
                        {s.p.name}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterCenter }} xs={2} >
                        {v.price} {v.currency}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterCenter }} xs={2} >
                        {v?.address?.substring(0, 6) + '***' + v?.address?.substring(v?.address.length - 4)}
                        <ContentCopyRoundedIcon
                          sx={{
                            fontSize: '20px',
                            color: '#5F6269',
                            marginLeft: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyToClipboard(v.address)}
                        />
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterCenter }} xs={2} >
                        <Box sx={{ width: 20, height: 20 }}><img className='global-img' src={v.avatar} /></Box>
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16, textAlign: 'center' }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterCenter }} xs={2} >
                        {m.unix(v?.closedtime).format('YYYY/MM/DD HH:mm:ss')}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText, ...theme.typobox.flexCenterRight }} xs={2} >
                        <Box onClick={() => handleItem(v)} sx={{ width: 60, height: 24, background: '#DEF9F5', borderRadius: '5px', color: '#24D5BA', ...theme.typobox.flexCenterCenter }}>
                          {v?.action.map(v => v == 5 ? $t('collection.buy') : $t('jyStatus.5'))}
                        </Box>
                      </Grid>
                      <Hidden smUp>
                        <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', position: 'absolute', right: 25, zIndex: 2, width: 200, height: 100, background: theme.palette.mode == 'dark' ? `#191919` : '#fff' }, ...theme.palette.mainText, ...theme.typobox.flexCenterRight }} xs={2} >
                          <Box onClick={() => handleItem(v)} sx={{ width: 60, height: 24, background: '#DEF9F5', borderRadius: '5px', color: '#24D5BA', ...theme.typobox.flexCenterCenter }}>
                            {v?.action.map(v => v == 5 ? $t('collection.buy') : $t('jyStatus.5'))}
                          </Box>
                        </Grid>
                      </Hidden>
                    </Grid>
                  )}
                  {
                    s.o?.data?.length == 0 ? <Box sx={{ height: 120, width: { xs: 'calc(100vw - 60px)', sm: '100%' }, ...theme.typobox.flexCenterCenter }}>
                      <EventNoteIcon sx={{ mr: 1 }} />
                      {$t('nodata')}
                    </Box> : ''
                  }
                </Grid>
              ) : index === 3 ? (
                <Grid container item xs={12} sx={{
                  width: { xs: 700, sm: '100%' },
                  maxWidth: { xs: '100%', sm: '100%' },
                  ...theme.typobox.flexCenterSpaceBetween
                }}>
                  {
                    offer?.data?.data?.map(v => (
                      <Grid container item xs={12} sm={5.8} sx={{
                        background: theme.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8',
                        p: 1,
                        mb: 1,
                        borderRadius: '4px',
                        ...theme.palette.text
                      }} >
                        <Grid item xs={12} sx={{ ...theme.palette.mainText, fontSize: { xs: 18, sm: 22 } }}>{Number(v.price)}  {v.symbol.symbol}</Grid>
                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>过期时间</Grid>
                        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
                          <Box sx={{ ...theme.palette.mainText }}>{m.unix(v.expire_time).format('YYYY/MM/DD HH:mm')}</Box>
                          <Box sx={{ ...theme.typobox.flexCenterCenter }}>订单来源: <Box sx={{
                            backgroundImage: `url(${v.buyer.member.avatar})`,
                            backgroundSize: '100%',
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            ml: 1
                          }} /></Box>
                        </Grid>
                      </Grid>
                    ))
                  }
                  {
                    offer?.data?.length == 0 ? <Box sx={{ height: 120, width: { xs: 'calc(100vw - 60px)', sm: '100%' }, ...theme.typobox.flexCenterCenter }}>
                      <EventNoteIcon sx={{ mr: 1 }} />
                      {$t('nodata')}
                    </Box> : ''
                  }
                </Grid>
              ) :
                <Grid container item xs={12} sx={{ width: { xs: 700, sm: '100%' }, maxWidth: 'initial' }}>
                  {[$t('cp'), $t('price'), $t('collection.from'), $t('public.time'), $t('public.Status')].map((v, i) =>
                    <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0' }, ...theme.palette.text, ...(i === 4 ? theme.typobox.flexCenterRight : theme.typobox.flexCenterCenter) }} xs={2.4} key={v}>{v}</Grid>
                  )}
                  {s.c?.data?.map(v =>
                    <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0' }, ...theme.palette.text }} xs={12} key={v.id}>
                      <Grid item sx={{ ...theme.typobox.flexCenterLeft, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={2.4} >
                        {s.p.name}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2.4} >
                        {v.price} {v.symbol}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2.4} >
                        {v?.from?.substring(0, 6) + '***' + v?.from?.substring(v?.from.length - 4)}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2.4} >
                        {m.unix(v?.createtime).format('YYYY/MM/DD HH:mm:ss')}
                      </Grid>
                      <Grid item sx={{ ...theme.typobox.flexCenterRight, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={2.4} >
                        {[$t('public.all'), '', '', $t('jyStatus.5'), $t('jyStatus.4'), $t('jyStatus.5'), $t('jyStatus.6'), $t('jyStatus.7'), $t('jyStatus.8'), $t('jyStatus.9'), $t('jyStatus.10')][v.type]}
                      </Grid>
                    </Grid>
                  )}
                  {
                    s.c?.data?.length == 0 ? <Box sx={{ height: 120, width: { xs: 'calc(100vw - 60px)', sm: '100%' }, ...theme.typobox.flexCenterCenter }}>
                      <EventNoteIcon sx={{ mr: 1 }} />
                      {$t('nodata')}
                    </Box> : ''
                  }
                </Grid>
          }
        </Grid>
      </Grid >
      {
        show ? <Grid className='global-position-fixed' sx={{
          top: 20,
          zIndex: 4,
        }} container>
          <Grid container onScroll={e => false} className='global-border-radius global-overflow-hidden'
            item xs={11} sm={8} sx={{
              margin: '0 auto',
              pl: 4,
              pr: 4,
              pt: 2,
              pb: 6,
              boxShadow: theme.palette.theme.boxShadow,
              ...theme.palette.warp.box,
              fontSize: 14,
            }}>
            <Grid item xs={12} lg={12} sx={{
              mb: 1,
              pb: 1,
              pt: 2,
              ...theme.palette.text,
              ...theme.typobox.flexCenterRight,
              borderBottom: `1px solid ${theme.palette.theme.color}`
            }}>
              <Box onClick={() => setShow(false)} sx={{ fontSize: { xs: 16, sm: 20 }, mr: 0 }}>{$t('gb')}</Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{
                width: '100%',
                height: { xs: 300, sm: 400 },
                backgroundImage: `url(${s.p.sourcefile_url})`,
                ...theme.components.backgroundIcon,
                backgroundSize: { xs: '100%', sm: '100%' }
              }}>
                {/* <img className="global-img" style={{ maxHeight: '100%' }} src={s.p.sourcefile_url} /> */}
              </Box>
            </Grid>
            <Grid container item sm={8} sx={{ fontSize: { xs: 18, sm: 22 }, mt: { xs: 2, sm: 0 } }} >
              <Grid xs={12} sm={12} sx={{ ...theme.typobox.flexCenterLeft, fontSize: { xs: 16, sm: 24 }, color: '#24D5BA', mt: { xs: 0, sm: 4 }, mb: 2, ml: 2 }} item>{s.p.name}<Box sx={{ fontSize: { xs: 14, sm: 16 }, ml: 2 }}>#{s.p.token_id}</Box></Grid>
              <Grid xs={12} sm={12} sx={{ fontSize: { xs: 14, sm: 20 }, ...theme.palette.text, mb: 2, ml: 2 }} item>{s.p.collection_name}</Grid>
              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 2 }}>
                <Box sx={{ ...theme.palette.text }}>{$t('public.price')}：</Box>
                <Box className="global-font-weight-bold" sx={{ ...theme.palette.mainText }}>{s.p?.price_message?.price} {s.p?.price_message?.currency}</Box>
              </Grid>
              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 1 }}>
                <Box sx={{ ...theme.palette.text }}>{$t('num')}：</Box>
                <TextField
                  type="number"
                  sx={theme.components.inputLabel}
                  variant="outlined"
                  size="small"
                  minRows={0}
                  onChange={e => Number(e.target.value) >= 0 ? setSellNum(Number(e.target.value) > Number(item.quantity) ? item.quantity : e.target.value.replace(/\b(0+)/gi, '')) : 0}
                  value={sellNum}
                />
              </Grid>
              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 2, justifyContent: { xs: 'space-between', sm: '' } }}>
                <Box sx={{ ...theme.palette.text, fontSize: { xs: 12, sm: 14 } }}>{$t('public.maxZZ')}: {item.quantity}</Box>
                <Hidden smUp>
                  <Box sx={{ ...theme.palette.text, fontSize: { xs: 12, sm: 18 }, ml: 2, float: 'right' }}>{$t('public.ygfy')}: <Box sx={{ ...theme.palette.mainText }}>{sellNum ? (s.p?.price_message?.price * sellNum).toFixed(6) : '----'} {s.p?.price_message?.currency}</Box></Box>
                </Hidden>
              </Grid>

              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 2 }}>
                <Box className="global-button" onClick={() => setStatus(1)} sx={{ ...theme.palette.btn.login, width: { xs: '100%', sm: 350 }, height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('collection.buy')}</Box>
                <Hidden smDown>
                  <Box sx={{ ...theme.palette.text, fontSize: { xs: 12, sm: 18 }, ml: 2 }}>{$t('public.ygfy')}: <Box sx={{ ...theme.palette.mainText }}>{sellNum ? (s.p?.price_message?.price * sellNum).toFixed(6) : '----'} {s.p?.price_message?.currency}</Box></Box>
                </Hidden>
              </Grid>
            </Grid>

          </Grid >
        </Grid > : ''
      }
      {
        zyShow ? <Grid className='global-position-fixed' sx={{
          top: 20,
          zIndex: 4,
        }} container>
          <Grid container onScroll={e => false} className='global-border-radius global-overflow-hidden'
            item xs={11} sm={8} sx={{
              margin: '0 auto',
              pl: 4,
              pr: 4,
              pt: 2,
              pb: 6,
              boxShadow: theme.palette.theme.boxShadow,
              ...theme.palette.warp.box,
              fontSize: 14,
            }}>
            <Grid item xs={12} lg={12} sx={{
              mb: 1,
              pb: 1,
              pt: 2,
              ...theme.palette.text,
              ...theme.typobox.flexCenterRight,
              borderBottom: `1px solid ${theme.palette.theme.color}`
            }}>
              <Box onClick={() => setZyShow(false)} sx={{ fontSize: { xs: 16, sm: 20 }, mr: 0 }}>{$t('gb')}</Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{
                width: '100%',
                height: { xs: 300, sm: 400 },
                backgroundImage: `url(${s.p.sourcefile_url})`,
                ...theme.components.backgroundIcon,
                backgroundSize: { xs: '100%', sm: '100%' }
              }}>
                {/* <img className="global-img" style={{ maxHeight: '100%' }} src={s.p.sourcefile_url} /> */}
              </Box>
            </Grid>
            <Grid container item sm={8} sx={{ fontSize: { xs: 18, sm: 22 }, mt: { xs: 2, sm: 0 } }} >
              <Grid xs={12} sm={12} sx={{ ...theme.typobox.flexCenterLeft, fontSize: { xs: 16, sm: 24 }, color: '#24D5BA', mt: { xs: 0, sm: 4 }, mb: 2, ml: 2 }} item>{s.p.name}<Box sx={{ fontSize: { xs: 14, sm: 16 }, ml: 2 }}>#{s.p.token_id}</Box></Grid>
              <Grid xs={12} sm={12} sx={{ fontSize: { xs: 14, sm: 20 }, ...theme.palette.text, mb: 2, ml: 2 }} item>{s.p.collection_name}</Grid>
              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 1 }}>
                <Box sx={{ ...theme.palette.text }}>{$t('num')}：</Box>
                <TextField
                  type="number"
                  sx={theme.components.inputLabel}
                  variant="outlined"
                  size="small"
                  minRows={0}
                  onChange={e => Number(e.target.value) >= 0 ? setZyNum(Number(e.target.value) > Number(s.p?.holds_count) ? s.p?.holds_count : e.target.value.replace(/\b(0+)/gi, '')) : 0}
                  value={zyNum}
                />
              </Grid>

              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 2, justifyContent: { xs: 'space-between', sm: '' } }}>
                <Box sx={{ ...theme.palette.text, fontSize: { xs: 12, sm: 14 } }}>{$t('yycps')}: {s.p?.holds_count}</Box>
              </Grid>

              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexStartLeftByColumn, ml: 2, mb: 2 }}>
                <Box sx={{ ...theme.palette.mainText, mb: 1, mt: 1, width: '100%' }}>{$t('zyzgqb')}：</Box>
                <TextField
                  label={$t('qsrdz')}
                  type="text"
                  sx={{
                    ...theme.components.inputLabel,
                    width: { xs: '100%', sm: 260 },
                    fontSize: { xs: 14 }
                  }}
                  variant="outlined"
                  onChange={e => setAdd(e.target.value)}
                  size="small"
                  value={add}
                  minRows={0}
                />
              </Grid>
              <Grid xs={12} sm={12} item sx={{ fontSize: { xs: 12, sm: 18 }, ...theme.typobox.flexCenterLeft, ml: 2, mb: 2 }}>
                <Box className="global-button" onClick={zy} sx={{ ...theme.palette.btn.login, width: { xs: '100%', sm: 350 }, height: { xs: 55, sm: 70 }, borderRadius: 50 }}>{$t('jyStatus.10')}</Box>
              </Grid>
            </Grid>

          </Grid >
        </Grid > : ''
      }
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '30px auto' }}>
        <Line {...s.p} />
      </Grid >
      {ld ? <Box className="global-pointer-events-none" sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, ...theme.palette.warp.box, ...theme.palette.mainText, zIndex: 2 }}>{[300, 40, 40, 40, 40].map((v, i) => <Skeleton variant="circle" animation='wave' height={v} key={i} sx={{ m: 4 }} />)}</Box> : ''}
      <ReadyOrder onRef={readyOrder} />
    </Box >
  )
}

export default Collection

function copyToClipboard(content) {
  const copyDom = document.createElement('textarea')
  copyDom.value = content
  copyDom.style.position = 'absolute'
  copyDom.style.top = '0'
  copyDom.style.left = '-9999px'
  document.body.appendChild(copyDom)

  copyDom.select()
  let successful = document.execCommand('copy')
  document.body.removeChild(copyDom)
  // console.log(successful,content);
  if (successful) _toast($t('public.copySuccess'))
}