import {
  Box,
  Grid,
  useTheme,
  Avatar,
  Typography,
  Button,
  Tab,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ListItemText,
  Checkbox,
  Divider,
  Collapse,
  ListItemButton,
  List,
  ListItem,
  IconButton,
  Drawer,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
  Tabs
} from '@mui/material'
import ReadyOrder from '@/components/ReadyOrder/index.jsx'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React, { useState, useRef, useEffect, useContext, createRef } from 'react'
import './collectionHome.css'
import icon1 from '@/assets/icon/collection1.svg'
import icon2 from '@/assets/icon/discord.png'
import icon3 from '@/assets/icon/twitter.png'
import icon4 from '@/assets/icon/wz.png'

import { useNavigate, useLocation } from 'react-router-dom'
import AppContext from '@/hooks/useAppContext'
import * as echarts from 'echarts'
// import { useNavigate } from 'react-router-dom'
import { globalApiSlice } from '@/features/global/globalSlice'
// import { makeStyles } from '@mui/styles';
import axios from 'axios'
import { JghjyeChart, SjhcyChart, JgfbChart } from './echarts.jsx'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import Activity from 'components/Activity/index.jsx'
import { Empty } from '@/components/Mine/list/index'

function Title(props) {
  const theme = useTheme()
  const WIDTH = props.width || 103
  return (
    <Grid container item xs={12} sm={12} md={12}>
      <Grid item>
        <Box
          sx={{
            fontSize: 36,
            '@media (max-width: 600px)': {
              fontSize: 20,
            },
          }}
        >
          {props.name}
        </Box>
        <Box
          sx={{
            height: { xs: 2, sm: 4, md: 6 },
            background: 'linear-gradient(90deg, #00F6D0, #0077F5);',
            width: WIDTH,
            marginTop: 1,
          }}
        ></Box>
      </Grid>
      {props.children}
    </Grid>
  )
}

// NFT item
function NftItem(props) {
  // console.log(props);
  const { setStore } = useContext(AppContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const add = (e, props) => {
    _.debounce(() => {
      console.log('e=', props)
      globalApiSlice.shoppingCartAddApi({ product_id: props.id, token_id: props.token_id }).then((result) => {
        if (result) setStore(v => ({ ...v, shoppingNum: result.shopping_cart })) & props.shoppingcart(props)
      })
    })
    e.stopPropagation()
  }
  return (
    <Box
      className="global-border-radius global-overflow-hidden global-position-relative"
      onClick={() => navigate('/collection?id=' + props.id)}
      sx={{
        width: 220,
        height: 220,

        ...theme.palette.boxShadow,
        ...theme.palette.warp,
        marginTop: 3,

        pb: 2,
        cursor: 'pointer',
        // mr: 3,
        '@media (max-width: 600px)': {
          width: '48.5%',
          height: '180px',
          mt: 1
        },
      }}
    >
      <Box sx={{ right: 0, p: '10px', zIndex: 9 }} onClick={e => add(e, props)} className="global-position-absolute">
        {
          props.shopping_cart ? <RemoveShoppingCartIcon sx={{ color: '#50DDC8', filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,.6))' }} /> : <AddShoppingCartIcon sx={{ color: '#50DDC8', filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,.6))' }} />
        }
      </Box>
      <Box sx={{
        width: 1, height: 220, position: 'relative', '@media (max-width: 600px)': {
          height: '180px'
        },
      }}>
        <img
          className="global-img"
          style={{ height: '100%' }}
          src={props.sourcefile_url}
        />

        <Box
          sx={{
            ...{
              padding: '12px',
              '@media (max-width: 600px)': {
                padding: '5px'
              },
              fontSize: 16,
              color: '#fff',
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '45%',
              width: 1,
              bgcolor: 'rgba(0,0,0,.2)',
            },
          }}
        >
          <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis', }} className='media-title16'>
            {props.name.replace(`#${props.token_id}`, '') === props.collection_name ? '#' + props.token_id :
              props.name
            }
          </p>
          <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis', }} className='media-title14'>{props.collection_name}</p>
          <p
            style={{
              fontSize: 16,
              position: 'absolute',
              right: '12px',
              bottom: '8px',
              margin: 0,
              '@media (max-width: 600px)': {
                left: 0,
                bottom: '5px'
              },
            }}
            className='media-title12'
          >
            价格：{!!Number(props.floor_price) ? props.floor_price : '--'}{props.symbol}
          </p>
        </Box>
      </Box>
    </Box>
  )
}
// 折叠
const TextExpandCollapse = ({ text, maxLength }) => {
  if (text) {
    const [expanded, setExpanded] = useState(false)

    const handleExpandCollapse = () => {
      setExpanded(!expanded)
    }

    return (
      <div>
        <Typography variant="body1" style={{ fontSize: 16 }}>
          {expanded
            ? text
            : `${text.slice(0, maxLength)}${text.length > maxLength ? '...' : ''
            }`}
        </Typography>
        {text.length > maxLength && (
          <div style={{ position: 'relative' }}>
            <span
              onClick={handleExpandCollapse}
              style={{
                color: 'rgba(36, 213, 186, 1)',
                fontSize: 14,
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                position: 'absolute',
                bottom: 0,
                right: 0,
                // '@media (max-width: 600px)': {
                //   position: 'relative',
                //   left:'50%'
                // },

              }}
            >
              {expanded ? $t('sq') : $t('zkydgd')}
            </span>
          </div>
        )}
      </div>
    )
  }
}
// nft面板
const Nft = (props) => {
  // console.log(props);
  const theme = useTheme()
  let [attributeList, setAttributeList] = React.useState([])
  const [state, setState] = useState({
    id: props.id,
    sort: 2, //排序方式
    search: '',
    filter_owner: null,//持有者
    filter_status: [],//状态
    filter_net: [], //主网ID列表
    filter_type: [], //类型ID列表
    filter_price_low: '', //最低价
    filter_price_high: '', //最高价
    filter_coin: '', //币种 
    filter_rare_low: '', //稀有度排名最小值
    filter_rare_height: '', //稀有度排名最大值 
    per_page: 20, //每页数
    page: props.page, //页码

  })
  // let [ filter_attribute, setFilter_attribute] = React.useState('')
  let filter_attribute = ''

  const handleChange = (event) => {
    props.setSt(0)
    props.setPage(1)
    setState(v => ({ ...v, sort: event.target.value, page: 1 }))
    // console.log(state);
  }
  const handleChangeh5 = (event) => {
    // setSorth5(event.target.value)
    props.setPage(1)
    setState(v => ({ ...v, sort: event.target.value, page: 1 }))
    // 参数慢一步同步的感觉
    // submit()
    delParams(event.target.value)
  }
  const handleChangeFilterStatus = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_status: [event.target.value], page: 1 }))
  }
  const handleChangeFilterOwner = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_owner: event.target.value, page: 1 }))
  }
  const handleChangeFilterPriceLow = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_price_low: +event.target.value, page: 1 }))
  }
  const handleChangeFilterPriceHigh = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_price_high: +event.target.value, page: 1 }))
  }
  const handleChangeFilterRareLow = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_rare_low: +event.target.value, page: 1 }))
  }
  const handleChangeFilterRareHigh = (event) => {
    props.setPage(1)
    setState(v => ({ ...v, filter_rare_height: +event.target.value, page: 1 }))
  }
  // 属性
  const [selectedIds, setSelectedIds] = useState([])
  const [openGroup, setOpenGroup] = useState(null)
  const handleMenuItemClick = (id) => () => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }
  // 属性框折叠、展开
  const handleClick = (groupName) => () => {
    setOpenGroup(openGroup === groupName ? null : groupName)
  }
  const handleChangeAttribute = (event) => {
    setSelectedIds(event.target.value)
  }
  const selectedIdsString = selectedIds.join(',').replace(/^,/, '')

  const [open, setOpen] = useState(false)
  // const sorts = [
  //   { value: 1, label: '价格从高到低' },
  //   { value: 2, label: '价格从低到高' },
  //   { value: 3, label: '稀有度从高到低' },
  //   { value: 4, label: '最新上架' },
  //   { value: 5, label: '最新成交' }
  // ]
  const sorts = $t('market.hjsortTypes').map(v => ({ value: v.key, label: v.name }))
  const {
    useAttributeListQuery,
    useCollectionProductListQuery,
  } = globalApiSlice

  attributeList = useAttributeListQuery({ series_id: props.id })

  let [collectionProductList, setCollectionProductList] = useState({})
  // 是否触发获取nft列表信号
  let [collectionProductListFlag, setCollectionProductListFlag] = useState(true)
  // console.log(collectionProductList);
  const [changedParams, setChangedParams] = useState({
    id: props.id,
    sort: state.sort,
    per_page: state.per_page,
    page: props.page
  })

  const CPL = useCollectionProductListQuery({ ...changedParams, page: props.page })

  //  判断请求参数是否变化
  const delParams = (data) => {
    // 有data表示是h5的排序方式单独发的请求
    if (data) {
      setChangedParams(v => ({ ...v, sort: data }))
      setCollectionProductListFlag(true)
      return
    }
    if (state.sort !== 2) {
      setChangedParams(v => ({ ...v, sort: state.sort }))
    }
    if (state.filter_coin !== '') {
      setChangedParams(v => ({ ...v, filter_coin: state.filter_coin }))
    }
    if (state.search !== '') {
      setChangedParams(v => ({ ...v, search: state.search }))
    }
    if (state.filter_owner !== null) {
      setChangedParams(v => ({ ...v, filter_owner: state.filter_owner }))
    }
    if (state.filter_status.length > 0) {
      setChangedParams(v => ({ ...v, filter_status: state.filter_status }))

    }
    if (state.filter_net.length > 0) {
      setChangedParams(v => ({ ...v, filter_net: state.filter_net }))

    }
    if (state.filter_type.length > 0) {
      setChangedParams(v => ({ ...v, filter_type: state.filter_type }))

    }
    if (state.filter_price_low !== '') {
      setChangedParams(v => ({ ...v, filter_price_low: state.filter_price_low }))
      // changedParams.filter_price_low = state.filter_price_low;
    }
    if (state.filter_price_high !== '') {
      setChangedParams(v => ({ ...v, filter_price_high: state.filter_price_high }))
      // changedParams.filter_price_high = state.filter_price_high;
    }
    if (state.filter_rare_low !== '') {
      setChangedParams(v => ({ ...v, filter_rare_low: state.filter_rare_low }))
      // changedParams.filter_rare_low = state.filter_rare_low;
    }
    if (state.filter_rare_height !== '') {
      setChangedParams(v => ({ ...v, filter_rare_height: state.filter_rare_height }))
      // changedParams.filter_rare_height = state.filter_rare_height;
    }
    if (filter_attribute !== '') {
      setChangedParams(v => ({ ...v, filter_attribute: filter_attribute }))
      // changedParams.filter_attribute = selectedIdsString;
    }
    console.log(1111111)
    console.log(changedParams)
    // 更改信号
    setCollectionProductListFlag(true)
  }


  // 确定 发送请求
  const submit = () => {
    filter_attribute = selectedIdsString
    delParams()
    setOpen(false)

  }
  // 清除
  const release = async () => {
    setCollectionProductListFlag(true)
    setChangedParams({
      id: props.id,
      sort: 2,
      per_page: state.per_page,
      page: state.page,
      ckR: true
    })

    setState(v => ({
      ...v,
      sort: 2, //排序方式
      search: '',
      filter_owner: null,//持有者
      filter_status: [],//状态
      filter_net: [], //主网ID列表
      filter_type: [], //类型ID列表
      filter_price_low: '', //最低价
      filter_price_high: '', //最高价
      filter_coin: '', //币种 
      filter_rare_low: '', //稀有度排名最小值
      filter_rare_height: '', //稀有度排名最大值 
    }))
    setSelectedIds([]) // 重置选中的属性
    filter_attribute = '' // 重置选中的属性
    setOpen(false)

  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log(e)
      if (e.target.value == '') {
        release()
      }
      // 再发送请求
      delParams()
    }
  }
  useEffect(() => {
    if (collectionProductList && props.page == 1) setCollectionProductList([])
    if (CPL.data && CPL.status == 'fulfilled') props.setSt(0) & setCollectionProductList(v => v.concat(CPL.data.data))
    if (CPL.data && CPL.status == 'fulfilled' && !CPL.data.data.length) props.setSt(2)
    if (attributeList.data) setAttributeList(v => v)
  }, [attributeList.data, CPL.data, props.page])
  const shoppingcart = p => {
    setCollectionProductList(v => {
      return v.map(v => v.id == p.id ? { ...v, shopping_cart: !v.shopping_cart } : v)
    })
  }
  return (
    // 筛选
    <Box>
      {/* pc */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',

        }}
        className='why-medianone'
      >
        {/* 筛选 */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '78%' }}>
          <FormControl
            size="small"
            sx={{ marginRight: '10px', mt: 1 }}
            className="size16"
          >
            <InputLabel id="sort">{$t('sortType')}</InputLabel>
            <Select
              labelId="sort"
              id="sortSelect"
              value={state.sort}
              label={$t('sortType')}
              onChange={handleChange}
            >
              {sorts.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ marginRight: '10px', mt: 1 }}>
            <InputLabel id="filter_status">{$t('cpzt')}</InputLabel>
            <Select
              labelId="filter_status"
              id="filter_status"
              value={state.filter_status}
              label={$t('cpzt')}
              sx={{ minWidth: 130 }}
              onChange={handleChangeFilterStatus}
            >

              <MenuItem value={1}>{$t('sellStatus')[2].name}</MenuItem>
              <MenuItem value={0}>{$t('yxj')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ marginRight: '10px', mt: 1 }}>
            <InputLabel id="filter_owner">{$t('collection.cyz')}</InputLabel>
            <Select
              labelId="filter_owner"
              id="filter_owner"
              value={state.filter_owner}
              label={$t('collection.cyz')}
              onChange={handleChangeFilterOwner}
              sx={{ minWidth: 130 }}

            >

              <MenuItem value={1}>{$t('tabbar.me')}</MenuItem>
              <MenuItem value={0}>{$t('public.all')}</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Grid item sx={theme.typobox.flexCenterLeft}>
              <span style={{ fontSize: '18px', color: '#666' }}>{$t('market.priceRange')}：</span>
              <TextField
                label={$t('market.minPrice')}
                type="number"
                sx={{ fontSize: '16px', color: '#999' }}
                variant="outlined"
                size="small"
                style={{ width: '120px' }}
                value={state.filter_price_low}
                onChange={handleChangeFilterPriceLow}
              />
            </Grid>
            <Grid item sx={theme.typobox.flexCenterCenter}>
              ~
            </Grid>
            <Grid item>
              <TextField
                label={$t('market.maxPrice')}
                sx={theme.components.inputLabel}
                type="number"
                variant="outlined"
                size="small"
                style={{ width: '120px' }}
                value={state.filter_price_high}
                onChange={handleChangeFilterPriceHigh}
              />
            </Grid>
          </Box>
          {/* <Box sx={{ display: 'flex' ,mt:1,mr:'10px'}}>
            <Grid item sx={theme.typobox.flexCenterLeft}>
              <span style={{fontSize:'18px',color:'#666'}}>稀有度排名：</span>
              <TextField
                label="最小值"
                type="number"
                sx={theme.components.inputLabel}
                variant="outlined"
                size="small"
                style={{width:'120px'}}
                value={state.filter_rare_low}
                onChange={handleChangeFilterRareLow}
              />
            </Grid>
            <Grid item sx={theme.typobox.flexCenterCenter}>
              ~
            </Grid>
            <Grid item>
              <TextField
                label="最大值"
                sx={theme.components.inputLabel}
                type="number"
                variant="outlined"
                size="small"
                style={{width:'120px'}}
                value={state.filter_rare_height}
                onChange={handleChangeFilterRareHigh}
              />
            </Grid>
          </Box> */}
          {/* 属性 */}
          <FormControl size="small" sx={{ marginRight: '10px', mt: 1 }}>
            <InputLabel id="attribute">{$t('collection.sx')}</InputLabel>

            <Select
              multiple
              id="attribute"
              value={selectedIds}
              sx={{ width: 300 }}
              onChange={handleChangeAttribute}
              renderValue={(selected) => {
                // console.log(selectedIds);
                const selectedItems = selected
                  .map((id) => {
                    const selectedItem = attributeList.data
                      .flatMap((group) => group.child)
                      .find((child) => child.id === id)
                    // console.log(selectedItem);
                    return selectedItem ? selectedItem.value : ''
                  })
                if (selectedItems[0] == '') {
                  selectedItems.shift()
                }
                // console.log(selectedItems,'33333333333');
                if (!selectedItems) {
                  return $t('select')
                }
                return selectedItems.join(',')
              }
              }
            >
              {attributeList.data ? attributeList.data.map((group) => (
                <div key={group.name}>
                  <ListItemButton onClick={handleClick(group.name)}>
                    <ListItemText primary={group.name} disabled />
                    {openGroup === group.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openGroup === group.name} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {group.child.map((child) => (
                        <div key={child.id} onClick={handleMenuItemClick(child.id)} style={{ display: 'flex' }}>
                          <Checkbox checked={selectedIds.includes(child.id)} />
                          <ListItemText primary={child.value} secondary={`(${child.number})${formatNumberWithFixedDecimals(child.rate)}%`} />
                        </div>
                      ))}
                    </List>
                  </Collapse>

                </div>
              )) : <></>}
            </Select>


          </FormControl>
          <div style={{ marginLeft: '10px', ...theme.typobox.flexCenterLeft }}>
            <span style={{ fontSize: '18px', color: '#24D5BA', margin: '0 10px', cursor: 'pointer' }} onClick={submit}>{$t('market.determine')}</span>
            <span style={{ fontSize: '18px', color: '#24D5BA', cursor: 'pointer' }} onClick={release}>{$t('market.reset')}</span>
          </div>
        </Box>
        <Box>
          <FormControl>
            <OutlinedInput
              size="small"
              placeholder={$t('inputKeyword')}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>
              }
              value={state.search}
              onChange={(e) => setState(v => ({ ...v, search: e.target.value }))}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Box>
      </Box>
      {/* h5 */}
      <Box className="why-mediablock" sx={{ display: 'none', width: 1 }} >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <FormControl sx={{ width: 1 }}>
            <OutlinedInput
              size="small"
              sx={{ width: '100%', mb: 1 }}
              placeholder={$t('inputKeyword')}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>
              }
              value={state.search}
              onChange={(e) => setState(v => ({ ...v, search: e.target.value }))}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl
            size="small"
            sx={{ mt: 1 }}
            className="size16"
          >
            <InputLabel id="sort">{$t('sortType')}</InputLabel>
            <Select
              labelId="sort"
              id="sortSelect"
              value={state.sort}
              label={$t('sortType')}
              onChange={handleChangeh5}
            >
              {sorts.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Box>
        {/* Drawer */}
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => { }}
        >
          <Box
            sx={{
              width: '100%',
              height: '400px',  // or whatever height you want
              padding: '30px 16px',
              position: 'relative'
            }}
          >
            {/* Close Icon Button */}
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8
              }}
              onClick={release}
            >
              <CloseIcon />
            </IconButton>
            <Box>
              <div className='divtb'>
                <FormControl size="small">
                  <InputLabel id="filter_status">{$t('cpzt')}</InputLabel>
                  <Select
                    labelId="filter_status"
                    id="filter_status"
                    value={state.filter_status}
                    label={$t('cpzt')}
                    sx={{ minWidth: 300 }}
                    onChange={handleChangeFilterStatus}
                  >

                    <MenuItem value={1}>{$t('ysj')}</MenuItem>
                    <MenuItem value={0}>{$t('yxj')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='divtb'>
                <FormControl size="small">
                  <InputLabel id="filter_owner">{$t('collection.cyz')}</InputLabel>
                  <Select
                    labelId="filter_owner"
                    id="filter_owner"
                    value={state.filter_owner}
                    label="{$t('collection.cyz')}"
                    onChange={handleChangeFilterOwner}
                    sx={{ minWidth: 300 }}

                  >

                    <MenuItem value={1}>{$t('tabbar.me')}</MenuItem>
                    <MenuItem value={0}>{$t('public.all')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='divtb'>
                <Box sx={{ display: 'flex' }}>
                  <Grid item sx={theme.typobox.flexCenterLeft}>
                    <span style={{ fontSize: '16px', color: '#666' }}>{$t('market.priceRange')}：</span>
                    <TextField
                      label={$t('market.minPrice')}
                      type="number"
                      sx={{ fontSize: '16px', color: '#999' }}
                      variant="outlined"
                      size="small"
                      style={{ width: '100px' }}
                      value={state.filter_price_low}
                      onChange={handleChangeFilterPriceLow}
                    />
                  </Grid>
                  <Grid item sx={theme.typobox.flexCenterCenter}>
                    ~
                  </Grid>
                  <Grid item>
                    <TextField
                      label={$t('market.maxPrice')}
                      sx={theme.components.inputLabel}
                      type="number"
                      variant="outlined"
                      size="small"
                      style={{ width: '100px' }}
                      value={state.filter_price_high}
                      onChange={handleChangeFilterPriceHigh}
                    />
                  </Grid>
                </Box>
              </div>
              {/* <div  className='divtb'>
          <Box sx={{ display: 'flex'}}>
            <Grid item sx={theme.typobox.flexCenterLeft}>
              <span style={{fontSize:'16px',color:'#666'}}>稀有度排名：</span>
              <TextField
                label="最小值"
                type="number"
                sx={theme.components.inputLabel}
                variant="outlined"
                size="small"
                style={{width:'100px'}}
                value={state.filter_rare_low}
                onChange={handleChangeFilterRareLow}
              />
            </Grid>
            <Grid item sx={theme.typobox.flexCenterCenter}>
              ~
            </Grid>
            <Grid item>
              <TextField
                label="最大值"
                sx={theme.components.inputLabel}
                type="number"
                variant="outlined"
                size="small"
                style={{width:'100px'}}
                value={state.filter_rare_height}
                onChange={handleChangeFilterRareHigh}
              />
            </Grid>
          </Box>
        </div> */}
              <div className='divtb'>
                {/* 属性 */}
                <FormControl size="small">
                  <InputLabel id="attribute">{$t('collection.sx')}</InputLabel>

                  <Select
                    multiple
                    id="attribute"
                    value={selectedIds}
                    sx={{ width: 300 }}
                    onChange={handleChangeAttribute}
                    renderValue={(selected) => {
                      // console.log(selectedIds);
                      const selectedItems = selected
                        .map((id) => {
                          const selectedItem = attributeList.data
                            .flatMap((group) => group.child)
                            .find((child) => child.id === id)
                          // console.log(selectedItem);
                          return selectedItem ? selectedItem.value : ''
                        })
                      if (selectedItems[0] == '') {
                        selectedItems.shift()
                      }
                      if (!selectedItems) {
                        return $t('select')
                      }
                      return selectedItems.join(',')
                    }
                    }
                  >
                    {attributeList.data ? attributeList.data.map((group) => (
                      <div key={group.name}>
                        <ListItemButton onClick={handleClick(group.name)}>
                          <ListItemText primary={group.name} disabled />
                          {openGroup === group.name ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openGroup === group.name} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {group.child.map((child) => (
                              <div key={child.id} onClick={handleMenuItemClick(child.id)} style={{ display: 'flex' }}>
                                <Checkbox checked={selectedIds.includes(child.id)} />
                                <ListItemText primary={child.value} secondary={`(${child.number})${formatNumberWithFixedDecimals(child.rate)}%`} />
                              </div>
                            ))}
                          </List>
                        </Collapse>

                      </div>
                    )) : <></>}
                  </Select>


                </FormControl>
              </div>

              <div className='divtb' style={{ ...theme.typobox.flexCenterCenter }}>

                <span style={{ fontSize: '16px', cursor: 'pointer', display: 'block', width: '45%', ...theme.typobox.flexCenterCenter, padding: '10px', borderRadius: '20px', ...theme.palette.border }} onClick={release}>{$t('qc')}</span>
                <span style={{ fontSize: '16px', backgroundColor: '#24D5BA', cursor: 'pointer', display: 'block', width: '45%', ...theme.typobox.flexCenterCenter, color: '#fff', padding: '10px', borderRadius: '20px' }} onClick={submit}>{$t('market.determine')}</span>
              </div>
            </Box>
          </Box>
        </Drawer>

      </Box>
      {/* 盒子 */}
      <Box
        sx={{
          ...{ margin: '0 0 15px' },
          ...theme.typobox.flexWarpWrap,
          justifyContent: 'space-between',
        }}
      >

        {
          collectionProductList.status == 'pending' ?
            <Box sx={{ ...theme.palette.text, mt: 2 }}>{$t('loadingText')}</Box> :
            collectionProductList?.length ? collectionProductList.map(v => <NftItem key={v.id} {...v} shoppingcart={shoppingcart} />)
              : <Box sx={{ ...theme.palette.text, mt: 2 }}>{$t('nodata')}</Box>
        }
        {/* {collectionProductList.data?.data?.length ? collectionProductList.data.data.map((v) => (
          <NftItem key={v.id} {...v}/>
        )):<>--{$t('nodata')}--</>} */}
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v) => (
          <Box key={v + 1000} sx={{ width: { xs: 220 } }} />
        ))}
      </Box>
    </Box>
  )
}
// 动态面板创建数据  账号？？？？
function createData(
  id,
  sourcefile_url1,
  type,
  sourcefile_url,
  from,
  to,
  price,
  symbol,
  createtime,
) {
  return {
    sourcefile_url1,
    type: handleType(type),
    sourcefile_url,
    from,
    to,
    price: price + symbol,
    createtime: formatDaTime(createtime),
  }
}
// 一串字符前六位后四位展示，中间****代替
function formatDate(date) {
  return date.substring(0, 6) + '****' + date.substring(date.length - 4)
}

// 处理类型格式化
function handleType(type) {
  switch (type) {
    case 0:
      return $t('public.all')
    case 3:
      return $t('jyStatus.5')
    case 4:
      return $t('jyStatus.4')
    case 5:
      return $t('jyStatus.5')
    case 6:
      return $t('jyStatus.6')
    case 7:
      return $t('jyStatus.7')
    case 8:
      return $t('jyStatus.8')
    case 9:
      return $t('jyStatus.9')
    case 10:
      return $t('jyStatus.10')
  }
}
// 时间戳转化成2023/07/21 18:12:45 格式时间
function formatDaTime(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  const m = addZero(date.getMonth() + 1)
  const d = addZero(date.getDate())
  const h = addZero(date.getHours())
  const mm = addZero(date.getMinutes())
  const s = addZero(date.getSeconds())
  return y + '/' + m + '/' + d + ' ' + h + ':' + mm + ':' + s
}
function addZero(num) {
  return num < 10 ? '0' + num : num
}
// 时间戳转化成 07/21格式时间
function yrDateTime(time) {
  const date = new Date(time * 1000)
  const m = addZero(date.getMonth() + 1)
  const d = addZero(date.getDate())
  return m + '/' + d
}
// 时间戳转化成 12:00格式时间
function sfDateTime(time) {
  const date = new Date(time * 1000)
  const h = addZero(date.getHours())
  const mm = addZero(date.getMinutes())
  return h + ':' + mm
}

// typeinit 
function initType(type) {
  switch (type) {
    case 0:
      return $t('public.all')
    case 3:
      return $t('jyStatus.5')
    case 4:
      return $t('jyStatus.4')
    case 5:
      return $t('jyStatus.5')
    case 6:
      return $t('jyStatus.6')
    case 7:
      return $t('jyStatus.7')
    case 8:
      return $t('jyStatus.8')
    case 9:
      return $t('jyStatus.9')
    case 10:
      return $t('jyStatus.10')
  }
}
// 动态面板
const Dynamic = (props) => {
  const theme = useTheme()
  const [type, setType] = React.useState(0)
  const [state, setState] = useState({
    collectionDynamic: [],

  })
  const [expanded, setExpanded] = React.useState(false)

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const { useCollectionDynamicQuery } = globalApiSlice
  const collectionDynamic = useCollectionDynamicQuery({ id_type: 1, id: props.id, type, per_page: 30, page: props.page })
  useEffect(() => {
    if (collectionDynamic.data && props.page == 1) setState((v) => ({ ...v, collectionDynamic: [] }))

    if (collectionDynamic.data && collectionDynamic.status == 'fulfilled') props.setSt(0) & setState((v) => ({ ...v, collectionDynamic: v.collectionDynamic.concat(collectionDynamic.data.data) }))
    if (collectionDynamic.data && collectionDynamic.status == 'fulfilled' && !collectionDynamic.data.data.length) props.setSt(2)

  }, [collectionDynamic.data])
  // console.log(collectionDynamic.data);
  const handleChange = (event) => {
    setType(event.target.value)
  }
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const typeList = [
    {
      value: 0,
      label: $t('public.all'),
    },
    {
      value: 3,
      label: $t('jyStatus.5'),
    },
    {
      value: 4,
      label: $t('jyStatus.4'),
    },
    {
      value: 5,
      label: $t('jyStatus.5'),
    },
    {
      value: 6,
      label: $t('jyStatus.6'),
    },
    {
      value: 7,
      label: $t('jyStatus.7'),
    },
    {
      value: 8,
      label: $t('jyStatus.8'),
    },
    {
      value: 9,
      label: $t('jyStatus.9'),
    },
    {
      value: 10,
      label: $t('jyStatus.10'),
    },
  ]
  // const rows = collectionDynamic.data ? collectionDynamic.data.data : []
  // const rows = []
  // console.log(rows);
  // const tableHeader = ['来源', '类型', 'NFT', '从', '到', '价格', '时间']
  const tableHeader = [
    $t('market.type'),
    'NFT',
    $t('public.from'),
    $t('public.to'),
    $t('public.price'),
    $t('public.time')
  ]

  //点击一个按钮传递参数，封装一个函数将参数粘贴到剪切板上
  function copyToClipboard(content) {
    const copyDom = document.createElement('textarea')
    copyDom.value = content
    copyDom.style.position = 'absolute'
    copyDom.style.top = '0'
    copyDom.style.left = '-9999px'
    document.body.appendChild(copyDom)

    copyDom.select()
    let successful = document.execCommand('copy')
    document.body.removeChild(copyDom)
    // console.log(successful,content);
    setOpen(successful)

    if (successful) {
      // 如果复制成功，则在一段时间后关闭 Snackbar
      setTimeout(() => {
        setOpen(false)
      }, 1000) // 这里可以设置持续显示的时间，单位是毫秒
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      <FormControl sx={{ width: '150px' }} size="small">
        <InputLabel id="type">{$t('public.transactions')}</InputLabel>
        <Select
          labelId="type"
          id="typeDynamic"
          value={type}
          label={$t('public.transactions')}
          onChange={handleChange}
        >
          {typeList.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* 表格 */}
      <Grid
        container
        item
        sx={{ margin: '0 auto', width: '100%' }}
        className="size16 why-medianone"
      >
        {
          state.collectionDynamic?.length > 0 ? (
            <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
              <Table sx={{ width: 1 }}>
                <TableHead>
                  <TableRow>
                    {tableHeader.map((item) => (
                      <TableCell
                        align="center"
                        key={item}
                        sx={{ color: '#888888', borderBottom: 'none' }}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {state.collectionDynamic?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& td, & th': { borderBottom: 'none' },
                      }}
                    >
                      {/* <TableCell component="th" scope="row" align="center" sx={{display:'flex',justifyContent:'center'}}>
                    <Avatar
                      src={row.sourcefile_url}
                      sx={{ width: 28, height: 28 }}
                    ></Avatar>
                  </TableCell> */}
                      <TableCell align="center" sx={{ color: '#888888' }}>
                        {initType(row.type)}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={row.sourcefile_url}
                          style={{ width: 50, height: 50, borderRadius: '5px' }}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#888888' }}>
                        {formatDate(row.from)}
                        <ContentCopyRoundedIcon
                          sx={{
                            fontSize: '20px',
                            color: '#5F6269',
                            marginLeft: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyToClipboard(row.from)}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#888888' }}>
                        {formatDate(row.to)}
                        <ContentCopyRoundedIcon
                          sx={{
                            fontSize: '20px',
                            color: '#5F6269',
                            marginLeft: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyToClipboard(row.to)}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#888888' }}>
                        {row.price}{row.symbol}
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#888888' }}>
                        {formatDaTime(row.createtime)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) :
            (
              <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
                <Table sx={{ width: 1 }}>
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((item) => (
                        <TableCell
                          align="center"
                          key={item}
                          sx={{ color: '#888888', borderBottom: 'none' }}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>
                <p style={{ display: 'flex', justifyContent: 'center' }}>--{$t('nodata')}--</p>
              </TableContainer>
            )
        }

      </Grid>
      {/* 复制提示语 */}
      <Box sx={{ width: 400 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          message={$t('public.copySuccess')}

        />
      </Box>
      {/* h5 */}
      <div className='why-mediablock' style={{ display: 'none' }}>
        {state.collectionDynamic?.length > 0 ? state.collectionDynamic?.map((row) => (
          <Accordion key={row.id} expanded={expanded === row.id} onChange={handleChangeExpanded(row.id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                position: 'relative'
              }}
            >
              {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
              General settings
            </Typography> */}
              <img
                src={row.sourcefile_url}
                style={{ width: 60, height: 60, borderRadius: '5px' }}
              />
              <div style={{ paddingLeft: '5px' }}>
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis' }}>
                  {row.name}
                </span>
                <p style={{ fontSize: '12px', color: '#888' }}>{formatDaTime(row.createtime)}</p>
              </div>
              <div style={{ position: 'absolute', top: '10px', right: '10px', padding: '0 5px', fontSize: '12px', color: "#fff", backgroundColor: '#00f6d0' }}>
                {initType(row.type)}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ paddingLeft: '65px', fontSize: '14px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ color: '#999' }}>{$t('price')}</span><span style={{ color: '#888' }}>{row.price}{row.symbol}</span></div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ color: '#999' }}>{$t('collection.from')}</span><span> {formatDate(row.from)}
                  <ContentCopyRoundedIcon
                    sx={{
                      fontSize: '16px',
                      color: '#5F6269',
                      marginLeft: '3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => copyToClipboard(row.from)}
                  /></span></div>
              </div>
            </AccordionDetails>
          </Accordion>
        )) : <p style={{ display: 'flex', justifyContent: 'center' }}>--{$t('nodata')}--</p>}
      </div>
    </Box>
  )
}
// 只保留2位小数
function formatNumberWithFixedDecimals(number) {
  if (isNaN(number)) {
    return '' // 如果输入的不是有效数字，返回空字符串
  }

  const parsedNumber = parseFloat(number) * 100
  return parsedNumber.toFixed(2)
}


// 数据面板
export const Data = (props) => {
  // console.log(props);
  const theme = useTheme()
  // const days = [
  //   { value: 1, label: '24小时' },
  //   { value: 7, label: '7天' },
  //   { value: 30, label: '30天' },
  //   { value: 60, label: '60天' },
  //   { value: 90, label: '90天' },
  //   { value: 365, label: '一年' },
  //   { value: 0, label: '全部' },
  // ]
  const days = $t('timeType').map(v => ({ label: v.name, value: v.key }))
  const steps = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
  ]

  const tableHeader = [$t('series.wallet'), $t('series.cy'), `%${$t('series.yyl')}`]
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const [jghjyeData, setJghjyeData] = useState({})
  const [sjhcyData, setSjhcyData] = useState({})
  const [jgfbData, setJgfbData] = useState({})
  const [cyzfbData, setCyzfbData] = useState([])
  const [rows, setRows] = useState([])

  // 自定义方法
  const fetchjghjye = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}/collection/trade_line`
      // const data = {id:props.id,page:1,per_page:10,sort:sorth5};

      const response = await axios.post(url, { id: props.id, type: 1, day: jghjyeday })
      // console.log(response);
      // 处理响应数据
      // params.type == 1 ? setState(v => ({ ...v, jghjyeData: response.data.data })) : setState(v => ({ ...v, sjhcyData: response.data.data }))
      setJghjyeData(response.data.data)

    } catch (error) {
      console.error(error)
    }
  }
  const fetchsjhcy = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}/collection/trade_line`
      // const data = {id:props.id,page:1,per_page:10,sort:sorth5};

      const response = await axios.post(url, { id: props.id, type: 2, day: sjhcyday })
      // console.log(response);
      // 处理响应数据
      // params.type == 1 ? setState(v => ({ ...v, jghjyeData: response.data.data })) : setState(v => ({ ...v, sjhcyData: response.data.data }))
      setSjhcyData(response.data.data)

    } catch (error) {
      console.error(error)
    }
  }
  const fetchjgfb = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}/collection/price_line`
      // const data = {id:props.id,page:1,per_page:10,sort:sorth5};

      const response = await axios.post(url, { id: props.id, step })
      // console.log(response);
      // 处理响应数据
      // params.type == 1 ? setState(v => ({ ...v, jghjyeData: response.data.data })) : setState(v => ({ ...v, sjhcyData: response.data.data }))
      setJgfbData(response.data.data)

    } catch (error) {
      console.error(error)
    }
  }
  const fetchcyzfb = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}/collection/holder_line`
      // const data = {id:props.id,page:1,per_page:10,sort:sorth5};

      const response = await axios.post(url, { id: props.id })
      // console.log(response);
      // 处理响应数据
      // params.type == 1 ? setState(v => ({ ...v, jghjyeData: response.data.data })) : setState(v => ({ ...v, sjhcyData: response.data.data }))
      setCyzfbData(response.data.data)
      // console.log(response.data,11111111111);

    } catch (error) {
      console.error(error)
    }
  }
  const fetchrows = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}/collection/whale`
      // const data = {id:props.id,page:1,per_page:10,sort:sorth5};

      const response = await axios.post(url, { id: props.id })
      // console.log(response);
      // 处理响应数据
      // params.type == 1 ? setState(v => ({ ...v, jghjyeData: response.data.data })) : setState(v => ({ ...v, sjhcyData: response.data.data }))
      setRows(response.data.data)
      // console.log(response.data.data,11111111111);

    } catch (error) {
      console.error(error)
    }
  }
  //点击一个按钮传递参数，封装一个函数将参数粘贴到剪切板上
  function copyToClipboard1(content) {
    console.log(content)
    const copyDom = document.createElement('textarea')
    copyDom.value = content
    copyDom.style.position = 'absolute'
    copyDom.style.top = '0'
    copyDom.style.left = '-9999px'
    document.body.appendChild(copyDom)

    copyDom.select()
    let successful = document.execCommand('copy')
    document.body.removeChild(copyDom)
    console.log(successful, content)
    setOpen(successful)

    if (successful) {
      // 如果复制成功，则在一段时间后关闭 Snackbar
      setTimeout(() => {
        setOpen(false)
      }, 1000) // 这里可以设置持续显示的时间，单位是毫秒
    }
  }

  const [day, setDay] = React.useState(1)
  const [jghjyeday, setJghjyeday] = React.useState(1)
  const [sjhcyday, setSjhcyday] = React.useState(1)
  const [step, setStep] = React.useState(5)


  const handleChange = (event) => {
    setDay(event.target.value)
  }
  const handleChangejghjyeday = (event) => {
    setJghjyeday(event.target.value)
  }
  const handleChangesjhcyday = (event) => {
    setSjhcyday(event.target.value)
  }

  const handleChangeStep = (event) => {
    setStep(event.target.value)
  }

  useEffect(() => {
    fetchjghjye()
  }, [jghjyeday])
  useEffect(() => {
    fetchsjhcy()
  }, [sjhcyday])
  useEffect(() => {
    fetchjgfb()
  }, [step])
  useEffect(() => {
    fetchcyzfb()
    fetchrows()
  }, [])

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        ...theme.typobox.flexWarpWrap,
      }}
    >
      {/* 价格和交易额 */}
      <Box
        className="global-border-radius global-overflow-hidden"
        sx={{
          width: '49%',
          '@media (max-width: 600px)': {
            width: '98%',
          },
          ...theme.palette.boxShadow,
          ...theme.palette.warp,
          marginTop: 2,
          padding: '25px 18px',
          // bgcolor: '#fbfbfb',
        }}
      >
        <Box sx={theme.typobox.flexCenterSpaceBetween}>
          <span >{$t('series.jghjye')}</span>
          {/* 选择框 */}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={jghjyeday}
              onChange={handleChangejghjyeday}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {days.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <div
            style={{
              width: '32%',
              // backgroundColor: '#fff',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
              // boxShadow:theme.palette.boxShadow.boxShadow,
            }}
            className="global-border-radius global-overflow-hidden"
          >

            <div style={{ ...theme.typobox.flexCenterLeft }}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#a0a0ae',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('issue.jye')}</span>
            </div>
            <div>{jghjyeData.data ? jghjyeData.tx_amount : '-'}</div>
          </div>
          <div
            style={{
              width: '32%',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
            }}
            className="global-border-radius global-overflow-hidden"
          >
            <div style={theme.typobox.flexCenterLeft}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#4ca152',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('pjjg')}</span>
            </div>
            <div>{jghjyeData.data ? jghjyeData.average : '-'}</div>
          </div>
          <div
            style={{
              width: '32%',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
            }}
            className="global-border-radius global-overflow-hidden"
          >
            <div style={theme.typobox.flexCenterLeft}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#3d5fe0',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('home.dbj')}</span>
            </div>
            <div>{jghjyeData.data ? jghjyeData.floor_price : '-'}</div>
          </div>
        </Box>
        {/* 图表 */}
        {jghjyeData.data ? (
          <div> < JghjyeChart data={jghjyeData.data} day={jghjyeday} /></div>
        ) : <></>}
        {/* <div>{   jghjyeData.data ? jghjyeChart(jghjyeData.data, jghjyeday) : ''}</div> */}
      </Box>
      {/* 上架和持有 */}
      <Box
        className="global-border-radius global-overflow-hidden"
        sx={{
          width: '49%',
          '@media (max-width: 600px)': {
            width: '98%',
          },
          ...theme.palette.boxShadow,
          ...theme.palette.warp,
          marginTop: 2,
          padding: '25px 18px',
          // bgcolor: '#fbfbfb',
        }}
      >
        <Box sx={theme.typobox.flexCenterSpaceBetween}>
          <span>{$t('series.sjhcy')}</span>
          {/* 选择框 */}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={sjhcyday}
              onChange={handleChangesjhcyday}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {days.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <div
            style={{
              width: '48%',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
            }}
            className="global-border-radius global-overflow-hidden"
          >
            <div style={theme.typobox.flexCenterLeft}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#4ca152',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('series.sjcps')}</span>
            </div>
            <div>{sjhcyData.data ? sjhcyData.up_number : '-'}</div>
          </div>
          <div
            style={{
              width: '48%',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
            }}
            className="global-border-radius global-overflow-hidden"
          >
            <div style={theme.typobox.flexCenterLeft}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#3d5fe0',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('series.cyzs')}</span>
            </div>
            <div>{sjhcyData.data ? sjhcyData.holder_number : '-'}</div>
          </div>
        </Box>
        {/* 图表 */}
        {sjhcyData.data ? (
          <div> < SjhcyChart data={sjhcyData.data} day={sjhcyday} /></div>
        ) : <></>}
        {/* <div>{state.sjhcyData.data  ? sjhcyChart(state.sjhcyData.data, sjhcyday) : ''}</div> */}
      </Box>
      {/*价格分布*/}
      <Box
        className="global-border-radius global-overflow-hidden"
        sx={{
          width: '49%',
          '@media (max-width: 600px)': {
            width: '98%',
          },
          ...theme.palette.boxShadow,
          ...theme.palette.warp,
          marginTop: 2,
          padding: '25px 18px',
          // bgcolor: '#fbfbfb',
        }}
      >
        <Box sx={theme.typobox.flexCenterSpaceBetween}>
          <span>{$t('series.jgfb')}</span>
          {/* 选择框 */}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={step}
              onChange={handleChangeStep}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {steps.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <div
            style={{
              width: '48%',
              backgroundColor: theme.palette.grey.bgff,
              boxShadow: 'rgb(137, 137, 137) 0px 4px 8px -2px',
              padding: '10px',
            }}
            className="global-border-radius global-overflow-hidden"
          >
            <div style={theme.typobox.flexCenterLeft}>
              <div
                style={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '2px',
                  backgroundColor: '#4ca152',
                  marginRight: '5px',
                }}
              ></div>
              <span>{$t('series.csdd')}</span>
            </div>
            <div>{jgfbData.data ? jgfbData.total_order : '-'}</div>
          </div>
        </Box>
        {/* 图表 */}
        {/* <div>{jgfbData.data ? jgfbChart(jgfbData) :''}</div> */}
        {jgfbData.data ? (
          <div> < JgfbChart data={jgfbData.data} step={step} /></div>
        ) : <></>}
      </Box>
      {/* 持有者分布 */}
      <Box
        className="global-border-radius global-overflow-hidden"
        sx={{
          width: '49%',
          '@media (max-width: 600px)': {
            width: '98%',
          },
          ...theme.palette.boxShadow,
          ...theme.palette.warp,
          marginTop: 2,
          padding: '25px 18px',
          // bgcolor: '#fbfbfb',
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          <span>{$t('series.cyzfb')}</span>
        </div>
        {cyzfbData.length ? cyzfbData.map((item, index) => {
          return (
            <div key={index} style={{ padding: '10px 0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{item.rate * 100}%</span>
                <span>
                  {['1', '2-3', '4-10', '11-25', '26-50', '50+'][index]} NFTs
                </span>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '22px',
                  borderRadius: '10px',
                  backgroundColor: '#9fa3af',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: `${item.rate * 100}%`,
                    backgroundColor: '#06b6d4',
                  }}
                ></div>
              </div>
            </div>
          )
        }) : <></>}
      </Box>
      {/* 巨鲸钱包 */}
      <Box
        className="global-border-radius global-overflow-hidden"
        sx={{
          width: 1,
          ...theme.palette.boxShadow,
          ...theme.palette.warp,
          marginTop: 2,
          padding: '25px 18px',
          '@media (max-width: 600px)': {
            padding: '20px 10px'
          },
          // bgcolor: '#fbfbfb',
        }}
      >
        <div className='media-ml8'>
          <span>{$t('series.jjqb')}</span>
        </div>
        <TableContainer component={Paper} sx={{ marginTop: '20px' }} className='why-medianone'>
          <Table sx={{ width: 1, border: 'none' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: '#888888', borderBottom: 'none' }}
                  colSpan={2}
                >
                  {$t('series.name')}
                </TableCell>
                {/* <TableCell
                className='why-mediablock'
                  align="center"
                  sx={{ color: '#888888', borderBottom: 'none' ,display:'none'}}
                  colSpan={3}
                >
                  账号
                </TableCell> */}
                {tableHeader.map((item) => (
                  <TableCell
                    align="center"
                    key={item}
                    sx={{ color: '#888888', borderBottom: 'none', whiteSpace: 'nowrap' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows ? rows?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td, & th': { borderBottom: 'none' },
                  }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Avatar
                      src={row.avatar}
                      sx={{ width: 28, height: 28 }}
                    ></Avatar>
                  </TableCell>
                  {/* <TableCell align="center" sx={{ color: '#888888' }}>
                    {row.username}
                  </TableCell> */}

                  <TableCell align="center" sx={{ color: '#888888' }}>
                    {formatDate(row.eth_address)}
                    <ContentCopyRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#5F6269',
                        marginLeft: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard1(row.eth_address)}
                    />
                  </TableCell>

                  <TableCell align="center" sx={{ color: '#888888' }}>
                    {row.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ color: '#888888' }}>
                    {formatNumberWithFixedDecimals(row.ratio)}%
                  </TableCell>
                </TableRow>
              )) : <></>}
            </TableBody>
          </Table>
        </TableContainer>

        <table className='why-mediablock' style={{ display: 'none', width: '100%' }}>
          <thead style={{ color: '#888888', fontSize: '14px', width: '100%' }}>
            <tr>
              <th></th>
              <th>{$t('series.name')}</th>
              {tableHeader.map((item, index) => (
                <th
                  style={{ color: '#888888' }}
                  key={index}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: '14px', width: '100%' }}>
            {rows ? rows?.map((row, index) => (
              <tr
                key={index}
              >
                <td align="center">
                  {index + 1}
                </td>
                <td align="center" style={{ padding: '0 5px' }}>
                  <Avatar
                    src={row.avatar}
                    sx={{ width: 28, height: 28 }}
                  ></Avatar>
                </td>
                <td align="center" className='why-medianone' style={{ padding: '0 5px' }}>
                  {row.username}
                </td>
                <td align="center" style={{ padding: '0 5px' }}>
                  {formatDate(row.eth_address)}
                  <ContentCopyRoundedIcon
                    sx={{
                      fontSize: '20px',
                      color: '#5F6269',
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => copyToClipboard1(row.eth_address)}
                  />
                </td>
                <td align="center" style={{ padding: '0 5px' }}>
                  {row.quantity}
                </td>
                <td align="center">
                  {formatNumberWithFixedDecimals(row.ratio)}%
                </td>
              </tr>
            )) : <></>}
          </tbody>
        </table>
        {/* 复制提示语 */}
        <Box sx={{ width: 400 }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            message={$t('public.copySuccess')}

          />
        </Box>
      </Box>
    </Box>
  )
  // return <></>
}

function CollectionHome() {
  const theme = useTheme()
  const currency = React.useContext(AppContext)

  const [activityVal, setActivityVal] = React.useState('1')
  const [value, setValue] = React.useState('1')
  const [page, setPage] = useState(1)
  const [st, setSt] = useState(1)

  const handleChange = (event, newValue) => {
    setSt(0)
    setPage(1)
    setValue(newValue)
  }


  const lt = useLocation()
  const id = lt.search.replace(/\?id=/, '')
  // console.log(id)

  const [state, setState] = useState({
    collectionShow: {}
  })

  const [activitys, setActivitys] = useState({
    s: [],
    e: [],
    n: []
  })

  const { useCollectionShowQuery } = globalApiSlice

  const readyOrder = createRef()
  const collectionShow = useCollectionShowQuery({ id })
  useEffect(() => {
  })
  useEffect(() => {
    if (collectionShow.data) {
      setState((v) => ({ ...v, collectionShow: collectionShow.data }))
      globalApiSlice.mergeApi({
        page: 1,
        per_page: 100,
        status: 1,
        project_id: collectionShow.data.project_id
      }).then(res => {
        setActivitys(v => ({ ...v, s: res.data }))
      })
      globalApiSlice.mergeApi({
        page: 1,
        per_page: 100,
        status: -1,
        project_id: collectionShow.data.project_id
      }).then(res => {
        setActivitys(v => ({ ...v, e: res.data }))
      })
      globalApiSlice.mergeApi({
        page: 1,
        per_page: 100,
        status: 0,
        project_id: collectionShow.data.project_id
      }).then(res => {
        setActivitys(v => ({ ...v, n: res.data }))
      })
    }
  }, [collectionShow.data])

  const hjcj = () => {
    
    const data = state.collectionShow
    const body = {
      id: 0,
      name: data.name,
      pay_coin: data.pay_coin,
      img: data.icon_url,
      series_id: data.id,
      max: data.offer.highest_price || '-',
      min: data.offer.floor_price || '-x',
      type: 2,
      maxNum: data.item_number
    }

    readyOrder.current.open(body)
  }

  return (
    <Box className="container global-content-auto"
      onScroll={e => _.throttle(() => _.scrollFactory({
        dom: e.target,
        bottomFnc: () => st === 0 ? setSt(1) & setPage(v => v + 1) & console.log(st) : console.log(st)
      }))}>
      {collectionShow.data ?
        (
          <Grid
            container
            item
            xs={12}
            sm={10}
            md={10}
            lg={10}
            sx={{ margin: '20px auto' }}
          >
            <Box
              sx={{
                position: 'relative',
                width: 1,
                ...theme.palette.boxShadow,
              }}
            >
              <Hidden smUp>
                <div
                  // src={collectionShow.data.background_url}
                  style={{
                    height: '158px',
                    // backgroundColor: 'pink',
                    width: '100%',
                    backgroundImage: `url(${collectionShow.data?.background_url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              </Hidden>
              <Hidden smDown>
                <div
                  // src={collectionShow.data.background_url}
                  style={{
                    height: '158px',
                    // backgroundColor: 'pink',
                    width: '100%',
                    backgroundImage: `url(${collectionShow.data?.pc_background || collectionShow.data?.background_url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              </Hidden>
              <Box
                sx={{
                  position: 'absolute',
                  height: 140,
                  top: '88px',
                  left: '58px',
                  display: 'flex',
                  '@media (max-width: 600px)': {
                    left: '10px'
                  }
                }}
              >
                <Avatar src={collectionShow.data.icon_url} sx={{ width: 90, height: 90 }} />
                <Box sx={{ ...theme.typobox.flexCenterLeft }}>
                  {/* <img src={icon1} style={{ width: '38px', height: '38px' }} /> */}
                  <a href={collectionShow.data.chain_browser} target="_blank" style={{ display: collectionShow.data.chain_browser ? 'block' : 'none' }}>
                    <img src={collectionShow.data.chain_image_url} style={{ width: '25px', height: '25px', marginRight: '8px', borderRadius: '50%' }} />
                  </a>
                  <a href={collectionShow.data.web_url} target="_blank" style={{ display: collectionShow.data.web_url ? 'block' : 'none' }}>
                    <img src={icon4} style={{ width: '25px', height: '25px', marginRight: '8px' }} />
                  </a>
                  <a href={collectionShow.data.twitter} target="_blank" style={{ display: collectionShow.data.twitter ? 'block' : 'none' }}>
                    <img src={icon3} style={{ width: '25px', height: '25px', marginRight: '8px' }} />
                  </a>
                  <a href={collectionShow.data.discord} target="_blank" style={{ display: collectionShow.data.discord ? 'block' : 'none' }}>
                    <img
                      src={icon2}
                      style={{ width: '25px', height: '25px', marginRight: '8px' }}
                    />
                  </a>
                </Box>
              </Box>
              <Box sx={{ padding: '20px 48px 22px', fontSize: 16 }} className='txt-p10'>
                <div>
                  <p style={{ color: '#888', ...theme.typobox.flexCenterLeft }}>
                    {collectionShow.data.name}
                    <Box onClick={hjcj} sx={{ ...theme.palette.mainText, p: 1, ml: 2, ...theme.palette.btn.login, borderRadius: 2, fontSize: { xs: 14, sm: 16 } }}>{$t('hjcj')}</Box>
                  </p>
                  <p className='txt-size14'>
                    <span className="txt-color1 txt-m5">NFTs</span>{collectionShow.data.item_number}{' '}
                    <span className="txt-color txt-m5">{$t('public.bs')}</span>{collectionShow.data.copyright_rate * 100}%{' '}
                    <span className="txt-color txt-m5">{$t('public.network')}</span>{collectionShow.data.net_name}{' '}
                  </p>
                  <p className='why-medianone'>
                    <span className="txt-color1">{$t('issue.scze')}</span>{collectionShow.data.sell_amount}{' '}
                    <span className="txt-color">{$t('home.dbj')}</span>{collectionShow.data.floor_price}{' '}
                    <span className="txt-color">{$t('collection.cyz')}</span>{collectionShow.data.holder_number}{' '}
                    <span className="txt-color">{$t('series.cyzfsd')}</span>{formatNumberWithFixedDecimals(collectionShow.data.holder_dispersion)}%
                    <span className="txt-color">{$t('series.sjs')}</span>{collectionShow.data.up_number}
                    <span className="txt-color">{$t('series.sjl')}</span>{formatNumberWithFixedDecimals(collectionShow.data.up_rate)}%
                  </p>
                  <div className='why-mediablock' style={{ display: 'none', backgroundColor: theme.palette.grey.bg, padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{collectionShow.data.sell_amount}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('issue.scze')}</span></div>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}><span>{collectionShow.data.floor_price}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('home.dbj')}</span></div>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}><span>{collectionShow.data.holder_number}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('collection.cyz')}</span></div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{formatNumberWithFixedDecimals(collectionShow.data.holder_dispersion)}%</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.cyzfsd')}</span></div>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{collectionShow.data.up_number}</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.sjs')}</span></div>
                      <div style={{ ...theme.typobox.flexCenterCenterByColumn, flex: 1 }}> <span>{formatNumberWithFixedDecimals(collectionShow.data.up_rate)}%</span><span style={{ fontSize: '14px', color: '#888' }}>{$t('series.sjl')}</span></div>
                    </div>
                  </div>
                </div>

                <TextExpandCollapse
                  className="txt-color"
                  maxLength={150}
                  text={collectionShow.data.describe}
                />
              </Box>
            </Box>
          </Grid>
        ) : <></>}

      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '20px auto' }}
      >
        <Box className="tabs" style={{ width: '100%' }}>
          <TabContext value={value}>
            <Box>
              <Hidden smDown>
                <TabList
                  variant="scrollable"
                  aria-label="visible arrows tabs example"
                  onChange={handleChange} >
                  <Tab label={$t('seriesTab[0].name')} value="1" />
                  <Tab label={$t('seriesTab[1].name')} value="2" />
                  <Tab label={$t('seriesTab[2].name')} value="3" />
                  <Tab label={$t('seriesTab[4].name')} value="4" />
                  <Tab label={$t('seriesTab[3].name')} value="5" />
                </TabList>
              </Hidden>
              <Hidden smUp>
                <TabList
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  allowScrollButtonsMobile
                  onChange={handleChange} >
                  <Tab label={$t('seriesTab[0].name')} value="1" />
                  <Tab label={$t('seriesTab[1].name')} value="2" />
                  <Tab label={$t('seriesTab[2].name')} value="3" />
                  <Tab label={$t('seriesTab[4].name')} value="4" />
                  <Tab label={$t('seriesTab[3].name')} value="5" />
                </TabList>
              </Hidden>
            </Box>

            <TabPanel value="1" sx={{ padding: '24px 0' }}>
              <Nft {...{ id, page, setSt, value, setPage }} />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '24px 0' }} className='media-padding5'>
              <Data {...{ id }} />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: '24px 0' }}>
              <Dynamic {...{ id, page, setSt, value, setPage }} />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: '24px 0' }}>
              <Grid container item xs={12} >
                <Grid className="tabs" style={{ width: '100%' }} item xs={12}>
                  <TabContext value={activityVal}>
                    <Grid item xs={12} >
                      <TabList onChange={(e, v) => setActivityVal(v)}>
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.jxz')} value="1" />
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.wks')} value="2" />
                        <Tab sx={{ fontSize: { xs: 14, sm: 16 } }} label={$t('mas.yjs')} value="3" />
                      </TabList>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                      <TabPanel value="1" sx={{ padding: '24px 0' }}>
                        {activitys.s.map(v => <Activity key={v.id} {...v} project_id={state.collectionShow.project_id} />)}
                        {!activitys.s.length ? <Empty /> : ''}
                      </TabPanel>
                      <TabPanel value="2" sx={{ padding: '24px 0' }}>
                        {activitys.n.map(v => <Activity key={v.id} {...v} project_id={state.collectionShow.project_id} />)}
                        {!activitys.n.length ? <Empty /> : ''}

                      </TabPanel>
                      <TabPanel value="3" sx={{ padding: '24px 0' }}>
                        {activitys.e.map(v => <Activity key={v.id} {...v} project_id={state.collectionShow.project_id} />)}
                        {!activitys.e.length ? <Empty /> : ''}
                      </TabPanel>
                    </Grid>

                  </TabContext>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="5" sx={{ padding: '24px 0' }}>
              <Notice />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
      <ReadyOrder onRef={readyOrder} />
    </Box>
  )
}
export default CollectionHome

function Notice() {
  const theme = useTheme()
  const lt = useLocation()
  const id = lt.search.replace(/\?id=/, '')
  const data = globalApiSlice.useArticleNoticeQuery({
    category: 'series',
    page: 1,
    series_id: id
  })
  const list = data?.data?.data || []
  return (
    <Grid container item xs={12} sx={{ pb: 2, mb: 1, ...theme.typobox.flexCenterCenter }}>
      {!list.length && data.status == 'fulfilled' ? <Empty /> : ''}

      {
        list.map(v => <Grid item xs={12}
          onClick={() => window.open(v.h5_url)}
          sx={{
            mb: 2,
            p: 2,
            ...theme.palette.boxShadow,
            ...theme.palette.warp.box,
            borderRadius: '4px'
          }}>
          <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
            <Box className="global-text-nowrap" sx={{ width: 'calc(100% - 100px)', ...theme.palette.mainText }}>{v.title}</Box>
            <Box sx={{ fontSize: { xs: 14, sm: 16, ...theme.palette.text } }}>{m.unix(v.publish_time).format('MM/DD HH:mm')}</Box>
          </Grid>
          <Grid item xs={12} sx={{ ...theme.typobox.flexCenterLeft, ...theme.palette.text, fontSize: { xs: 14, sm: 16 } }}>
            {v.summary}
          </Grid>
        </Grid>)
      }
    </Grid>
  )
}