import * as echarts from 'echarts'
import React, { useState, useRef, useEffect } from 'react'
import '../Publish/publish.css'
// 时间戳转化成 07/21格式时间
function yrDateTime(time) {
  const date = new Date(time * 1000)
  const m = addZero(date.getMonth() + 1)
  const d = addZero(date.getDate())
  return m + '/' + d
}
// 时间戳转化成 12:00格式时间
function sfDateTime(time) {
  const date = new Date(time * 1000)
  const h = addZero(date.getHours())
  const mm = addZero(date.getMinutes())
  return h + ':' + mm
}
// 时间戳转化成2023/07/21 18:12:45 格式时间
function formatDaTime(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  const m = addZero(date.getMonth() + 1)
  const d = addZero(date.getDate())
  const h = addZero(date.getHours())
  const mm = addZero(date.getMinutes())
  const s = addZero(date.getSeconds())
  return y + '/' + m + '/' + d + ' ' + h + ':' + mm + ':' + s
}
function addZero(num) {
  return num < 10 ? '0' + num : num
}
export const JghjyeChart = ({data, day}) => {
  // console.log(data, day);
  const chartRef = useRef(null)

  useEffect(() => {
    const chart = echarts.init(chartRef.current)

    const option = {
      tooltip: {},

      xAxis: {
        type: 'category',
        data: data.map((item) =>
          day == 1 ? sfDateTime(item.time) : yrDateTime(item.time),
        ),
        axisLabel: {
          fontSize: 14,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: $t('issue.jye'),
          axisLabel: {
            fontSize: 16,
          },
        },
        {
          type: 'value',
          name: `${$t('pjjg')}} | ${$t('home.dbj')}`,
          axisLabel: {
            fontSize: 16,
          },
        },
      ],
      series: [
        {
          name: $t('issue.jye'),
          type: 'bar',
          yAxisIndex: 0,
          data: data.map((item) => item.tx_amount),
          itemStyle: {
            color: '#a0a0ae', // 设置柱状图颜色
          },
        },
        {
          name: $t('pjjg'),
          type: 'line',
          yAxisIndex: 1,
          data: data.map((item) => item.average),
          lineStyle: {
            color: '#4ca152',
          },
        },
        {
          name: $t('home.dbj'),
          type: 'line',
          yAxisIndex: 1,
          data: data.map((item) => item.floor_price),
          lineStyle: {
            color: '#3d5fe0',
          },
        },
      ],
    }

    chart.setOption(option)

    return () => {
      chart.dispose()
    }
  }, [data])

  return <div ref={chartRef} style={{ width: '98%', height: '400px',padding:'0 5px' }} />
}

// sjhcyChart
export const SjhcyChart = ({data, day}) => {
  console.log(data, day);
  const chartRef1 = useRef(null)

  useEffect(() => {
    const chart = echarts.init(chartRef1.current)

    const option = {
      tooltip: {},

      xAxis: {
        type: 'category',
        data: data.map((item) =>
          day == 1 ? sfDateTime(item.time) : yrDateTime(item.time),
        ),
        axisLabel: {
          fontSize: 14,
        },
      },
      yAxis: [
        {
          type: 'value',

          axisLabel: {
            fontSize: 16,
          },
        },
        {
          type: 'value',

          axisLabel: {
            fontSize: 16,
          },
        },
      ],
      series: [
        {
          name: $t('series.sjcps'),
          type: 'line',
          yAxisIndex: 0,
          data: data.map((item) => item.up_number),
          lineStyle: {
            color: '#4ca152',
          },
        },
        {
          name: $t('series.cyzs'),
          type: 'line',
          yAxisIndex: 1,
          data: data.map((item) => item.holder_number),
          lineStyle: {
            color: '#3d5fe0',
          },
        },
      ],
    }

    chart.setOption(option)

    return () => {
      chart.dispose()
    }
  }, [data])

  return <div ref={chartRef1} style={{ width: '95%', height: '400px' }} />
}


// jgfbChart
export const JgfbChart = ({data, step}) => {
  console.log(data,step);
  const chartRef2 = useRef(null)

  useEffect(() => {
    const chart = echarts.init(chartRef2.current)

    const option = {
      tooltip: {},

      xAxis: {
        type: 'category',
        data: data.map((item) => item.x),
        axisLabel: {
          fontSize: 14,
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            fontSize: 16,
          },
        },
      ],

      series: [
        {
          name: $t('series.csdd'),
          type: 'bar',
          data: data.map((item) => item.number),
          itemStyle: {
            color: '#a0a0ae', // 设置柱状图颜色
          },
        },
      ],
    }

    chart.setOption(option)

    return () => {
      chart.dispose()
    }
  }, [data])

  return <div ref={chartRef2} style={{ width: '95%', height: '400px' }} />
}