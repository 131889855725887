import * as React from 'react'
import { useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AppContext from '@/hooks/useAppContext.js'
import {
  Box, Grid, InputAdornment, Select, FormControl, MenuItem, useTheme,
  OutlinedInput, InputLabel, TextField, Button, NativeSelect,
  Hidden, Skeleton
} from '@mui/material'
import { globalApiSlice } from '@/features/global/globalSlice'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
function f_p(map = {}) {
  const s = { key: useLocation().key }
  Object.keys(map).forEach(k => { if (map[k]) { s[k] = map[k] } })
  return s
}

function Project(p) {
  const { setStore } = useContext(AppContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const add = e => {
    _.debounce(() => {
      globalApiSlice.shoppingCartAddApi({ product_id: p.id, token_id: p.token_id }).then((result) => {
        if (result) setStore(v => ({ ...v, shoppingNum: result.shopping_cart })) & p.shoppingcart(p)
      })
    })
    e.stopPropagation()
  }
  return (
    <Box
      onClick={() => navigate('/collection?id=' + p.id)}
      className="global-border-radius global-overflow-hidden global-position-relative global-pointer"
      sx={{
        width: { xs: 160, sm: 200 },
        height: { xs: 160, sm: 200 },
        ...theme.palette.boxShadow,
        ...theme.palette.warp,
        ...theme.palette.text,
        marginRight: { xs: 0, sm: 0 },
        marginTop: 3,
        backgroundImage: `url(${p.sourcefile_url})`,
        ...theme.components.backgroundIcon,
        backgroundSize: 'auto 100%'
      }}>
      <Box sx={{ right: 0, p: '10px' }} onClick={add} className="global-position-absolute">
        {
          p.shopping_cart ? <RemoveShoppingCartIcon sx={{ color: '#50DDC8', filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,.6))' }} /> : <AddShoppingCartIcon sx={{ color: '#50DDC8', filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,.6))' }} />
        }
      </Box>

      <Box className="global-position-absolute"
        sx={{
          ...{
            width: '100%',
            bottom: 0,
            padding: '0 16px',
            paddingBottom: '10px',
            fontSize: { xs: 12, sm: 16 },
            backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.6) 100%)'
          }
        }}>
        <Box className="global-font-weight-bold" sx={{ ...{ margin: '10px 0', fontSize: { xs: 14, sm: 18 }, color: '#fff' } }}>
          {p.name.replace(`#${p.token_id}`, '') === p.collection_name ? '#' + p.token_id :
            p.name
          }
          {/* collection_name */}
          {/* token_id */}
        </Box>
        <Box className="global-font-weight-bold" sx={{ ...theme.typobox.flexCenterSpaceBetween, ...{ marginTop: '5px', color: '#fff' } }}><Box sx={{ mr: '10px' }}>{p.collection_name}</Box><Box>{p.floor_price} {p.symbol}</Box></Box>
      </Box>
    </Box>
  )
}


function Market() {
  const theme = useTheme()

  const { useMarketProductListQuery, useNetIndexQuery, useCollectionTypeQuery } = globalApiSlice

  const [s, setS] = useState({
    mpl: [],
    ni: [],
    ct: []
  })

  const l = useLocation()
  const query = new URLSearchParams(l.search)
  const keyword = query.get('keyword')

  const [stEl, setStEl] = useState(2)

  const [p, setP] = useState({
    page: 1,
    per_page: 30,
    search: keyword,
    sort: stEl || '',
    filter_price_low: '',
    filter_price_high: '',
    filter_type: [],
    filter_net: []
  })

  // const sort = [{
  //   "key": 4,
  //   "name": "最新上架"
  // }, {
  //   "key": 2,
  //   "name": "价格从低到高"
  // }, {
  //   "key": 1,
  //   "name": "价格从高到低"
  // }]

  const sort = $t('market.sortTypes')

  const iv = React.useRef(keyword || '')
  const pl = React.useRef('')
  const ph = React.useRef('')
  const [niEl, setNiEl] = useState('')
  const [ctEl, setCtEl] = useState('')
  const mpl = useMarketProductListQuery({ ...f_p(p) })
  const ni = useNetIndexQuery({})
  const ct = useCollectionTypeQuery({})
  const [ld, setLd] = useState(true)
  const [st, setSt] = useState(1)

  React.useEffect(() => {
    if (mpl.data && p.page == 1) setS(v => ({ ...v, mpl: [] }))
    if (mpl.data && mpl.status == 'fulfilled') setSt(0) & setS(v => ({ ...v, ...{ mpl: v.mpl.concat(mpl.data.data) } }))
    if (mpl.data && mpl.status == 'fulfilled' && !mpl.data.data.length) setSt(2)

    if (ni.data) setS(v => ({ ...v, ...{ ni: ni.data } }))
    if (ct.data) setS(v => ({ ...v, ...{ ct: ct.data } }))
    if (![mpl].filter(v => !v.data).length && ld) setLd(false)
  }, [mpl.data, p])
  const shoppingcart = p => {
    setS(v => ({ ...v, mpl: v.mpl?.map(v => v.id == p.id ? { ...v, shopping_cart: !v.shopping_cart } : v) || [] }))
  }

  return (
    <Box onScroll={e => _.throttle(() => _.scrollFactory({
      dom: e.target,
      bottomFnc: () => st === 0 ? setSt(1) & setP(v => ({ ...v, page: v.page + 1 })) : ''
    }))} className="global-content-auto" sx={{ ...theme.components.layoutMainBox, ...{ marginTop: 0 } }}>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '5px auto', mt: 5 }}>
        <Grid xs={12} container item sx={theme.typobox.flexCenterCenter}>
          <Grid item xs={8} sm={7} md={7}>
            <FormControl fullWidth>
              <OutlinedInput
                id="searchInput"
                size="small"
                onInput={e => { iv.current = e.target.value }}
                placeholder={$t('inputKeyword')}
                startAdornment={<InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>}
              />
              {
                (() => { $('#searchInput').val(keyword || iv.current) })()
              }
            </FormControl>
          </Grid>
          <Hidden smUp>
            <Grid item xs={.5} sm={0}></Grid>
          </Hidden>
          <Grid container item xs={3.5} sm={5} md={5} sx={theme.typobox.flexCenterRight} >
            <FormControl fullWidth sx={{ width: { xs: '100%', sm: 100 } }}>
              <InputLabel sx={theme.components.inputLabel} size="small">{$t('market.type')}</InputLabel>
              <Select
                size="small"
                value={ctEl}
                label="类型"
                onChange={e => setCtEl(e.target.value)}
              >
                {
                  s.ct?.map(v => <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>)
                }
              </Select>
            </FormControl>
            {/* {
              [4.5, 4.5, 3].map((v, i) => (
                <Grid item xs={v} key={i}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: '16px' }} size="small">价格从低到高</InputLabel>
                    <Select
                      size="small"
                      value={age}
                      sx={{ fontSize: '16px' }}
                      label="价格从低到高"
                      placeholder='333'
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ))
            } */}

          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
        <Grid item xs={3} sm={1.5}>
          <TextField
            label={$t('market.minPrice')}
            type="number"
            fullWidth
            onInput={e => pl.current = e.target.value}
            sx={theme.components.inputLabel}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={2} sm={.5} sx={theme.typobox.flexCenterCenter}>~</Grid>
        <Grid item xs={3} sm={1.5}>
          <TextField
            label={$t('market.maxPrice')}
            sx={theme.components.inputLabel}
            onInput={e => ph.current = e.target.value}
            type="number"
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={.5}></Grid>
        <Grid item xs={3.5} sm={1.5}>
          <FormControl fullWidth >
            <InputLabel size="small" sx={theme.components.inputLabel}>{$t('market.blockNet')}</InputLabel>
            <Select
              size="small"
              value={niEl}
              label={$t('market.blockNet')}
              placeholder='333'
              onChange={e => setNiEl(e.target.value)}
            >
              {
                s.ni?.map(v => <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={.5}></Grid>
        <Hidden smDown>
          <Grid item xs={1} sx={theme.typobox.flexCenterRight}>
            <Button fullWidth
              onClick={e => {
                setS(v => ({ ...v, ...{ mpl: [] } })); setP(v => ({
                  ...p, ...{
                    page: 1,
                    search: iv.current,
                    filter_net: niEl ? [niEl] : '',
                    filter_type: ctEl ? [ctEl] : '',
                    sort: stEl,
                    filter_price_low: pl.current,
                    filter_price_high: ph.current
                  }
                })); setSt(1)
              }}
              sx={{
                ...theme.palette.btn.fnc,
                '&:hover': {
                  ...theme.palette.btn.fnc,
                  opacity: 0.8
                }
              }}>{$t('public.cx')}</Button>
          </Grid>
        </Hidden>
      </Grid>

      <Hidden smUp>
        <Grid container item xs={11} sx={{ ...theme.typobox.flexCenterLeft, margin: '0 auto', mb: 2 }}>
          <Grid container item xs={3.5}>
            <Button fullWidth
              onClick={e => {
                setS(v => ({ ...v, ...{ mpl: [] } })); setP(v => ({
                  ...p, ...{
                    page: 1,
                    search: iv.current,
                    filter_net: niEl ? [niEl] : '',
                    filter_type: ctEl ? [ctEl] : '',
                    sort: stEl,
                    filter_price_low: pl.current,
                    filter_price_high: ph.current
                  }
                })); setSt(1)
              }}
              sx={{
                ...theme.palette.btn.fnc,
                '&:hover': {
                  ...theme.palette.btn.fnc,
                  opacity: 0.8
                }
              }}>{$t('public.cx')}</Button>
          </Grid>
        </Grid>
      </Hidden>

      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ ...{ margin: '0 auto' }, ...theme.typobox.flexCenterLeft }}>
        <Grid item xs={3} sm={2} sx={theme.palette.text}>{$t('public.orderType')}</Grid>
        <Grid item xs={3.5} sm={2}>
          <FormControl variant="standard" fullWidth>
            <Select
              value={stEl}
              onChange={e => setStEl(e.target.value)}
              size="small"
              label="Age"
            >
              {sort.map(v => <MenuItem key={v.key} value={v.key}>{v.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
        <Box sx={{ ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
          {s?.mpl?.map(v => <Project shoppingcart={shoppingcart} key={v.id} {...v} />)}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 1000} sx={{ width: { xs: 200 } }} />)}
          {st == 1 ? <Box sx={{ ...theme.palette.text, mt: 2, alignItems: 'center', width: '100%' }}>{$t('loadingText')}</Box> : ''}
          {(mpl.status == 'fulfilled' && !s.mpl.length) || st == 2 ? <Box sx={{ ...theme.palette.text, mt: 2, alignItems: 'center', width: '100%' }}>{$t('nodata')}</Box> : ''}
        </Box>
      </Grid>
      {ld ? <Box sx={{ width: '100%', height: 999999999999999, position: 'absolute', top: 0, left: 0, ...theme.palette.warp.box, ...theme.palette.mainText, zIndex: 2 }}>{[300, 40, 40, 40, 40].map((v, i) => <Skeleton variant="circle" animation='wave' height={v} key={i} sx={{ m: 4 }} />)}</Box> : ''}
    </Box >
  )
}

export default Market

