import { Box, Grid, useTheme, Hidden, Menu, Divider, Avatar, ListItem, Drawer, InputLabel, MenuItem, Select, List, FormControl, OutlinedInput, ListItemText, InputAdornment, ListItemButton, ListItemIcon } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import QBICON from '@/assets/icon/qianbao.png'
import QBICONW from '@/assets/icon/qianbao-w.png'
import YUELIANG from '@/assets/icon/yueliang.png'
import TAIYANG from '@/assets/icon/taiyangtianqi.png'
import DIQIUQUANUI from '@/assets/icon/diqiuquanqiu.png'
import DIQIUQUANUIW from '@/assets/icon/diqiuquanqiu-w.png'
import defaultAvatar from '@/assets/icon/mineIcon/defaultAvatar.png'
import { useDispatch, useSelector as uS } from 'react-redux'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { changeMode as cm } from '@/features/user/userSlice'
import { globalApiSlice as GAS } from '@/features/global/globalSlice'
import SearchIcon from '@mui/icons-material/Search'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import LanguageIcon from '@mui/icons-material/Language'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AppContext from '@/hooks/useAppContext.js'
import Popover from '@mui/material/Popover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuIcon from '@mui/icons-material/Menu'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import PerCenterDrawer from '@/components/Mine/perCenterDrawer'
import contentEthereum from '@/hooks/contentEthereum'
import Badge from '@mui/material/Badge'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

function SimpleList() {
  const theme = useTheme()
  const n = useNavigate()
  const [adr, setAdr] = useState('')
  const [sign, setSign] = useState('')
  const [uI, setUI] = useState({})
  const [cEthS, setCEthS] = useState(false)
  const { store, setStore } = useContext(AppContext)

  const cWallet = async () => {
    const TOKEN = sessionStorage.getItem('TOKEN')
    if (!TOKEN) { n('/login'); _toast($t('qxdl')); return }
    if (!window.ethereum) { _toast($t('qz')); return }
    const eth = await contentEthereum()
    setStore(v => ({ ...v, ethereum: eth }))
  }

  if (!window.ethereum) {
    return (
      <Box onClick={e => e.stopPropagation()} className="global-position-absolute global-border-radius global-overflow-hidden" sx={{
        top: 50,
        width: 200,
        backgroundColor: theme.palette.warp.box,
        ...theme.palette.mainText,
        ...theme.palette.boxShadow
      }}>
        <List component="nav" aria-label="main mailbox folders">
          {!store.ethereum?.address ? <ListItem onClick={cWallet} button><Box >{$t('ljqb')}</Box></ListItem> : <ListItem button>
            <Box>{store.ethereum?.address.substring(0, 6) + '****' + store.ethereum?.address.substring(adr.length - 4)}
            </Box>
          </ListItem>}
        </List>
      </Box>
    )
  }

  return (
    <Box onClick={e => e.stopPropagation()} className="global-position-absolute global-border-radius global-overflow-hidden" sx={{
      top: 50,
      width: 200,
      backgroundColor: theme.palette.warp.box,
      ...theme.palette.mainText,
      ...theme.palette.boxShadow
    }}>
      <List component="nav" aria-label="main mailbox folders">
        {!store.ethereum?.address ? <ListItem onClick={cWallet} button><Box >{$t('ljqb')}</Box></ListItem> : <ListItem button sx={{ ...theme.typobox.flexCenterCenter }}> <Box onClick={cWallet} sx={{ ...theme.typobox.flexCenterCenter }}>{store.ethereum?.address.substring(0, 6) + '****' + store.ethereum?.address.substring(store.ethereum?.address.length - 4)}
          <ContentCopyRoundedIcon
            sx={{
              fontSize: '20px',
              color: '#5F6269',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => copyToClipboard(store.ethereum?.address)}
          /></Box></ListItem>}
      </List>
    </Box>
  )
}

function Meru(p) {
  const theme = useTheme()
  const { store, setStore } = useContext(AppContext)
  const [showDrawer, setShowDrawer] = useState(false)
  const d = useDispatch()
  const md = uS(s => s.user.mode)
  const iconTypo = {
    width: { xs: 60, sm: 100, md: 100 },
    height: { xs: 60, sm: 60, md: 60 },
  }


  const n = useNavigate()
  const l = useLocation()

  const [show, setShow] = useState(false)
  const [tShow, setTShow] = useState(false)
  const IEl = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  let USER = sessionStorage.getItem('USER') || {}
  try {
    USER = JSON.parse(USER)
  } catch (err) {
    USER = {}
  }

  const handleTabs = (v, e) => {
    p.s('')
    v.route == '/mine' ? handleClick() : $(IEl.current).find('input').val('')
    v.handle ? v.handle(v, e) : setTShow(false) & n(v.route)
    // p.s('') & v.handle ? v.handle() : n(v.route)
  }
  const handleClick = (e) => {
    const TOKEN = sessionStorage.getItem('TOKEN')
    if (!TOKEN) { n('/login'); _toast($t('qxdl')); return }
    setShowDrawer(true)
  }
  const handleHd = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [langEl, setLangEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const tabs = [
    // { name: '市场', route: '/market' },
    { name: $t('tabbar.market'), route: '/Ranking' }, // 老排行榜
    { name: $t('tabbar.issue'), route: '/publish' },
    { name: $t('notice'), route: '/notice' },
    {
      name: $t('huodong'),
      route: '/inviteNew',
      children: [
        { name: $t('public.yqlx'), route: '/inviteNew' },
        { name: $t('public.pthd'), route: '/medal?index=1' },
      ]
    },
  ]
  document.body.onclick = function () {
    setShow(v => false)
    // setTShow(v => false)
  }
  return (
    <Grid
      container
      item
      xs={11}
      sm={10}
      md={10}
      lg={10}
      sx={{
        ...theme.typobox.flexCenterCenter,
      }}
    >
      <Grid item xs={3} sm={4} sx={{ ...theme.typobox.flexCenterLeft }}>
        <Hidden smDown>
          <Box
            className={md == 'dark' ? 'global-logo-w' : 'global-logo'}
            onClick={() => l.pathname !== '/home' ? handleTabs({ route: '/home' }) : $('.global-content-auto').animate({ scrollTop: 0 })}
            sx={{
              ...theme.components.backgroundIcon,
              ...iconTypo,
              height: 40,
              mt: 1,
              ...{ marginRight: 3 },
            }}
          ></Box>

        </Hidden>
        <Hidden smUp>
          <Box
            className={md == 'dark' ? 'global-logo-p-w' : 'global-logo-p-d'}
            onClick={() => l.pathname !== '/home' ? n('/home') : $('.global-content-auto').animate({ scrollTop: 0 })}
            sx={{
              ...iconTypo,
              ...theme.components.backgroundIcon,
            }}
          ></Box>
        </Hidden>
        <Hidden smDown>
          {/* onBlur={e => !e.target.value ? n(-1) : ''} */}
          <Box sx={{ height: 36, }}>
            <FormControl fullWidth >
              <OutlinedInput
                ref={IEl}
                size="small"
                sx={{ background: md == 'dark' ? '' : '#F8F8F8' }}
                onClick={() => l.pathname.indexOf('search') < 0 ? n('/search') : ''}
                onInput={e => _.debounce(() => p.s(e.target.value), 500)}
                placeholder={$t('inputKeyword')}
                startAdornment={<InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>}
              />
            </FormControl>
            {/* <input
              onClick={() => l.pathname.indexOf('search') < 0 ? n('/search') : ''}
              onChange={e => _.debounce(() => p.s(e.target.value), 500)}
              style={theme.palette.input}
              placeholder="请输入关键词"
              className="global-input"
            /> */}
          </Box>

        </Hidden>
      </Grid>
      <Grid
        onClick={e => e.stopPropagation()}
        item
        xs={9}
        sm={8}
        sx={{ ...theme.typobox.flexCenterRight }}
      >
        <Hidden smDown>
          {tabs.map((v, i) => (
            <Grid
              className="global-pointer"
              onClick={(e) => v.route == '/inviteNew' ? handleHd(e) : handleTabs(v)}
              item
              sx={{
                fontSize: { xs: 12, sm: 14, md: store.lang === 'YW' ? 14 : 18 },
                marginRight: { sm: 1, md: 4 },
                ...(l.pathname.indexOf(v.route) !== -1 ? theme.palette.mainText : theme.palette.text)
              }}
              key={v.name}
            >
              {v.name}
            </Grid>
          ))}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Grid container item sx={{ fontSize: { xs: 14, sm: 16 } }}
              style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Grid style={{ padding: '10px 20px' }} onClick={() => n('/inviteNew')}>{$t('public.yqlx')}</Grid>
              <Grid style={{ padding: '10px 20px' }} onClick={() => n('/medal?index=1')}>{$t('public.pthd')}</Grid>
            </Grid>
          </Popover>
        </Hidden>

        <Hidden smUp>
          <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 1, sm: 2 } }}>
            <SearchIcon onClick={() => l.pathname.indexOf('search') < 0 ? n('/search') : ''} sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" />
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 2 } }}>
            <LanguageIcon onClick={e => setLangEl(e.currentTarget)} sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" />
          </Grid>
          <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 2 } }}>
            {/* {
            theme.palette.mode !== 'dark' ? <img className='global-pointer' style={{ display: 'block', width: 25 }} src={TAIYANG} onClick={() => d(cm())} /> : <img className='global-pointer' style={{ display: 'block', width: 25 }} src={YUELIANG} onClick={() => d(cm())} />
          } */}
            {
              theme.palette.mode !== 'dark' ?
                <DarkModeIcon sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} onClick={() => d(cm())} fontSize="inherit" />
                : <LightModeIcon sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} onClick={() => d(cm())} fontSize="inherit" />
            }
          </Grid>
        </Hidden>

        <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: store.ethereum?.address ? 0 : 2 } }}>
          <Badge badgeContent={store.shoppingNum} sx={{ '& span': { color: '#fff' } }} color="primary">
            <ShoppingCartIcon onClick={() => sessionStorage.getItem('TOKEN') ? setStore(v => ({ ...v, shoppingOpen: true })) : n('/login')} sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" />
          </Badge>
        </Grid>

        <Hidden smUp>
          <Grid item xs={1} className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 3 } }}>
            {/* <img className='global-pointer' style={{ display: 'block', width: 25 }} src={theme.palette.mode == 'dark' ? QBICONW : QBICON} onClick={() => setShow(v => !v)} /> */}
            <AccountBalanceWalletIcon sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" onClick={() => setShow(v => !v)} />
            {show ? <SimpleList /> : ''}
          </Grid>
        </Hidden>

        <Hidden smDown>
          {
            store.ethereum?.address ?
              <Grid item xs={3} sm={2} className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 3 }, ml: 2 }}>
                <Box className="global-overflow-hidden global-border-radius"
                  onClick={() => setShow(v => !v)}
                  sx={{ ...theme.typobox.flexCenterCenter, width: '100%', ...theme.palette.border, height: 45, fontSize: 16 }}>
                  <Box className="global-text-nowrap" sx={{ width: 'calc(100% - 60px)' }}>{store.ethereum.address.substring(0, 6) + '****' + store.ethereum.address.substring(store.ethereum.address.length - 4)}</Box>
                  <ArrowDropDownIcon sx={{ fontSize: 30 }} />
                </Box>
                {show ? <SimpleList /> : ''}
              </Grid> :
              <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 3 } }}>
                {/* <img className='global-pointer' style={{ display: 'block', width: 25 }} src={theme.palette.mode == 'dark' ? QBICONW : QBICON} onClick={() => setShow(v => !v)} /> */}
                <AccountBalanceWalletIcon sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" onClick={() => setShow(v => !v)} />
                {show ? <SimpleList /> : ''}
              </Grid>
          }

        </Hidden>
        {/* <Hidden smDown> */}

        <Hidden smUp>
          <Drawer
            anchor={'top'}
            open={tShow}
            onClose={() => setTShow(false)}
          // onClose={setTShow(v => !v)}
          >
            <Box
              sx={{
                width: 'auto'
              }}
              role="presentation"
            >
              <Divider />
              <List sx={{ p: 0 }}>
                {
                  tabs.concat(
                    {
                      name: $t('set.themes'),
                      handle: () => d(cm()),
                      message: theme.palette.mode === 'dark' ? $t('theme')[0].name : $t('theme')[1].name
                    },
                    {
                      name: $t('set.language'),
                      ariaControls: 'lang-menu',
                      handle: (v, e) => setLangEl(e.currentTarget),
                      message: $t('language')
                    }
                  ).map((v, i) => (
                    <Box key={v.name}>
                      <ListItem sx={{
                        ...theme.palette.warp.box
                      }} disablePadding>
                        {v.children ?
                          (
                            <Grid item xs={12}>
                              <ListItemButton>
                                <ListItemText primary={v.name} />
                              </ListItemButton>
                              <Divider />
                              {v.children.map(v => (
                                <ListItemButton aria-controls={v.ariaControls} key={v.name} onClick={e => handleTabs(v, e)}>
                                  <ListItemText sx={{ ml: 5 }} primary={v.name} />
                                  <ArrowForwardIosIcon sx={{ ml: 2 }} />
                                </ListItemButton>
                              ))}
                            </Grid>
                          )
                          :
                          <ListItemButton
                            onClick={e => handleTabs(v, e)}
                            aria-controls={v.ariaControls}
                          >
                            <ListItemText primary={v.name} />
                            <Box onClick={e => handleTabs(v, e) & e.stopPropagation()}>{v.message}</Box>
                            <ArrowForwardIosIcon sx={{ ml: 2 }} />

                          </ListItemButton>
                        }
                      </ListItem>

                      <Divider />
                    </Box>
                  ))}
              </List>
            </Box>
          </Drawer>
        </Hidden>
        <Menu
          id="lang-menu"
          anchorEl={langEl}
          keepMounted
          open={Boolean(langEl)}
          onClose={() => setLangEl(false)}
        >
          <MenuItem onClick={() => setTShow(false) & setLangEl(false) & setStore(v => ({ ...v, lang: 'YW' }))}>English</MenuItem>
          <MenuItem onClick={() => setTShow(false) & setLangEl(false) & setStore(v => ({ ...v, lang: 'ZH' }))}>简体中文</MenuItem>
          <MenuItem onClick={() => setTShow(false) & setLangEl(false) & setStore(v => ({ ...v, lang: 'TW' }))}>繁体中文</MenuItem>
        </Menu>
        {/* </Hidden> */}
        <Hidden smUp>
          <Grid item xs={1} className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 1, sm: 0 } }}>
            <MenuIcon sx={{ fontSize: 30, color: theme.palette.mode == 'dark' ? '#ddd' : '#666' }} fontSize="inherit" onClick={() => setTShow(v => !v)} />
            {/* {
              tShow ?
                <Box className="global-position-absolute global-border-radius global-overflow-hidden" sx={{
                  top: 50,
                  width: 100,
                  backgroundColor: theme.palette.warp.box,
                  ...theme.palette.mainText,
                  ...theme.palette.boxShadow,
                  textAlign: 'center'
                }}>
                  <List component="nav" aria-label="main mailbox folders">
                    {tabs.concat({ name: '主题', handle: () => d(cm()) }, { name: '语言', handle: () => { } }).map(v => <ListItem key={v.route} sx={{ ...theme.typobox.flexCenterCenter, ...(l.pathname.indexOf(v.route) !== -1 ? { color: '#00EFCA' } : theme.palette.text) }} onClick={() => handleTabs(v) & setTShow(false)} button><Box >{v.name}</Box></ListItem>)}
                  </List>
                </Box>
                : ''
            } */}
          </Grid>
        </Hidden>

        <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight }}>
          <Avatar sx={{ fontSize: 30 }} src={USER.avatar || defaultAvatar} onClick={handleClick} />
        </Grid>
        {/* <Hidden smUp>
          <Grid item xs={10} sx={{ height: 36, ...theme.typobox.flexCenterRight }}>
            <Box onClick={() => d(cm())} sx={{ mr: 4, ...theme.palette.text }}>变色</Box>
            <Box sx={{ mr: 0, ...theme.palette.text }}>换语言</Box>
          </Grid>
        </Hidden> */}
        <PerCenterDrawer show={showDrawer} onClose={() => { setShowDrawer(false) }}></PerCenterDrawer>
      </Grid>
    </Grid>
  )
}

function Main(p) {
  const theme = useTheme()
  return (
    <Box
      className="global-position-relative"
      sx={{
        ...theme.typobox.flexCenterCenter,
        ...{ height: { xs: 60, sm: 80, md: 80 } },
        ...{
          boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.08);',
          zIndex: '2',
        },
      }}
    >
      <Meru {...p} />
    </Box>
  )
}

export default Main

function copyToClipboard(content) {
  const copyDom = document.createElement('textarea')
  copyDom.value = content
  copyDom.style.position = 'absolute'
  copyDom.style.top = '0'
  copyDom.style.left = '-9999px'
  document.body.appendChild(copyDom)

  copyDom.select()
  let successful = document.execCommand('copy')
  document.body.removeChild(copyDom)
  // console.log(successful,content);
  if (successful) _toast($t('public.copySuccess'))
}