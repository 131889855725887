import { useTheme, Box, Grid, Drawer, Skeleton } from '@mui/material'
import { globalApiSlice } from '@/features/global/globalSlice.js'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import EventNoteIcon from '@mui/icons-material/EventNote'
import empty from '@/assets/icon/mineIcon/empty.png'
import { useDispatch, useSelector as uS } from 'react-redux'

function Notice() {
  const history = useLocation()
  const n = useNavigate()
  const theme = useTheme()
  const { useArticleNoticeQuery } = globalApiSlice
  const [p, setP] = useState(1)
  const [l, setL] = useState([])
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState({
    category: 'all',
    home_show: 0,
    page: 1,
    per_page: 20
  })
  const [project, setProject] = useState([])
  const [ld, setLd] = useState(true)
  const md = uS(s => s.user.mode)
  const post = async (ops = {
  }) => {
    const params = { ...body, ...ops }
    if (loading === 1 || loading === 2) return
    setLoading(1)
    try {
      const result = await globalApiSlice.articleNoticeApi(params)
      setBody(params)
      setLoading(0)
      setL(params.page == 1 ? result.data || [] : l.concat(result.data))
      if (params.page !== 1 && result.data.length == 0) {
        setLoading(2)
      }
    } catch (err) {
      setLoading(0)
    }
  }


  useEffect(() => {
    globalApiSlice.projectListApi({ per_page: 1000 }).then(result => {
      setProject(result.data)
      setLd(false)
    })
    post()
  }, [])

  return (
    <Box onScroll={e => _.throttle(() => _.scrollFactory({
      dom: e.target,
      bottomFnc: () => post({ page: body.page + 1 })
    }))} className="global-content-auto" sx={{ ...theme.components.layoutMainBox, ...{ marginTop: 0 } }}>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{
        fontSize: { xs: 12, sm: 14 },
        margin: '5px auto', mt: 5
      }}>
        <Box container item xs={12} sx={{
          whiteSpace: 'nowrap',
          overflow: 'auto'
        }}>
          <Box
            sx={{
              width: { xs: 60, sm: 80 },
              mr: { xs: 1, sm: 2 },
              verticalAlign: 'top',
              display: 'inline-block'
            }}
            onClick={() => {
              setLoading(0)
              post({ category: 'all', page: 1, project_id: undefined })
            }}
          >
            <Box
              className={md == 'dark' ? 'global-logo-p-w' : 'global-logo-p-d'}
              sx={{
                width: { xs: 40, sm: 60 },
                height: { xs: 40, sm: 60 },
                mb: 1,
                ml: 'auto',
                mr: 'auto',
                mt: 1,
                borderRadius: '50%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat !important',
                backgroundPosition: '0 center',
                boxShadow: theme.palette.theme.boxShadow
              }}></Box>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                color: body.category == 'all' ? '#24D5BA' : '',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >{$t('public.all')}</Box>
          </Box>
          <Box
            sx={{
              width: { xs: 60, sm: 80 },
              mr: { xs: 1, sm: 2 },
              verticalAlign: 'top',
              display: 'inline-block'
            }}
            onClick={() => {
              setLoading(0)
              post({ category: 'platform', page: 1, project_id: undefined })
            }}
          >
            <Box
              className={md == 'dark' ? 'global-logo-p-w' : 'global-logo-p-d'}
              sx={{
                width: { xs: 40, sm: 60 },
                height: { xs: 40, sm: 60 },
                mb: 1,
                ml: 'auto',
                mr: 'auto',
                mt: 1,
                borderRadius: '50%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat !important',
                backgroundPosition: '0 center',
                boxShadow: theme.palette.theme.boxShadow
              }}></Box>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                color: body.category == 'platform' ? '#24D5BA' : '',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >Xmeta-HK</Box>
          </Box>
          {
            project.map(v => <Box
              sx={{
                width: { xs: 60, sm: 80 },
                mr: { xs: 1, sm: 2 },
                verticalAlign: 'top',
                display: 'inline-block'
              }}
              onClick={() => {
                setLoading(0)
                post({ category: 'project', page: 1, project_id: v.id })
              }}
              key={v.id}>
              <Box sx={{
                width: { xs: 40, sm: 60 },
                height: { xs: 40, sm: 60 },
                mb: 1,
                ml: 'auto',
                mr: 'auto',
                mt: 1,
                backgroundImage: `url(${v.avatar})`,
                borderRadius: '50%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                boxShadow: theme.palette.theme.boxShadow
              }}></Box>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: body.project_id == v.id ? '#24D5BA' : '',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >{v.name}</Box>
            </Box>)
          }
        </Box>
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '5px auto', mt: 5 }}>
        {
          l?.length == 0 ?
            <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto', ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterCenter }}>
              <Box sx={{ width: { xs: '100%', sm: 200 }, ...theme.typobox.flexCenterCenterByColumn, ...theme.palette.text }}>
                <img src={empty} className='global-img' />
                {$t('nodata')}
              </Box>
            </Grid>
            : ''
        }
        {l?.map(v => <Grid onClick={() => {
          v.h5_url ? window.open(v.h5_url, '_blank') : window.open(window.location.origin + '/#/notice/detail?id=' + v.id)
        }} key={v.id} item xs={12} sx={{ borderBottom: `1px solid ${theme.palette.theme.color}`, pb: { xs: 2, sm: 4 }, mb: { xs: 2, sm: 4 } }}>
          <Box className="global-overflow-hidden" sx={{ ...theme.typobox.flexRevertSpaceBetween, mb: 2 }}>
            <Box className="global-text-nowrap-2" sx={{ ...theme.palette.mainText, fontSize: { xs: 18, sm: 24 }, width: { xs: '100%' }, textAlign: 'justify' }}>{v.title}</Box>
          </Box>
          <Grid container item xs={12} sx={{ mb: 1 }}>
            <Grid item xs={9} sx={{ ...theme.typobox.flexCenterLeft }}>
              {v.series.slice(0, 2).map(s =>
                <Box sx={{ ...theme.typobox.flexCenterLeft, mr: 1 }}>
                  <Box sx={{
                    width: { xs: 20, sm: 40 },
                    height: { xs: 20, sm: 40 },
                    mr: 1,
                    backgroundImage: `url(${s.info.icon_url})`,
                    borderRadius: '50%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}></Box>
                  <Box sx={{
                    fontSize: { xs: 14, sm: 16 }
                  }}>{s.info.name}</Box>
                </Box>)}
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right', ...theme.palette.text, fontSize: { xs: 14, sm: 16 } }}>
              {m.unix(v.publish_time).format('MM/DD HH:mm')}
            </Grid>
          </Grid>
          <Box sx={{ ...theme.palette.text, fontSize: { xs: 14, sm: 18 } }}>{v.summary}</Box>
        </Grid>)}
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '5px auto', ...theme.palette.text, mt: 5 }}>
        {loading == 1 ? $t('loadingText') : ''}
        {loading == 2 ? $t('nomore') : ''}
      </Grid>
      {ld ? <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, ...theme.palette.warp.box, ...theme.palette.mainText, zIndex: 2 }}>{[300, 40, 40, 40, 40].map((v, i) => <Skeleton variant="circle" animation='wave' height={v} key={i} sx={{ m: 4 }} />)}</Box> : ''}
    </Box>
  )
}

export function NoticeDetail() {
  const lt = useLocation()
  const id = lt.search.replace(/\?id=/, '')
  const t = useTheme()
  const { useArticleContentQuery } = globalApiSlice

  const AC = useArticleContentQuery({ article_id: id })
  useEffect(() => {
    $('#fasfaf').html(AC?.data?.content)
  }, [AC.data])
  return <Box>
    <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '5px auto', mt: 5, '& img': { width: '100%' } }}>
      <Grid item xs={12} sx={{ ...t.palette.mainText, fontSize: { xs: 20, sm: 24 } }}>{AC?.data?.title}</Grid>
      <Grid item xs={12} sx={{ ...t.palette.text, fontSize: { xs: 16, sm: 20 } }}>{m.unix(AC?.data?.publish_time).format('YYYY/MM/DD HH:mm')}</Grid>
      <Grid item xs={12} sx={{ ...t.palette.text, fontSize: { xs: 18, sm: 22 } }} id="fasfaf"></Grid>
    </Grid>
  </Box>
}

export default Notice
