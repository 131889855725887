import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, useTheme, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import myNftBgi from "@/assets/img/myNftBgi.png"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import style from '@/components/Mine/mine.module.css'
import { globalApiSlice } from '@/features/global/globalSlice'
import List from "./list/index"
import { useNavigate } from 'react-router-dom'
import defaultAvatar from '@/assets/icon/mineIcon/defaultAvatar.png'
import { dealType, tabsArr, tradeTrends, getExpireDate, visibilityChange } from '@/components/Mine/data'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import contentEthereum from '@/hooks/contentEthereum'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction.js"
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import BatchGround from "@/components/Mine/batchGround"
import { useLocation, useParams } from "react-router-dom"
import AppContext from "@/hooks/useAppContext"
import copy from 'copy-to-clipboard'


const {
    useMyProductQuery,
    useShelvesQuery,
    useSearchSeriesQuery,
    useUserInfoQuery,
    useTradeLogQuery,
    useBatchUpSignMsgQuery,
    useNetNftSetApprovalQuery,
    useBatchUpQuery
} = globalApiSlice
//copy
export function Copy(props) {
    const theme = useTheme()
    const handleClick = async (event) => {
        copy(props.walletCode) && _toast($t('public.copySuccess'))
    }
    return (
        <Grid sx={{ ...theme.palette.mainText }}>
            <ContentCopyIcon onClick={handleClick} sx={{ fontSize: { xs: 22, sm: 18 } }}></ContentCopyIcon>
        </Grid>
    )
}

//用户信息组件
function UserInfo(props) {
    const { store, setStore } = useContext(AppContext)
    const navigate = useNavigate()
    const userInfo = props.userinfo?.data?.user
    const walletCode = store.ethereum?.address ? store.ethereum?.address : ''
    return (
        <div className={style.flex}>
            <div style={{ width: '100%', padding: '5vh 0', display: 'flex', alignItems: 'center' }}>
                <div style={{
                    width: '70px', height: '70px', borderRadius: '100px',
                    backgroundImage: `url(${userInfo && userInfo.avatar ? userInfo.avatar : defaultAvatar})`,
                    backgroundSize: 'contain', marginRight: '20px'
                }}></div>
                <div>
                    {userInfo && <div>{userInfo.username}</div>}
                    {!userInfo && <div onClick={() => navigate('/login')}>{$t('wdl')}</div>}
                    {walletCode && <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                        <div>{visibilityChange(walletCode, [6, 4])}</div>
                        <Copy walletCode={walletCode}></Copy>
                    </div>}
                    {!walletCode && <div style={{ color: '#888888', fontSize: '1vw' }}>{$t('pljqb')}</div>}
                </div>
            </div>
            {/*<div style={{width:'10vw'}} className={style.flex}>*/}
            {/*    {TopImg.map(item=>{*/}
            {/*        return <img key={item.icon} src={item.icon} width={item.width} height={item.height}/>*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    )
}
//搜索框
function SearchInput(props) {
    const onChange = (e) => {
        props.onSearch(e.target.value)
    }
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', margin: '20px 0' }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={$t('inputKeyword')}
                style={{ backgroundColor: 'inherit' }}
                onChange={onChange}
            />
        </Paper>
    )
}
//下拉框
export function VSelect(v) {
    const [val, setVal] = React.useState(v.defaultVal)
    const selectChange = (event) => {
        setVal(event.target.value)
        v.onSelect(event.target.value, v.keyWord)
    }
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">{v.label}</InputLabel>
            <Select labelId="demo-simple-select-autowidth-label" id="demo-simple-select-autowidth"
                value={val} onChange={selectChange} autoWidth label={v.label}>
                {v.value.map((item, index) => {
                    return <MenuItem value={item.value || item.id} key={index}>{item.label || item.series_name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

function FirstTabs(data) {
    let list = data.list
    const cRef = useRef(null)
    const seriesObj = [{ id: 0, series_name: $t('public.all') }]
    const selectArr = [
        { label: $t('public.sjzt'), list: dealType, keyWord: 'status', tabVal: [0, 4], defaultVal: '2' },
        { label: $t('market.type'), list: tradeTrends, keyWord: 'type', tabVal: [3], defaultVal: '0' },
        { label: $t('public.hjmc'), list: seriesObj.concat(data.searchSeries), keyWord: 'series_id', tabVal: [0, 1, 2, 3, 4, 5, 6], defaultVal: '0' }
    ]
    const handleChange = (event, newValue) => {
        const value = $(event.target).attr('data-id')
        data.click(Number(value))
        if (cRef.current) {
            cRef.current.clearChangeList()
        }
    }
    const onSearch = (value) => {
        data.onSearch(value)
    }
    const onSelect = (e, value) => {
        data.onSelect(e, value)
    }
    const hanleGround = (list) => {
        data.onGround(list)
    }
    const onPrice = (val) => {
        data.onPrice(val)
    }
    const onTime = (val) => {
        data.onTime(val)
    }
    const onTransfer = (val) => {
        data.onTransfer(val)
    }
    const onOffShelf = () => {
        data.onOffShelf()
    }
    console.log('selectArr', selectArr)
    console.log('data', data.tabVal)
    return (
        <Box style={{ width: '100%' }}>
            <Tabs value={data.tabVal} onChange={handleChange} variant={'scrollable'}
                visibleScrollbar={false}>
                {tabsArr.map(item => {
                    return <Tab data-id={item.value} key={item.value} value={Number(item.value)} label={item.label} {...a11yProps(0)}></Tab>
                })}
            </Tabs>
            {![5, 6].includes(data.tabVal) && <SearchInput onSearch={onSearch}></SearchInput>}

            <div style={{ display: 'flex', justifyContent: 'right' }}>
                {/*0上架状态，合集名称1合集名称2合集名称3类型，合集名称4上架状态，合集名称**/}
                {selectArr.map((item, index) => {
                    return <div key={index}>
                        {item.tabVal.includes(data.tabVal) && <VSelect onSelect={onSelect}
                            keyWord={item.keyWord} value={item.list} label={item.label}
                            defaultVal={item.defaultVal}></VSelect>}
                    </div>
                })}
            </div>
            {list && <List onOffShelf={onOffShelf} ref={cRef} list={list} click={data.click} tab={data.tabVal} onPrice={onPrice} hanleGround={hanleGround}
                onTime={onTime} loading={data.loading} expireTime={data.time} onTransfer={onTransfer}></List>}
        </Box>
    )
}
//tabs内容框
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} className={style.Typography}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

//批量转移弹窗
function TransferDialog(props) {
    const { onClose, selectedValue, open } = props
    const handleClose = () => {
        onClose(selectedValue)
    }
    const handleListItemClick = (value) => {
        onClose(value)
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{$t('public.plzy')}</DialogTitle>
            <div></div>
        </Dialog>
    )
}
TransferDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}
function Mine() {
    const theme = useTheme()
    const { store, setStore } = useContext(AppContext)
    const walletCode = store.ethereum?.address ? store.ethereum?.address : ''
    const [tab, setTab] = useState(0)
    const [searchVal, setSearchVal] = useState(null)
    const [status, setStatus] = useState(undefined)
    const [type, setType] = useState(undefined)
    const [seriesId, setSeriesId] = useState(undefined)
    const [nftList, setNftList] = useState(null)
    const [sign, setSign] = useState('')
    const [upSignForm, setUpSignForm] = useState({})
    const [price, setPrice] = useState('')
    const [expireTime, setExpireTime] = useState('')
    const [upSignMsg, setUpSignMsg] = useState(null)
    const [upForm, setUpForm] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showTrans, setShowTrans] = useState(false)
    const [changeList, setChangeList] = useState([])
    const [step, setStep] = useState(1)
    const [st, setSt] = useState(0)

    //查询表单
    const [queryForm, setQueryForm] = useState({
        search: searchVal,//代币ID，藏品名称
        per_page: 30,//每页数量
        page: 1,//页码
        status: status == '2' ? undefined : status,
        type: type,
        series_id: seriesId
    })
    const [state, setState] = useState({
        myProduct: [],
        searchSeries: [],
        nftData: {}
    })
    const nftForm = {
        chain_id: nftList ? nftList[0].chain_id : '',
        nft_contract_address: nftList ? nftList[0].contract_address : '',
        approved: true,
        protocol: nftList ? nftList[0].protocol : '',
    }
    const getList = (val) => {
        setQueryForm(v => {
            if (val == 5 || val == 6 || v.series_id == 0) {
                delete v.series_id
            }
            return { ...v, page: 1 }
        })

        setTab(val)
    }
    const onSearch = (value) => {
        setSearchVal(value)
        setQueryForm(v => ({ ...v, search: value, page: 1 }))
    }
    const onSelect = (val, key) => {
        const map = {
            status: undefined,
            type: undefined,
            series_id: undefined
        }
        if (key == 'status') {
            map.status = val == 2 ? undefined : val
        } else if (key == 'type') {
            map.type = val
        } else if (key == 'series_id') {
            map.series_id = val
        }
        const query = {}
        Object.keys(map).forEach(k => {
            if (map[k] !== undefined) {
                query[k] = map[k]
            }
        })
        setQueryForm(v => {
            if (val == 2) v.status = undefined
            if (tab == 5 || tab == 6) {
                if (map.series_id === 0) {
                    delete v.series_id
                    delete query.series_id
                }
            }
            return _.clone(Object.assign(v, { page: 1 }, { rd: Math.random() }, query))
        })
    }
    const getSign = async (list) => {
        setStep(2)
        setChangeList(list)
    }
    const onGround = async (nftData) => {
        let form = {
            data: nftData.data.data,
            to: nftList[0].contract_address,
            chain_id: nftList[0].chain_id,
            type: 5,
            id: nftData.data.hash_id,
        }
        try {
            const signature = await ethereumSendTransaction(form)
            setSign(signature)
            batchUpMsg()
        } catch (err) {
        }
    }
    //获取批量上架信息
    const batchUpMsg = () => {
        let products = nftList.map(item => {
            return {
                product_id: item.product_id,
                quantity: item.quantity,
                price: price,
                endTime: (new Date(expireTime)).getTime() / 1000,
            }
        })
        setUpSignForm({ products: products })
    }
    const onBatchUp = (data) => {
        let form = {
            message: data.message,
            signature: sign,
            orders: data.orders,
        }
        setUpForm(form)
    }
    //获取输入的价格
    const onPrice = (val) => {
        setPrice(val)
    }
    //获取过期时间
    const onTime = (val) => {
        let time = getExpireDate(val.num)
        setExpireTime(time)
    }
    //批量转移
    const onTransfer = (list) => {
        setChangeList(list)
        setShowTrans(true)
    }
    const handleClose = (value) => {
        setShowTrans(false)
    }
    const onCancel = () => {
        setStep(1)
    }
    const onOffShelf = async () => {
        setQueryForm(v => ({ ...v, page: 1 }))
        let res = await globalApiSlice.shelvesApi({ ...queryForm, status: 1 })
        setState(v => ({ ...v, myProduct: res }))
        setStore(v => ({ ...v, shelfOpen: false }))
    }
    const onConfirmGround = async () => {
        setStep(1)
        setQueryForm(v => {
            return _.clone(Object.assign(v, { page: 1 }, { rd: Math.random() }))
        })
        let res = await globalApiSlice.myProductApi({ ...queryForm, is_blindbox: 0 })
        if (res) {
            setState(v => ({ ...v, myProduct: res }))
        }
    }
    const onCancat = (myProduct) => {
        if (state.myProduct.length > 0 && st == state.myProduct.data.length) return state.myProduct
        if (queryForm.page > 1) {
            setSt(myProduct.total)
            let obj = {
                data: myProduct.data ? state.myProduct.data.concat(myProduct.data) : []
            }
            return obj
        } else {
            setSt(myProduct.total)
            return myProduct
        }
    }
    //我的NFT
    //useShelvesQuery({...form,status:2})

    const userinfo = useUserInfoQuery()
    const searchSeries = useSearchSeriesQuery({ type: 1, ckR: walletCode })
    const nftData = useNetNftSetApprovalQuery({ ...nftForm, ckR: nftList })
    const upSignMsgRes = useBatchUpSignMsgQuery({ ...upSignForm, ckR: JSON.stringify(upSignForm) != '{}' })
    const bacthUpRes = useBatchUpQuery({ ...upForm, ckR: upForm })
    const location = useLocation()
    useEffect(() => {
        console.log('tab', tab)
        let q = null
        if (tab == 0) q = () => globalApiSlice.myProductApi({ ...queryForm, is_blindbox: 0 })
        if (tab == 1) q = () => globalApiSlice.shelvesApi({ ...queryForm, status: 1 })
        if (tab == 2) q = () => globalApiSlice.shelvesApi({ ...queryForm, status: 2 })
        if (tab == 3) q = () => globalApiSlice.tradeLogApi({ ...queryForm })
        if (tab == 4) q = () => globalApiSlice.myProductApi({ ...queryForm, is_blindbox: 1 })
        if (tab == 5) q = () => globalApiSlice.offerSelfQuotationApi({ ...queryForm, })
        if (tab == 6) q = () => globalApiSlice.offerReceiveApi({ ...queryForm })
        q().then(res => {
            console.log('onCancat(res)', onCancat(res))
            setState(v =>
                ({ ...v, myProduct: onCancat(res) }))
            closeLoad()
        })
    }, [tab, queryForm])
    useEffect(() => {
        showLoad($t('qqz'))
        if (location?.state?.tab) {
            setTab(location.state.tab)
        }
        if (!walletCode) {
            closeLoad()
            const TOKEN = sessionStorage.getItem('TOKEN')
            if (TOKEN) {
                contentEthereum().then(eth => {
                    if (!eth) {
                        _toast($t('pljqb'))
                        return
                    }
                    setStore(v => ({ ...v, ethereum: eth }))
                })
            }
            return
        }
        if (searchSeries.data) setState(v => ({ ...v, searchSeries: searchSeries.data }))
        if (nftData.data) {
            setState(v => ({ ...v, nftData: nftData.data }))
            onGround(nftData)
        }
        if (upSignMsgRes.data) {
            setUpSignMsg(v => ({ ...v, upSignMsg: upSignMsgRes.data }))
            onBatchUp(upSignMsgRes.data)
        }
        if (bacthUpRes.status == "rejected") {
            setLoading(false)
        }
    }, [searchSeries.data, nftData.data, upSignMsgRes.data, bacthUpRes.data])
    const onPage = () => {
        if (step == 1) {
            setQueryForm(v => ({ ...v, page: state.myProduct.data.length > 0 ? (v.page + 1) : 1 }))
        }
    }
    return (
        <Box onScroll={e => _.throttle(() => _.scrollFactory({
            dom: e.target,
            bottomFnc: onPage
        }))} className="global-content-auto" sx={{ ...theme.components.layoutMainBox, ...{ marginTop: 0 } }}>
            {step == 1 && <div style={{
                backgroundImage: `url(${myNftBgi})`,
                backgroundRepeat: 'no-repeat', backgroundSize: '100% 300px'
            }}>
                <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
                    <UserInfo userinfo={userinfo}></UserInfo>
                    <FirstTabs list={state.myProduct.data} click={getList} onSearch={onSearch}
                        tabVal={tab} searchSeries={state.searchSeries} onSelect={onSelect}
                        onGround={getSign} onPrice={onPrice} onTime={onTime} onOffShelf={onOffShelf}
                        loading={loading} time={expireTime} onTransfer={onTransfer}></FirstTabs>
                </Grid>
                {/*<TransferDialog open={showTrans} onClose={handleClose} list={changeList}></TransferDialog>*/}
            </div>}
            {step == 2 && <BatchGround list={changeList} onCancel={onCancel} onConfirmGround={onConfirmGround}></BatchGround>}
        </Box>
    )
}
export default Mine