//个人中心抽屉
import Drawer from "@mui/material/Drawer";
import {Box, Grid, Avatar, useTheme, TextField} from "@mui/material";
import style from "@/components/Mine/mine.module.css";
import {perCenter} from "@/components/Mine/data";
import defaultAvatar from '@/assets/icon/mineIcon/defaultAvatar.png'
import xyJson from '@/components/Mine/tsconfig.json'
import {Copy} from "@/components/Mine/index"
import {useNavigate} from "react-router-dom";
import {visibilityChange,helpCenter,isApp,isAndroid} from "@/components/Mine/data"
import { globalApiSlice } from '@/features/global/globalSlice'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import cameraIcon from '@/assets/icon/mineIcon/camera.png'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {useState,useEffect} from "react";
import Address from "./address"
import connectWallet from "@/components/Mine/connectWallet"
const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
function HelpCenter(props){
    const theme = useTheme()
    const onCancel = () =>{
        props.onCancel()
    }
    const navToUrl = (v) => (e) => {
        let url = v.type==4?props.appUrl:v.url
        window.open(url)
    }
    return(
        <Grid container direction="column" justifyContent="flex-start" alignItems="baseline"
               style={{padding: '0 20px'}}>
            <Box sx={{
                ...theme.palette.mainText, ...{
                    color: theme.palette.text,
                    fontSize: {xs: 12, sm: 14, md: 16, lg: 16}, textAlign: 'justify'
                }
            }}
                 className={style.perCenterCell}>
                <Grid container spacing={2} sx={{color: '#333333',fontSize: {xs: 16, sm: 18, md: 20}}} style={{ paddingBottom:'15px'}}>
                    <Grid item xs={1} onClick={onCancel} sx={{...theme.palette.mainText}}>{'<'}</Grid>
                    <Grid item xs={8} onClick={onCancel} sx={{...theme.palette.mainText}}>{$t('help')}</Grid>
                </Grid>
            </Box>
            <Grid sx={{width:'100%'}}>
                {helpCenter.map((v,i)=><Grid key={i} style={{width:'100%',display:"flex",alignItems:"center",justifyContent:"space-between"
                    ,height:'50px'}} sx={{fontSize: {xs: 14, sm: 16, md: 16, lg: 16}}} onClick={navToUrl(v)}>
                    <Grid>{v.label}</Grid>
                    <Grid>{'>'}</Grid>
                </Grid>)}
            </Grid>
        </Grid>
    )
}
function PerCenterDrawer(props) {
    const theme = useTheme()
    const n = useNavigate()
    const [step, setStep] = useState(1)
    const [appUrl,setAppUrl] = useState('')
    const [addressList, setAddressList] = useState([])
    const [walletList,setWalletList] = useState([])
    const [userinfo,setUserInfo] = useState({})
    const [infoForm, setInfoForm] = useState({
        avatar: '',
        description: '',
        nickname: ''
    })
    const walletCode = window?.ethereum?.selectedAddress ? window.ethereum.selectedAddress : ''
    const onClose = () => {
        props.onClose()
    }
    const getVersion = async () => {
        let plat = !isApp() ? 1 : (isApp() && isAndroid()) ? 1 : 2
        let res = await globalApiSlice.getVersionApi({version: `1.0.0,${plat}`})
        setAppUrl(res.downloadurl)
    }
    const getWalletList = async () => {
        let res = await globalApiSlice.userInfoApi({})
        if (res) {
            setWalletList(res.address)
        }
    }
    const getAddressList = async () => {
        let res = await globalApiSlice.addressListApi()
        if (res.data) {
            setAddressList(res.data)
        }
    }
    const toNext = (v) => async (event) => {
        if (v.value == 4) {
            await getWalletList()
            setStep(v.value)
            return;
        }
        if (v.value == 5) {
            await getAddressList()
            setStep(v.value)
            return;
        }
        if (v.value == 6) {
            setStep(v.value)
            return;
        }
        if (v.value == 8 || v.value == 9) {
            props.onClose()
            n(v.path, {state: {name: v.value == 8 ? 'yhxy' : 'yszc',value:v.value}})
            return;
        }
        if (v.value==7){
            getVersion()
            setStep(v.value)
            return;
        }
        props.onClose()
        n(v.path, {state: {tab: v.value}})
    }
    const onEquitLogin = () => {
        sessionStorage.clear()
        n('login')
    }
    const onSubmitInfo = async () => {
        if (!infoForm.nickname){
            _toast($t('user.set.inputname'))
            return;
        }
        if (!infoForm.description){
            _toast($t('user.set.inputdes'))
            return;
        }
        if (infoForm.description.length<10){
            _toast($t('jlqsrzs10gzf'))
            return;
        }
        let res = await globalApiSlice.editUserInfoApi(infoForm)
        _toast($t('public.saveSuccess'))
        getUserInfo()
        setStep(1)
    }
    const removeAddress = (v)=>async () => {
        let res = await globalApiSlice.removeAddressApi({address:v.eth_address})
        _toast($t('jbcg'))
        await getWalletList()
    }
    const onCancel = () => {
        setStep(1)
    }
    const onUpload = async (e) => {
        let file = e?.target?.files[0]
        let newFile = new window.File([file], 'image', {type: file.type})
        let formData = new FormData()
        formData.append('image', newFile)
        let res = await globalApiSlice.uploadApi(formData)
        if (res) {
            setInfoForm(v => ({...v, avatar: res.url}))
        }
    }
    const onPutInfo = (name) => (e) => {
        setInfoForm(v => ({...v, [name]: e.target.value}))
    }
    const getUserInfo = () =>{
        let isLogin = sessionStorage.getItem('TOKEN')
        if (isLogin){
            globalApiSlice.userInfoApi({}).then(res=>{
                setWalletList(res.address)
                setUserInfo(res.user)
                setInfoForm({
                    avatar: res.user.avatar,
                    description: res.user.description,
                    nickname: res.user.nickname
                })
            })
        }
    }
    const onConnect = async () => {
        let res = await connectWallet()
        if (res){
            await getWalletList()
        }
    }
    useEffect(() => {
        getUserInfo()
    }, [])
    return (
        <Drawer anchor={'right'} open={props.show} onClose={onClose}
                style={{boxShadow: '-4px 0px 2px 0px rgba(0,0,0,0.3)'}}>
            <Grid sx={{width: {xs: '100vw', sm: 250}}}>
                {/*个人中心*/}
                {step == 1 && <Grid container direction="column" justifyContent="flex-start" alignItems="baseline"
                                    style={{padding: '0 20px'}}>
                    <Box style={{padding: '30px 0'}} sx={{
                        ...theme.typobox.flexCenterLeft, ...theme.palette.mainText, ...{
                            color: theme.palette.text,
                            fontSize: {xs: 12, sm: 14, md: 16, lg: 16}, textAlign: 'justify'
                        }
                    }}
                         className={style.perCenterCell}>
                        <Grid item className='global-position-relative' sx={{
                            fontSize: {xs: 14, sm: 14, md: 16, lg: 16},
                            width: '100%',
                            height: 36, ...theme.typobox.flexCenterLeft,
                            mr: {xs: 2, sm: 0}
                        }}
                              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <Grid sx={{...theme.palette.mainText}} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Avatar sx={{fontSize: 30}} src={userinfo?.avatar || defaultAvatar}
                                        style={{marginRight: '6px'}}/>{$t('public.grzx')}
                            </Grid>
                            <Grid onClick={() => {
                                props.onClose()
                            }} sx={{display: {xs: 'block', sm: 'none'}}}>{$t('public.fh')}</Grid>
                        </Grid>
                    </Box>
                    {/*<Grid container sx={{ ...theme.typobox.flexCenterSpaceBetween,padding:'20px 0' }}>*/}
                    {/*    <Grid item className='global-position-relative' sx={{ height: 36, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 0 } }}>*/}
                    {/*        <Avatar sx={{ fontSize: 30 }} src={ defaultAvatar} />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={6} sm={6} className='global-position-relative' sx={{ height: 30, ...theme.typobox.flexCenterRight, mr: { xs: 2, sm: 3 }, ml: 2 }}>*/}
                    {/*        <Box className="global-overflow-hidden global-border-radius"*/}
                    {/*             sx={{ ...theme.typobox.flexCenterCenter, width: '100%', ...theme.palette.border, height: 45, fontSize: 16 }}>*/}
                    {/*            <Box className="global-text-nowrap" sx={{ width: 'calc(100% - 60px)' }}>*/}
                    {/*                {visibilityChange(walletCode,[6,4])}*/}
                    {/*            </Box>*/}
                    {/*            <ArrowDropDownIcon sx={{ fontSize: 30 }} />*/}
                    {/*        </Box>*/}
                    {/*    </Grid>*/}
                    {/*    <Copy walletCode={walletCode}></Copy>*/}
                    {/*</Grid>*/}
                    {perCenter.map(v =>
                        <Box sx={{
                            ...theme.palette.mainText, ...{
                                fontSize: {xs: 14, sm: 14, md: 16, lg: 16}, textAlign: 'justify'
                            }
                        }}
                             className={style.perCenterCell} onClick={toNext(v)} key={v.value}>{v.label}</Box>
                    )}
                    <Box sx={theme.typobox.flexCenterCenter} style={{width: '100%'}}>
                        <Box onClick={onEquitLogin} className="global-button" sx={{
                            fontSize: {xs: 16, sm: 18}, ...theme.palette.btn.login,
                            width: 100,
                            height: {xs: 40, sm: 40},
                            borderRadius: 50,
                            color: '#070809',
                            marginTop: '20px',
                            fontWeight: 600
                        }}>{$t('tc')}</Box>
                    </Box>
                </Grid>}
                {/*    钱包管理*/}
                {step == 4 && <Grid container direction="column" justifyContent="flex-start" alignItems="baseline"
                                    style={{padding: '0 20px'}}>
                    <Box sx={{
                        ...theme.palette.mainText, ...{
                            fontSize: {xs: 12, sm: 14, md: 16, lg: 16}, textAlign: 'justify'
                        }
                    }}
                         className={style.perCenterCell}>
                        <Grid container spacing={2} sx={{...theme.palette.mainText,color: '#333333',fontSize: {xs: 16, sm: 18, md: 18}}}>
                            <Grid item xs={1} onClick={onCancel} sx={{...theme.palette.mainText}}>{'<'}</Grid>
                            <Grid item xs={8} onClick={onCancel} sx={{...theme.palette.mainText}}>{$t('walletManage')}</Grid>
                            <Grid item xs={3} sx={{...theme.palette.mainText}} style={{display:"flex",alignItems:"center",justifyContent:"right"}}>
                                <AddCircleOutlineIcon onClick={onConnect} sx={{fontSize: {xs:22,sm:20}}}></AddCircleOutlineIcon>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid sx={{width: '100%'}}>
                        {walletList.map(v => <Grid container spacing={2} sx={{
                            fontSize: {xs: 12, sm: 16, md: 18}, color: '#888888', height: 50, margin: 0,
                            borderBottom: '1px solid #DEE1E1', width: '100%'
                        }} key={v.id}>
                            <Grid item xs={8} sx={{...theme.palette.mainText,fontSize:{xs:18,sm:16}}}>{visibilityChange(v.eth_address, [6, 4])}</Grid>
                            <Grid item xs={2}><Copy walletCode={v.eth_address}></Copy></Grid>
                            <Grid item xs={2} sx={{...theme.palette.mainText,alignItems: "center"}}>
                                <DeleteForeverIcon sx={{fontSize: {xs:22,sm:20}}} onClick={removeAddress(v)}></DeleteForeverIcon>
                            </Grid>
                        </Grid>)}
                    </Grid>
                </Grid>}
                {/*    修改信息*/}
                {step == 6 && <Grid container direction="column" justifyContent="flex-start" alignItems="baseline"
                                    style={{padding: '0 20px'}}>
                    <Box sx={{
                        ...theme.palette.mainText, ...{
                            color: theme.palette.text,
                            fontSize: {xs: 12, sm: 14, md: 16, lg: 16}, textAlign: 'justify'
                        }
                    }}
                         className={style.perCenterCell}>
                        <Grid container spacing={2} sx={{color: '#333333',fontSize: {xs: 16, sm: 18, md: 18}}}>
                            <Grid item xs={1} onClick={onCancel} sx={{...theme.palette.mainText}}>{'<'}</Grid>
                            <Grid item xs={8} onClick={onCancel} sx={{...theme.palette.mainText}}>{$t('editinfo')}</Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                        width: {xs: 50, sm: 50},
                        height: {xs: 50, sm: 50},
                        margin: '30px 0 20px 0',
                        backgroundImage: `url(${infoForm?.avatar || defaultAvatar})`,
                        ...theme.components.backgroundIcon,
                        ...theme.typobox.flexCenterCenter,
                        backgroundRepeat: "no-repeat",
                        borderRadius: {xs: 50, sm: 50},
                        backgroundSize: {xs: '100%', sm: '100%'}
                    }}>
                        <Button component="label" variant="contained" href="#file-upload"
                                sx={{background: "none", boxShadow: 'none'}} className={style.uploadBtn}
                                onChange={onUpload}>
                            <VisuallyHiddenInput type="file"/>
                            <img src={cameraIcon} style={{width: 24, height: 24}}/>
                        </Button>
                    </Box>
                    <Paper component="form" sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                        width: '100%', border: '1px solid #888888', borderRadius: '10px'
                    }}>
                        <InputBase sx={{ml: 1, flex: 1}} placeholder={$t('user.set.inputname')}
                                   defaultValue={userinfo.nickname} onChange={onPutInfo('nickname')}/>
                    </Paper>
                    <Paper component="form" sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                        width: '100%', border: '1px solid #888888', borderRadius: '10px', marginTop: '30px'
                    }}>
                        <InputBase multiline sx={{ml: 1, flex: 1}} placeholder={$t('user.set.inputdes')} minRows={4}
                                   defaultValue={userinfo.description} onChange={onPutInfo('description')}/>
                    </Paper>
                    <Box sx={theme.typobox.flexCenterCenter} style={{width: '100%'}}>
                        <Box onClick={onSubmitInfo} className="global-button" sx={{
                            ...theme.palette.btn.login,
                            width: 100,
                            height: {xs: 25, sm: 40},
                            borderRadius: 50,
                            color: '#070809',
                            marginTop: '20px',
                            fontWeight: 600
                        }}>{$t('public.savebtn')}</Box>
                    </Box>
                </Grid>}
                {/*    地址管理*/}
                {step == 5 && <Address list={addressList} onCancel={onCancel} onUpdate={getAddressList}></Address>}
            {/*    帮助中心*/}
                {step == 7 && <HelpCenter onCancel={onCancel} appUrl={appUrl}></HelpCenter>}
            </Grid>
        </Drawer>
    )
}
export default PerCenterDrawer