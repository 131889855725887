import { globalApiSlice } from '@/features/global/globalSlice'
import { React, useState } from "react"
import { Box, Grid, Avatar, useTheme, TextField, Drawer } from "@mui/material"
import style from "@/components/Mine/mine.module.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import radioIcon from "@/assets/icon/mineIcon/radio.png"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import Switch from "@mui/material/Switch"
import unRadioIcon from "@/assets/icon/mineIcon/unRadio.png"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import { Empty } from '@/components/Mine/list/index'
import VTreeSelect from './treeSelect'
function Address(props) {
    const theme = useTheme()
    const [isAdd, setIsAdd] = useState(false)
    const [address, setAddress] = useState('')
    const [submitType, setSubmitType] = useState(0)
    const [addForm, setAddForm] = useState({
        code: "",
        address: "",
        name: "",
        mobile: "",
        is_default: 0
    })
    const toEdit = (v) => (e) => {
        let form = {
            code: v.code,
            address: v.address,
            name: v.name,
            mobile: v.mobile,
            id: v.id,
            is_default: v.is_default
        }
        setAddForm(form)
        setIsAdd(true)
        setSubmitType(1)
    }
    const hanleDefault = (e) => {
        addForm.is_default = e.target.checked ? 1 : 0
        setAddForm(addForm)
    }
    const onSubmit = (form = {}, type) => {
        let obj = form.target ? {} : form
        let params = Object.assign(_.clone(addForm), obj)
        let phoneReg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
        if (!phoneReg.test(params.mobile)) {
            _toast($t('qsrzqdlxfss'))
            return
        }
        globalApiSlice[(type == 1 || submitType == 1) ? 'modifyAddressApi' : 'addAddressApi'](params).then(res => {
            _toast($t('public.saveSuccess'))
            props.onUpdate()
            setIsAdd(false)
            setSubmitType(0)
        })
    }
    const getInput = (name) => (e) => {
        addForm[name] = e.target.value
        setAddForm(addForm)
    }
    const toDefault = (v) => (e) => {
        let form = {
            code: v.code,
            address: v.address,
            name: v.name,
            mobile: v.mobile,
            id: v.id,
            is_default: v.is_default == 0 ? 1 : 0
        }
        setAddForm(form)
        onSubmit(form, 1)
    }
    const delAddress = (v) => (e) => {
        let res = globalApiSlice.delAddressApi({ id: v.id })
        _toast($t('sccg'))
        props.onUpdate()
    }
    const getAddress = (val) => {
        setAddForm({ ...addForm, code: val })
    }
    return (
        <Grid container direction="column" justifyContent="flex-start" alignItems="baseline"
            style={{ padding: '0 20px' }}>
            <Box sx={{
                ...theme.palette.mainText, ...{
                    color: theme.palette.text,
                    fontSize: { xs: 12, sm: 14, md: 16, lg: 16 }, textAlign: 'justify'
                }
            }}
                className={style.perCenterCell}>
                <Grid container spacing={2} sx={{ color: '#333333', fontSize: { xs: 16, sm: 16, md: 18 } }}>
                    <Grid item xs={1} onClick={() => { props.onCancel() }} sx={{ ...theme.palette.mainText }}>{'<'}</Grid>
                    <Grid item xs={8} onClick={() => { props.onCancel() }} sx={{ ...theme.palette.mainText }}>{$t('dzgl')}</Grid>
                    <Grid item xs={3} sx={{ ...theme.typobox.flexCenterRight }}>
                        <AddCircleOutlineIcon sx={{ ...theme.palette.mainText }} fontSize={'17px'} onClick={() => { setIsAdd(true) }}></AddCircleOutlineIcon>
                    </Grid>
                </Grid>
            </Box>
            {(!isAdd && Number(props.list.length) == 0) && <Grid sx={{ ...theme.typobox.flexCenterCenter, width: '100%' }}>
                <Empty></Empty>
            </Grid>}
            {!isAdd && props.list.map((v, i) => <Grid container spacing={2} sx={{
                color: '#333333', margin: 0,
                borderBottom: '1px solid #DEE1E1', width: '100%', padding: '15px 0'
            }} key={i}>
                <Grid sx={{ ...theme.palette.mainText, width: '100%', fontSize: { xs: 16, sm: 18, md: 18 } }}>{v.name} {v.mobile}</Grid>
                <Grid sx={{ width: '100%', margin: '15px 0', color: "#888888", fontSize: { xs: 14, sm: 14, md: 14 } }}>{v.code}{v.address}</Grid>
                <Grid container direction="row" justifyContent="space-between" sx={{ width: '100%' }}
                    style={{ display: "flex", alignItems: "center" }}>
                    <Grid style={{ display: "flex", alignItems: "center" }}
                        sx={{ fontSize: { xs: 14, sm: 14, md: 14 } }} onClick={toDefault(v)}>
                        <img src={v.is_default == 1 ? radioIcon : unRadioIcon} className={style.radioIcon} style={{ width: '2.5vh', height: '2.5vh' }} />{$t('mas.setdefault')}
                    </Grid>
                    <Grid container direction="row" style={{ display: "flex", alignItems: "center" }}
                        sx={{ width: 'auto', ...theme.palette.mainText }}>
                        <Grid sx={{ ...theme.typobox.flexCenterCenter, marginRight: '20px', fontSize: { xs: 14, sm: 14, md: 14 } }} onClick={toEdit(v)}>
                            <EditIcon fontSize={'15px'}></EditIcon>{$t('mas.edit')}</Grid>
                        <Grid sx={{ ...theme.typobox.flexCenterCenter, fontSize: { xs: 14, sm: 14, md: 14 } }} onClick={delAddress(v)}>
                            <DeleteForeverIcon fontSize={'15px'}></DeleteForeverIcon>{$t('remove')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>)}
            {isAdd && <Grid sx={{ marginTop: '20px', width: '100%' }}>
                <Paper component="form" sx={{
                    p: '2px 4px', display: 'flex', alignItems: 'center',
                    width: '100%', border: '1px solid #888888', borderRadius: '10px', marginTop: '15px'
                }}>
                    <InputBase sx={{ ml: 1, flex: 1, fontSize: { xs: 14, sm: 16 }, ...theme.palette.mainText }} placeholder={$t('qsrshr')}
                        defaultValue={addForm.name} label={$t('qsrshr')} onChange={getInput('name')} />
                </Paper>
                <Paper component="form" sx={{
                    p: '2px 4px', display: 'flex', alignItems: 'center',
                    width: '100%', border: '1px solid #888888', borderRadius: '10px', marginTop: '15px'
                }}>
                    <InputBase sx={{ ml: 1, flex: 1, fontSize: { xs: 14, sm: 16 }, ...theme.palette.mainText }} placeholder={$t('qsrlxfs')} onChange={getInput('mobile')}
                        defaultValue={addForm.mobile} label={$t('qsrlxfs')} />
                </Paper>
                <Paper component="form" sx={{
                    p: '2px 4px', display: 'flex', alignItems: 'center',
                    width: '100%', border: '1px solid #888888', borderRadius: '10px', marginTop: '15px'
                }}>
                    <VTreeSelect getAddress={getAddress}></VTreeSelect>
                </Paper>
                <Paper component="form" sx={{
                    p: '2px 4px', display: 'flex', alignItems: 'center',
                    width: '100%', border: '1px solid #888888', borderRadius: '10px', marginTop: '15px'
                }}>
                    <InputBase sx={{ ml: 1, flex: 1, fontSize: { xs: 14, sm: 16 }, ...theme.palette.mainText }} label={$t('qsrxxdz')} placeholder={$t('qsrxxdz')}
                        defaultValue={addForm.address} onChange={getInput('address')} />
                </Paper>
                <Grid sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, marginTop: '15px', color: "#888888", ...theme.typobox.flexCenterSpaceBetween }}>
                    <Grid sx={{ ...theme.palette.mainText }}>{$t('mas.setdefault')}</Grid>
                    <Switch defaultChecked={addForm.is_default == 1} size="small" onChange={hanleDefault} />
                </Grid>
                <Box sx={theme.typobox.flexCenterCenter} style={{ width: '100%' }}>
                    <Box onClick={onSubmit} className="global-button" sx={{ fontSize: { xs: 16, sm: 18 }, ...theme.palette.btn.login, width: 100, height: { xs: 40, sm: 40 }, borderRadius: 50, color: '#070809', marginTop: '20px', fontWeight: 600 }}>{$t('public.savebtn')}</Box>
                </Box>
                {/*<AddressDialog show={showAddressDialog} onClose={()=>{setShowAddressDialog(false)}}></AddressDialog>*/}
            </Grid>}
        </Grid>
    )
}
export default Address