import { useEffect, useState } from 'react'
import {
  Box, Grid, Tabs, Tab, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, InputAdornment, Select, FormControl, MenuItem, useTheme,
  OutlinedInput, InputLabel, TextField, Button, NativeSelect, Hidden, Skeleton
} from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'

import { useNavigate, useLocation } from 'react-router-dom'
import LIWU from '@/assets/icon/liwu.png'
import { globalApiSlice as GAS } from '@/features/global/globalSlice'
import defaultAvatar from '@/assets/icon/mineIcon/defaultAvatar.png'
import myNftBgi from "@/assets/img/myNftBgi.png"
import DH from '@/assets/dh.gif'

import empty from '@/assets/icon/mineIcon/empty.png'
import CJ from '@/assets/icon/cj.png'
import style from '@/components/Mine/mine.module.css'
import contentEthereum from '@/hooks/contentEthereum.js'
let _index = 0
let _body = void 0

//用户信息组件
function UserInfo(props) {
  const UI = GAS.useUserInfoQuery()
  const userInfo = UI?.data?.user || {}
  const navigate = useNavigate()
  const walletCode = window?.ethereum?.selectedAddress ? window.ethereum.selectedAddress : ''
  return (
    <div className={style.flex}>
      <div style={{ width: '100%', padding: '5vh 0', display: 'flex', alignItems: 'center' }}>
        <div style={{
          width: '70px', height: '70px', borderRadius: '100px',
          backgroundImage: `url(${userInfo && userInfo.avatar ? userInfo.avatar : defaultAvatar})`,
          backgroundSize: 'contain', marginRight: '20px'
        }}></div>
        <div>
          {userInfo && <div>{userInfo.username}</div>}
          {!userInfo && <div onClick={() => navigate('/login')}>{$t('wdl')}</div>}
          {walletCode && <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <div>{walletCode.substring(0, 6) + '****' + walletCode.substring(walletCode.length - 4)}</div>

            <ContentCopyRoundedIcon
              sx={{
                fontSize: '20px',
                color: '#5F6269',
                marginLeft: '5px',
                cursor: 'pointer',
              }}
              onClick={() => copyToClipboard(walletCode)}
            />
          </div>}
          {!walletCode && <div style={{ color: '#888888', fontSize: '1vw' }}>{$t('pljqb')}</div>}
        </div>
      </div>
      {/*<div style={{width:'10vw'}} className={style.flex}>*/}
      {/*    {TopImg.map(item=>{*/}
      {/*        return <img key={item.icon} src={item.icon} width={item.width} height={item.height}/>*/}
      {/*    })}*/}
      {/*</div>*/}
    </div>
  )
}

function Medal() {
  const theme = useTheme()
  const n = useNavigate()
  const [body, setBody] = useState(_body || { type: 1 })
  const [index, setIndex] = useState(_index || 0)
  const LIST = (() => GAS[['useMedalIndex', 'useLotteryList', 'useLotteryLog', 'useLotteryScoreLog'][index] + 'Query'])()({ ...body })
  const [item, setItem] = useState({})

  const MR = GAS.useMedalReceiveQuery({
    ckR: !!(item.id && item.receive == 1),
    id: item.id
  })
  const [l, setL] = useState([])
  const handleC = (e, i) => setIndex(i) & setL([]) & ((b) => {
    setBody(b); _body = b; _index = i
  })(i == 0 ? { type: 1, rd: Math.random() } :
    i == 1 ? { page: 1, per_page: 99999, rd: Math.random() } :
      i == 2 ? { page: 1, per_page: 99999, status: 'all', rd: Math.random() } :
        { mark: 1, rd: Math.random() }
  )
  useEffect(() => {
    const i = window.location.href.match(/\?index=([0-9])/)
    if (i) handleC(null, Number(i[1]))
  }, [])
  useEffect(() => {
    if (LIST.data && index == 0) { setL(LIST.data) } else
      if (LIST.data && index == 1) { setL(LIST.data.data) } else
        if (LIST.data && index == 2) { setL(LIST.data.data) } else
          if (LIST.data && index == 3) { setL(LIST.data.data) }
    if (MR.error && MR.status == 'rejected') {
      _toast(MR.error)
      setItem(() => ({}))
    } else if (!MR.error && MR.status == 'fulfilled' && item.id && item.receive == 1) {
      _toast($t('lqcg'))
      setItem(() => ({}))
      setBody(v => ({ ...v, rd: Math.random() }))
    }
  }, [LIST.data, item, MR])

  return (
    <Box className="global-position-relative  global-content-auto" sx={{
      ...theme.palette.warp,
      paddingBottom: 0,
      background: theme.palette.mode == 'dark' ? '#444848' : '#F6F5FA',
      backgroundImage: `url(${myNftBgi})`,
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat'
    }}>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0px auto' }}>
        <UserInfo />
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0px auto', mb: 2 }}>
        <Tabs value={index}
          onChange={handleC} aria-label="basic tabs example">
          {$t('masMenuList').map(v => v.name).map((v, i) => {
            return <Tab key={i} label={v} {...((index) => ({
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            })(i))}></Tab>
          })}
        </Tabs>
      </Grid>

      {/* <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
        {
          ['勋章', '抽奖', '我的抽奖', 'MAS记录'].map((v, i) => <Box
            className="global-border-radius"
            onClick={() => setIndex(i) & setL([]) & setBody(
              i == 0 ? { type: 1, rd: Math.random() } :
                i == 1 ? { page: 1, per_page: 99999, rd: Math.random() } :
                  i == 2 ? { page: 1, per_page: 99999, status: 'all', rd: Math.random() } :
                    { mark: 1, rd: Math.random() }
            )}
            sx={{
              padding: '5px 20px',
              fontSize: { xs: 12, sm: 16 },
              mr: 2,
              color: i == index ? '#fff' : theme.palette.text.color,
              background: i == index ? 'linear-gradient(90deg, #248AE8, #35F4D2)' : '#EDEDED'
            }}
            key={v}>{v}</Box>)
        }
      </Grid > */}

      {
        index === 0 ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0px auto', mt: 2, mb: 2 }}>
          {
            $t('mas.xztab').map((v, i) => <Box
              className="global-border-radius"
              onClick={(e) => setIndex(0) & setL([]) & ((b) => {
                setBody(b); _body = b; _index = 0
              })({ type: i + 1, rd: Math.random() & e.stopPropagation() })}
              sx={{
                padding: '5px 20px',
                fontSize: { xs: 12, sm: 16 },
                mr: 2,
                color: i == body.type - 1 ? '#fff' : theme.palette.text.color,
                background: i == body.type - 1 ? 'linear-gradient(90deg, #248AE8, #35F4D2)' : '#EDEDED'
              }}
              key={v}>{v}</Box>)
          }
        </Grid > : ''
      }

      {
        index === 2 ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
          {
            [{ name: $t('public.all'), value: 'all' }, { name: $t('mas.wzj'), value: 0 }, { name: $t('mas.yzj'), value: 1 }, { name: $t('mas.ylq'), value: 2 }, { name: $t('mas.yfj'), value: 3 }].map((v, i) => <Box
              className="global-border-radius"
              onClick={() => setIndex(2) & setL([]) & ((b) => {
                setBody(b); _body = b; _index = 2
              })({ status: v.value, rd: Math.random() })}
              sx={{
                padding: { xs: '5px 10px', sm: '5px 20px' },
                fontSize: { xs: 12, sm: 16 },
                mr: 2,
                mb: 1,
                color: v.value == body.status ? '#fff' : theme.palette.text.color,
                background: v.value == body.status ? 'linear-gradient(90deg, #248AE8, #35F4D2)' : '#EDEDED'
              }}
              key={v.value}>{v.name}</Box>)
          }
        </Grid > : ''
      }

      {
        index === 3 ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
          {
            [{ name: $t('mas.hq'), value: '1' }, { name: $t('mas.sy'), value: '2' }].map((v, i) => <Box
              className="global-border-radius"
              onClick={() => setIndex(3) & setL([]) & ((b) => {
                setBody(b); _body = b; _index = 3
              })({ mark: v.value, rd: Math.random() })}
              sx={{
                padding: '5px 20px',
                fontSize: { xs: 12, sm: 16 },
                mr: 2,
                color: v.value == body.mark ? '#fff' : theme.palette.text.color,
                background: v.value == body.mark ? 'linear-gradient(90deg, #248AE8, #35F4D2)' : '#EDEDED'
              }}
              key={v.value}>{v.name}</Box>)
          }
        </Grid > : ''
      }

      {
        LIST.status == 'fulfilled' && l.length == 0 ?
          <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto', ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterCenter }}>
            <Box sx={{ width: { xs: '100%', sm: 200 }, ...theme.typobox.flexCenterCenterByColumn, ...theme.palette.text }}>
              <img src={empty} className='global-img' />
              {$t('nodata')}
            </Box>
          </Grid>
          : ''
      }

      {
        index == 0 && LIST.status == 'fulfilled' ?
          <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto', ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }}>
            {
              (body.type == 1 || body.type == 2) ?
                l?.map(v => <Box
                  key={v.id}
                  className="global-border-radius"
                  onClick={() => n('/medal/luck?id=' + v.id)}
                  sx={{
                    width: { xs: '46%', sm: 250 },
                    mb: 2,
                    ...theme.typobox.flexCenterCenterByColumn,
                    ...theme.palette.warp.box
                  }}>
                  <Box sx={{
                    width: 100,
                    height: 100,
                    mt: 3,
                    mb: 2,
                    borderRadius: '50%',
                    background: '#24D5BA',
                    ...theme.components.backgroundIcon,
                    backgroundImage: `url(${v.image})`,
                    backgroundSize: 'cover'
                  }}></Box>
                  <Box className="global-font-weight-bold" sx={{ fontSize: { xs: 16, sm: 18 } }}>{v.name}</Box>
                  <Box sx={{ ...theme.typobox.flexCenterCenter, m: 1, fontSize: { xs: 16, sm: 18 }, fontWeight: 'bold' }}>{v.mas}<Box sx={{ fontWeight: 400, fontSize: { xs: 15, sm: 17 }, ...theme.palette.text }}>/MAS</Box></Box>
                  <Box className="global-text-nowrap-2" sx={{ ml: 4, mr: 4, textAlign: 'justify', fontSize: { xs: 14, sm: 16 }, height: { xs: 4, sm: 50 }, ...theme.palette.text }}>{v.intro}</Box>
                  <Box onClick={(e) => setItem(v) & e.stopPropagation()} className="global-border-radius" sx={{ ...theme.palette.btn.login, ...theme.typobox.flexCenterCenter, width: { xs: 100, sm: 160 }, height: { xs: 40, sm: 40 }, m: 2, background: v.receive == 0 ? '#9CA3AF' : theme.palette.btn.login.background }}>{$t('mas.lq')}</Box>
                </Box>)
                :
                l?.map(v => <Box className="global-border-radius"
                  key={v.id}
                  onClick={() => n('/medal/luck?id=' + v.id)}
                  sx={{
                    width: { xs: '46%', sm: 250 },
                    mt: 2,
                    height: 200,
                    ...theme.typobox.flexCenterCenterByColumn,
                    // ...theme.palette.warp.box
                  }}>
                  <Box sx={{
                    width: 100,
                    height: 100,
                    ...theme.components.backgroundIcon,
                    backgroundImage: `url(${v.image})`,
                    mt: 3,
                    mb: 2,
                    borderRadius: '50%',
                    backgroundSize: 'cover'
                  }}></Box>
                  <Box className="global-font-weight-bold" sx={{ fontSize: { xs: 16, sm: 18 }, mb: 2 }}>{v.name}</Box>
                </Box>)

            }
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 1000} sx={{ width: { xs: '46%', sm: 250 } }} />)}
          </Grid > : ''
      }
      {
        index == 1 && LIST.status == 'fulfilled' ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
          {
            l?.map(v => <Grid container
              onClick={() => n('/medal/prize?id=' + v.id)}
              item key={v.id} className='global-border-radius global-box-sizing-border' xs={12} sx={{ ...theme.palette.warp.box, padding: 3, mb: 2 }}>
              <Grid item xs={9}>
                <Box className="global-text-nowrap" sx={{ fontSize: { xs: 16, sm: 20 } }}>{v.title}</Box>
                <Box sx={{ ...theme.typobox.flexCenterLeft, fontSize: { xs: 14, sm: 18 }, mt: 1 }}>
                  <Box sx={{ width: { xs: 20, sm: 30, flexShrink: 0 }, height: { xs: 20, sm: 30 }, mr: 1 }}>
                    <img className='global-img' src={LIWU} />
                  </Box>
                  {$t('mas.jp')}：{v?.items?.map(v => `${Number(v.amount) > 1 ? Number(v.amount) : ''} ${v.name} x ${v.quantity}`)}
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ ...theme.typobox.flexRevertRight }}>
                <Box className="global-border-radius" sx={{ color: '#fff', background: v.lo_status == 1 ? theme.palette.btn.fnc.background : '#9CA3AF', ...theme.typobox.flexCenterCenter, fontSize: { xs: 14, sm: 20 }, width: { xs: 70, sm: 160 }, height: { xs: 40, sm: 40 } }}>
                  {v.lo_status == 0 ? $t('mas.wks') : v.lo_status == 1 ? $t('mas.jxz') : $t('mas.yjs')}
                </Box>

              </Grid>
            </Grid>)
          }

        </Grid > : ''
      }

      {
        index == 2 && LIST.status == 'fulfilled' ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
          {
            l?.map(v => <Grid container
              onClick={() => n('/medal/prize?id=' + v.lottery_id)}
              item key={v.id} className='global-border-radius global-box-sizing-border' xs={12} sx={{ ...theme.palette.warp.box, padding: 3, mb: 2 }}>
              <Grid item xs={9}>
                <Box className="global-text-nowrap" sx={{ fontSize: { xs: 16, sm: 20 } }}>{v.title}</Box>
                <Box sx={{ ...theme.typobox.flexCenterLeft, fontSize: { xs: 14, sm: 18 }, mt: 1 }}>
                  <Box sx={{ width: { xs: 20, sm: 30 }, height: { xs: 20, sm: 30 }, mr: 1 }}>
                    <img className='global-img' src={LIWU} />
                  </Box>
                  {$t('mas.wdjp')}：{Number(v.amount) > 1 ? Number(v.amount) : ''} {v.name}
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ ...theme.typobox.flexRevertRightByColumn }}>
                <Box className="global-border-radius" sx={{ color: '#fff', background: v.status != 0 ? theme.palette.btn.fnc.background : '#9CA3AF', ...theme.typobox.flexCenterCenter, fontSize: { xs: 14, sm: 20 }, width: { xs: 70, sm: 160 }, height: { xs: 40, sm: 40 } }}>
                  {v.status == 0 ? $t('mas.wzj') : v.status == 1 ? $t('mas.yzj') : v.status == 2 ? $t('mas.ylq') : $t('mas.yfj')}
                </Box>
                {/* <Box>xxxxxxxx</Box> */}
              </Grid>
            </Grid>)
          }

        </Grid > : ''
      }

      {
        index == 3 && LIST.status == 'fulfilled' ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
          {
            l?.map(v => <Grid container item key={v.id} className='global-border-radius global-box-sizing-border' xs={12} sx={{ ...theme.palette.warp.box, padding: 3, mb: 2 }}>
              <Grid item xs={9}>
                <Box className="global-text-nowrap" sx={{ fontSize: { xs: 16, sm: 20 } }}>{v.memo}</Box>
                <Box sx={{ ...theme.typobox.flexCenterLeft, ...theme.palette.text, fontSize: { xs: 14, sm: 18 }, mt: 1 }}>
                  {m.unix(v.createtime).format('YYYY/MM/DD HH:mm')}
                </Box>
              </Grid>
              <Grid className='global-font-weight-bold' item xs={3} sx={{ ...theme.typobox.flexCenterRight }}>
                + {v.score}
              </Grid>
            </Grid>)
          }

        </Grid > : ''
      }
    </Box >
  )
}

export default Medal

export function Luck() {
  const l = useLocation()
  const t = useTheme()
  const [ld, setLd] = useState(true)

  const query = new URLSearchParams(l.search)
  const id = query.get('id')
  const MS = GAS.useMedalShowQuery({ id })

  useEffect(() => {
    $('#dsaf').html(MS?.data?.rule_info)
    $('#fajk').html(MS?.data?.detail)
    if (![MS].filter(v => !v.data).length && ld) setLd(false)

  }, [MS.data])
  return (
    <Box className="global-content-auto">
      {ld ? <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, ...t.palette.warp.box, ...t.palette.mainText, zIndex: 2 }}>{[300, 40, 40, 40, 40].map((v, i) => <Skeleton variant="circle" animation='wave' height={v} key={i} sx={{ m: 4 }} />)}</Box> : ''}
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
        <Grid container item xs={12} sx={{ ...t.typobox.flexRevertSpaceBetween, mt: 2 }}>
          <Box sx={{
            width: { xs: 80, sm: 150 }, height: { xs: 80, sm: 150 }, borderRadius: '50%', ...t.components.backgroundIcon,
            backgroundImage: `url(${MS?.data?.image})`,
            backgroundSize: 'cover'
          }}></Box>
          <Box sx={{ width: { xs: 'calc(100% - 90px)', sm: 'calc(100% - 200px)' }, ml: { xs: '10px', sm: '50px' } }}>
            <Box sx={{ ...t.typobox.flexRevertSpaceBetween }}>
              <Box className="global-font-weight-bold" sx={{ fontSize: { xs: 18, sm: 24 }, ...t.palette.mainText }}>{MS?.data?.name}</Box>
              <Box sx={{ fontSize: { xs: 16, sm: 22 }, ...t.palette.mainText }}>MAS: 222</Box>
            </Box>
            <Box sx={{ fontSize: { xs: 14, sm: 18 }, ...t.palette.text, lineHeight: 1.5, mt: { xs: 0, sm: 4 }, textAlign: 'justify' }} id="fajk"></Box>
          </Box>
        </Grid>
        <Grid container item xs={12} sx={{ mt: { xs: 2, sm: 4 } }}>
          <Grid item xs={12} sx={{ fontSize: { xs: 16, sm: 22 }, ...t.palette.mainText }}>{$t('mas.getmethod')}：</Grid>
          <Grid item xs={12} sx={{ ...t.palette.text, fontSize: { xs: 14, sm: 20 }, lineHeight: 2, textAlign: 'justify' }} id="dsaf">
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ mt: 4, ...t.typobox.flexCenterCenter }}>
          <Box className="global-border-radius" sx={{ ...t.palette.btn.login, ...t.typobox.flexCenterCenter, width: { xs: '100%', sm: 600 }, height: { xs: 40, sm: 60 }, mt: 2, background: MS?.data?.status != 2 ? '#9CA3AF' : t.palette.btn.login.background }}>
            {MS?.data?.status == 2 ? $t('mas.xztab')[1] : MS?.data?.status == 3 ? $t('mas.ylq') : $t('mas.bklq')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export function Prize() {
  const l = useLocation()
  const t = useTheme()
  const [s, setS] = useState(0)
  const [ld, setLd] = useState(true)
  const [data, setData] = useState({})
  const [item, setItem] = useState({})
  const [rd, setRd] = useState(0)

  const query = new URLSearchParams(l.search)
  const id = query.get('id')
  const LI = GAS.useLotteryInfoQuery({ id })

  const LD = GAS.useLotteryDrawQuery({ id, ckR: !!(s == 2), rd })

  useEffect(() => {
    if (LI.data) setData(LI.data)
    if (LD.data && s == 2) {
      setS(3)
      if (LD.data.status == 0) {
        setTimeout(() => { setS(4) }, 1000)
      } else {
        setItem(LD.data)
        setTimeout(() => { setS(5) }, 1000)
      }
    } else if (s == 2 && LD.status == 'rejected') {
      setS(0)
    }

    $('#safsa').html(LI?.data?.desc)
  }, [LI.data, s, LD])

  return (
    <Box className="global-content-auto">
      {/* <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
        <Box className="global-border-radius" sx={{
          width: '100%',
          height: { xs: 200, sm: 300 },
          background: '#553816'
        }}></Box>
      </Grid> */}
      {
        s == 3 ? <Box className="global-position-fixed" sx={{ ...t.typobox.flexCenterCenter, width: '100%', top: 0, left: 0 }}>
          <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
            <img src={DH} className='global-img' style={{ background: 'rgba(0,0,0,0)' }} />
          </Box>
        </Box> : ''
      }
      <Dialog
        open={s == 1}
        onClose={() => setS(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >{$t('mas.qrcj')}</DialogTitle>
        <DialogContent>
          <DialogContentText >
            {$t('mas.qrxh')}{data.score}MAS{$t('mas.dh')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setS(0)} color="primary">
            {$t('cancel')}
          </Button>
          <Button onClick={() => setS(2)} color="primary" autoFocus>
            {$t('market.determine')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={s == 5}
        onClose={() => setS(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >{$t('mas.gxzj')}</DialogTitle>
        <DialogContent>
          {
            item?.lottery_item_category == 1 || item?.lottery_item_category == 2 ?
              <Box sx={{ width: 500 }}>{+item.amount} {item.name}</Box> :
              <Box sx={{ width: 500 }}>{item.name}</Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setS(0)} color="primary">
            {$t('market.determine')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={s == 4}
        onClose={() => setS(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{$t('mas.xxcy')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {$t('mas.hyh')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setS(0)} color="primary">
            {$t('market.determine')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid id="safsa" container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto' }}>
        {/* {data.desc} */}
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '20px auto', ...t.palette.mainText, ...t.typobox.flexCenterCenter }}>
        <Grid item xs={8} sx={{ ...t.typobox.flexCenterLeft }}>
          <Box sx={{ width: 25, height: 25, mr: 2, flexShrink: 0 }}><img className='global-img' src={CJ}></img></Box>
          <Box className='global-font-weight-bold' sx={{ fontSize: { xs: 20, sm: 24 } }}>{data.title}</Box>
        </Grid>
        <Grid item xs={4} sx={{ ...t.typobox.flexCenterRight }}>
          <Box className="global-border-radius" sx={{ width: 120, height: 50, background: data.lo_status == 1 ? t.palette.btn.fnc.background : '#9CA3AF', color: '#fff', ...t.typobox.flexCenterCenter, fontSize: { xs: 14, sm: 20 }, ...t.typobox.flexCenterCenter }}>
            {data.lo_status == 0 ? $t('mas.wks') : data.lo_status == 1 ? $t('mas.jxz') : $t('mas.yjs')}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>{$t('mas.jp')}：<Box>{data?.items?.map(v => `${Number(v.amount) > 1 ? Number(v.amount) : ''} ${v.name} x ${v.quantity}`)}</Box></Box>
          <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>{$t('mas.kjsj')}：<Box>{m.unix(data.start_at).format('YYYY/MM/DD HH:mm')}  ~  {m.unix(data.end_at).format('YYYY/MM/DD HH:mm')}</Box></Box>
          {/* <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>勋章要求：<Box>勋章</Box></Box> */}
          <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>{$t('xh')}MAS：<Box>{data.score}</Box></Box>
          {/* <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>限制次数：<Box>1</Box></Box> */}
          <Box sx={{ ...t.typobox.flexCenterLeft, mt: 2 }}>{data.joins}{$t('mas.cyrs')}</Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4, ...t.typobox.flexCenterCenter }}>
          <Box onClick={() => data.lo_status == 1 ? setRd(Math.random()) & setS(1) : ''} className="global-border-radius" sx={{ ...t.palette.btn.login, ...t.typobox.flexCenterCenter, width: { xs: '100%', sm: 600 }, height: { xs: 60, sm: 80 }, mt: 2, background: LI?.data?.lo_status != 1 ? '#9CA3AF' : t.palette.btn.login.background }}>
            {data.lo_status == 1 ? $t('mas.ljcj') : data.lo_status == 0 ? $t('mas.wks') : $t('mas.yjs')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

function copyToClipboard(content) {
  const copyDom = document.createElement('textarea')
  copyDom.value = content
  copyDom.style.position = 'absolute'
  copyDom.style.top = '0'
  copyDom.style.left = '-9999px'
  document.body.appendChild(copyDom)

  copyDom.select()
  let successful = document.execCommand('copy')
  document.body.removeChild(copyDom)
  // console.log(successful,content);
  if (successful) _toast($t('public.copySuccess'))
}
