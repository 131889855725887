
import { useTheme, Box, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { globalApiSlice as GAS } from '@/features/global/globalSlice'
import { baseQuery } from '@/app/api/apiSlice'
import { useEffect, useState, useReducer as uR, useRef } from 'react'
import styles from './index.module.css'

import loginBgP from '@/assets/img/loginBgP.png'
import loginBgPD from '@/assets/img/loginBgPD.png'

const formTypo = {
  width: { xs: 'calc(100% - 60px)', sm: 280, md: 350 },
  height: { xs: 50, sm: 50, md: 50 },
}

const lineTypo = {
  marginBottom: { xs: 2, sm: 2, md: 2 },
}

const formWarpTypp = {
  width: { xs: 'calc(100% - 60px)', sm: 380, md: 450 },
  height: { xs: 500, sm: 450, md: 500 },
  padding: '30px 0',
}

function Warp(p) {
  const theme = useTheme()
  return (
    <Box className="global-position-relative" sx={formWarpTypp} style={{
      ...theme.typobox.flexCenterCenterByColumn, ...theme.palette.warp.box, ...{
        boxShadow: '0px 5px 60px 0px rgba(64, 64, 64, 0.13)',
        borderRadius: 20
      }
    }} >
      {p.children}
    </Box>
  )
}

function Reset(p) {
  const n = useNavigate()
  const theme = useTheme()

  const [e, setE] = useState('')
  const [s, setS] = useState(0)
  const [m, setM] = useState(0)
  const [code, setCode] = useState('')
  const [pd, setPd] = useState('')
  const [rp, setRp] = useState('')
  const [time, setTime] = useState(null)

  const MF = GAS.useMemberFindPasswordQuery({
    email: e,
    password: pd,
    verify_code: code,
    ckR: !!(e && pd && code && s == 3)
  })

  useEffect(() => {
    if (s == 1) {
      if (time == null) {
        const t = (() => { let i = 60; const time = setInterval(() => { i--; i ? setM(v => v - 1) : clearInterval(time) & setTime(null) & setM(v => v - 1) & setS(0) }, 1000); return time })()
        setTime(v => t)
      }
    }

    if (s == 2) {
      setS(0)
      const reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
      if (!e) { _toast($t('inputemail')) } else
        if (!reg.test(e)) { _toast($t('qsrzqdyx')) } else
          if (!code) { _toast($t('inputcode')) } else
            if (!pd) { _toast($t('inputpw')) } else
              if (!(pd == rp)) { _toast($t('notwo')) } else { setS(3) }
    }

    if (s == 3) {
      if (MF.error) { _toast(MF.error); setS(0) } else if (
        MF.status == "fulfilled" && !MF.error
      ) {
        _toast($t('pwChangeS'))
        n('/login')
        setS(0)
      }
    }
  }, [s, MF])
  const smsPost = () => {
    if (s) return
    fetch(
      import.meta.env.VITE_API_URL
      + '/common'
      + '/sendCode',
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'accept-language': 'zh_CN',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: e,
          scene: 2
        })
      }
    ).then(() => {
      const reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
      if (reg.test(e)) {
        setS(1)
        setM(60)
        _toast('ok')
      } else {
        _toast($t('qsrzqdyx'))
        setS(0)
      }
    }).catch(err => {
      console.log('err====fret', err)
      _toast(err)
    })
  }
  return (
    <Warp {...theme}>
      <Box sx={{ position: 'absolute', left: 20, top: 20, fontSize: { xs: 12, sm: 16 }, color: '#24D5BA' }} onClick={() => n(-1)}>{$t('public.fh')}</Box>
      <Box className="global-position-relative" sx={{ ...formTypo, ...lineTypo, textAlign: { xs: 'center' } }}>{$t('forgetpw')}</Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <input className='global-input' placeholder={$t('public.inputemail')} value={e} onInput={e => setE(e.target.value)} />
      </Box>
      <Box className="global-position-relative" sx={{ ...formTypo, fontSize: { xs: 12, sm: 14, md: 16 }, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input className='global-input' placeholder={$t('public.inputcode')} value={code} onInput={e => setCode(e.target.value)} />
        <Box onClick={smsPost} className={styles.yzm}>{!m ? $t('public.getcode') : m}</Box>
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input type='password' className='global-input' placeholder={$t('szxddlmm')} value={pd} onInput={e => setPd(e.target.value)} />
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input type='password' className='global-input' placeholder={$t('qrdlmm')} value={rp} onInput={e => setRp(e.target.value)} />
      </Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <button onClick={() => setS(2)} style={theme.palette.btn.login} className='global-button'>{$t('xgmm')}</button>
      </Box>
    </Warp>
  )
}

function Resigned(props) {
  const fUp = uR(bool => !bool)[1]
  const n = useNavigate()
  const theme = useTheme()
  const [e, setE] = useState('')
  const [s, setS] = useState(0)
  const [m, setM] = useState(0)
  const [code, setCode] = useState('')
  const [p, setP] = useState('')
  const [rp, setRp] = useState('')
  const [i, setI] = useState('')
  const [time, setTime] = useState(null)

  const [checked, setChecked] = useState(false)


  const { useMemberRegisterQuery } = GAS

  const MR = useMemberRegisterQuery({
    email: e,
    password: p,
    verify_code: code,
    inviter: i,
    ckR: !!(e && p && code && s == 3)
  })

  useEffect(() => {
    if (s == 1) {
      if (time == null) {
        const t = (() => { let i = 60; const time = setInterval(() => { i--; i ? setM(v => v - 1) : clearInterval(time) & setTime(null) & setM(v => v - 1) & setS(0) }, 1000); return time })()
        setTime(v => t)
      }
    }

    if (s == 2) {
      setS(0)
      const reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
      if (!e) { _toast($t('public.inputemail')) } else
        if (!reg.test(e)) { _toast($t('qsrzqdyx')) } else
          if (!code) { _toast($t('public.inputcode')) } else
            if (!p) { _toast($t('public.inputpw')) } else
              if (!(p == rp)) { _toast($t('lcsrdmmbyy')) } else
                if (!checked) { _toast($t('readUser')) } else { setS(3) }
    }

    if (s == 3) {
      if (MR.error) { _toast(MR.error); setS(0) } else if (
        MR.status == "fulfilled" && !MR.error
      ) {
        _toast($t('registerSuccess'))
        n('/login')
        setS(0)
      }
    }
  }, [s, MR])
  const smsPost = () => {
    if (s) return
    const reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
    if (!reg.test(e)) { _toast($t('qsrzqdyx')); return }
    fetch(
      import.meta.env.VITE_API_URL
      + '/common'
      + '/sendCode',
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'accept-language': 'zh_CN',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: e,
          scene: 1
        })
      }
    ).then(() => {
      const reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
      if (reg.test(e)) {
        setS(1)
        setM(60)
        _toast('ok')
      } else {
        _toast($t('qsrzqdyx'))
        setS(0)
      }
    }).catch(err => {
      console.log('err====fret', err)
      _toast(err)
    })
  }
  return (
    <Warp {...props}>
      <Box sx={{ position: 'absolute', left: 20, top: 20, fontSize: { xs: 12, sm: 16 }, color: '#24D5BA' }} onClick={() => n(-1)}>{$t('public.fh')}</Box>
      <Box className="global-position-relative" sx={{ ...formTypo, ...lineTypo, textAlign: { xs: 'center' }, pt: 2 }}>{$t('public.register')}X-Meta·HK</Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <input className='global-input' placeholder={$t('public.inputemail')} value={e} onInput={e => setE(e.target.value)} />
      </Box>
      <Box className="global-position-relative" sx={{ ...formTypo, fontSize: { xs: 12, sm: 14, md: 16 }, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input className='global-input' placeholder={$t('public.inputcode')} value={code} onInput={e => setCode(e.target.value)} />
        <Box onClick={smsPost} className={styles.yzm}>{!m ? $t('public.getcode') : m}</Box>
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input type='password' className='global-input' placeholder={$t('szxddlmm')} value={p} onInput={e => setP(e.target.value)} />
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input type='password' className='global-input' placeholder={$t('qrdlmm')} value={rp} onInput={e => setRp(e.target.value)} />
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input className='global-input' placeholder={$t('public.inputinviteCode')} value={i} onInput={e => setI(e.target.value)} />
      </Box>
      <Box sx={{
        ...formTypo,
        ...theme.typobox.flexCenterLeft,
        ...{
          fontSize: { xs: 6, sm: 12, md: 10 },
          marginBottom: { xs: 2, sm: 2, md: 4 }
        },

      }}>
        <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} sx={{ fontSize: { xs: 20, sm: 20, md: 20 } }} size="0" />
        {/* 确认注册代表已阅读并同意<a target="_bank" href={`https://file.x-meta.hk/user/fwtk-zh-Hans.html`}><Box sx={{ color: '#24D5BA' }}>{'《服务协议》'}</Box></a> */}
        {$t('confirmUser')}<Box sx={{ color: '#24D5BA' }} onClick={() => n('/link', { state: { name: `yhxy` } })}>{`《${$t('fwxy')}》`}</Box>
      </Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <button onClick={() => setS(2)} style={theme.palette.btn.login} className='global-button'>{$t('public.register')}</button>
      </Box>
    </Warp>
  )
}


function Login(props) {
  const theme = useTheme()
  const n = useNavigate()

  const [e, setE] = useState('')
  const [p, setP] = useState('')
  const [checked, setChecked] = useState(true)
  const [ULE, setULE] = useState(sessionStorage.getItem('ULE'))
  const [s, setS] = useState(0)

  const LG = GAS.useLoginQuery({ e, p, ckR: !!s })

  const handleLogin = async () => {
    const result = await GAS.loginApi({ email: e, password: p })
    if (ULE) { setE(ULE); setChecked(true) }
    if (checked) { sessionStorage.setItem('ULE', e) } else { sessionStorage.setItem('ULE', '') }
    sessionStorage.setItem('TOKEN', result.token)
    sessionStorage.setItem('USER', JSON.stringify(result.user))
    window.TOKEN = result.token
    window.USER = result.user
    n('/home')
  }

  useEffect(() => {
    if (ULE) { setE(ULE); setChecked(true) }
    if (s == 1 && LG.data && LG.status == 'fulfilled') {
      if (checked) { sessionStorage.setItem('ULE', e) } else { sessionStorage.setItem('ULE', '') }
      sessionStorage.setItem('TOKEN', LG.data.token)
      sessionStorage.setItem('USER', JSON.stringify(LG.data.user))
      window.TOKEN = LG.data.token
      window.USER = LG.data.user
      n('/home')
    } else if (LG.status == 'rejected') {
      setS(0)
    }
  }, [ULE, LG])

  return (
    <Warp {...theme}>
      <Box sx={{ ...formTypo, ...lineTypo, width: { xs: 120, sm: 120, md: 162 } }} className={'global-logo'} style={theme.components.backgroundIcon} ></Box>
      <Box sx={{ ...formTypo, ...lineTypo, textAlign: { xs: 'center' } }}>{$t('welcome')}</Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <input className='global-input' placeholder={$t('public.inputemail')} value={e} onInput={e => setE(e.target.value)} />
      </Box>
      <Box sx={{ ...formTypo, marginBottom: { xs: 2, sm: 2, md: 2 } }}>
        <input className='global-input' type='password' placeholder={$t('public.inputpw')} value={p} onInput={p => setP(p.target.value)} />
      </Box>
      <Box sx={{
        ...formTypo,
        ...theme.typobox.flexCenterLeft,
        ...{
          fontSize: { xs: 6, sm: 12, md: 10 },
          marginBottom: { xs: 2, sm: 2, md: 4 }
        },

      }}>
        <Checkbox sx={{ fontSize: { xs: 20, sm: 20, md: 20 } }} checked={checked} onChange={e => setChecked(e.target.checked)} size="0" />
        {$t('jzzh')}
      </Box>
      <Box sx={{ ...formTypo, ...lineTypo }}>
        <button onClick={() => handleLogin()} style={theme.palette.btn.login} className='global-button'>{$t('public.login')}</button>
      </Box>
      <Box sx={{ ...formTypo, ...lineTypo, ...{ fontSize: { xs: 10, sm: 14, md: 16 }, marginBottom: 0 } }} style={theme.typobox.flexCenterSpaceBetween}>
        <div onClick={() => n('/login?reset=true')} className='global-pointer'>{$t('forgetpw')}</div>
        <div onClick={() => n('/login?resigned=true')} className='global-pointer'>{$t('public.registers')}</div>
      </Box>
    </Warp>
  )
}

function Main(props) {
  const theme = useTheme()

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  console.log(query.get('resigned'))

  let Component = Login

  if (query.get('resigned')) Component = Resigned

  if (query.get('reset')) Component = Reset

  return (
    <Grid container item xs={12} sx={
      { alignItems: { xs: 'end', sm: 'center' } }
    } className={styles.box} style={{ backgroundImage: theme.palette.mode == 'dark' ? `url(${loginBgPD}))` : `url(${loginBgP})` }}>
      <Grid item xs={12} sm={8} sx={
        { marginBottom: { xs: 5, sm: 0 } }
      } className={styles.loginBox}>
        <Component {...props} />
      </Grid>
    </Grid>
  )
}

export default Main