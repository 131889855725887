import CircularProgress from "@mui/material/CircularProgress";
import {Box, Grid} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import React,{useContext} from "react";
import AppContext from "@/hooks/useAppContext";

function Loading(props){
    const { store,setStore } = useContext(AppContext)
    window.showLoad = (text) =>{
        setStore(v => ({ ...v, shelfLoad:{loading:true, text:text}}))
    }
    window.closeLoad = () =>{
        setStore(v => ({ ...v, shelfLoad:{loading:false, text:''}}))
    }
    return(
        <Box>
            {/* (theme) => theme.zIndex.drawer + 1 */}
            <Backdrop sx={{ color: '#fff', zIndex: 9999 }}
                      open={store.shelfLoad.loading} style={{flexDirection:"column"}}>
                <CircularProgress color="inherit" />
                <Grid>{store.shelfLoad.text}</Grid>
            </Backdrop>
        </Box>
    )
}
export default Loading