import * as React from 'react'
import Radio from '@mui/material/Radio'
import { styled } from '@mui/material/styles'
import style from '@/components/Mine/mine.module.css'
import { omit } from '@/components/Mine/data'
import {
    Accordion, AccordionDetails, AccordionSummary,
    FormControlLabel,
    Hidden,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Box, useTheme, Grid } from "@mui/material"
import { selectMode } from '@/features/user/userSlice'
import { useSelector } from 'react-redux'

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein }
}

export default function BasicTable(data) {
    const columns = [
        { label: $t('public.hj'), prop: "name", key: 1 },
        { label: $t('home.dbj'), prop: "floor_price", key: 2 },
        { label: $t('cje'), prop: "change_amount", key: 3 },
        { label: $t('jys'), prop: "tx_amount", key: 4 },
        { label: $t('series.sjl'), prop: "up_rate", key: 5 },
        { label: $t('series.cyzfsd'), prop: "holder_dispersion", key: 6 }
    ]
    const theme = useTheme()
    const mode = useSelector(selectMode)
    const navigate = useNavigate()
    const sticky = {
        position: { xs: 'sticky', sm: 'none' },
        left: { xs: 0, sm: '' }
    }
    const [sortType, setSortType] = React.useState(1)
    const [expanded, setExpanded] = React.useState(false)
    const handleChangeExpanded = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const onClick = () => {
        setSortType(sortType == 1 ? 2 : 1)
        data.click()
    }
    return (
        <Grid className={style.rankTable} sx={{ width: '100%', overflowX: { xs: 'auto', sm: 'hidden' } }}>
            <Grid
                container
                item
                sx={{ margin: '0 auto', width: '100%' }}
                className="size16"
            >
                {
                    data?.list?.length > 0 ? (
                        <TableContainer component={Paper} sx={{ marginTop: '30px' }}
                            style={{ background: "none", boxShadow: "none" }}>
                            <Table sx={{ width: 1 }}>
                                <Hidden>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((item, index) => (
                                                <TableCell
                                                    align={index == 0 ? 'left' : 'center'}
                                                    key={item.label}
                                                    sx={{ ...theme.palette.background, position: { xs: index == 0 ? 'sticky' : '' }, left: { xs: index == 0 ? 0 : '' }, minWidth: { xs: '150px', sm: 'auto' }, color: '#888888', borderBottom: 'none' }}
                                                    onClick={onClick}
                                                    style={item.key == 3 ? { display: "flex", justifyContent: "center", alignItems: "center" } : { backgroundColor: mode === 'dark' ? '#191919' : '#ffffff' }}>
                                                    {item.label}
                                                    {item.key == 3 && <div style={{ marginLeft: '15px' }} className={sortType == 1 ? style.amountSortTriangle : style.amountSortDownTriangle}></div>}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.list?.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '& td, & th': { borderBottom: 'none' },
                                                }}
                                            >
                                                {/* <TableCell component="th" scope="row" align="center" sx={{display:'flex',justifyContent:'center'}}>
                    <Avatar
                      src={row.sourcefile_url}
                      sx={{ width: 28, height: 28 }}
                    ></Avatar>
                  </TableCell> */}
                                                <TableCell align="center" sx={{ position: { xs: 'sticky', sm: 'none' }, left: { xs: 0, sm: '' } }}
                                                    style={{ backgroundColor: mode === 'dark' ? '#191919' : '#ffffff' }}>
                                                    <Grid className={style.ranlCellItem} style={{ justifyContent: 'left', alignItems: 'center' }}
                                                        onClick={() => navigate(`/collection-home?id=${row.id}`)}>
                                                        <div className={style.hjIcon}>
                                                            <img src={row.icon_url}></img>
                                                        </div>
                                                        <div>{row.name}</div>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className={style.ranlCellItem}>
                                                        <div>{row.floor_price}{row.pay_coin}</div>
                                                        <div className={style.rankRedText}>{omit(row.change_price, 2)}%</div>
                                                        <div className={style.rankUpTriangle}></div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: '#888888' }}>
                                                    <div className={style.ranlCellItem}>
                                                        <div>{row.tx_amount}{row.pay_coin}</div>
                                                        <div className={style.rankGreenText}>{omit(row.change_amount, 2)}%</div>
                                                        <div className={style.rankDownTriangle}></div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: '#888888' }}>
                                                    <div className={style.ranlCellItem}>
                                                        <div>{row.tx_number}</div>
                                                        <div className={style.rankRedText}>{omit(row.change_number, 2)}%</div>
                                                        <div className={style.rankUpTriangle}></div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: '#888888' }}>
                                                    <div className={style.ranlCellItem}>{omit(row.up_rate * 100, 2)}%</div>
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: '#888888' }}>
                                                    <div className={style.ranlCellItem}>{omit(row.holder_dispersion * 100, 2)}%</div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Hidden>
                                {/*                        <Hidden>*/}
                                {/*                            <div className='why-mediablock' style={{ display: 'none' }}>*/}
                                {/*                                {data.list?.length > 0 ? data.list?.map((row) => (*/}
                                {/*                                    <Accordion key={row.id} expanded={expanded === row.id} onChange={handleChangeExpanded(row.id)}>*/}
                                {/*                                        <AccordionSummary*/}
                                {/*                                            expandIcon={<ExpandMoreIcon />}*/}
                                {/*                                            aria-controls="panel1bh-content"*/}
                                {/*                                            id="panel1bh-header"*/}
                                {/*                                            sx={{*/}
                                {/*                                                position: 'relative'*/}
                                {/*                                            }}*/}
                                {/*                                        >*/}
                                {/*                                            /!* <Typography sx={{ width: '33%', flexShrink: 0 }}>*/}
                                {/*  General settings*/}
                                {/*</Typography> *!/*/}
                                {/*                                            <img*/}
                                {/*                                                src={row.icon_url}*/}
                                {/*                                                style={{ width: 60, height: 60, borderRadius: '5px' }}*/}
                                {/*                                            />*/}
                                {/*                                            <div style={{ paddingLeft: '5px' }}>*/}
                                {/*                                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis' }}>*/}
                                {/*                                                  {row.name}*/}
                                {/*                                                </span>*/}
                                {/*                                            </div>*/}
                                {/*                                        </AccordionSummary>*/}
                                {/*                                        <AccordionDetails>*/}
                                {/*                                            <div style={{ paddingLeft: '15px', fontSize: '14px' }}>*/}
                                {/*                                                {columns.map(v=>*/}
                                {/*                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
                                {/*                                                        <span style={{ color: '#999' }}>{v.prop=='name'?'': v.label}</span>*/}
                                {/*                                                        {v.prop=='floor_price'&&<div style={{display:"flex",alignItems:"center"}}>*/}
                                {/*                                                            <div>{row.floor_price}{row.pay_coin}</div>*/}
                                {/*                                                            <div className={style.rankRedText}>{omit(row.change_price,2)}%</div>*/}
                                {/*                                                            <div className={style.rankUpTriangle}></div>*/}
                                {/*                                                        </div>}*/}
                                {/*                                                        {v.prop=='change_amount'&&<div style={{display:"flex",alignItems:"center"}}>*/}
                                {/*                                                            <div>{row.change_amount}{row.pay_coin}</div>*/}
                                {/*                                                            <div className={style.rankGreenText}>{omit(row.change_amount,2)}%</div>*/}
                                {/*                                                            <div className={style.rankDownTriangle}></div>*/}
                                {/*                                                        </div>}*/}
                                {/*                                                        {v.prop=='tx_amount'&&<div style={{display:"flex",alignItems:"center"}}>*/}
                                {/*                                                            <div>{row.tx_amount}</div>*/}
                                {/*                                                            <div className={style.rankRedText}>{omit(row.change_number,2)}%</div>*/}
                                {/*                                                            <div className={style.rankUpTriangle}></div>*/}
                                {/*                                                        </div>}*/}
                                {/*                                                        {v.prop=='up_rate'&&<div style={{display:"flex",alignItems:"center"}}>*/}
                                {/*                                                            {omit(row.up_rate*100,2)}%*/}
                                {/*                                                        </div>}*/}
                                {/*                                                        {v.prop=='holder_dispersion'&&<div style={{display:"flex",alignItems:"center"}}>*/}
                                {/*                                                            {omit(row.holder_dispersion*100,2)}%*/}
                                {/*                                                        </div>}*/}
                                {/*                                                    </div>)}*/}
                                {/*                                            </div>*/}
                                {/*                                        </AccordionDetails>*/}
                                {/*                                    </Accordion>*/}
                                {/*                                )) : <p style={{ display: 'flex', justifyContent: 'center' }}>--{$t('nodata')}--</p>}*/}
                                {/*                            </div>*/}
                                {/*                        </Hidden>*/}
                            </Table>
                        </TableContainer>
                    ) :
                        (
                            <TableContainer component={Paper} sx={{ marginTop: '30px' }}
                                style={{ background: "none", boxShadow: "none" }}>
                                <Table sx={{ width: 1 }}>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((item) => (
                                                <TableCell
                                                    align="center"
                                                    key={item.label}
                                                    sx={{ color: '#888888', borderBottom: 'none' }}
                                                >
                                                    {item.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <p style={{ display: 'flex', justifyContent: 'center' }}>--{$t('nodata')}--</p>
                            </TableContainer>
                        )
                }
            </Grid>
        </Grid>
    )
}