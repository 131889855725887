import cancelIcon from "@/assets/icon/mineIcon/cancel.svg"
import style from "@/components/Mine/mine.module.css"
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import platformIcon from "@/assets/icon/mineIcon/platform.svg"
import editIcon from "@/assets/icon/mineIcon/edit.svg"
import moneyIcon from "@/assets/icon/mineIcon/money.svg"
import shouyiIcon from "@/assets/icon/mineIcon/shouyi.svg"
import removeIcon from "@/assets/icon/mineIcon/remove.svg"
import React, { useState, useEffect, useContext } from "react"
import { FormControlLabel, Grid, Hidden, TextField, useTheme } from "@mui/material"
import { getExpireDate, timeOption, tools, omit } from "@/components/Mine/data"
import grounding from "@/components/Mine/ground"
import Dialog from '@mui/material/Dialog'
import AppContext from '@/hooks/useAppContext.js'
import loading from '@/assets/icon/mineIcon/loading.gif'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'


//输入框
function Input(props) {
    const onChange = (e) => {
        props.getInput(e.target.value)
    }
    const onAdor = () => {
        if (props.onAdor) {
            props.onAdor()
        }
    }
    return (
        <OutlinedInput placeholder={props.disabled ? (props.samePrice ? props.samePrice : '----') : props.placeholder || $t('qsrjg')} disabled={props.disabled} endAdornment={<InputAdornment position="end" className={props.adorClassName} onClick={onAdor}>{props.coin}</InputAdornment>}
            aria-describedby="outlined-weight-helper-text" inputProps={{ 'aria-label': 'weight', }} onChange={onChange} defaultValue={props.value} value={props.value}
            style={{ width: props.width || '180px', height: '38px', color: "#888888", background: '#FFFFFF', borderRadius: '10px', fontSize: "14px" }}
        />
    )
}
function LoadingDialog(props) {
    const theme = useTheme()
    const { store, setStore } = useContext(AppContext)
    const dialogStyle = {
        background: 'none'
    }
    const handleClose = () => {
        // props.onClose()
    }
    return (
        <Grid className={style.loadingDialog}>
            <Hidden smDown>
                <Dialog onClose={handleClose} open={props.open}>
                    {store.authContent.status == 2 && <Grid style={{ display: "flex", padding: '20px', alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={loading} style={{ width: '50px', height: '50px' }} />
                        <Grid style={{ textAlign: "center" }}>{$t('nftsqz')}...</Grid>
                        <Grid style={{ textAlign: "center" }}>{$t('cpsq')}</Grid>
                        <Grid>{store.authContent.name} * {store.authContent.num}</Grid>
                    </Grid>}
                    {store.authContent.status != 2 && <Grid style={{ display: "flex", padding: '20px', alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={loading} style={{ width: '50px', height: '50px' }} />
                        <Grid>{$t('sjz')}</Grid>
                        <Grid>{$t('qdkqbwccz')}</Grid>
                    </Grid>}
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer anchor={'bottom'} open={props.open} onClose={handleClose}>
                    {store.authContent.status == 2 && <Grid style={{ display: "flex", padding: '20px', alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={loading} style={{ width: '50px', height: '50px' }} />
                        <Grid style={{ textAlign: "center" }}>{$t('nftsqz')}...</Grid>
                        <Grid style={{ textAlign: "center" }}>{$t('cpsq')}</Grid>
                        <Grid>{store.authContent.name} * {store.authContent.num}</Grid>
                    </Grid>}
                    {store.authContent.status != 2 && <Grid style={{ display: "flex", padding: '20px', alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <img src={loading} style={{ width: '50px', height: '50px' }} />
                        <Grid>{$t('sjz')}</Grid>
                        <Grid>{$t('qdkqbwccz')}</Grid>
                    </Grid>}
                </Drawer>
            </Hidden>
        </Grid>
    )
}
function BatchGround(props) {
    const theme = useTheme()
    const { store, setStore } = useContext(AppContext)
    const [list, setList] = useState([])
    const [time, setTime] = useState(1)
    const [expireDate, setExpireDate] = useState(getExpireDate(1))
    const [coin, setCoin] = useState('')
    const [same, setSame] = useState(false)
    const [price, setPrice] = useState('')
    const [totalFee, setTotalFee] = useState('')
    const [open, setOpen] = useState(false)
    const [appSameDrawer, setAppSameDrawer] = useState(false)
    //选择时间
    const onChangeTime = (e) => {
        setTime(e.target.value)
        setExpireDate(getExpireDate(e.target.value))
    }
    //app输入单个价格变化
    const getSinglePrice = (i) => (e) => {
        let arr = _.clone(list)
        arr[i] = {
            ...arr[i],
            price: e,
            YGSY: getYGSY(e, arr[i].quantity, arr[i]),
            ptfwf: omit(arr[i].platform_rate * e * arr[i].quantity),
            bs: omit(arr[i].copyright_rate * e * arr[i].quantity)
        }
        computedTotalFee(arr, i)
    }
    //设置单个价格
    const onSetAprice = (index) => {
        let arr = list.map((i, idx) => {
            if (idx == index) {
                return {
                    ...i,
                    price: price,
                    isEdit: false,
                    YGSY: getYGSY(price, i.quantity, i),
                    ptfwf: omit(i.platform_rate * price * i.quantity),
                    bs: omit(i.copyright_rate * price * i.quantity)
                }
            } else return i
        })
        // setList(arr)
        computedTotalFee(arr, index)
    }
    function getYGSY(p, n, i) {
        let price = 1 - i.platform_rate
        price = tools.Mul(+price, +p)
        price = tools.Mul(+price, +n)
        return +omit(price)
    }
    //计算总的平台服务费、{$t('public.bs')}、预估收益
    const computedTotalFee = (arr, index) => {
        let ptfwf = 0
        let bs = 0
        let totalYGSY = 0
        arr.forEach(i => {
            if (i.ptfwf) {
                ptfwf += Number(i.ptfwf)
            }
            if (i.bs) {
                bs += Number(i.bs)
            }
            if (i.YGSY) {
                totalYGSY += Number(i.YGSY)
            }
        })
        setList(arr)
        setTotalFee({ ptfwf: ptfwf, bs: bs, totalYGSY: totalYGSY })
    }
    //获取输入框值
    const getInput = (v, i, e) => {
        setPrice(e)
        list.map(v => {
            return {
                ...v,
                price: e
            }
        })
    }
    const getNum = (v, i, e) => {
        let YGSY = getYGSY(v.price, e, v)
        let ptfwf = omit(v.platform_rate * e * v.price)
        let bs = omit(v.copyright_rate * e * v.price)
        setTotalFee({ ptfwf: ptfwf, bs: bs, totalYGSY: YGSY })
    }
    const getSameInput = (e, p) => {
        const reg = /^\d+(?=\.{0,1}\d+$|$)/
        if (e && !reg.test(e)) {
            _toast($t('qsrzzshxs'))
            return
        }
        setPrice(e)
        list.forEach(i => {
            i.price = e
        })
        let item = list[0]
        let YGSY = getYGSY(e, item.quantity, item)
        let ptfwf = omit(item.platform_rate * e * item.quantity)
        let bs = omit(item.copyright_rate * e * item.quantity)
        setTotalFee({ ptfwf: ptfwf, bs: bs, totalYGSY: YGSY })
        setAppSameDrawer(false)
    }
    //是否统一价格
    const onChangeSame = (e) => {
        setSame(e.target.checked)
        if (!e.target.checked) {
            setPrice(0)
            setTotalFee({ ptfwf: 0, bs: 0, totalYGSY: 0 })
        }
        let arr = props.list.map((item, idx) => {
            return {
                ...item,
                isEdit: !e.target.checked
            }
        })
        setList(arr)
    }
    const onRemove = (i) => {
        let arr = JSON.parse(JSON.stringify(list))
        arr.splice(i, 1)
        setList(arr)
    }
    const onCancel = () => {
        props.onCancel()
    }
    const onEdit = (i) => {
        let arr = list.map((item, idx) => {
            if (idx == i) {
                item.isEdit = true
                return item
            } else {
                return item
            }
        })
        setList(arr)
    }
    const onAdor = (i) => (e) => {
        let arr = _.clone(list)
        // arr[i]
    }
    const incomeArr = [
        { icon: platformIcon, label: $t('public.ptfwf'), value: "ptfwf" },
        { icon: moneyIcon, label: $t('public.bs'), value: "bs" },
        { icon: shouyiIcon, label: $t('public.ygsy'), value: "totalYGSY" }
    ]
    const columns = ['NFT', $t('price'), $t('num'), $t('public.ptfwf'), $t('public.bs'), $t('public.ygsy'), '']
    async function onGround() {
        let test = list.map(i => {
            return i.price ? true : false
        })
        if (test.includes(false)) {
            _toast($t('qsrjg'))
            return
        }
        let arr = []
        let date = Date.parse(expireDate)
        list.forEach((i, index) => {
            arr.push({
                index: index,
                product_id: i.product_id,
                price: i.price,
                coin: i.pay_coin,
                quantity: i.quantity,
                endTime: date.toString().substr(0, 10),
                ygsy: totalFee.totalYGSY
            })
        })
        setOpen(true)
        let res = await grounding(list, arr, setStore)
        if (res == -1) {
            setOpen(false)
            return
        }
        if (res) {
            setOpen(false)
            props.onConfirmGround()
        }

    }
    useEffect(() => {
        if (list.length == 0) {
            let arr = props.list.map(i => {
                return {
                    ...i,
                    isEdit: true
                }
            })
            setList(arr)
            setCoin(arr[0].pay_coin)
        }
    },)
    return (
        <Box className={style.batchGround} sx={{ width: { xs: '90%', sm: "80%" }, margin: { xs: '20px auto', sm: "50px auto" } }}>
            <Hidden smDown>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '500px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={cancelIcon} style={{ width: '28px', height: '28px', marginRight: '10px' }} onClick={onCancel} />
                            <div className={style.batch_title}>{$t('plsj')}</div>
                        </div>
                        <div style={{ fontSize: '18px', fontWeight: 400, color: '#888888', marginTop: '20px' }}>{$t('nkyzddgnftszsjjg')}NFT{$t('szjg')}</div>
                        <div className={style.batch_title} style={{ margin: '30px 0' }}>{$t('public.ddgqsj')}</div>
                        <div style={{ width: '300px' }}>
                            {time != 99 && <div style={{ textAlign: 'right', fontSize: '12px', marginTop: '20px' }}>{$t('collection.endtime')}：{expireDate}</div>}
                            <Select id="demo-simple-select" value={time} label="Age" onChange={onChangeTime}
                                style={{ width: '300px', height: '38px' }}>
                                {timeOption.map(item => <MenuItem value={item.value} key={item.value}>
                                    {item.name}
                                </MenuItem>)}
                            </Select>
                        </div>
                        <div className={style.batch_title} style={{ marginTop: '20px' }}>设置价格</div>
                        <div style={{ marginTop: '20px', display: "flex", alignItems: "center" }}>
                            <div>{$t('setPrice2')}</div>
                            <Switch checked={same} onChange={onChangeSame} />
                            <Input coin={coin} disabled={!same} getInput={getSameInput}></Input>
                        </div>
                    </div>
                    <div style={{ width: "300px", borderRadius: '10px', border: '1px solid #888888', padding: "10px" }}>
                        <div style={{ fontSize: '20px', fontWeight: 600, color: '#333333', marginBottom: "20px" }}>{$t('sy2')}</div>
                        {incomeArr.map((item, index) => <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
                            <img src={item.icon} style={{ width: '28px', height: '28px' }} />
                            <div>{item.label}</div>
                            <div>{totalFee[item.value] ? omit(totalFee[item.value]) : 0}{coin}</div>
                        </div>)}
                        <div style={{ display: "flex", justifyContent: "right" }}>
                            <div style={{
                                width: '108px', height: '46px', lineHeight: "46px", borderRadius: '48px',
                                textAlign: "center", background: 'linear-gradient(90deg, #00F6D0 0%, #0077F5 100%)',
                                fontSize: '22px', fontWeight: 500, color: '#070809'
                            }} onClick={onGround}>{$t('public.sj')}</div>
                        </div>
                    </div>
                </div>
                <Grid container item xs={12} sx={{ width: { xs: 700, sm: '100%' }, maxWidth: 'initial' }}>
                    {columns.map((v, i) =>
                        <Grid item sx={{
                            ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0' },
                            ...(i === 5 ? theme.typobox.flexCenterRight : i === 0 ? theme.typobox.flexCenterLeft : theme.typobox.flexCenterCenter), ...theme.palette.text,
                        }} xs={i == 6 ? 0.5 : i == 5 ? 1.5 : 2} key={v}>{v}</Grid>
                    )}
                    {list?.map((v, i) =>
                        <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: 14, margin: '20px 0' }, ...theme.palette.text }} xs={12} key={v.id}>
                            <Grid item sx={{ ...theme.typobox.flexCenterLeft, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={2} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={v.sourcefile_url} style={{ width: "50px", height: "50px", marginRight: "20px" }} />
                                    #{v.token_id}
                                </div>
                                {/*<div>地板价：</div>*/}
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2} >
                                <Input coin={v.pay_coin} samePrice={price} disabled={!v.isEdit} getInput={getInput.bind(event, v, i)}></Input>
                                {(!v.isEdit) && <img src={editIcon} style={{ width: ' 21px', height: '21px', marginLeft: '15px' }}
                                    onClick={onEdit.bind(event, i)} />}
                                {(v.isEdit) && <div style={{ color: "#00F6D0", fontSize: "16px", fontWeight: "600", marginLeft: '15px' }} onClick={onSetAprice.bind(event, i)}>{$t('market.determine')}</div>}
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2} >
                                <Input coin={$t('j')} value={v.quantity} getInput={getNum.bind(event, v, i)} width='120px'></Input>
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0', textAlign: 'center' }, ...theme.palette.mainText }} xs={2} >
                                {v.price ? omit(v.price * v.quantity * v.platform_rate) : 0}{v.pay_coin}
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterCenter, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={2} >
                                {v.price ? omit(v.price * v.quantity * v.copyright_rate) : 0}{v.pay_coin}
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterRight, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={1.5} >
                                {v.price ? omit(getYGSY(v.price, v.quantity, v)) : 0}{v.pay_coin}
                            </Grid>
                            <Grid item sx={{ ...theme.typobox.flexCenterRight, ...{ fontSize: { xs: 12, sm: 16 }, margin: '5px 0' }, ...theme.palette.mainText }} xs={0.5} >
                                <img src={removeIcon} onClick={onRemove.bind(event, i)} style={{ width: "16px", height: "15px" }} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <LoadingDialog open={open} />
            </Hidden>
            <Hidden smUp>
                <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Grid sx={{ ...theme.typobox.flexCenterLeft }}>
                        <img src={cancelIcon} style={{ width: '18px', height: '18px', marginRight: '10px' }} onClick={onCancel} />
                        <div className={style.batch_title} style={{ fontSize: 16 }}>{$t('plsj')}</div>
                    </Grid>
                    <FormControlLabel labelPlacement="start" control={<Switch checked={appSameDrawer} onChange={() => { setAppSameDrawer(!appSameDrawer) }} />} label={$t('setPrice2')} />
                </Grid>
                <Grid className={style.appSeriesName}>{list[0]?.series_name}</Grid>
                <Grid>
                    {list.map((v, i) => <Grid key={i} style={{ padding: '20px 0' }}>
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                            <img src={v.sourcefile_url} style={{ width: 52, height: 52, marginRight: '10px' }} />
                            <Grid>
                                <Grid>#{v.token_id}</Grid>
                                <Grid>{v.series_name}</Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: '20px 0 10px 0' }}>
                            <span>{$t('public.ddgqsj')}</span>
                            <span>{expireDate}</span>
                        </Grid>
                        <Grid>
                            <Input width={'100%'} coin={coin} disabled={false}
                                getInput={getSinglePrice(i)} value={v.price}></Input>
                        </Grid>
                        <Grid style={{ marginTop: 10 }}>
                            <Input width={'100%'} value={v.quantity} coin={$t('public.all')} disabled={false}
                                getInput={getNum.bind(event, v, i)} adorClassName={style.inputAdornment}
                                placeholder={`${$t('ower')}：${v.quantity}`} onAdor={onAdor(i)}></Input>
                        </Grid>
                        <Grid container spacing={1} style={{ marginTop: 4 }}>
                            <Grid item xs={6}>{`${$t('public.ptfwf')}(${v.platform_rate * 100}%)`}</Grid>
                            <Grid item xs={6}>{`${$t('public.bs')}(${v.copyright_rate * 100}%)`}</Grid>
                            <Grid item xs={6} style={{ color: '#000000' }}>{v.price ? omit(v.price * v.quantity * v.platform_rate) : 0}{v.pay_coin}</Grid>
                            <Grid item xs={6} style={{ color: '#000000' }}>{v.price ? omit(v.price * v.quantity * v.copyright_rate) : 0}{v.pay_coin}</Grid>
                        </Grid>
                        {i != list.length - 1 && <Divider style={{ marginTop: 20 }} />}
                    </Grid>)}
                </Grid>
                <Grid className={style.appGroundDialog}>
                    <Grid style={{ marginRight: 30 }}>
                        <Grid style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{$t('public.ygsy')}</Grid>
                        <Grid>{totalFee.totalYGSY ? omit(totalFee.totalYGSY) : 0}{coin}</Grid>
                    </Grid>
                    <Grid className={style.groundSubmitBtn} sx={{ ...theme.typobox.flexCenterCenter }} style={{ flex: 1, height: 40 }} onClick={onGround}>{$t('public.sj')}</Grid>
                </Grid>
                <LoadingDialog open={open} />
                <Drawer anchor={'bottom'} open={appSameDrawer} onClose={() => { setAppSameDrawer(false) }}>
                    <Grid style={{ padding: 10 }}>
                        <Grid>
                            <Grid>{$t('setPrice2')}</Grid>
                        </Grid>
                        <Grid style={{ marginTop: 25, color: '#888888' }}>{$t('setPrice')}</Grid>
                        <Grid style={{ margin: '10px 0 20px 0' }}>
                            <Input width={'100%'} coin={coin} disabled={false} getInput={getInput.bind('', event, '')}></Input>
                        </Grid>
                        <Grid className={style.groundSubmitBtn} sx={{ ...theme.typobox.flexCenterCenter }} style={{ width: '100%', height: 40 }} onClick={getSameInput.bind(event, price)}>{$t('market.determine')}</Grid>
                    </Grid>
                </Drawer>
            </Hidden>
        </Box>
    )
}
export default BatchGround