export default {
  id: 71,
  name: "Conflux eSpace (Testnet)",
  network: "CFX",
  nativeCurrency: {
    name: "CFX",
    symbol: "CFX",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://evmtestnet.confluxrpc.com"],
    },
    public: {
      http: ["https://evmtestnet.confluxrpc.com"],
    },
  },
}