import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import React from 'react'
import store from './app/store'
import AppThemeProvider from './themes/AppThemeProvider'
import { SnackbarProvider } from 'notistack'
import App from './App'
import './main.css'
import moment from 'moment'
import $ from 'jquery'
import './web3'

window.m = moment
window.$ = $

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <AppThemeProvider>
        <SnackbarProvider autoHideDuration={3000} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
          <App />
        </SnackbarProvider>
      </AppThemeProvider>
    </Provider>
  </React.StrictMode >,
)
