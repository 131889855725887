import { useEffect, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { Box, useTheme, Grid } from "@mui/material"
import cancelIcon from "@/assets/icon/mineIcon/bc_cancel.svg"
import style from "@/components/Mine/mine.module.css"
import AppContext from '@/hooks/useAppContext.js'
import tsJson from "@/components/Mine/tsconfig.json"
function Link() {
    const theme = useTheme()
    const n = useNavigate()
    const [url, setUrl] = useState(1)
    const { store, setStore } = useContext(AppContext)
    const location = useLocation()
    const [title, setTitle] = useState('')
    const toCancel = () => {
        n(-1)
    }
    // This would turn the iframe blue.
    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const value = query.get('value')
        const url = query.get('url')
        if (location?.state?.value || value) {
            setTitle((location.state?.value || value) == 8 ? $t('yhxy') : $t('yszc'))
        }
        if (location?.state?.name) {
            console.log('store.lang', store.lang)
            let str = tsJson[location?.state?.name]
            if (store.lang == 'YW') {
                str = tsJson[location?.state?.name + 'yw']
            }
            if (store.lang == 'TW') {
                str = tsJson[location?.state?.name + 'tw']
            }
            str = str.replace(/\n/g, '<br/>')
            setUrl(str)
            return
        }
        if (location?.state?.url || url) {
            setUrl(location.state?.url || decodeURIComponent(url))
        }
    })
    return (
        <Box sx={{ width: { xs: '95%', sm: '70%' }, height: '90vh', margin: '0 auto' }}>
            <Grid container sx={{ ...theme.typobox.flexCenterLeft, margin: '30px 0', fontWeight: 600, color: '#333333', fontSize: { xs: 16, sm: 20 } }} onClick={toCancel}>
                <img src={cancelIcon} style={{ width: '28px', height: '28px', marginRight: 20 }} />
                <Grid sx={{ ...theme.palette.mainText }}>{title}</Grid>
            </Grid>
            <Grid style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center" }}
                dangerouslySetInnerHTML={{ __html: url }} sx={{ ...theme.palette.mainText }}>
            </Grid>
        </Box>
    )
}
export default Link