import React from 'react'
import 'tc-public-tools'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { selectMode } from '@/features/user/userSlice'

const DARK = {
  background: '#000000',
  color: '#fff',
  boxShadow: '0px 4px 9px 2px rgba(255,255,255,0.2)'
}

const NIGHT = {
  background: '#fff',
  color: '#888888',
  boxShadow: '0px 4px 9px 0px rgba(0,0,0,0.16);'
}


function AppThemeProvider({ children }) {
  const mode = useSelector(selectMode)
  const theme = responsiveFontSizes(
    createTheme({
      palette: {
        mode,
        theme: mode === 'dark' ? DARK : NIGHT,
        background: {
          default: mode === 'dark' ? 'rgba(0,0,0,.9)' : '#fff',
          opposite: mode === 'dark' ? '#FCFBFA' : '#000000',
          paper: mode === 'dark' ? '#131313' : '#FCFCFC',
        },
        primary: {
          main: '#24D5BA',
        },
        warp: {
          box: mode === 'dark' ? {
            background: '#282D2D'
          } : {
            background: '#fff'
          }
        },
        boxShadow: {
          boxShadow: mode == 'dark' ? DARK.boxShadow : NIGHT.boxShadow
        },
        border: mode === 'dark' ? {
          border: '1px solid #fff',
        } : {
          border: '1px solid #000',
        },
        input: mode === 'dark' ? {
          background: '#E8E8E8',
          color: '#fff'
        } : {
          background: '#E8E8E8',
          color: '#000'
        },
        btn: {
          fnc: mode === 'dark' ? {
            color: '#fff',
            background: '#24D5BA'
          } : {
            color: '#fff',
            background: '#24D5BA'
          },
          login: mode === 'dark' ? {
            color: '#fff',
            fontSize: '16px',
            background: 'linear-gradient(90deg, #248AE8, #35F4D2)'
          } : {
            color: '#fff',
            fontSize: '16px',
            background: 'linear-gradient(90deg, #248AE8, #35F4D2)'
          }
        },
        text: mode === 'dark' ? {
          color: '#6b7280'
        } : {
          color: NIGHT.color
        },

        mainText: mode === 'dark' ? {
          color: DARK.color
        } : {
          color: '#333'
        },

        currentText: mode === 'dark' ? {
          color: DARK.color
        } : {
          color: NIGHT.color
        },

        grey: {
          50: mode === 'dark' ? 'hsl(0, 0%, 10%)' : 'hsl(0, 5%, 95%)',
          100: mode === 'dark' ? 'hsl(0, 0%, 20%)' : 'hsl(0, 0%, 90%)',
          200: mode === 'dark' ? 'hsl(0, 0%, 30%)' : 'hsl(0, 0%, 80%)',
          300: mode === 'dark' ? 'hsl(0, 0%, 40%)' : 'hsl(0, 0%, 70%)',
          400: mode === 'dark' ? 'hsl(0, 0%, 50%)' : 'hsl(0, 0%, 60%)',
          500: mode === 'dark' ? 'hsl(0, 0%, 60%)' : 'hsl(0, 0%, 50%)',
          600: mode === 'dark' ? 'hsl(0, 0%, 70%)' : 'hsl(0, 0%, 40%)',
          700: mode === 'dark' ? 'hsl(0, 0%, 80%)' : 'hsl(0, 0%, 30%)',
          800: mode === 'dark' ? 'hsl(0, 0%, 90%)' : 'hsl(0, 0%, 20%)',
          900: mode === 'dark' ? 'hsl(0, 5%, 95%)' : 'hsl(0, 0%, 10%)',
          bg: mode === 'dark' ? 'hsl(0, 0%, 15%)' : 'hsl(0, 5%, 95%)',
          bgff: mode === 'dark' ? 'hsl(0, 0%, 15%)' : '#fff',
          bgblack: mode === 'dark' ? '#fff' : '#000',
        },
        gradient: {
          bronze: 'linear-gradient(180deg, #9C6D3E 0%, #E8C8A9 100%)',
          silver: 'linear-gradient(180deg, #808080 0%, #DFDFDF 100%)',
          gold: 'linear-gradient(180deg, #A3873C 0%, #E3D294 100%)',
        },
      },
      typobox: {
        flexWarpWrap: {
          display: 'flex',
          flexWrap: 'wrap'
        },
        flexCenterLeft: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left'
        },
        flexCenterLeftByColumn: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          flexDirection: 'column'
        },
        flexStartLeft: {
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'left',
        },
        flexStartLeftByColumn: {
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'left',
          flexDirection: 'column'
        },
        flexCenterRight: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right'
        },
        flexCenterCenter: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        flexRevertSpaceBetween: {
          display: 'flex',
          alignItems: 'revert',
          justifyContent: 'space-between'
        },
        flexCenterSpaceBetween: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        flexCenterCenterByColumn: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        },
        flexCenterRightByColumn: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          flexDirection: 'column'
        },
        flexRevertRight: {
          display: 'flex',
          alignItems: 'revert',
          justifyContent: 'center',
        },
        flexRevertRightByColumn: {
          display: 'flex',
          alignItems: 'revert',
          justifyContent: 'center',
          flexDirection: 'column'
        },
        flexEndRightByColumn: {
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'center',
          flexDirection: 'column'
        }
      },
      typography: {
        fontFamily: 'Dosis, sans-serif',

        h1: {
          fontSize: '26px',
          fontWeight: '600',
          // lineHeight: '33px',
        },
        h2: {
          fontSize: '22px',
          fontWeight: '600',
          // lineHeight: '28px',
        },
        h3: {
          fontSize: '20px',
          fontWeight: '600',
          // lineHeight: '25px',
        },
        h4: {
          fontSize: '18px',
          fontWeight: '600',
          // lineHeight: '23px',
        },
        h5: {
          fontSize: '16px',
          fontWeight: '500',
          // lineHeight: '20px',
        }
      },
      components: {
        inputLabel: {
          fontSize: { xs: 16 },
          color: '#999'
        },
        layoutMainBox: {
          margin: '30px 0'
        },
        backgroundIcon: {
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              // ---CSS BODY--- \\
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
              textDecoration: 'none',
              lineHeight: '16px',
              transition: 'all 0.1s ease-in-out',
              '&:hover': {
                opacity: 0.8,
              },
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              aspectRatio: '1/1',
            },
          },
        },
      },
    }),
  )
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default AppThemeProvider
