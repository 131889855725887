import { Box, Grid, Dialog, useTheme, DialogTitle, Button, DialogContent, DialogActions, DialogContentText, Hidden } from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AppContext from '@/hooks/useAppContext'
import contentEthereum from '@/hooks/contentEthereum'
import { globalApiSlice } from '@/features/global/globalSlice'
import { useState, useContext, useRef, useImperativeHandle } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import EventNoteIcon from '@mui/icons-material/EventNote'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import { ConstructionOutlined, WrongLocationSharp } from '@mui/icons-material'
import { getAuth } from 'components/Mine/ground.js'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction"

function Nft(props) {
  const theme = useTheme()
  const n = useNavigate()
  const { store, setStore } = useContext(AppContext)
  const [conditions, setConditions] = useState(props.conditions.map(v => ({ ...v, open: false })) || [])

  const closeDialog = v => {
    setConditions(l => l.map(d => ({ ...d, open: false })))
  }

  const add = async (v) => {


    const TOKEN = sessionStorage.getItem('TOKEN')
    if (!TOKEN) { n('/login'); _toast($t('qxdl')); return }

    let eth = null
    if (store.ethereum?.address) {
      eth = store.ethereum
    } else {
      _toast($t('pljqb'))
      eth = await contentEthereum()
      setStore(v => ({ ...v, ethereum: eth }))
    }
    setConditions(l => {
      const c = l.map(d => d.id == v.id ? { ...d, open: true } : d)
      props.setSelect(c)
      return c
    })
  }
  const deleteSelect = (e, v, n) => {
    v.nfts = v.nfts.map(d => d.id !== n.id ? d : {})
    setConditions(l => {
      const c = l.map(d => d.id == v.id ? { ...v } : d)
      props.setSelect(c)
      return c
    })
    e.stopPropagation()
  }
  return (
    <Box >
      {props.header ? <Box sx={{ ...theme.typobox.flexWarpWrap, alignItems: 'center' }}>
        <Box className="global-font-weight-bold" sx={{ mr: 2, ...theme.palette.mainText }}>{$t('condition')}</Box>
        {props.conditions.map((v, i) => <Box
          onClick={() => _alert({
            title: $t('condition'), temp: <Box sx={theme.typobox.flexWarpWrap}>
              {
                props.conditions.map(v => <Box
                  sx={{ ...theme.typobox.flexCenterCenter, mr: 3, mb: 1 }}>
                  <Box sx={{ ...theme.typobox.flexCenterCenter }}>
                    <Box
                      sx={{
                        width: 40, height: 40,
                        backgroundImage: `url(${v.image})`,
                        mr: 1, borderRadius: 1,
                        ...theme.components.backgroundIcon,
                        ...theme.palette.boxShadow
                      }}></Box>
                    <Box sx={{ mt: 1 }}>{v.series.name}</Box>
                  </Box>
                  <Box sx={{ mt: 1 }} className="global-font-weight-bold">x {v.amount}</Box>
                </Box>)
              }
            </Box>
          })}
          key={i} sx={{ ...theme.typobox.flexCenterLeft, mr: 3, mb: 1 }}>
          <Box sx={{
            width: 40, height: 40,
            backgroundImage: `url(${v.image})`,
            mr: 1, borderRadius: 1,
            ...theme.components.backgroundIcon,
            ...theme.palette.boxShadow
          }}></Box>
          <Box className="global-font-weight-bold">x {v.amount}</Box>
        </Box>)}
      </Box> : ''}
      {props.main && props.status == 1 ? <Box sx={{ mt: 3, ...theme.typobox.flexWarpWrap }}>
        {
          conditions.map((v, i) => <Box key={i} sx={{ ...theme.typobox.flexCenterLeft, mr: 2, mb: 1 }}>
            {
              v.nfts?.length ? v.nfts.map((n, j) => n.id ? <Box
                className='global-position-relative'
                key={j}
                sx={{
                  width: { xs: 60, sm: 80 },
                  height: { xs: 60, sm: 80 },
                  mr: 2,
                  border: '2px solid #333333',
                  borderRadius: '8px',
                  backgroundImage: `url(${n.sourcefile_url})`,
                  ...theme.components.backgroundIcon
                }}>
                <DoNotDisturbOnIcon onClick={e => deleteSelect(e, v, n)} className='global-position-absolute'
                  sx={{ color: '#00F6D0', top: 5, right: 5, fontSize: 16 }}
                />
              </Box> : <Box
                onClick={() => add(v)}
                key={j}
                sx={{
                  width: { xs: 60, sm: 80 },
                  height: { xs: 60, sm: 80 },
                  mr: 2,
                  border: '2px solid #333333',
                  borderRadius: '8px',
                  ...theme.typobox.flexCenterCenter
                }}>
                <ControlPointIcon fontSize='12' />
              </Box>) : ''
            }

            <Dialog
              open={v.open}
            >
              <SelectedDialog {...props} nft={v} closeDialog={closeDialog} setConditions={setConditions} />
            </Dialog>
            {/* {
              v.map((d, j) => <Box
                onClick={() => add(v, i)}
                sx={{
                  width: { xs: 60, sm: 80 },
                  height: { xs: 60, sm: 80 },
                  mr: j == v.length - 1 ? 0 : 2,
                  border: '2px solid #333333',
                  borderRadius: '8px',
                  ...theme.typobox.flexCenterCenter
                }}>
                <ControlPointIcon fontSize='12' />
              </Box>)
            } */}
            {i !== props.conditions.length - 1 ? <Box>&</Box> : ''}
          </Box>)
        }

      </Box> : ''}

    </Box>
  )
}

export function Activity(p) {
  const theme = useTheme()
  const [commitDialog, setCommitDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [select, setSelect] = useState([])
  const { store, setStore } = useContext(AppContext)
  const nftRef = useRef()
  const nftRefPc = useRef()
  const p_series = p.conditions.map(v => v.series)

  useEffect(() => {
    setSelect(
      p.conditions.map(v => Object.assign(v, {
        nfts: Array.from({ length: v.amount }, () => ({}))
      }))
    )
    // setSelect(p.conditions.map(v => Array.from({ length: v.amount }, (_, i) => ({}))))
  }, [])

  const netNftApprovalStatus = async skill => {
    const { protocol, chain_id, contract_address } = skill
    const res = await globalApiSlice.netNftApprovalStatusApi({
      chain_id: chain_id,
      nft_contract_address: contract_address,
      protocol: protocol
    })

    if (res == 1) {
      return ({ ...skill, nftstatus: 1 })
    }

    if (res == 0) {
      const res = await globalApiSlice.netNftSetApprovalApi({
        chain_id,
        nft_contract_address: contract_address,
        approved: true,
        protocol,
      })

      if (res) {
        const sign = await ethereumSendTransaction({
          data: res.data,
          to: contract_address,
          chain_id,
          type: 5,
          id: [res.hash_id],
        })
        if (sign) {
          await new Promise((resolve) => {
            const time = setInterval(() => {
              globalApiSlice.netNftApprovalStatusApi({
                chain_id,
                nft_contract_address: contract_address,
                protocol
              }).then(res => {
                if (res == 0) {
                  clearInterval(time)
                  resolve()
                }
              })
            }, 1000)
          })
          return ({ ...skill, nftstatus: 1 })
        } else {
          _toast($t('jj'))
        }
      }
    }
  }

  const registerAuth = async skill => {
    const res = await globalApiSlice.netRegisterStatusApi({ chain_id: skill.chain_id })
    if (res == 1) {
      const res = await netNftApprovalStatus(skill)
      return res
    }
    if (res == 2) {
      await new Promise(resolve => {
        const t = setInterval(() => {
          globalApiSlice.netRegisterStatusApi({
            chain_id: skill.chain_id
          }).then(res => {
            if (res.code == 1 && res.data == 1) {
              clearInterval(t)
              resolve()
            }
          })
        }, 1000)
      })
      const res = await netNftApprovalStatus(skill)
      return res
    }
    if (res == 0) {
      const res = await globalApiSlice.netregisterApi({ chain_id: skill.chain_id })
      if (res) {
        const data = {
          data: res.registerAuth_data,
          to: res.exchange_address,
          chain_id: skill.chain_id,
          type: 4,
          id: [res.hash_id],
        }
        await ethereumSendTransaction(data)
        await new Promise(resolve => {
          let t = setInterval(async () => {
            const res = await globalApiSlice.netRegisterStatusApi({
              chain_id: skill.chain_id
            })
            if (res == 0) {
              clearInterval(t)
              resolve()
            }
          }, 1000)
        })
        const res = await netNftApprovalStatus(skill)
        return res
      }
    }
  }

  const commit = async () => {
    try {
      const nfts = select.map(v => v.nfts).flat(Infinity)
      if (nfts.filter(v => !v.id).length) return _toast($t('bmzhctj'))
      showLoad($t('qdkqbwccz'))
      const ps = nfts.map(v => registerAuth(v))
      const list = await Promise.all(ps)
      const result = await globalApiSlice.mergeEncodeMessageApi({
        merge_id: p.id,
        tokens: list.map(v => ({
          chain_id: v.chain_id,
          contract_address: v.contract_address,
          product_name: v.product_name,
          protocol: v.protocol,
          series_id: v.series_id,
          sourcefile_url: v.sourcefile_url,
          token_id: v.token_id
        }))
      })

      const sign = await ethereumSendTransaction({
        ...result,
        id: [result.id],
        type: 6,
        to: result.exchange_address,
        chain_id: result.block_chain_id
      })
      if (sign) {
        _toast($t('hccg'))
        setSelect(p.conditions)
        setCommitDialog(false)
        closeLoad()
      }
    } catch (err) {
      _toast($t('partAuthFail'))
      closeLoad()
    }
  }

  const haderDom = <Box sx={{ display: 'flex', alignItems: 'left' }}>
    {p_series.map((v, i) => <Box sx={{
      display: 'flex',
      alignItems: 'center',
      mr: 2
    }} key={v.id}>
      <Box sx={{ width: { xs: 20, sm: 40 }, height: { xs: 20, sm: 40 }, ...theme.palette.boxShadow, borderRadius: '50%', mr: { xs: 1, sm: 2 }, backgroundImage: `url(${v.icon_url})`, ...theme.components.backgroundIcon }}></Box>
      <Box>{v.name}</Box>
    </Box>)}
  </Box>

  return (
    <Grid container item xs={12} sx={{ pb: 2, mb: 5, borderBottom: `1px solid ${theme.palette.theme.color}` }}>
      <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
        <Box className='global-text-nowrap-2'
          onClick={() => _alert({
            title: $t('include') + '：', temp: haderDom
          })}
          sx={{
            width: { xs: 'calc(100% - 100px)', sm: 'calc(100% - 300px)' },
            fontSize: { xs: 18, sm: 24 }
          }}>{p.title}</Box>
        <Box sx={{ ...theme.typobox.flexCenterLeft }}>
          <Box sx={{
            width: { xs: 40, sm: 84 },
            height: { xs: 26, sm: 40 },
            fontSize: { xs: 12, sm: 16 },
            background: p.status == -1 ? '#999' : '#00F6D0',
            borderRadius: '4px',
            borderBottomRightRadius: p.status == 1 ? 0 : '',
            borderTopRightRadius: p.status == 1 ? 0 : '',
            ...theme.typobox.flexCenterCenter,
            color: '#333',
            ...theme.palette.boxShadow
          }}>
            {p.status == 1 ? $t('mas.jxz') : ''}
            {p.status == 0 ? $t('mas.wks') : ''}
            {p.status == -1 ? $t('mas.yjs') : ''}
          </Box>
          {
            p.status == 1 ? <Box sx={{
              fontSize: { xs: 8, sm: 14 },
              width: { xs: 50, sm: 84 },
              height: { xs: 26, sm: 40 },
              background: 'rgba(25,49,46,.7)',
              color: '#00F6D0',
              ...theme.typobox.flexCenterCenter,
            }}>{$t('sy')}{p.surplus}</Box> : ''
          }
        </Box>
      </Grid>
      <Grid container item xs={12} sx={{ mt: 3, mb: 1 }}>
        {p_series.map((v, i) => <Box
          onClick={() => _alert({
            title: $t('include') + '：', temp: haderDom
          })} sx={{
            ...theme.typobox.flexCenterLeft
          }} key={v.id}>
          {i ? <Box sx={{ ml: { xs: 1, sm: 2 }, mr: { xs: 1, sm: 2 } }}>&</Box> : ''}
          <Box sx={{ width: { xs: 20, sm: 40 }, height: { xs: 20, sm: 40 }, ...theme.palette.boxShadow, borderRadius: '50%', mr: { xs: 1, sm: 2 }, backgroundImage: `url(${v.icon_url})`, ...theme.components.backgroundIcon }}></Box>
          <Box>{v.name}</Box>
        </Box>)}
      </Grid>
      <Grid container item xs={12} sx={{ mt: 1, mb: 1 }}>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <Box sx={{ ml: { xs: 0, sm: 6 } }}>
              <Nft  {...p} header={true} select={select} setSelect={setSelect} />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6}
          className='global-text-nowrap-3'
          onClick={() => _alert({ message: p.description })}
          sx={{
            ...theme.palette.text,
            fontSize: { xs: 14, sm: 16 },
            textAlign: 'justify',
            mt: 1,
            lineHeight: 1.6,
            WebkitLineClamp: { xs: 3, sm: 'inherit' }
          }}>

          {p.description}
          {/* <span style={{ color: '#00F6D0' }}>更多</span> */}
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <Box sx={{ ml: { xs: 0, sm: 6 } }}>
              <Nft {...p} main={true} select={select} setSelect={setSelect} />
            </Box>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <Box sx={{ ml: { xs: 0, sm: 6 } }}>
              <Nft ref={nftRefPc} {...p} header={true} main={true} select={select} setSelect={setSelect} />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      {
        p.status == 1 ? <Grid container item xs={12} sx={{ mt: { xs: 0, sm: 3 }, mb: 1, ...theme.typobox.flexCenterRight }}>
          <Box
            onClick={() => p.status == 1 ? setCommitDialog(true) : ''} sx={{
              width: { xs: 80, sm: 120 },
              height: { xs: 35, sm: 50 },
              fontSize: { xs: 14, sm: 16 },
              background: 'linear-gradient(90deg, #00F6D0, #0077F5);',
              borderRadius: '20px',
              ...theme.typobox.flexCenterCenter,
              color: p.status == 1 ? '#fff' : '#999',
              ...theme.palette.boxShadow
            }}>
            {$t('synthesiss')}
          </Box>
        </Grid> : ''
      }

      <Dialog
        open={commitDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {$t('public.tips')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {$t('hctips')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommitDialog(false)}>{$t('cancel')}</Button>
          <Button onClick={commit} autoFocus>
            {$t('market.determine')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  )
}

function SelectedDialog(p) {
  const theme = useTheme()
  const [select, setSelect] = useState([])
  const [list, setList] = useState([])
  const n = useNavigate()
  const MP = globalApiSlice.useMyProductQuery({
    is_blindbox: 0,
    page: 1,
    per_page: 10000,
    series_id: p.nft.series.id,
    status: 0
  })
  useEffect(() => {
    if (MP.data) {
      const l = MP.data.data.map(v => {
        if (p.nft.nfts.findIndex(n => n.id == v.id) > -1) {
          return { ...v, checked: true }
        }
        return v
      })
      setList(l)
      setSelect(l.filter(v => v.checked))
    }
  }, [MP.data])
  const handle = v => {
    const l = list.map(d => d.id === v.id ? { ...d, checked: !v.checked } : d)
    const s = l.filter(v => v.checked)
    if (s.length > p.nft.amount) return _toast(`${zdzkxz}${p.nft.amount}nft`)
    setList(l)
    setSelect(s)
  }
  const commit = () => {
    const { nft } = p
    nft.nfts = select.concat(Array.from({ length: nft.nfts.length - select.length }, () => ({})))
    p.setConditions(l => {
      const c = l.map(v => v.id == nft.id ? { ...nft } : v)
      return c
    })
    p.closeDialog()
  }
  return <Grid container item xs={12}>
    <Box sx={{
      width: 500
    }}>
      <DialogTitle>{$t('chooseNft')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => p.closeDialog()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid xs={12} item sx={{ ...theme.typobox.flexCenterLeft }}>
          <Box sx={{
            width: { xs: 60, sm: 80 },
            height: { xs: 60, sm: 80 },
            background: 'red',
            backgroundImage: `url(${p.nft.series.icon_url})`,
            borderRadius: '10px',
            ...theme.components.backgroundIcon
          }}></Box>
          <Box sx={{
            width: 'calc(100% - 150px)',
            margin: '0 20px',
            ...theme.typobox.flexCenterLeftByColumn
          }}>
            <Box sx={{ width: '100%', fontSize: 18 }}>{p.nft.series.name}</Box>
            <Box sx={{ width: '100%', ...theme.palette.text, ...theme.typobox.flexCenterLeft }}>
              <Box sx={{ fontSize: 16 }}>{$t('holdsum')}</Box>
              <Box className="global-font-weight-bold" sx={{ ...theme.palette.mainText, ml: 1 }}>
                {p.nft.series.series_nfts}
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...theme.typobox.flexCenterCenter }}>{select.length}/{p.nft.amount}</Box>
        </Grid>
        <Grid xs={12} item sx={{ ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween, mt: 3 }}>
          {
            list.map(v => <Box
              onClick={() => handle(v)}
              key={v.id} sx={{ width: { xs: 60, sm: 80 }, mr: 1, mb: 1, position: 'relative' }}>
              <Box sx={{ height: { xs: 60, sm: 80 }, width: { xs: 60, sm: 80 }, backgroundImage: `url(${v.sourcefile_url})`, ...theme.components.backgroundIcon, borderRadius: '10px' }}></Box>
              <Box sx={{ width: '100%', textAlign: 'center' }}>#{v.token_id}</Box>
              {v.checked ? <CheckCircleOutlineIcon fontSize='8' sx={{ color: '#00F6D0', position: 'absolute', top: 5, right: 5 }} /> : ''}
            </Box>)
          }

          {
            [0, 1, 2, 3, 4, 5, 6].map(v => <Box key={v + 'xasf'} sx={{ width: { xs: 60, sm: 80 }, mr: 1 }}></Box>)
          }

        </Grid>
        {
          MP.status === 'fulfilled' && list.length == 0 ? <Grid xs={12} item sx={{ ...theme.typobox.flexCenterCenterByColumn, mb: 2, mt: 2 }}>
            <EventNoteIcon sx={{ fontSize: { xs: 35, sm: 50 } }} />
            <Box sx={{ ...theme.typobox.flexCenterCenter, fontSize: { xs: 16, sm: 20 }, mt: 1, ...theme.palette.text }}>{$t('myxgcp')}<Box onClick={() => n(`/market?keyword=${p.nft.series.name}`)} sx={{ fontSize: 14, color: '#24D5BA', ml: 1 }}>{$t('collection.buy')}</Box></Box>
          </Grid> : ''
        }

        <Grid xs={12} item sx={{ ...theme.typobox.flexCenterLeft }}>
          <Grid item xs={12}
            onClick={commit}
            className='global-border-radius'
            sx={{
              ...theme.palette.btn.login,
              ...theme.typobox.flexCenterCenter,
              height: { xs: 40, sm: 60 },
              fontSize: { xs: 14, sm: 20 }
            }}>
            {$t('market.determine')}
          </Grid>
        </Grid>
      </DialogContent>
    </Box>
  </Grid>
}


export default Activity
