import { useImperativeHandle } from 'react'
import { Box, Grid, useTheme, Dialog, MenuItem, Select, FormControl, Hidden, TextField } from '@mui/material'
import { useState, useContext } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { zhCN, enUS } from '@mui/x-date-pickers/locales'
import AppContext from '@/hooks/useAppContext.js'
import { globalApiSlice } from '@/features/global/globalSlice'
import contentEthereum from '@/hooks/contentEthereum'
import dayjs from 'dayjs'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

function ReadyOrder(props) {
  const theme = useTheme()
  // const [data, setData] = useState({
  //   sourcefile_url: 'https://file-xmeta.oss-cn-hongkong.aliyuncs.com/uploads/images/GxqHKOsUaK89Jk0EGqo9087oIsvz31Z1l98qxebu.png',
  // })
  const [data, setData] = useState(null)
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().getTime() + 24 * 3600 * 1000))

  const { store, setStore } = useContext(AppContext)

  const [pay_coin, setPay_coin] = useState(10)
  const [price, setPrice] = useState('')
  const [amount, setAmount] = useState(1)

  const handle = async () => {
    showLoad($t('qqz'))
    try {
      let eth = null
      if (store.ethereum?.address) {
        eth = store.ethereum
      } else {
        _toast($t('pljqb'))
        eth = await contentEthereum()
        setStore(v => ({ ...v, ethereum: eth }))
      }
      const body = {
        type: data?.type,
        series_id: data?.series_id,
        amount: amount,
        price,
        symbol_id: pay_coin,
        expire_time: (new Date(selectedDate).getTime() / 1000).toFixed(0)
      }
      const result = await globalApiSlice.offerQuotationApi(body)
      const sign = await ethereumSendTransaction({
        data: result.data,
        to: result.token_address,
        chain_id: result.block_chain_id,
        type: result.hash_type,
        id: [result.id]
      })
      if (result.hash_type) {
        await globalApiSlice.hashReturnApi({
            ids: [result.id],
            type: result.hash_type,
            hash: sign
        })
    }
      _toast('操作成功')
      closeLoad()
      close()
    } catch (err) {
      console.log('err', err)
      closeLoad()
    }
  }

  const changePrice = e => {
    setPrice(e.target.value)
  }

  const changeAmount = e => {
    setAmount(e.target.value >= data.maxNum ? data.maxNum : e.target.value)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }
  const handlePayCoin = (event) => {
    setPay_coin(event.target.value)
  }

  const open = async data => {
    const pay_coin_list = await globalApiSlice.commonSymbolApi({ product_id: data?.id })
    data = { ...data, pay_coin_list }
    if (pay_coin_list.length) setPay_coin(pay_coin_list[0].id)
    setData(data)
  }
  const close = () => setData(null)

  useImperativeHandle(props.onRef, () => ({
    open, close
  }))

  return (
    <Dialog anchor={'center'} maxWidth={'sm'} fullWidth={true} open={Boolean(data)} >
      <Grid container item xs={11} sm={11} sx={{ margin: { xs: '10px auto', sm: '20px auto' } }}>
        <Grid item xs={12} sx={{
          mb: 1,
          pb: 1,
          ...theme.palette.text,
          ...theme.typobox.flexCenterRight,
          borderBottom: `1px solid ${theme.palette.theme.color}`
        }}>
          <Box onClick={() => setData(null)} sx={{ fontSize: { xs: 16, sm: 20 }, mr: 0 }}>{$t('gb')}</Box>
        </Grid>
        <Grid item xs={4} sm={4} sx={{
          // width: 200, height: 200,
          // backgroundImage: `url(${data?.sourcefile_url})`,
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat'
        }}>
          <img className='global-img' src={data?.img} />
        </Grid>
        <Grid item xs={1} sm={1}>
        </Grid>
        <Grid item xs={7} sm={7}>
          <Grid item xs={12} sx={{
            ...theme.typobox.flexCenterLeft,
            fontSize: { xs: 20, sm: 40 }
          }}>{data?.name} {data?.id === 0 ? '' : '#'}{data?.token_id}</Grid>
          {data?.id !== 0 ? <Grid item sx={{ fontSize: { xs: 14, sm: 20 } }} xs={12}>合集：{data?.collection_name}</Grid> : ''}
          <Grid item xs={12} sx={{ fontSize: { xs: 14, sm: 20 } }}>
            <Grid item xs={12} >
              地板价：{data?.min} {data?.pay_coin}
            </Grid>
            <Grid item xs={12} >
              最高出价：{data?.max} {data?.pay_coin}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterLeft }}>
          <Grid item xs={4} sx={{ textAlign: 'justify', textAlignLast: 'justify', lineHeight: 3 }} >
            单价
          </Grid>
        </Grid>
        <Grid className='global-position-relative' item xs={12}  >
          <TextField
            fullWidth={true}
            type='number'
            value={price}
            onInput={changePrice}
          ></TextField>
          <FormControl
            className='global-position-absolute'
            variant="standard" sx={{ top: 10, right: 10 }}>
            <Select
              value={pay_coin}
              variant="standard"
              onSelect={handlePayCoin}
            >
              {data?.pay_coin_list?.map(v => <MenuItem id={v.id} key={v.id} value={v.id}>{v.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        {data?.id === 0 ?
          <Grid item xs={12} sx={{ ...theme.typobox.flexCenterLeft }}>
            <Grid item xs={4} sx={{ textAlign: 'justify', textAlignLast: 'justify', lineHeight: 3 }} >
              数量
            </Grid>
          </Grid>
          : ''}
        {data?.id === 0 ?
          <Grid className='global-position-relative' item xs={12}  >
            <TextField
              fullWidth={true}
              type='number'
              value={amount}
              onInput={changeAmount}
            ></TextField>
            <FormControl
              className='global-position-absolute'
              variant="standard" sx={{ top: 10, right: 10 }}>
            </FormControl>
          </Grid>
          : ''}
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterLeft }}>
          <Grid item xs={4} sx={{ textAlign: 'justify', textAlignLast: 'justify', lineHeight: 3 }} >
            过期时间
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <LocalizationProvider
            // localeText={zhCN.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale={store.lang === 'ZH' ? zhCN : enUS}
            dateFormats={store.lang === 'YW' ? false : { monthAndYear: `YYYY年 MM月` }}
            dateAdapter={AdapterDayjs}>
            <DateTimePicker
              minDate={dayjs(new Date().getTime() + 24 * 3600 * 1000)}
              sx={{ width: '100%' }}
              value={selectedDate} onChange={handleDateChange} />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterRight, mt: 1, ...theme.palette.text }} >
          总价：<Box sx={{ ...theme.palette.mainText, mr: 1 }}>{(price * amount).toFixed(4) || '-'}</Box> {data?.pay_coin}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }} >
          <Box className="global-button" onClick={handle} sx={{ ...theme.palette.btn.login, height: { xs: 55, sm: 70 }, borderRadius: 2 }}>{$t('cj')}</Box>
        </Grid>
      </Grid>
    </Dialog >
  )
}

export default ReadyOrder
