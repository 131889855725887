import { Box, Grid, useTheme, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React, { useState, useLayoutEffect, useEffect, useMemo, memo } from 'react'
import IMG1 from '@/assets/img/home1.png'
import ICON1 from '@/assets/icon/yuanquan.png'
import { Hidden } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'

import AppContext from '@/hooks/useAppContext'
import { globalApiSlice } from '@/features/global/globalSlice'
import apiSlice from '@/app/api/apiSlice'

function isE(a = {}, b = {}) {
  const a1 = Object.keys(a)
  const b1 = Object.keys(b)

  if (a1.length != b1.length) return false

  for (const k in a) {
    if (typeof a[k] == 'object' || typeof b[k] == 'object') {
      if (!isE(a[k], b[k])) return false
    } else if (a[k] !== b[k]) return false
  }
  return true
}


export function Collection(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Box
      onClick={() => navigate('/collection?id=' + props.id)}
      className="global-border-radius global-overflow-hidden global-position-relative global-pointer"
      sx={{
        width: { xs: 160, sm: 200 },
        height: { xs: 160, sm: 200 },
        ...theme.palette.boxShadow,
        ...theme.palette.warp.box,
        marginTop: 3,
        marginRight: 1,
        backgroundImage: `url(${props.sourcefile_url})`,
        ...theme.components.backgroundIcon,
        backgroundSize: 'auto 100%'
      }}>

      <Box className="global-position-absolute"
        sx={{
          ...{
            width: '100%',
            bottom: 0,
            padding: '0 16px',
            paddingBottom: '10px',
            fontSize: 16,
            backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.6) 100%)'
          }
        }}>
        <Box className="global-font-weight-bold global-text-nowrap" sx={{ ...{ margin: '10px 0', fontSize: { xs: 16, sm: 20 }, color: '#fff' } }}>{props.name}</Box>
        <Box className="global-font-weight-bold" sx={{ ...theme.typobox.flexCenterSpaceBetween, ...{ marginTop: '5px', color: '#fff', fontSize: { xs: 10, sm: 14 } } }}>
          <Grid item xs={6} sx={{ ...theme.typobox.flexCenterLeft, width: '100%' }}>
            <Box className="global-overflow-hidden" sx={{ width: 20, height: 20, marginRight: 1, borderRadius: '50%', }}>
              <img className='global-img' src={props.serice_icon_url} />
            </Box>
            <Box className="global-text-nowrap" sx={{ width: 'calc(100% - 40px)' }}>{props.collection_name}</Box>
          </Grid>
          <Grid item xs={6} sx={theme.typobox.flexCenterRight}>{props.floor_price}{props.pay_coin}</Grid>
        </Box>
      </Box>
    </Box>
  )
}

function HotMint(props) {
  const theme = useTheme()
  const n = useNavigate()
  return (
    <Box
      className="global-border-radius global-overflow-hidden"
      sx={{
        width: { xs: '46%', sm: 200 },
        height: { xs: 260, sm: 276 },
        ...theme.palette.boxShadow,
        ...theme.palette.warp.box,
        marginRight: { xs: 0, sm: 3 },
        marginTop: 3
      }}
      onClick={() => n('/publish-info?id=' + props.id)}
      >
      
      <Box sx={{ width: 220, height: 160 }}>
        <img className='global-img' src={
          props.publish_sourcefile_url
        } style={{ height: '100%' }} />
      </Box>
      <Box sx={{ ...{ padding: '0 16px', fontSize: { xs: 12, sm: 16 } } }}>
        <Box className="global-font-weight-bold global-text-nowrap" sx={{ ...theme.palette.mainText, ...{ margin: '10px 0', fontSize: { xs: 16, sm: 20 } } }}>{props.name}</Box>
        <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.text }}><Box>{$t('num')}</Box><Box>{$t('price')}</Box></Box>
        <Box className="global-font-weight-bold" sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, ...{ marginTop: '5px' } }}><Box>{props.publish_total}</Box><Box>{props.publish_price}{props.pay_coin}</Box></Box>
      </Box>
    </Box>
  )
}

function ChangeTopProject(props) {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <Box sx={theme.typobox.flexCenterCenterByColumn}
      onClick={() => navigate('/collection-home?id=' + props.id)}
    >
      <Box className="global-overflow-hidden" sx={{
        width: { xs: 50, sm: 100 }, height: { xs: 50, sm: 100 },
        borderRadius: '50%',
        backgroundImage: `url(${props.icon_url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        {/* <img src={props.icon_url} className='global-img' /> */}
      </Box>
      <Box sx={{ fontSize: { xs: 14, sm: 17 }, color: '#00EFCA', marginTop: 1 }}>{Number(props.change_amount) === 0 ? '-' : `↑&nbsp;${props.change_amount}%`}</Box>
    </Box>
  )
}

function HomeProjectHost(props) {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Box
      onClick={() => navigate(`/project?id=${props.id}`)}
      className="global-border-radius global-overflow-hidden"
      sx={{
        display: 'inline-block',
        m: 1,
        mb: 3,
        verticalAlign: 'top',
        pb: 1,
        ...theme.palette.warp.box,
        ...theme.palette.boxShadow,
      }}
    >
      <Box sx={{
        width: 200,
        height: 150,
        // backgroundColor: '#00EFCA',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${props.background})`
      }}></Box>
      <Box sx={{ m: 1, fontSize: 18 }}>{props.name}</Box>
      {
        props.series.length ? <Box>
          <Swiper
            style={{
              width: 200,
              height: 100
            }}
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
            autoplay={false}
            loop={false}
          >
            {
              props?.series?.map(v => <SwiperSlide key={v.id}>
                <Box className="global-border-radius" sx={{
                  m: 1,
                  ...theme.typobox.flexCenterLeft,
                  background: theme.palette.mode == 'dark' ? '#414949' : '#F8F8F8'
                }}>
                  <Box className="global-border-radius"
                    sx={{
                      width: 60,
                      height: 60,
                      mr: '10px',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${v.icon_url})`
                    }}></Box>
                  <Box sx={{ fontSize: 14, width: 'calc(100% - 70px)' }}>
                    <Box className="global-text-nowrap">{v.name}</Box>
                    <Box>{v.floor_price}{v.pay_coin}</Box>
                  </Box>
                </Box>
              </SwiperSlide>)
            }

          </Swiper>
        </Box> : ''
      }
    </Box>
  )
}

export function HotProject(props) {
  // console.log(props);
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <Box
      className="global-border-radius global-overflow-hidden"
      onClick={() => navigate('/collection-home?id=' + props.id)}
      sx={{
        width: { xs: '46%', sm: 200 },
        height: { xs: 260, sm: 280 },
        ...theme.palette.boxShadow,
        ...theme.palette.warp.box,
        marginRight: { xs: 0, sm: 3 },
        marginTop: { xs: 3, sm: 3 }
      }}>
      <Box sx={{ width: '100%', height: 160 }}>
        <img className='global-img' src={props.background_url} style={{ height: '100%' }} />
      </Box>
      <Box sx={{ ...{ padding: '0 16px', fontSize: { xs: 12, sm: 16 } } }}>
        <Box className="global-font-weight-bold global-text-nowrap" sx={{ ...theme.palette.mainText, ...{ margin: '10px 0', fontSize: { xs: 16, sm: 20 } } }}>{props.name}</Box>
        <Box sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.text, fontSize: { xs: 10, sm: 14 } }}><Box>{$t('home.dbj')}</Box><Box>{$t('home.24tradingVol')}</Box></Box>
        <Box className="global-font-weight-bold" sx={{ ...theme.typobox.flexCenterSpaceBetween, ...theme.palette.mainText, ...{ marginTop: '5px', fontSize: { xs: 10, sm: 14 } } }}><Box>{props.floor_price}{props.pay_coin}</Box><Box>{props.tx_amount}{props.pay_coin}</Box></Box>
      </Box>
    </Box>
  )
}

export function Title(props) {
  const theme = useTheme()
  const WIDTH = props.width || 80
  return props.children ?
    // sx={{ ...theme.typobox.flexCenterSpaceBetween, ...{} }}
    <Grid container item xs={12} sm={12} md={12} sx={{ fontSize: { xs: 20 } }}>

      <Grid item xs={4} sm={2} md={2}>
        <Box>{props.name}</Box>
        <Box sx={
          {
            height: { xs: 2, sm: 4, md: 6 },
            background: 'linear-gradient(90deg, #00F6D0, #0077F5);',
            width: WIDTH,
            marginTop: 1
          }
        }></Box>
      </Grid>
      {props.children}
    </Grid> :
    <Grid container item xs={12} sm={12} md={12} sx={{ fontSize: { xs: 20 } }}>
      <Grid item xs={12} sm={2} md={2}>
        <Box>{props.name}</Box>
        <Box sx={
          {
            height: { xs: 2, sm: 4, md: 6 },
            background: 'linear-gradient(90deg, #00F6D0, #0077F5);',
            width: WIDTH,
            marginTop: 1
          }
        }></Box>
      </Grid>
    </Grid>

}

function RankProject(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Grid container item xs={12} sm={12} md={6}
      onClick={() => navigate('/collection-home?id=' + props.id)}
      sx={{
        ...theme.palette.mainText,
        ...{ fontSize: 18, textAlign: 'center', height: 55, margin: '20px 0' },
        ...theme.typobox.flexCenterSpaceBetween,
        fontSize: { xs: 12, sm: 16 }
      }}>
      <Grid item xs={1}>{props.i + 1}</Grid>
      <Grid item sx={theme.typobox.flexCenterCenter} xs={2}>
        <img className='global-img global-border-radius' src={props.icon_url} style={{ width: '50px', height: '50px' }} />
      </Grid>
      <Grid item className='global-text-nowrap' xs={4} >{props.name}</Grid>
      <Grid item xs={2.5}>
        <Grid sx={theme.typobox.flexCenterCenter} >{props.floor_price}<div className='global-icon-eth'></div></Grid>
      </Grid>
      <Grid container item xs={2.5} sx={theme.typobox.flexRevertRightByColumn}>
        {/* <Grid item sx={theme.typobox.flexCenterRight} >{props.tx_amount}<div className='global-icon-eth'></div></Grid> */}
        <Grid item sx={{ color: '#24D5BA', fontSize: 14, textAlign: 'right' }}>{isNaN(Number(props.change_amount)) ? '-' : (Number(props.change_amount) * 100).toFixed(2)}%</Grid>
      </Grid>
    </Grid>
  )
}

function Home() {
  const theme = useTheme()
  let { store: { blockchain, lang } } = React.useContext(AppContext)
  if (!blockchain) blockchain = []
  const times = [
    { name: 'All', value: 5 },
    { name: '1h', value: 1 },
    { name: '24h', value: 2 },
    { name: '7d', value: 3 },
    { name: '30d', value: 4 }
  ]


  const [state, setState] = useState({
    collectionHot: [],
    collectionTop: [],
    newsHot: [],
    castHot: [],
    changeTop: [],
    banner: [],
    homeProjectHost: []
  })
  const [p, setP] = useState({ time_type: 2 })
  const [ld, setLd] = useState(true)
  const {
    useActivityBannerQuery,
    useCollectionHotQuery,
    useNewsHotQuery,
    useCastHotQuery,
    useCollectionTopQuery,
    useChangeTopQuery,
    useHomeProjectHotQuery
  } = globalApiSlice
  const [page, setPage] = useState(1)
  const [st, setSt] = useState(1)
  const homeProjectHost = useHomeProjectHotQuery({})
  const collectionHot = useCollectionHotQuery({})
  const collectionTop = useCollectionTopQuery({ ...p, chain: p.main_network_id, ckR: !!p.coin })
  const newsHot = useNewsHotQuery({ page: page, per_page: 30 })
  const changeTop = useChangeTopQuery({})
  const castHot = useCastHotQuery({})
  const banner = useActivityBannerQuery({ position: 'home' })
  useEffect(() => {
    console.log('homeProjectHost', homeProjectHost)
    if (homeProjectHost.data) setState(v => ({ ...v, homeProjectHost: homeProjectHost.data }))
    if (collectionTop.data) setState(v => ({ ...v, collectionTop: collectionTop.data }))
    if (collectionHot.data) setState(v => ({ ...v, collectionHot: collectionHot.data }))
    if (changeTop.data) setState(v => ({ ...v, changeTop: changeTop.data }))
    if (castHot.data) setState(v => ({ ...v, castHot: castHot.data }))
    if (newsHot.data && page == 1) setState(v => ({ ...v, newsHot: [] }))
    if (newsHot.data && newsHot.status == 'fulfilled') setSt(0) & setState(v => ({ ...v, ...{ newsHot: v.newsHot.concat(newsHot.data.data) } }))
    if (newsHot.data && newsHot.status == 'fulfilled' && !newsHot.data.data.length) setSt(2)
    if (banner.data) setState(v => ({ ...v, banner: banner.data }))
    if (blockchain.length && !p.id) setP(v => ({ ...v, ...blockchain[0] }))
    if (![collectionHot, collectionTop, newsHot, changeTop, castHot, newsHot].filter(v => !v.data).length && ld) setLd(false)
  }, [homeProjectHost.data, collectionHot.data, newsHot.data, collectionTop.data, changeTop.data, castHot.data, blockchain])
  const store = React.useContext(AppContext)

  return (
    <Box className="global-content-auto" onScroll={e => _.throttle(() => _.scrollFactory({
      dom: e.target,
      bottomFnc: () => st === 0 ? setSt(1) & setPage(v => v + 1) : ''
    }))}>
      {
        state?.banner?.length ?
          <Box sx={{ marginBottom: 5, background: 'rgba(0,0,0,.8)', height: { xs: 200, sm: 350, md: 450, lg: 600 } }}>
            <Swiper
              style={{ height: '100%' }}
              modules={[Autoplay]}
              autoplay={true}
              loop={true}
            >
              {
                state?.banner?.map(v => <SwiperSlide key={v.id}>
                  {/* <img className='global-img' src={v.image} /> */}
                  <a target="_bank" href={v.jump_url}><Box sx={{ width: '100%', height: '100%', backgroundPosition: 'center', backgroundSize: { xs: 'auto 100%' }, backgroundRepeat: 'no-repeat', backgroundImage: `url(${v.image})` }}></Box></a>
                </SwiperSlide>)
              }

            </Swiper>
          </Box> : <Box sx={{ m: 4 }}></Box>
      }

      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
        <Title {...{ name: $t('home.hjph') }}>
          <Grid xs={8} sm={10} md={10} item sx={{ ...theme.typobox.flexCenterRight, ...theme.palette.text }}>
            <Grid item sx={{
              ...theme.typobox.flexCenterLeft, ...{
                fontSize: 16
              }
            }}>
              <Hidden smDown>
                <Grid sx={{ ...{ marginRight: 2 }, ...theme.typobox.flexCenterCenter }}>
                  <img src={ICON1} style={{ marginRight: '5px' }} />
                  {$t('public.ssgxz')}
                </Grid>
              </Hidden>
              <Hidden smDown>
                {times.map(v =>
                  <Grid className={`global-pointer ${v.value === p.time_type ? 'global-font-weight-bold' : ''}`} onClick={e => setP(d => ({ ...d, ...{ time_type: v.value } }))} sx={{ marginRight: 1.2, ...v.value == p.time_type ? theme.palette.mainText : {} }} key={v.name}>{v.name}</Grid>)}
              </Hidden>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Box className='global-border-radius '
                sx={{
                  ...theme.typobox.flexCenterSpaceBetween,
                  ...theme.palette.border,
                  ...{ padding: '1px' }
                }}>
                {blockchain.map((v, i) => <Box
                  onClick={e => v.id !== p.id ? setP(d => ({ ...d, ...v })) : ''}
                  className="global-pointer global-border-radius"
                  sx={{
                    flex: 1,
                    height: 35,
                    background: v.id == p.id ? '#00EFCA' : '',
                    fontSize: { xs: 12, sm: 16 },
                    ...theme.typobox.flexCenterCenter,
                    ...theme.palette.text,
                    ...v.id === p.id ? theme.palette.currentText : {}
                  }} key={v.id}>{v.name}</Box>)}
              </Box>
            </Grid>
          </Grid>
        </Title>
      </Grid>

      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' } }}>
        <Grid container item xs={12} sm={12} md={6} sx={{ ...theme.palette.text, ...{ fontSize: { xs: 12, sm: 14 }, textAlign: 'center' }, ...theme.typobox.flexCenterSpaceBetween }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>{$t('home.xm')}</Grid>
          <Grid item xs={2.5}>{$t('home.dbj')}</Grid>
          <Grid item xs={2.5} sx={{ textAlign: 'right' }}>{times.filter(v => v.value == p.time_type)[0].name}{$t('zdf')}</Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={6} sx={{ ...theme.palette.text, ...{ fontSize: { xs: 12, sm: 14 }, textAlign: 'center' }, ...theme.typobox.flexCenterSpaceBetween }}>
          {
            state.collectionTop.length > 1 ? (
              <Hidden smDown>
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={4}>{$t('home.xm')}</Grid>
                  <Grid item xs={2.5}>{$t('home.dbj')}</Grid>
                  <Grid item xs={2.5} sx={{ textAlign: 'right' }}>{times.filter(v => v.value == p.time_type)[0].name}{$t('zdf')}</Grid>
                </Grid>
              </Hidden>
            ) : ''
          }
        </Grid>
        {state.collectionTop.map((v, i) => <RankProject key={v.id} {...{ ...v, i }} />)}
        <Grid container item xs={12} sm={12} md={6} sx={{ ...theme.palette.text, ...{ fontSize: { xs: 12, sm: 16 }, textAlign: 'center' }, ...theme.typobox.flexCenterSpaceBetween }}>
          {
            state.collectionTop?.length == 0 ? <Box sx={{ ...theme.typobox.flexCenterCenter, width: '100%', ...theme.palette.text, height: 80 }}>{$t('nodata')}</Box> : ''
          }
        </Grid>
      </Grid>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
        <Title {...{ name: $t('home.rmxmf') }} />
        <Grid item xs={12} sx={{
          whiteSpace: 'nowrap',
          margin: '30px 0',
          overflowX: 'auto'
        }} >
          {state.homeProjectHost.map(v => <HomeProjectHost key={v.id} {...v} />)}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 1000} sx={{
            width: { xs: '46%', sm: 200 }, marginRight: { xs: 0, sm: 3 },
            marginTop: 0
          }} />)}
        </Grid>
      </Grid>

      {/* <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
        <Title {...{ name: $t('home.rmhj') }} />
        <Grid item xs={12} sx={{ ...{ margin: '30px 0' }, ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
          {state.collectionHot.map(v => <HotProject key={v.id} {...v} />)}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 1000} sx={{
            width: { xs: '46%', sm: 200 }, marginRight: { xs: 0, sm: 3 },
            marginTop: 0
          }} />)}
        </Grid>
      </Grid> */}


      {
        state.castHot.length ?
          <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
            <Title {...{ name: $t('home.rmzz') }} />


            <Grid item xs={12} sx={{ ...{ margin: '30px 0' }, ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
              {state.castHot.map(v => <HotMint key={v.id} {...v} />)}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 100000} sx={{
                width: { xs: '46%', sm: 200 },
                marginRight: { xs: 0, sm: 3 },
              }} />)}
            </Grid>

          </Grid> : ''
      }


      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
        <Title {...{ name: $t('home.zfzg') }} />
        <Grid xs={12} item sx={{ ...{ margin: '30px 0', marginBottom: 0 }, ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
          {state.changeTop.map(v => <ChangeTopProject key={v.id} {...v} />)}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 100000} sx={{
            width: { xs: 50, sm: 100 },
            marginRight: 1
          }} />)}
        </Grid>
      </Grid>

      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: { xs: '20px auto', sm: '50px auto' }, marginBottom: 0 }}>
        <Title {...{ name: $t('home.rmsx') }} />
        <Grid xs={12} item sx={{ ...{ margin: '30px 0' }, ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
          {state.newsHot.map(v => <Collection key={v.id + Math.random()} {...v} />)}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 100000} sx={{
            width: { xs: '46%', sm: 200 },
            marginRight: 1
          }} />)}
          {st == 1 ? <Box sx={{ ...theme.palette.text, mt: 2, alignItems: 'center', width: '100%' }}>{$t('loadingText')}</Box> : ''}
          {(newsHot.status == 'fulfilled' && !state.newsHot.length) || st == 2 ? <Box sx={{ ...theme.palette.text, mt: 2, alignItems: 'center', width: '100%' }}>{$t('nodata')}</Box> : ''}
        </Grid>
      </Grid>
      {ld ? <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, ...theme.palette.warp.box, ...theme.palette.mainText, zIndex: 2 }}>{[300, 40, 40, 40, 40].map((v, i) => <Skeleton variant="circle" animation='wave' height={v} key={i} sx={{ m: 4 }} />)}</Box> : ''}
    </Box >
  )


}

export default Home
