
import { globalApiSlice as GAS } from '@/features/global/globalSlice'
import { useEffect, useRef, useState, useContext } from 'react'
import AppContext from '@/hooks/useAppContext.js'

export default async () => {
  if (!window.ethereum) {
    setTimeout(() => _toast('请在钱包的浏览器内打开使用'), 2000)
    return
  }

  if (window.ethereum.selectedAddress == null) {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
  }

  let USER = sessionStorage.getItem('USER') || {}
  try {
    USER = JSON.parse(USER)
  } catch (err) {
    USER = {}
  }

  if (USER.address) return USER

  const ALM = await GAS.authLoginMessageApi({
    address: window.ethereum.selectedAddress,
    chain: window.ethereum.networkVersion,
    inviter: '',
  })
  const sign = await window.ethereum.request({
    method: 'personal_sign',
    params: [ALM.login_message, window.ethereum.selectedAddress]
  })

  const AL = await GAS.authLoginApi({
    address: window.ethereum.selectedAddress,
    sign,
    chain: window.ethereum.networkVersion,
  })

  const UI = await GAS.getUserInfoApi({ id: AL.user_id })
  const data = { ...USER, ...UI, ...AL }
  sessionStorage.setItem('USER', JSON.stringify(data))
  sessionStorage.setItem('Q_TOKEN', data.token)
  sessionStorage.setItem('Q_TOKEN_TYPE', data.token_type)
  return data
}

// // 连接钱包
// export default function contentEthereum() {
//   const n = useRef(0)
//   const m = useRef(1)
//   const lm = useRef('')
//   const [sign, setSign] = useState('')
//   const [uI, setUI] = useState({})
//   const [state, setState] = useState({})
//   const { store, setStore } = useContext(AppContext)

//   if (!window.ethereum) {
//     n.current = n.current + 1
//     if (!n) setTimeout(() => _toast('请在钱包的浏览器内打开使用'), 2000)
//     return
//   }

//   let USER = sessionStorage.getItem('USER') || {}
//   try {
//     USER = JSON.parse(USER)
//   } catch (err) {
//     USER = {}
//   }

//   if (!window.ethereum.selectedAddress && off) {
//     window.ethereum.request({ method: 'eth_requestAccounts' })
//   }


//   // if (USER.address) return USER

//   const ALM = GAS.useAuthLoginMessageQuery({
//     address: window.ethereum.selectedAddress,
//     chain: window.ethereum.networkVersion,
//     inviter: '',
//     ckR: !!window.ethereum.selectedAddress && off
//   })

//   const AL = GAS.useAuthLoginQuery({
//     address: window.ethereum.selectedAddress,
//     sign,
//     chain: window.ethereum.networkVersion,
//     ckR: !!sign && off
//   })

//   const UI = GAS.useGetUserInfoQuery({
//     id: AL.data?.user_id,
//     ckR: !!(uI.user_id && AL.data && AL.data.user_id) && off
//   })

//   useEffect(() => {
//     console.log('store.metamaskMark', store.metamaskMark, ALM)
//     if (ALM.data) {
//       if (!sign && m.current !== store.metamaskMark && ALM.data.login_message !== lm.current) {
//         m.current = store.metamaskMark
//         lm.current = ALM.data.login_message
//         setState(v => ({}))
//         window.ethereum.request({
//           method: 'personal_sign',
//           params: [ALM.data.login_message, window.ethereum.selectedAddress]
//         }).then(v => {
//           setSign(() => v)
//         })
//       } else {
//         if (AL.data && !uI.user_id) {
//           setUI(v => AL.data)
//         } else {
//           if (UI.data && !state.address) {
//             const data = {
//               ...USER,
//               ...uI,
//               address: UI.data.address
//             }
//             sessionStorage.setItem('USER', JSON.stringify(data))
//             sessionStorage.setItem('Q_TOKEN', uI.token)
//             sessionStorage.setItem('Q_TOKEN_TYPE', uI.token_type)
//             setState(() => data)
//             setSign(() => '')
//           }
//         }
//       }
//     }
//   }, [ALM, sign, AL, UI, state])
//   if (USER.address) return USER
//   if (state.address) return state
//   return {}
// }