import React from 'react'
import { useDispatch } from 'react-redux'

import { setHistory } from '@/features/global/globalSlice'

function notPage ()  {
  return <div>404</div>
}

function RouterGuard(r) {
  const { element, path } = r
  const LoadableComponent = element || notPage
  const dispatch = useDispatch()

  dispatch(setHistory(path))

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <LoadableComponent {...r} />
    </div>
  )
}

export default RouterGuard
