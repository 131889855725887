import Layout from 'components/Layout/index.jsx'
import Home from 'components/Home/index.jsx'
import Login from 'components/Login/index.jsx'
import Market from 'components/Market/index.jsx'
import Collection from 'components/Collection/index.jsx'
import Publish from 'components/Publish/index.jsx'
import PublishInfo from 'components/PublishInfo/index.jsx'
import Mine from 'components/Mine/index.jsx'
import Search from 'components/Search/index.jsx'
import CollectionHome from 'components/CollectionHome/index.jsx'
import Ranking from 'components/ranking/index.jsx'
import Medal, { Luck, Prize } from 'components/Medal/index.jsx'
import InviteNew from 'components/inviteNew/index'
import Notice, { NoticeDetail } from 'components/Notice/index'
import Link from 'components/link/index.jsx'
import Webview from 'components/Webview/index'
import Project from 'components/Project/index.jsx'

export default {
  Layout,
  Home,
  Login,
  Market,
  Collection,
  Publish,
  PublishInfo,
  Mine,
  Search,
  CollectionHome,
  Ranking,
  Medal,
  Luck,
  Prize,
  InviteNew,
  Notice,
  NoticeDetail,
  Link,
  Webview,
  Project
}
