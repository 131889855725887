import { Box, Grid, useTheme, Dialog, Stepper, Step, StepLabel, MenuItem, Select, FormControl, Hidden, TextField } from '@mui/material'
import { globalApiSlice } from '@/features/global/globalSlice'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { zhCN, enUS } from '@mui/x-date-pickers/locales'
import contentEthereum from '@/hooks/contentEthereum'
import { useState, useContext, useImperativeHandle, useRef } from 'react'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction"
import AppContext from '@/hooks/useAppContext'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'

function StepperComponent(props) {
  const [data, setData] = useState(null)
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(1)
  const open = async prams => {
    const data = {
      product_id: prams.product_id,
      offer_id: prams.offer_id,
      close: prams.close,
      click: prams.click
    }
    setData(data)
  }
  const close = () => setData(null)

  const auth = () => new Promise(async (resolve) => {
    const res = await globalApiSlice.offerIsNftApproveApi({
      product_id: data.product_id
    })
    if (res == 0) {
      const result = await globalApiSlice.offerSetApproveAllApi({
        product_id: data.product_id
      })
      await ethereumSendTransaction({
        data: result.data,
        to: result.nft_address,
        chain_id: result.block_chain_id,
        gas_limit: result.gas_limit
      })
      await new Promise(resolve => {
        let t = setInterval(async () => {
          const res = await globalApiSlice.offerIsNftApproveApi({
            product_id: data.product_id
          })
          if (res == 1) {
            clearInterval(t)
            resolve(true)
          }
        }, 4000)
      })
    }
    if (res == 1) {
      resolve(true)
    }
    if (res == 2) {
      resolve(new Promise((resolve) => {
        const t = setInterval(() => {
          globalApiSlice.offerIsNftApproveApi({
            product_id: data.product_id
          }).then(result => {
            if (result.data == 1) {
              clearInterval(t)
              resolve(true)
            }
          })
        }, 4000)
      }))
    }
  })

  const authHandler = async () => {
    const result = await auth()
    if (result) setActiveStep(2)
  }

  const chujiaHandler = async () => {
    showLoad($t('qqz'))
    try {
      const res = await globalApiSlice.offerAcceptApi({
        product_id: data.product_id,
        offer_id: data.offer_id
      })
      await ethereumSendTransaction({
        data: res.data,
        to: res.offer_address,
        chain_id: res.block_chain_id,
        gas_limit: `${res.gas_limit}`,
        type: res.hash_type,
        id: [res.id]
      })
      _toast($t('offer.czwc'))
      close()
      closeLoad()
      data?.close()
      data.click()
    } catch(err) {
      closeLoad()
    }
  }

  useImperativeHandle(props.onRef, () => ({
    open, close
  }))
  return (
    <Dialog anchor={'center'} maxWidth={'sm'} fullWidth={true} open={Boolean(data)} >
      <Grid container item xs={11} sm={11} sx={{ margin: { xs: '10px auto', sm: '20px auto' } }}>
        {$t('offer.jscj')}
      </Grid>
      <Grid container item xs={11} sm={11} sx={{ margin: { xs: '10px auto', sm: '20px auto' } }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
          {[$t('offer.cshzh'), $t('offer.sq'), $t('offer.wc')].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container item xs={11} sm={11} sx={{ margin: { xs: '10px auto', sm: '20px auto', textAlign: 'center' } }}>
        {activeStep === 0 && <Grid xs={12}>{$t('offer.step1')}</Grid>}
        {activeStep === 1 && <Grid xs={12}>{$t('offer.step2')}</Grid>}
        {activeStep === 2 && <Grid xs={12}>{$t('offer.step3')}</Grid>}
      </Grid>
      <Grid container item xs={11} sm={11} sx={{
        margin: { xs: '10px auto', sm: '20px auto' },
        ...theme.typobox.flexCenterRight,
      }}>
        <Box onClick={close} sx={{ mr: 2, ...theme.palette.text }}>{$t('cancel')}</Box>
        {activeStep === 1 && <Box sx={{
          ...theme.palette.btn.login,
          p: 1,
          pl: 2,
          pr: 2,
          fontSize: { xs: 16, sm: 20 },
          borderRadius: 1
        }} onClick={authHandler}>{$t('offer.sq')}</Box>}
        {activeStep === 2 && <Box
          sx={{
            ...theme.palette.btn.login,
            p: 1,
            pl: 2,
            pr: 2,
            fontSize: { xs: 16, sm: 20 },
            borderRadius: 1
          }}
          onClick={chujiaHandler}>{$t('offer.jscj')}</Box>}
      </Grid>
    </Dialog >
  )
}

function BigAccept(props) {
  const theme = useTheme()
  const [data, setData] = useState(null)
  const stepperComponent = useRef(null)

  const { store, setStore } = useContext(AppContext)
  const close = () => setData(null)

  const handle = async () => {
    stepperComponent.current.open({
      product_id: data.product_id,
      offer_id: data.id,
      close: close
    })
  }

  const open = async prams => {
    const result = await globalApiSlice.offerReceiveInfoApi({
      product_id: prams.product_id,
      offer_id: prams.offer_id
    })
    const data = {
      fee: result?.fee,
      type: result?.offer?.type,
      sourcefile_url: result?.product?.product?.sourcefile_url,
      name: result?.product?.product?.name,
      series_name: result?.product?.product?.series?.name,
      token_id: result?.product?.product?.token_id,
      id: result?.offer?.id,
      status: result?.offer?.status,
      symbol_cion: result?.offer?.symbol?.symbol,
      product_id: result?.product?.product_id,
      offer_price: result?.offer?.price
    }
    setData(data)
  }

  useImperativeHandle(props.onRef, () => ({
    open, close
  }))

  return (
    <Dialog anchor={'center'} maxWidth={'sm'} fullWidth={true} open={Boolean(data)} >
      <StepperComponent onRef={stepperComponent} />
      <Grid container item xs={11} sm={11} sx={{ margin: { xs: '10px auto', sm: '20px auto' } }}>
        <Grid item xs={12} sx={{
          mb: 1,
          pb: 1,
          ...theme.palette.text,
          ...theme.typobox.flexCenterRight,
          borderBottom: `1px solid ${theme.palette.theme.color}`
        }}>
          <Box onClick={() => setData(null)} sx={{ fontSize: { xs: 16, sm: 20 }, mr: 0 }}>{$t('gb')}</Box>
        </Grid>
        <Grid item xs={4} sm={4} sx={{
          // width: 200, height: 200,
          // backgroundImage: `url(${data?.sourcefile_url})`,
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat'
        }}>
          <img className='global-img' src={data?.sourcefile_url} />
        </Grid>
        <Grid item xs={1} sm={1}>
        </Grid>
        <Grid item xs={7} sm={7}>
          <Grid item xs={12} sx={{
            ...theme.typobox.flexCenterLeft,
            fontSize: { xs: 20, sm: 40 }
          }}>{data?.type == 2 ? data?.series_name : data?.name} {data?.type == 1 ? `#${data?.token_id}` : ''}</Grid>
          <Grid item xs={12} sx={{ fontSize: { xs: 14, sm: 20 }, mt: 1 }}>
            <Grid item xs={12} sx={{ mb: 1 }} >
              {$t('home.dbj')}：{data?.fee?.floor_price} {data?.symbol_cion}
            </Grid>
            <Grid item xs={12} >
              {$t('offer.zgcj')}：{data?.fee?.highest_price} {data?.symbol_cion}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
          <Box>{$t('offer.zgcj')}</Box>
          <Box sx={{ ...theme.typobox.flexEndRightByColumn }}>
            <Box>{data?.offer_price} {data?.symbol_cion}</Box>
            <Box>{$t('offer.dydbj')} {data?.low_rate || 0}%</Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
          <Box>{$t('public.ptfwf')}({data?.platform_rate}%)</Box>
          <Box>{data?.fee?.platform_fee} {data?.symbol_cion}</Box>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
          <Box>{$t('public.bs')}</Box>
          <Box>{data?.fee?.royalty_rate}%</Box>
        </Grid>
        <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween }}>
          <Box>{$t('offer.yjhd')}</Box>
          <Box sx={{ ...theme.typobox.flexEndRightByColumn }}>
            <Box>{data?.fee?.earnings} {data?.symbol_cion}</Box>
            <Box>{$t('offer.dydbj')} {data?.low_rate || 0}%</Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }} >
          <Box className="global-button" onClick={handle} sx={{ ...theme.palette.btn.login, height: { xs: 55, sm: 70 }, borderRadius: 2 }}>{$t('offer.jscj')}</Box>
        </Grid>
      </Grid>
    </Dialog >
  )
}

export default BigAccept
