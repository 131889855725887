export default {
  id: 1030,
  name: "Conflux eSpace",
  network: "CFX",
  nativeCurrency: {
    name: "CFX",
    symbol: "CFX",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://evm.confluxrpc.com"],
    },
    public: {
      http: ["https://evm.confluxrpc.com"],
    },
  },
}