import { globalApiSlice } from '@/features/global/globalSlice'
import ethereumSendTransaction from "@/hooks/ethereumSendTransaction"
import utils from 'web3-utils'
import {
    arbitrum,
    mainnet,
    polygon,
    polygonMumbai,
    goerli,
    arbitrumGoerli
} from '@wagmi/chains'
import cfxTest from "../../chains/cfxTest.js"
import cfx from "../../chains/cfx.js"
const resPromise = []
let product = []
let proList = []
const chainsList= {
    '1030': cfx,
    '71': cfxTest,
    '42161': arbitrum,
    '421613': arbitrumGoerli,
    '1': mainnet,
    '5': goerli,
    '137': polygon,
    '80001': polygonMumbai
}
const rpcUrls= {
    1030: [],
    71: [],
    42161: [
        "https://arb1.arbitrum.io/rpc",
        "https://arb1.croswap.com/rpc",
        "https://rpc.ankr.com/arbitrum",
        "https://1rpc.io/arb",
        "https://arbitrum.getblock.io/api_key/mainnet/",
        "https://arb-mainnet.g.alchemy.com/v2/demo",
        "https://arbitrum.blockpi.network/v1/rpc/public",
        "https://arbitrum-one.public.blastapi.io",
        "https://endpoints.omniatech.io/v1/arbitrum/one/public",
        "https://arb-mainnet-public.unifra.io",
        "https://arbitrum.api.onfinality.io/public",
        "https://rpc.arb1.arbitrum.gateway.fm",
        "https://arbitrum-one.publicnode.com",
        "https://arbitrum.meowrpc.com",
        "https://api.zan.top/node/v1/arb/one/public",
        "https://arbitrum.drpc.org",
    ],
    421613: [],
    1: [],
    5: [
        "https://rpc.ankr.com/eth_goerli",
        "https://endpoints.omniatech.io/v1/eth/goerli/public",
        "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        "https://eth-goerli.public.blastapi.io",
        "https://eth-goerli.g.alchemy.com/v2/demo",
        "https://goerli.blockpi.network/v1/rpc/public",
        "https://eth-goerli.api.onfinality.io/public",
        "https://rpc.goerli.eth.gateway.fm",
        "https://ethereum-goerli.publicnode.com",
        "https://goerli.gateway.tenderly.co",
        "https://gateway.tenderly.co/public/goerli",
        "https://api.zan.top/node/v1/eth/goerli/public",
        "https://rpc.notadegen.com/eth/goerli"
    ],
    137: [
        "https://rpc-mainnet.maticvigil.com",
        "https://endpoints.omniatech.io/v1/matic/mainnet/public",
        "https://polygon-rpc.com",
        "https://rpc-mainnet.matic.network",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet-full-rpc.bwarelabs.com",
        "https://matic-mainnet-archive-rpc.bwarelabs.com",
        "https://poly-rpc.gateway.pokt.network/",
        "https://rpc.ankr.com/polygon",
        "https://polygon-mainnet.public.blastapi.io",
        "https://polygonapi.terminet.io/rpc",
        "https://1rpc.io/matic",
        "https://polygon-mainnet.rpcfast.com?api_key=xbhWBI1Wkguk8SNMu1bvvLurPGLXmgwYeC4S6g2H7WdwFigZSmPWVZRxrskEQwIf",
        "https://polygon-bor.publicnode.com",
        "https://polygon-mainnet-public.unifra.io",
        "https://polygon-mainnet.g.alchemy.com/v2/demo",
        "https://polygon.blockpi.network/v1/rpc/public",
        "https://polygon.api.onfinality.io/public",
        "https://polygon.rpc.blxrbdn.com/",
        "https://polygon.drpc.org",
        "https://polygon.gateway.tenderly.co",
        "https://gateway.tenderly.co/public/polygon",
        "https://api.zan.top/node/v1/polygon/mainnet/public",
        "https://polygon.meowrpc.com",
    ],
    80001: [
        "https://rpc-mumbai.maticvigil.com",
        "https://endpoints.omniatech.io/v1/matic/mumbai/public",
        "https://rpc.ankr.com/polygon_mumbai",
        "https://polygontestapi.terminet.io/rpc",
        "https://polygon-testnet.public.blastapi.io",
        "https://polygon-mumbai.g.alchemy.com/v2/demo",
        "https://polygon-mumbai.blockpi.network/v1/rpc/public",
        "https://polygon-mumbai-bor.publicnode.com",
        "https://polygon-mumbai.gateway.tenderly.co",
        "https://gateway.tenderly.co/public/polygon-mumbai",
        "https://api.zan.top/node/v1/polygon/mumbai/public"
    ]
}
const grounding = (list,arr,setStore) => {
    product = JSON.parse(JSON.stringify(arr))
    proList = JSON.parse(JSON.stringify(list))
    return new  Promise(async (resolve, reject) => {
        for (let i = 0; i < list.length; i++) {
            const skill = list[i]
            const res = await getSkillPromise(skill,setStore)
            if (res==-1){
                resolve(res)
                return
            }
            resPromise.push(res)
        }
        setStore(v => ({ ...v, authContent: {
                name:v.authContent.name,
                num:v.authContent.num,
                status:2
            } }))
        // 必须全部授权
        if (resPromise.length == list.length) {
            let res = await putMsg()
            if (res==-1){
                resolve(res)
                return
            }
            if (res) {
                resolve(true)
            }
        } else {
            alert($t('partAuthFail'))
            reject(false)
        }
    })
}
const getSkillPromise =(value,setStore) =>{
    return new Promise(async (resolve, reject) => {
        // setTimeout(async () => {
        const res = await getAuth(value,setStore)
        resolve(res)
        // }, 3000);
    })
}
export const getAuth = async (skill,setStore) => {
    return new Promise(async (resolve, reject) => {
        const chain_id = skill.chain_id
        setStore(v => ({ ...v, authContent: {
            name:`${skill.product_name}#${skill.token_id}`,
            num:skill.quantity,
            status:v.authContent.status
            } }))
        // 网络注册授权状态查询
        let res = await globalApiSlice.netRegisterStatusApi({chain_id})
        if (res == 0) {
            setStore(v => ({ ...v, authContent: {
                    name:v.authContent.name,
                    num:v.authContent.num,
                    status:0
                } }))
            // 网络未注册
            let res2 = await globalApiSlice.netregisterApi({chain_id})
            if (res2) {
                let obj = {
                    data: res2.registerAuth_data,
                    to: res2.exchange_address,
                    chain_id,
                    type: 4,
                    id: [res2.hash_id],
                }
                let r = await send_transaction(obj)
                if (r){
                    let t = setInterval(async () => {
                        res = await globalApiSlice.netRegisterStatusApi({
                            chain_id
                        })
                        if (res == 0) {
                            clearInterval(t)
                            resolve(netNftApprovalStatus(skill,setStore))
                        }
                    }, 1000)
                }else {
                    setStore(v => ({ ...v, authContent: {
                            name:'',
                            num:v.authContent.num,
                            status:-1
                        } }))
                }
            }
        }
        if (res == 1) {
            // 网络已注册
            resolve(netNftApprovalStatus(skill,setStore))
        }
        if (res == 2) {
            // 注册中
            let t = setInterval(async () => {
                res = await globalApiSlice.netRegisterStatusApi({
                    chain_id
                })
                if (res.code == 1 && res.data == 1) {
                    clearInterval(t)
                    resolve(netNftApprovalStatus(skill,setStore))
                }
            }, 1000)
        }
    })
}
//查询NFT注册授权状态
function netNftApprovalStatus(data,setStore){
    const {
        chain_id,
        contract_address,
        protocol
    } = data
    return new Promise(async (resolve, reject) => {
        setStore(v => ({ ...v, authContent: {
                name:v.authContent.name,
                num:v.authContent.num,
                status:1
            } }))
        let res3 = await globalApiSlice.netNftApprovalStatusApi({
            chain_id,
            nft_contract_address: contract_address,
            protocol
        })
        if (res3==0){
            let res4 = await globalApiSlice.netNftSetApprovalApi({
                chain_id,
                nft_contract_address: contract_address,
                approved: true,
                protocol,
            })
            if (res4){
                let tx_hash = await send_transaction({
                    data: res4.data,
                    to: contract_address,
                    chain_id,
                    type: 5,
                    id: [res4.hash_id],
                })
                if (tx_hash) {
                    let t = setInterval(async () => {
                        res3 = await globalApiSlice.netNftApprovalStatusApi({
                            chain_id,
                            nft_contract_address: contract_address,
                            protocol
                        })
                        if (res3 == 0) {
                            clearInterval(t)
                            resolve({
                                ...data,
                                nftstatus: 1
                            })
                        }
                    }, 1000)
                }else {
                    //拒绝
                    setStore(v => ({ ...v, authContent: {
                            name:'',
                            num:v.authContent.num,
                            status:-1
                        } }))
                    resolve(-1)
                }
            }
        }
        if (res3 == 1) {
            resolve({
                ...data,
                nftstatus: 1
            })
        }
    })
}
//编码->上架
const putMsg = () => {
    return new Promise(async (resolve, reject) => {
        let res = await globalApiSlice.batchUpSignMsgApi({
            products: product
        })
        if (res) {
            let sign = await signMessage({
                message: res.message,
                chain_id: proList[0].chain_id
            })
            if (sign) {
                let res2 = await globalApiSlice.batchUpApi({
                    orders: res.orders,
                    message: res.message,
                    signature: sign
                })
                if (res2 == '成功') {
                    setTimeout(() => {
                        _toast($t('public.sjcg'))
                    }, 1000)
                    resolve(true)
                } else {
                    reject(false)
                }
            }else {
                resolve(-1)
            }
        }
    })
}
const signMessage =async (data) => {
    try {
        const walletCode = window?.ethereum?.selectedAddress?window.ethereum.selectedAddress:''
        let chain_id = await window.ethereum.networkVersion
        if (data.chain_id && (data.chain_id != chain_id)) {
            let switchRes = await window.ethereum.request({
                "method": "wallet_switchEthereumChain",
                "params": [{
                    "chainId": utils.numberToHex(data.chain_id)
                }]
            });
            // 4902	无法识别的链 ID。首先尝试使用“wallet_addEthereumChain”添加链。
            if (switchRes) {
                await window.ethereum.request({
                    "method": "wallet_addEthereumChain",
                    "params": [chainsList[data.chain_id]]
                });
            }
            // return false
        }
        let sign = await window.ethereum.request({
            "method": "eth_signTypedData_v4",
            "params": [
                walletCode,
                data.message
            ],
        })
        return sign
    } catch (e) {
        return null
    }
}
// 发送交易
async function send_transaction(data) {
    const walletCode = window?.ethereum?.selectedAddress ? window.ethereum.selectedAddress : ''
    // 如果没有连接，先连接，再发送交易
    if (!walletCode) {
        _toast($t('public.ljqb'))
    } else {
        let chain_id = await window.ethereum.networkVersion
        const chainData = chainsList[data.chain_id]
        if (data.chain_id && (data.chain_id != chain_id)) {
            try {
                let switchRes = await window.ethereum.request({
                    "method": "wallet_switchEthereumChain",
                    "params": [{
                        "chainId": utils.numberToHex(data.chain_id)
                    }]
                });

                // 4902	无法识别的链 ID。首先尝试使用“wallet_addEthereumChain”添加链。
                if (switchRes) {
                    await window.ethereum.request({
                        "method": "wallet_addEthereumChain",
                        "params": [{
                            chainId: utils.numberToHex(data.chain_id),
                            chainName: chainData.name,
                            rpcUrls: rpcUrls[data.chain_id],
                            blockExplorerUrls: [
                                chainData.blockExplorers.default.url
                            ],
                            nativeCurrency: chainData.nativeCurrency
                        }]
                    })
                }
            } catch (e) {
                await window.ethereum.request({
                    "method": "wallet_addEthereumChain",
                    "params": [{
                        chainId: utils.numberToHex(data.chain_id),
                        chainName: chainData.name,
                        rpcUrls: rpcUrls[data.chain_id],
                        blockExplorerUrls: [
                            chainData.blockExplorers.default.url
                        ],
                        nativeCurrency: chainData.nativeCurrency
                    }]
                })
            }
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        let estimateGas = 0
        let gas = {
            71: '200000',
            42161: '5000000'
        }

        try {
            estimateGas = await window.ethereum.request({
                "method": "eth_estimateGas",
                "params": [{
                    "from": walletCode,
                    "to": data.to,
                    "data": data.data,
                    "chainId": utils.numberToHex(data.chain_id),
                    "value": data.value ? utils.numberToHex(+data.value) : '0x0',
                }, null]
            });

            let result = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: walletCode,
                    to: data.to,
                    value: data.value ? utils.numberToHex(data.value) : 0,
                    data: data.data,
                    gas: estimateGas
                }],
            })
            if (data.type) {
                await globalApiSlice.hashReturnApi({
                    ids: data.id,
                    type: data.type,
                    hash: result
                })
            }
            return result;
        } catch (e) {
            let msg = ''
            if (typeof e == 'object') {
                msg = e && ((e.data && e.data.message) || e.message)
                if (e.code == -32603) {
                    _toast($t('yebz'))
                }
            } else {
                _toast(e)
            }
            return null
        }
    }
}
//下架
export function offShelf(id, chain_id) {
    return new Promise(async (resolve, reject) => {
        let res = await globalApiSlice.offShelfApi({
            order_id: id
        })
        if (res.data) {
            let res2 = await send_transaction({
                id: [res.order_id],
                type: 2,
                to: res.exchange_address,
                data: res.data,
                chain_id
            })
            if (res2) {
                _toast($t('collection.xjcg'))
                resolve(true)
            }else {
                resolve(-1)
            }
        }else {
            reject(false)
        }
    })
}
export default grounding