import { Box, Grid, useTheme, Select, FormControl, MenuItem, OutlinedInput, InputAdornment, Hidden } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { HotProject, Collection } from 'components/Home/index'
import { useEffect, useRef, useState } from 'react'
import { globalApiSlice } from '@/features/global/globalSlice'

function HotP(v) {
  const theme = useTheme()
  const hEl = useRef(null)

  setTimeout(() => { try { $(hEl.current).find('div')[0].style.width = '100%'; $(hEl.current).find('div')[0].style.marginTop = 0 } catch (err) { } })
  setTimeout(() => $(hEl.current).find('div')[6] ? $(hEl.current).find('div')[6].innerText = $t('home.jyl') : '')

  return <Box ref={hEl}
    sx={{
      width: { xs: '46%', sm: 200 },
      height: { xs: 260, sm: 280 },
      ...theme.palette.boxShadow,
      ...theme.palette.warp.box,
      marginRight: { xs: 0, sm: 3 },
      marginTop: { xs: 3, sm: 3 }
    }}><HotProject {...v} /></Box>
}

function Search(p) {
  const { v } = p
  const [val, setVal] = useState('')
  const { useMarketCollectionQuery, useMarketProductListQuery } = globalApiSlice
  const [s, setS] = useState([])
  const [i, setI] = useState(0)
  const [stEl, setStEl] = useState(2)
  const t = useTheme()
  const mc = (i => i ? useMarketProductListQuery : useMarketCollectionQuery)(i)({ search: (v || val) === '' ? undefined : (v || val), sort: stEl, per_page: 9999 })
  useEffect(() => {
    setS([])
    if (mc.data) setS(v => _.clone(mc?.data?.data) || [])
  }, [mc])

  const sort = $t('market.sortTypes')

  // const sort = [{
  //   "key": 4,
  //   "name": "最新上架"
  // }, {
  //   "key": 2,
  //   "name": "价格从低到高"
  // }, {
  //   "key": 1,
  //   "name": "价格从高到低"
  // }]


  return (
    <Box>
      <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '30px auto', ...t.palette.text }}>
        <Hidden smUp>
          <Grid item xs={12} sx={{ mb: 2 }} >
            <FormControl fullWidth>
              <OutlinedInput
                size="small"
                onInput={e => _.debounce(() => setVal(e.target.value), 500)}
                placeholder={$t('inputKeyword')}
                startAdornment={<InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>}
              />
            </FormControl>
          </Grid>
        </Hidden>
        <Box className={i == 0 ? "global-font-weight-bold" : ''} sx={{ width: 120, fontSize: 20, mr: 2, ...(i == 0 ? t.palette.mainText : {}) }} >
          <Box onClick={() => { setI(0) }}>{$t('public.hj')}</Box>
          <Box sx={{ background: 'linear-gradient(90deg, #00F6D0, #0077F5);', width: 120, height: 2, mt: 2 }}></Box>
        </Box>
        <Box onClick={() => { setI(1) }} className={i == 1 ? "global-font-weight-bold" : ''} sx={{ width: 120, fontSize: 20, ...(i == 1 ? t.palette.mainText : {}) }} >
          <Box>{$t('cp')}</Box>
          <Box sx={{ background: 'linear-gradient(90deg, #00F6D0, #0077F5);', width: 120, height: 2, mt: 2 }}></Box>
        </Box>
      </Grid>
      {
        i == 1 ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ ...{ margin: '0 auto' }, ...t.typobox.flexCenterLeft }}>
          <Grid item xs={3} sm={2} sx={t.palette.text}>{$t('public.orderType')}</Grid>
          <Grid item xs={3.5} sm={2}>
            <FormControl variant="standard" fullWidth>
              <Select
                value={stEl}
                onChange={e => setStEl(e.target.value)}
                size="small"
                label="Age"
              >
                {sort.map(v => <MenuItem key={v.key} value={v.key}>{v.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid> : ''
      }

      {i == 0 ? <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
        {
          mc.status === 'pending' ? <Box>{$t('loadingText')}..</Box> : mc.status === 'fulfilled' && !mc.data.data.length ? <Box>{$t('nodata')}..</Box> :
            <Grid xs={12} item sx={{ ...t.typobox.flexWarpWrap, ...t.typobox.flexCenterSpaceBetween }} >
              {s.map(v => <HotP key={v.id} {...v} />)}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 1000} sx={{
                width: { xs: '46%', sm: 200 }, marginRight: { xs: 0, sm: 3 },
                marginTop: 0
              }} />)}
            </Grid>
        }
      </Grid> : <Grid container item xs={11} sm={10} md={10} lg={10} sx={{ margin: '0 auto' }}>
        {
          mc.status === 'pending' ? <Box>{$t('loadingText')}..</Box> : mc.status === 'fulfilled' && !mc.data.data.length ? <Box>{$t('nodata')}..</Box> :
            <Grid xs={12} item sx={{ ...t.typobox.flexWarpWrap, ...t.typobox.flexCenterSpaceBetween }} >
              {s.map(v => <Collection key={v.id} {...{ ...v, serice_icon_url: v.collection_icon_url, pay_coin: v.symbol }} />)}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <Box key={v + 100000} sx={{
                width: { xs: '46%', sm: 200 },
                marginRight: 1
              }} />)}
            </Grid>
        }
      </Grid>}
    </Box>
  )
}

export default Search
