import ethIcon from "@/assets/icon/mineIcon/eth.png"
import netIcon from "@/assets/icon/mineIcon/net.png"
import twitterIcon from "@/assets/icon/mineIcon/twitter.png"
import discordIcon from "@/assets/icon/mineIcon/discord.png"
import style from '@/components/Mine/mine.module.css'
import html2canvas from "html2canvas"

import ZH from '@/lang/zh.json'
import EN from '@/lang/en.json'
import TW from '@/lang/tw.json'

const lang = (() => {
    const store = sessionStorage.getItem('lang') || 'ZH'
    let lang = EN
    switch (store) {
        case 'ZH':
            lang = ZH
            break
        case 'YW':
            lang = EN
            break
        case 'TW':
            lang = TW
            break
    }
    return lang
})()

export let product = {
    "data": [
        {
            "id": 1,//我的藏品id
            "product_id": 9,//藏品ID
            "floor_price": "1.88", //价格
            "sourcefile_url": "https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512",//藏品图片
            "status": 3, //状态 0=已下架,1=已上架，2=已转移，3=已售出
            "pay_coin": "CFX", //支付币种
            "copyright_rate": "0.10",//版税费率
            "quantity": 1,//数量
            "product_name": "鼠",//藏品名称
            "series_name": "景观高度", //合集名称
            "platform_rate": "0.10", //平台手续费率
            "is_blindbox": 1,//是否盲盒 0 否，1 是
            "open_status": "0", //盲盒是否开启 0 否，1 是
            "open_at": "4646432147", //开盒时间
            "now_at": "4646432147", //当前时间
        },
        {
            "id": 2,//我的藏品id
            "product_id": 9,//藏品ID
            "floor_price": "1.88", //价格
            "sourcefile_url": "https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512",//藏品图片
            "status": 3, //状态 0=已下架,1=已上架，2=已转移，3=已售出
            "pay_coin": "CFX", //支付币种
            "copyright_rate": "0.10",//版税费率
            "quantity": 1,//数量
            "product_name": "牛",//藏品名称
            "series_name": "景观高度", //合集名称
            "platform_rate": "0.10", //平台手续费率
            "is_blindbox": 1,//是否盲盒 0 否，1 是
            "open_status": "0", //盲盒是否开启 0 否，1 是
            "open_at": "4646432147", //开盒时间
            "now_at": "4646432147", //当前时间
        },
        {
            "id": 3,//我的藏品id
            "product_id": 9,//藏品ID
            "floor_price": "1.88", //价格
            "sourcefile_url": "https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512",//藏品图片
            "status": 3, //状态 0=已下架,1=已上架，2=已转移，3=已售出
            "pay_coin": "CFX", //支付币种
            "copyright_rate": "0.10",//版税费率
            "quantity": 1,//数量
            "product_name": "虎",//藏品名称
            "series_name": "景观高度", //合集名称
            "platform_rate": "0.10", //平台手续费率
            "is_blindbox": 1,//是否盲盒 0 否，1 是
            "open_status": "0", //盲盒是否开启 0 否，1 是
            "open_at": "4646432147", //开盒时间
            "now_at": "4646432147", //当前时间
        }
    ]
}

export let userinfo = {
    "data": {
        "user": { // 用户信息
            "id": 3, // 用户ID
            "username": "569246938@qq.com", // 用户名
            "nickname": "张三", // 昵称
            "email": "569246938@qq.com", // Email
            "avatar": "https://img2.baidu.com/it/u=1790834130,1952230725&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500", // 头像
            "description": "", //简介
            "inviter_code": "fs8f1" //邀请码
        },
        "current": { // 当前登录
            "id": 11,
            "eth_address": "0x011C267329919d4D712C220FCBd3Aa4d5d6B79De",
            "chain_id": 71,
            "is_logined": 1,
            "chain": "ethereum"
        },
        "address": [ // 地址列表
            {
                "id": 3, // 地址ID
                "eth_address": "0xEc58a6c6fdbAFB7Bbd3C10921997814ae84fB5AC", // 地址
                "chain_id": "71", // 链ID
                "chain": "ethereum", // 区块链
            }
        ]
    }
}

export const userOrder = {
    "data": [
        {
            "id": 9,
            "product_id": 9,
            "series_id": 0,
            "side": 1, //1：买单 2：卖单
            "quantity": 1,//数量
            "price": "0.0500000000",//价格
            "status": 1,//状态 side=1  3=交易成功  4=交易失败 5=超时关闭| side=2 1=上架成功 2=下架成功 3=交易成功 5=超时关闭
            "pay_coin": "ETH",//支付币种
            "closedtime": 1682649489,//失效时间
            "createtime": 1682649489,//创建时间
            "sourcefile_url": "https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512",//藏品图片
            "product_name": "牛",//藏品名称
            "series_name": '合集名称',//合集名称
            "platform_rate_fee": "0.1",//平台手续费率
            "copyright_rate_fee": "0.05",//版税费率
            "chain_id": 71 //链ID
        }
    ]
}

export const tradeLog = {
    "data": [
        {
            "id": 1,
            "sourcefile_url": "https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512",//图片
            "type": 4,//类型 0=提交资料,1=审核驳回,2=审核通过, 3=铸造, 4=上架, 5=下架, 6=到期, 7=出价, 8=成交, 9=成交失败, 10=转移
            "from": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",//卖家address
            "to": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",//买家address
            "quantity": 1,//数量
            "price": "0.0100000000",//价格
            "createtime": 1682583458,//创建时间
            "product_name": "虎",//藏品名称
            "gas": "0.0000000000",//gas费
            "royalty": "0.0000000000",//版税
            "fee": "0.0000000000",//手续费
            "deal_price": "0.0000000000",//成交价格
            "series_name": "景观高度",//合集名称
            "createtime_format": "15天前"
        }
    ]
}

export const rankingData = {
    "data": [
        {
            "id": 1,
            "icon_url": "",
            "pay_coin": "CFX",
            "up_rate": "0.0000", // 上架率
            "holder_dispersion": "1.3220", // 持有分散度
            "name": "LOL1",
            "change_number": 0, // 成交数涨跌幅
            "change_amount": "0.0000", // 交易额涨幅
            "tx_number": 0, // 成交数
            "tx_amount": "1.0000", // 成交额
            "floor_price": "0.0000", // 地板价
            "change_price": "0.0000", // 地板价涨幅
            "sequence": 2 // 序列
        },
        {
            "id": 2,
            "icon_url": "",
            "pay_coin": "CFX",
            "up_rate": "0.0000", // 上架率
            "holder_dispersion": "2.3220", // 持有分散度
            "name": "LOL2",
            "change_number": 0, // 成交数涨跌幅
            "change_amount": "0.0000", // 交易额涨幅
            "tx_number": 0, // 成交数
            "tx_amount": "1.0000", // 成交额
            "floor_price": "0.0000", // 地板价
            "change_price": "0.0000", // 地板价涨幅
            "sequence": 2 // 序列
        },
        {
            "id": 3,
            "icon_url": "",
            "pay_coin": "CFX",
            "up_rate": "0.0000", // 上架率
            "holder_dispersion": "3.3220", // 持有分散度
            "name": "LOL3",
            "change_number": 0, // 成交数涨跌幅
            "change_amount": "0.0000", // 交易额涨幅
            "tx_number": 0, // 成交数
            "tx_amount": "1.0000", // 成交额
            "floor_price": "0.0000", // 地板价
            "change_price": "0.0000", // 地板价涨幅
            "sequence": 2 // 序列
        }
    ]
}

//我的-数据字典
export const keyWord = (tab) => {
    switch (tab) {
        case 0:
            return {
                img: 'sourcefile_url',
                price: 'floor_price',
                coin: 'pay_coin',
                name: 'product_name',
                seriesName: 'series_name'
            }
        case 1:
            return {
                img: 'sourcefile_url',
                price: 'floor_price',
                coin: 'pay_coin',
                name: 'product_name',
                seriesName: 'series_name'
            }
        case 2:
            return {
                img: 'sourcefile_url',
                price: 'price',
                coin: 'pay_coin',
                name: 'product_name',
                seriesName: 'series_name',
                time1: 'createtime',
                time2: 'closedtime',
                gas: 'gas',
                bs: 'copyright_rate_fee',
                sxf: 'platform_rate_fee'
            }
        case 3:
            return {
                img: 'sourcefile_url',
                price: 'price',
                coin: 'pay_coin',
                name: 'product_name',
                seriesName: 'series_name'
            }
        case 4:
            return {
                img: 'sourcefile_url',
                price: 'floor_price',
                coin: 'pay_coin',
                name: 'product_name',
                seriesName: 'series_name'
            }
    }
}

// export const expireTimeOption = [
//     { name: '1小时', value: 1, num: 0 },
//     { name: '24小时', value: 2, num: 1 },
//     { name: '7天', value: 3, num: 7 },
//     { name: '30天', value: 4, num: 30 }
// ]

export const expireTimeOption = lang.home.timeType.map(v => ({ name: v.name, value: v.key }))

export const timeOption = [
    { name: '1小时', value: 0 },
    { name: '24小时', value: 1 },
    { name: '7天', value: 7 },
    { name: '30天', value: 30 }
]

export const TopImg = [
    { icon: ethIcon, width: 12, height: 16 },
    { icon: netIcon, width: 17, height: 17 },
    { icon: twitterIcon, width: 15, height: 13 },
    { icon: discordIcon, width: 17, height: 13 }
]
//tabs列表
export const tabsArr = [
    { label: lang.menuList[0].name, value: '0' },
    { label: lang.menuList[1].name, value: '1' },
    { label: lang.menuList[3].name, value: '5' },
    { label: lang.menuList[4].name, value: '6' },
    { label: lang.menuList[2].name, value: '2' },
    { label: lang.menuList2[1].name, value: '3' },
    { label: lang.menuList2[2].name, value: '4' }
]
//上架状态
export const dealType = lang.sellStatus.map(v => ({
    label: v.name,
    value: `${v.key == -1 ? 2 : v.key}`
}))
// export const dealType = [
//     { label: "全部", value: '2' },
//     { label: "未上架", value: '0' },
//     { label: "已上架", value: '1' }
// ]
//交易动态
// export const tradeTrends = [
//     { label: "全部", value: '0' },
//     { label: "铸造", value: '3' },
//     { label: "上架", value: '4' },
//     { label: "下架", value: '5' },
//     { label: "到期", value: '6' },
//     { label: "出价", value: '7' },
//     { label: "成交", value: '8' },
//     { label: "成交失败", value: '9' },
//     { label: "转移", value: '10' }
// ]
export const tradeTrends = lang.lsTypes.map(v => ({
    label: v.name,
    value: `${v.key}`
}))

//上架中-表格columns
export const sjzColumns = [
    { label: lang.public.ly, prop: "laiyuan", xs: 0.6, xm: 1 },
    { label: "NFT", prop: "series_name", xs: 2, xm: 3 },
    { label: lang.public.price, prop: "price", xs: 1, xm: 2 },
    { label: lang.num, prop: "quantity", xs: 1, xm: 1 },
    { label: lang.public.spfbsj, prop: "createtime", xs: 2, xm: 2 },
    { label: lang.public.ddgqsj, prop: "closedtime", xs: 2, xm: 2 },
    { label: "", prop: "handle", xs: 2, xm: 1 }
]
export const jydtColumns = [
    { label: lang.public.ly, prop: "laiyuan", width: "50px", xs: 0.6, xm: 1 },
    { label: "NFT", prop: "series_name", width: "150px", xs: 1.5, xm: 2 },
    { label: lang.market.type, prop: "type", width: "50px", xs: 1, xm: 1 },
    { label: lang.public.zzcjj, prop: "deal_price", width: "100px", xs: 1.5, xm: 2 },
    { label: lang.public.from, prop: "from", width: "150px", xs: 2, xm: 2 },
    { label: lang.public.to, prop: "to", width: "150px", xs: 2, xm: 2 },
    { label: lang.num, prop: "quantity", width: "50px", xs: 1, xm: 1 },
    { label: lang.public.time, prop: "createtime", width: "50px", xs: 1, xm: 1 }
]
export const perCenter = [
    // {label:"我的NFT",path:"mine",value:0},
    // {label:"上架中",path:"mine",value:1},
    // {label:"报价中",path:"mine",value:3},
    // {label:"失效订单",path:"mine",value:2},
    { label: lang.public.wdzy, path: "mine", value: 0 },
    { label: lang.walletManage, path: "", value: 4 },
    { label: lang.dzgl, path: "", value: 5 },
    { label: lang.xgxx, path: "", value: 6 },
    { label: lang.help, path: "", value: 7 },
    { label: lang.yhxy, path: "link", value: 8 },
    { label: lang.yszc, path: "link", value: 9 }
]
export const sxddColumns = [
    { label: lang.public.ly, prop: "laiyuan", width: "50px", xs: 0.6, xm: 1 },
    { label: "NFT", prop: "series_name", width: "150px", xs: 1.5, xm: 2 },
    { label: lang.public.price, prop: "price", width: "50px", xs: 1, xm: 2 },
    { label: lang.num, prop: "quantity", width: "100px", xs: 1.5, xm: 2 },
    { label: lang.public.spfbsj, prop: "createtime", width: "50px", xs: 1, xm: 2 },
    { label: lang.ddgqsj, prop: "closedtime", width: "50px", xs: 1, xm: 2 }
]
export const invNewColumns = [
    { label: lang.invite.id, prop: "index" },
    { label: lang.email, prop: "email" },
    { label: lang.bandWallet, prop: "inviter_status" },
    { label: lang.time, prop: "createtime" }
]
export const helpCenter = [
    { label: lang.syzn, type: 1, url: "https://imh93dkr.eqxiu.cn/v/168884?cc=6iDZxC7G" },
    { label: lang.wdkf, type: 2, url: "https://manager.xiquess.com/customer?tenantID=1674605851306565634&customerID=&companyID=&openCustomerID=&openCompanyID=&channelType=1674693020301860866" },
    { label: lang.jrsq, type: 3, url: "https://imh93dkr.eqxiu.cn/v/168913?cc=ohuu8mD8" },
    { label: lang.gfapp, type: 4, url: "" },
    { label: lang.qbapp, type: 5, url: "https://file.x-meta.hk/app/TokenPocket-pro.apk" }
]
export const h5url = 'https://testh5.1chain.art/'
/**
 * 时间戳转换日期
 */
export const formatDate = (timestamp, type) => {
    if (timestamp) {
        let length = timestamp.toString().length
        var date = length > 12 ? new Date(timestamp) : new Date(timestamp * 1000)//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()) + ':'
        var s = (date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds())
        if (D < 10) D = `0${D}`
        if (type == 'time') {
            return Y + M + D + h + m + s
        }
        return Y + M + D
    } else {
        return ''
    }
}
//是否手机端
export function isApp() {
    let sUserAgent = navigator.userAgent.toLowerCase()
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad"
    let bIsMidp = sUserAgent.match(/midp/i) == "midp"
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4"
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os"
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb"
    let bIsAndroid = sUserAgent.match(/android/i) == "android"
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce"
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile"
    if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
        return false
    } else {
        return true
    }
}
export const isAndroid = () => {
    const u = navigator.userAgent
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        return true
    }
    return false
}

//计算过期时间
export function getExpireDate(n) {// n为多少天，-代表往前，+代表往后
    let expireTime = null
    let date1 = new Date()
    let date2 = new Date(date1)
    if (n !== 0) {
        date2.setDate(date1.getDate() + (n == 0 ? 0 : n))
    }
    if (n == 5) {
        return ''
    }
    let y = date2.getFullYear()
    let m = ("0" + (date2.getMonth() + 1)).slice(-2)
    let d = ("0" + date2.getDate()).slice(-2)
    let h = date2.getHours() + (n == 0 ? 1 : 0)
    let mm = ("0" + date2.getMinutes()).slice(-2)
    let s = date2.getSeconds() < 10 ? '0' + date2.getSeconds() : date2.getSeconds()
    expireTime = `${y}-${m}-${d} ${h}:${mm}:${s}`
    return expireTime
}

// 截取价格小数点后4位
export function omit(num, length = 4) {
    if (`${num}`.split('.').length > 1 && `${num}`.split('.')[1].length > 4) {
        return (+num).toFixed(length)
    } else {
        return num
    }
}

//交易动态-类型
// 0=提交资料,1=审核驳回,2=审核通过, 3=铸造, 4=上架, 5=下架, 6=到期, 7=出价, 8=成交, 9=成交失败, 10=转移
export function transType(type) {
    switch (type) {
        case 0:
            return $t('tjzl')
        case 1:
            return $t('shbh')
        case 2:
            return $t('shtg')
        case 3:
            return $t('jyStatus.5')
        case 4:
            return $t('jyStatus.4')
        case 5:
            return $t('jyStatus.5')
        case 6:
            return $t('jyStatus.6')
        case 7:
            return $t('jyStatus.7')
        case 8:
            return $t('jyStatus.8')
        case 9:
            return $t('jyStatus.9')
        case 10:
            return $t('jyStatus.10')
    }
}
// *号处理,前5后4
export function visibilityChange(val, len = [5, 4]) {
    if (val) {
        let _len = val.length
        return val.substring(0, len[0]) + '****' + val.substring(_len - len[1], _len)
    } else {
        return '--'
    }
}

//计算时间差，几分钟前、几小时前、几天前、
export function timeago(time) {
    var data = new Date(time)
    var dateTimeStamp = data.getTime()
    var minute = 1000 * 60      //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60
    var day = hour * 24
    var week = day * 7
    var month = day * 30
    var year = month * 12
    var now = new Date().getTime()   //获取当前时间毫秒
    var diffValue = now - dateTimeStamp//时间差
    var result = ""
    if (diffValue < 0) {
        result = "" + lang.wl
    }
    var minC = diffValue / minute  //计算时间差的分，时，天，周，月
    var hourC = diffValue / hour
    var dayC = diffValue / day
    var weekC = diffValue / week
    var monthC = diffValue / month
    var yearC = diffValue / year
    if (yearC >= 1) {
        result = " " + parseInt(yearC) + lang.nq
    } else if (monthC >= 1 && monthC < 12) {
        result = " " + parseInt(monthC) + lang.yq
    } else if (weekC >= 1 && weekC < 5 && dayC > 6 && monthC < 1) {
        result = " " + parseInt(dayC) + lang.tq
    } else if (dayC >= 1 && dayC < 7) {
        result = " " + parseInt(dayC) + lang.tq
    } else if (hourC >= 1 && hourC < 24) {
        result = " " + parseInt(hourC) + lang.xsq
    } else if (minC >= 1 && minC < 60) {
        result = " " + parseInt(minC) + lang.fzq
    } else if (diffValue >= 0 && diffValue < minute) {
        result = lang.gg
    }
    return result
}

//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：Add(arg1,arg2)
//返回值：arg1加上arg2的精确结果
export const tools = {
    Add(arg1, arg2) {
        arg1 = parseFloat(arg1)
        arg2 = parseFloat(arg2)
        var r1, r2, m
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(100, Math.max(r1, r2))
        return (this.Mul(arg1, m) + this.Mul(arg2, m)) / m
    },
    //减法函数，用来得到精确的减法结果
    //说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
    //调用：Sub(arg1,arg2)
    //返回值：arg1减去arg2的精确结果
    Sub(arg1, arg2) {
        arg1 = parseFloat(arg1)
        arg2 = parseFloat(arg2)
        var r1, r2, m, n
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        console.log(this.Mul(arg2, m))
        //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2
        return ((this.Mul(arg1, m) - this.Mul(arg2, m)) / m).toFixed(n)
    },

    //乘法函数，用来得到精确的乘法结果
    //说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
    //调用：Mul(arg1,arg2)
    //返回值：arg1乘以arg2的精确结果
    Mul(arg1, arg2) {
        arg1 = parseFloat(arg1)
        arg2 = parseFloat(arg2)
        var m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString()
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },

    //除法函数，用来得到精确的除法结果
    //说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
    //调用：Div(arg1,arg2)
    //返回值：arg1除以arg2的精确结果
    Div(arg1, arg2) {
        arg1 = parseFloat(arg1)
        arg2 = parseFloat(arg2)
        var t1 = 0,
            t2 = 0,
            r1, r2
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { }
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return this.Mul(r1 / r2, Math.pow(10, t2 - t1))
    }
}

export const createPoster = (canvas) => {
    if (canvas) {
        const url = canvas.toDataURL()
        const a = document.createElement('a')
        a.download = 'QRCode.png'
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
}
function onDownload(canvas) {
    const url = canvas.toDataURL()
    const a = document.createElement('a')
    a.download = 'QRCode.png'
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}