import componentList from 'components'
import RouterGuard from './routerGuard.jsx'
import { Navigate } from 'react-router-dom'

const { Layout, Notice, Home, Project, Webview, Market, NoticeDetail, Luck, Prize, Login, Medal, Collection, Publish, PublishInfo, Mine, Search, CollectionHome, Ranking, InviteNew, Link } =
  componentList

const createRoutes = (l) =>
  l.map((v) =>
    Object.assign(v, {
      element: v.redirect ? (
        <Navigate to={v.redirect} />
      ) : (
        <RouterGuard {...v} />
      ),
    }),
  )

const actionComponentMap = createRoutes([
  {
    path: '/home',
    element: Home,
  },
  {
    path: '/market',
    element: Market,
  },
  {
    path: '/collection',
    element: Collection,
  },
  {
    path: '/publish',
    element: Publish,
  },
  {
    path: '/publish-info',
    element: PublishInfo,
  },
  {
    path: '/mine',
    element: Mine,
  },
  {
    path: '/link',
    element: Link,
  },
  {
    path: '/inviteNew',
    element: InviteNew,
  },
  {
    path: '/search',
    element: Search,
  },
  {
    path: '/collection-home',
    element: CollectionHome,
  },
  {
    path: '/ranking',
    element: Ranking,
  },
  {
    path: '/medal',
    element: Medal,
  },
  {
    path: '/medal/luck',
    element: Luck
  },
  {
    path: '/medal/prize',
    element: Prize
  },
  {
    path: '/notice',
    element: Notice
  },
  {
    path: '/notice/detail',
    element: NoticeDetail
  },
  {
    path: '/project',
    element: Project
  }
])

export const componentMap = createRoutes([
  {
    path: '/',
    element: Layout,
    exact: true,
    children: actionComponentMap,
  },
  {
    path: '/login',
    element: Login,
    exact: true,
  },
])
