import React, { useImperativeHandle, forwardRef, memo, useContext } from 'react'
import { Grid, Button, Box, useTheme, Hidden } from "@mui/material"
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import style from '@/components/Mine/mine.module.css'
import drawerCloseIcon from '@/assets/icon/mineIcon/drawerClose.png'
import radioIcon from '@/assets/icon/mineIcon/radio.png'
import doubt from '@/assets/icon/mineIcon/doubt.png'
import {
    expireTimeOption, omit, sjzColumns, jydtColumns, sxddColumns, transType, visibilityChange,
    timeago, formatDate, keyWord
} from '@/components/Mine/data'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import { useNavigate } from 'react-router-dom'
import empty from '@/assets/icon/mineIcon/empty.png'
import { LoadingButton } from "@mui/lab"
import { Copy } from "@/components/Mine/index"
import upIcon from '@/assets/icon/mineIcon/up.png'
import downIcon from '@/assets/icon/mineIcon/down.png'
import { offShelf } from "@/components/Mine/ground"
import { useState } from "react"
import { globalApiSlice } from '@/features/global/globalSlice'
import BigAccept from '@/components/Big/accept'
import { useRef } from 'react'

const payNum = 1

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

export function Empty() {
    return (
        <div className={style.emptyBox}>
            <img src={empty} className={style.emptyIcon} />
            <div className={style.emptyText}>{$t('nodata')}</div>
        </div>
    )
}

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

//从底部弹窗
const ShelvesDrawer = memo(function Srawer(props) {
    const openShelvesDialog = () => {
        props.openShelvesDialog()
    }
    const onRemove = (item, e) => {
        props.onRemove(item)
    }
    const onTransfer = () => {
        props.onTransfer()
    }
    return (
        <Grid style={{ position: "fixed", bottom: 0 }}
            sx={{ width: { xs: '91%', sm: '83.3%' } }}>
            {props.list.length > 0 && <div className={style.shelvesDrawer}>
                <div className={style.changeListItem}>
                    {props.list.map((item, index) => {
                        return <Grid className={style.drawerItem} key={item.id}
                            sx={{ width: { xs: 52, sm: 100 }, height: { xs: 52, sm: 100 }, borderRadius: { xs: 2, sm: 4 } }}>
                            <img src={item.sourcefile_url} className={style.drawerItemName} />
                            <Grid className={style.drawerCloseIcon} sx={{ width: { xs: 14, sm: 28 }, height: { xs: 14, sm: 28 } }}>
                                <img src={drawerCloseIcon} onClick={onRemove.bind(event, item)} style={{ width: '100%', height: '100%' }} />
                            </Grid>
                        </Grid>
                    })}
                </div>
                <div className={`${style.shelversBtn} ${style.handleBtn}`} onClick={openShelvesDialog}>{$t('public.sj')}</div>
                {/*<div className={`${style.transferBtn}`} onClick={onTransfer}>*/}
                {/*    <div>转移</div>*/}
                {/*</div>*/}
            </div>}
        </Grid>
    )
})
//列表卡片
function ListItem(props) {
    const theme = useTheme()
    const navigate = useNavigate()

    const onChangeGoods = () => {
        props.onChangeGoods()
    }
    const isChecked = () => {
        let arr = props.changeList.filter(i => { return i.id == props.item.id })
        return arr.length > 0
    }
    //查看详情
    const onCheckDetail = (e) => {
        e.stopPropagation() //阻止原生事件冒泡
        e.nativeEvent.stopImmediatePropagation() //阻止最外层document上的事件冒泡
        navigate('/collection?id=' + props.item.product_id)
    }
    const keyword = keyWord(props.tab)
    return (
        <Box
            className="global-border-radius global-overflow-hidden"
            sx={{
                width: 220,
                height: 220,

                ...theme.palette.boxShadow,
                ...theme.palette.warp,
                marginTop: 3,

                pb: 2,
                cursor: 'pointer',
                // mr: 3,
                '@media (max-width: 600px)': {
                    width: '48.5%',
                    height: '180px',
                    mt: 1
                },
            }}
        >
            <Box sx={{
                width: 1, height: 220, position: 'relative', '@media (max-width: 600px)': {

                    height: '180px'
                },
            }}>
                <img
                    className="global-img"
                    style={{ background: '#24D5BA', height: '100%' }}
                    src={`${props.item[keyword?.img]}`}
                />
                <Box
                    sx={{
                        ...{
                            padding: '12px',
                            '@media (max-width: 600px)': {
                                padding: '5px'
                            },
                            fontSize: 16,
                            color: '#fff',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            height: '45%',
                            width: 1,
                            bgcolor: 'rgba(0,0,0,.2)',
                        },
                    }}
                >
                    <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis', }} className='media-title16'>
                        {props.item[keyword?.name]?.replace(`#${props?.item?.token_id}`, '') === props?.item[keyword?.seriesName] ? '#' + props?.item?.token_id :
                            props.item[keyword?.name]
                        }
                    </p>
                    <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis', }} className='media-title14'>{props.item[keyword?.seriesName]}</p>
                    {props.tab == 0 && <p
                        style={{
                            fontSize: 16,
                            position: 'absolute',
                            right: '12px',
                            bottom: '8px',
                            margin: 0,
                            '@media (max-width: 600px)': {
                                left: 0,
                                bottom: '5px'
                            },
                        }}
                        className='media-title12'
                    >
                        {$t('price')}：{!!Number(props.item[keyword?.price]) ? props.item[keyword?.price] : '--'}{props.item[keyword?.coin]}
                    </p>}
                    {props.tab == 4 && <p style={{
                        fontSize: 16,
                        position: 'absolute',
                        right: '12px',
                        bottom: '8px',
                        margin: 0,
                        '@media (max-width: 600px)': {
                            left: 0,
                            bottom: '5px'
                        },
                    }}
                        className='media-title12'>
                        {props.item.open_status == 0 && <span>{$t('sy')}：{props.item.open_at}{$t('day')}</span>}
                    </p>}
                </Box>
                <Box className={style.goodsCard}>
                    {props.tab == 4 && <div className={style.dkqBtn}>{props.item.open_status == 0 ? $t('dkq') : $t('ykq')}</div>}
                    <Grid className={`${style.changeOverlay} ${style.cardSize}
            ${isChecked() ? style.showOverlay : style.hiddenOverlay}`} onClick={onChangeGoods}>
                        <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                            {props.tab == 0 && <Grid className={style.changeRadio}>
                                {isChecked() && <img src={radioIcon} className={style.radioIcon} />}
                            </Grid>}
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid className={style.changeHandleBtn} onClick={onCheckDetail}
                                sx={{ fontSize: { xs: 12, sm: 14 }, borderRadius: { xs: 10, sm: 20 } }}>{$t('publi.ckxq')}</Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

function HarvestChujia(v) {
    const theme = useTheme()
    const bigAccept = useRef(null)
    const handle = async () => {
        _alert({
            message: $t('qqrcz'),
            cacleShow: true
        }).then(async () => {
            await globalApiSlice.offerRejectApi({
                offer_id: v.id,
                product_id: v.product_id
            })
            _toast($('offer.czwc'))
            v.props.click(v.props.tab)
        })
    }
    const accept = async () => {
        const body = {
            offer_id: v.id,
            product_id: v.product_id,
            click: () => v.props.click(v.props.tab)
        }
        bigAccept.current.open(body)
    }
    return (
        <Grid container item xs={12} sx={{ background: theme.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8', p: 2, borderRadius: 2, mb: 1 }}>
            <BigAccept onRef={bigAccept} />
            <Grid item className='global-position-relative' xs={12} sx={{ ...theme.typobox.flexStartLeft, ...theme.palette.mainText }}>
                <Box sx={{
                    width: { xs: 60 },
                    height: { xs: 60 },
                    background: '#F3F4F6',
                    borderRadius: 2,
                    backgroundImage: `url(${v?.product?.sourcefile_url})`,
                    backgroundPosition: 'center',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat'
                }}></Box>
                <Box sx={{ ml: 1, width: 'calc(100% - 120px)' }}>
                    <Box sx={{ fontSize: { xs: 20 }, mb: 1 }}>{v.type == 2 ? v?.series?.name : v?.product?.name} #{v.product?.token_id}</Box>
                    <Box sx={{ ...theme.typobox.flexCenterLeft }}>{$t('offer.cjdx')}：<Box sx={{ ...theme.palette.text }}>{v.type == 2 ? $t('offer.hjcj') : `${v.product?.name}#${v.product?.token_id}`}</Box></Box>
                </Box>
                {
                    v?.product?.trade_status !== null &&
                    <Box className="global-position-absolute" sx={{ ml: 1, top: 0, right: 0 }}>
                        <Box sx={{ ...theme.typobox.flexCenterLeft }}>{$t('offer.status')[v?.product?.trade_status + 2]}</Box>
                    </Box>
                }
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('public.from')}</Box>
                <Box>{v?.from?.substring(0, 6) + '***' + v?.from?.substring(v?.from.length - 4)}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('public.to')}</Box>
                <Box>{v?.to?.substring(0, 6) + '***' + v?.to?.substring(v?.to.length - 4)}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('num')}</Box>
                <Box>{v?.amount}
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('offer.cjgqsj')}</Box>
                <Box>{m.unix(v.expire_time).format('YYYY/MM/DD HH:mm:ss')}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('offer.dj')}</Box>
                <Box>{v.price} {v?.symbol?.symbol}</Box>
            </Grid>
            {
                v?.product?.trade_status == null &&
                <Grid item xs={12} sx={{ ...theme.typobox.flexCenterCenter, mt: 1 }}>
                    <Box onClick={accept} sx={{ p: 1, pl: 3, pr: 3, borderRadius: 20, ...theme.palette.btn.login, mr: 1, fontSize: { xs: 16, sm: 20 } }}>{$t('offer.jscj')}</Box>
                    <Box onClick={handle} sx={{ p: 1, pl: 3, pr: 3, borderRadius: 20, border: `1px solid ${theme.palette.mainText.color}` }}>{$t('offer.jjcj')}</Box>
                </Grid>
            }
        </Grid>
    )
}

function Chujia(v) {
    const theme = useTheme()
    const handle = async () => {
        _alert({
            message: $t('qqrcz'),
            cacleShow: true
        }).then(async () => {
            await globalApiSlice.offerCancelQuotationApi({
                offer_id: v.id
            })
            v.props.click(v.props.tab)
        })
    }
    return (
        <Grid container item xs={12} sx={{ background: theme.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8', p: 2, borderRadius: 2, mb: 1 }}>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterLeft, ...theme.palette.mainText }}>
                <Box sx={{
                    width: { xs: 60 },
                    height: { xs: 60 },
                    background: '#F3F4F6',
                    borderRadius: 2,
                    backgroundImage: `url(${v?.series?.icon_url})`,
                    backgroundPosition: 'center',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat'
                }}></Box>
                <Box sx={{ ml: 1 }}>
                    <Box sx={{ fontSize: { xs: 20 }, mb: 1 }}>{v.type == 2 ? v?.series?.name : v?.product?.name}</Box>
                    <Box sx={{ ...theme.typobox.flexCenterLeft }}>{$t('offer.cjdx')}：<Box sx={{ ...theme.palette.text }}>{v.type == 2 ? $t('offer.hjcj') : `${v.product?.name}#${v.product?.token_id}`}</Box></Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('num')}</Box>
                <Box>{v?.amount}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('offer.cjgqsj')}</Box>
                <Box>{m.unix(v.expire_time).format('YYYY/MM/DD HH:mm:ss')}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterSpaceBetween, mt: 1 }}>
                <Box>{$t('offer.dj')}</Box>
                <Box>{v.price} {v?.symbol?.symbol}</Box>
            </Grid>
            <Grid item xs={12} sx={{ ...theme.typobox.flexCenterCenter, mt: 1 }}>
                <Box onClick={handle} sx={{ p: 1, pl: 3, pr: 3, borderRadius: 20, border: `1px solid ${theme.palette.mainText.color}` }}>{$t('offer.qxcj')}</Box>
            </Grid>
        </Grid>
    )
}


function UnitPrice(props) {
    let keyword = props.tab == 1 ? { gas: 'gas', bs: 'copyright_rate_fee', sxf: 'platform_rate_fee', coin: 'pay_coin' }
        : props.tab == 2 ? { gas: 'gas', bs: 'copyright_rate_fee', sxf: 'platform_rate_fee', coin: 'pay_coin' }
            : { gas: 'gas', bs: 'royalty', sxf: 'fee', coin: 'currency' }
    let item = props.item
    return (
        <div style={{ fontSize: '1.5vh', fontWeight: 600, color: '#888888' }}
            sx={{ width: { xs: '100%', sm: '50vw' }, position: { xs: '', sm: 'absolute' }, top: { xs: '', sm: '4vh' } }}>
            gas：{item[keyword?.gas] > 0 ? item[keyword?.gas] : 0}{item[keyword?.coin]}
            {$t('public.bs')}：{item[keyword?.bs] > 0 ? item[keyword?.bs] : 0}{item[keyword?.coin]}{$t('public.sxf')}：{item[keyword?.sxf] > 0 ? item[keyword?.sxf] : 0}{item[keyword?.coin]}</div>
    )
}

//表格卡片
function TableItem(props) {
    const theme = useTheme()
    const [showPrice, setShowPrice] = React.useState(false)
    const [priceItem, setPriceItem] = React.useState({})
    let columns = props.tab == 1 ? sjzColumns : props.tab == 2 ? sxddColumns : props.tab == 3 ? jydtColumns : {}
    const onItemClick = (col, v, e) => {
        if (col.prop == 'price' || col.prop == 'deal_price') {
            setPriceItem(v)
            setShowPrice(!showPrice)
        }
    }
    const onOffShelf = async (id, chain_id) => {
        showLoad($t('xjz'))
        let res = await offShelf(id, chain_id)
        if (res == -1) {
            closeLoad()
            return
        }
        if (res) {
            props.onOffShelf()
        }
    }
    return (
        <Box style={{ width: '100%', overflowX: 'auto' }}>
            <Hidden smDown>
                <Grid container item xs={12} sx={{ width: { xs: 1000, sm: '100%' }, maxWidth: 'initial' }}>
                    {columns.map((v, i) =>
                        <Grid item sx={{
                            ...{
                                fontSize: '1.5vh',
                                fontWeight: 600, color: '#888888', margin: '20px 0'
                            }, ...theme.palette.text, ...(i == columns.length - 1 ? theme.typobox.flexCenterRight : theme.typobox.flexCenterLeft)
                        }} xs={v.xs} key={i} sm={v.xm}>{v.label}</Grid>
                    )}
                </Grid>
                <Grid container item xs={12} sx={{ width: { xs: 1000, sm: '100%' }, maxWidth: 'initial' }}>
                    {props.list.map((v) =>
                        <Grid item sx={{ width: { xs: 1000, sm: '100%' }, maxWidth: 'initial', ...theme.typobox.flexCenterLeft, ...{ fontSize: 14, margin: '20px 0' }, ...theme.palette.text }} xs={12} key={v.id}>
                            {columns.map((col, idx) =>
                                <Grid className={col.prop == 'price' ? style.blockBox : ''} item key={col.prop}
                                    sx={{
                                        ...(idx == columns.length - 1 ? theme.typobox.flexCenterRight : theme.typobox.flexCenterLeft), ...{
                                            fontSize: { xs: 12, sm: 16 },
                                            margin: '5px 0'
                                        }, ...theme.palette.mainText
                                    }} xs={col.xs} sm={col.xm}>
                                    {/*上架中*/}
                                    {props.tab == 1 && <div className={style.mineTableCellItem}>
                                        {col.prop == 'laiyuan' && <div className={style.disabledRadio}></div>}
                                        {col.prop != 'series_name' && <div onClick={onItemClick.bind(event, col, v)}>{col.prop == 'price' ? Math.floor(v[col.prop] * 100) / 100 : col.prop == 'createtime' || col.prop == 'closedtime' ? formatDate(v[col.prop], 'time') : v[col.prop]}</div>}
                                        {col.prop == 'series_name' && <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={v.sourcefile_url} style={{ width: '5vh', height: '5vh', borderRadius: '10px', marginRight: '2vh' }} />
                                            <div>
                                                <div>{v.series_name}</div>
                                                <div>#{v.token_id}</div>
                                            </div>
                                        </div>}
                                        {col.prop == 'price' && <div style={{ display: "flex", alignItems: "center" }} onClick={onItemClick.bind(event, col, v)}>
                                            <div>{v.pay_coin}</div>
                                            <img src={showPrice && priceItem.id == v.id ? upIcon : downIcon}
                                                style={{ width: '1.5vh', height: '1.5vh', marginLeft: '1vh' }} />
                                        </div>}
                                        {(col.prop == 'price' && showPrice && priceItem.id == v.id)
                                            && <UnitPrice item={priceItem} show={showPrice} tab={props.tab}></UnitPrice>}
                                        {col.prop == 'handle' && <div className={style.offShelfBtn} onClick={onOffShelf.bind(event, v.id, v.chain_id)}>{$t('public.xj')}</div>}
                                    </div>}
                                    {/*失效订单*/}
                                    {props.tab == 2 && <div className={style.mineTableCellItem}>
                                        {col.prop == 'laiyuan' && <div className={style.disabledRadio}></div>}
                                        {col.prop != 'series_name' && <div>{col.prop == 'price' ? Math.floor(v[col.prop] * 100) / 100 : col.prop == 'createtime' || col.prop == 'closedtime' ? formatDate(v[col.prop], 'time') : v[col.prop]}</div>}
                                        {col.prop == 'series_name' && <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={v.sourcefile_url} style={{ width: '5vh', height: '5vh', borderRadius: '10px', marginRight: '2vh' }} />
                                            <div>
                                                <div>{v.series_name}</div>
                                                <div>#{v.token_id}</div>
                                            </div>
                                        </div>}
                                        {col.prop == 'price' && <div style={{ display: "flex", alignItems: "center" }} onClick={onItemClick.bind(event, col, v)}>
                                            <div>{v.pay_coin}</div>
                                            <img src={showPrice && priceItem.id == v.id ? upIcon : downIcon}
                                                style={{ width: '1.5vh', height: '1.5vh', marginLeft: '1vh' }} />
                                        </div>}
                                        {(col.prop == 'price' && showPrice && priceItem.id == v.id)
                                            && <UnitPrice item={priceItem} show={showPrice} tab={props.tab}></UnitPrice>}
                                    </div>}
                                    {/*交易动态*/}
                                    {props.tab == 3 && <div className={style.mineTableCellItem} style={{ position: "relative" }}>
                                        {col.prop == 'laiyuan' && <div className={style.disabledRadio}></div>}
                                        {col.prop == 'series_name' && <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={v.sourcefile_url} style={{ width: '5vh', height: '5vh', borderRadius: '10px', marginRight: '2vh' }} />
                                            <div>
                                                <div>{v.series_name}</div>
                                                <div>#{v.token_id}</div>
                                            </div>
                                        </div>}
                                        {col.prop != 'series_name' &&
                                            <div>
                                                {col.prop == 'deal_price' ? Math.floor(v[col.prop] * 100) / 100
                                                    : col.prop == 'createtime' ? timeago(v[col.prop] * 1000)
                                                        : col.prop == 'type' ? transType(v.type)
                                                            : col.prop == 'from' || col.prop == 'to' ? visibilityChange(v[col.prop]) : v[col.prop]}</div>
                                        }
                                        {col.prop == 'quantity' && <div>{$t('j')}</div>}
                                        {col.prop == 'deal_price' && <div onClick={onItemClick.bind(event, col, v)}
                                            style={{ display: "flex", alignItems: "center" }}>
                                            <div>{v.currency}</div>
                                            <img src={showPrice && priceItem.id == v.id ? upIcon : downIcon}
                                                style={{ width: '1.5vh', height: '1.5vh', marginLeft: '1vh' }} />
                                        </div>}
                                        {((col.prop == 'from' || col.prop == 'to') && v[col.prop]) && <Copy walletCode={v[col.prop]}></Copy>}
                                        {(col.prop == 'deal_price' && showPrice && priceItem.id == v.id) && <UnitPrice item={priceItem} show={showPrice} tab={props.tab}></UnitPrice>}
                                    </div>}
                                </Grid>)}
                        </Grid>
                    )}
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Grid container item xs={12} sx={{ width: { xs: '100%', sm: '100%' }, maxWidth: 'initial' }}>
                    {props.list.map((v, i) => <Grid key={i} style={{ width: '100%', marginTop: '15px', backgroundColor: theme.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8', borderRadius: '6px', padding: '15px' }}>
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                            <img src={v.sourcefile_url} style={{ width: 52, height: 52, marginRight: '10px' }} />
                            <Grid>
                                <Grid>#{v.token_id}</Grid>
                                <Grid>{v.series_name}</Grid>
                            </Grid>
                        </Grid>
                        {columns.slice(2, columns.length).map((col, idx) =>
                            <Grid key={col.prop + idx} style={{ margin: '5px 0', display: 'flex', justifyContent: 'space-between' }}>
                                <span>{col.label}</span>
                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {col.prop == 'deal_price' ? Math.floor(v[col.prop] * 100) / 100
                                        : col.prop == 'createtime' && props.tab == 3 ? timeago(v[col.prop] * 1000)
                                            : col.prop == 'createtime' && props.tab != 3 ? formatDate(v[col.prop], 'time')
                                                : col.prop == 'closedtime' ? formatDate(v[col.prop], 'time')
                                                    : col.prop == 'type' ? transType(v.type)
                                                        : (col.prop == 'from' || col.prop == 'to') ? visibilityChange(v[col.prop])
                                                            : v[col.prop]}{idx == 0 ? v.pay_coin : ''}
                                    {((col.prop == 'from' || col.prop == 'to') && v[col.prop]) && <Copy walletCode={v[col.prop]}></Copy>}
                                </span>
                            </Grid>)}
                        <Grid>
                            <UnitPrice item={v} tab={props.tab}></UnitPrice>
                        </Grid>
                        {props.tab == 1 && <Box sx={{ ...theme.typobox.flexCenterRight }} style={{ width: '100%' }}>
                            <Box className="global-button" onClick={onOffShelf.bind(event, v.id, v.chain_id)} style={{ width: '25%', borderRadius: '10px', fontSize: 14 }} sx={{ ...theme.palette.btn.login, height: { xs: 30, sm: 30 }, color: '#070809', fontWeight: 600 }}>{$t('public.xj')}</Box>
                        </Box>}
                    </Grid>)}
                </Grid>
            </Hidden>
        </Box>
    )
}
const computedRate = (item, price) => {
    let ptfwf = item.platform_rate * price
    let bs = item.copyright_rate * price
    return {
        ptfwf, bs, platform_rate: item.platform_rate, copyright_rate: item.copyright_rate
    }
}

//列表
const List = forwardRef(({ ...data }, ref) => {
    const theme = useTheme()
    const [changeList, setChangeList] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [isExpand, setIsExpand] = React.useState(false)
    const [time, setTime] = React.useState('')
    const [price, setPrice] = React.useState('')
    const [rate, setRate] = React.useState({ ptfwf: '--', bs: '--' })
    const [curSeriesId, setCurSeriesId] = useState('')
    const selectChange = (event) => {
        setTime(event.target.value)
        data.onTime(event.target.value)
    }
    const clearChangeList = () => {
        setChangeList([])
    }
    useImperativeHandle(ref, () => ({
        clearChangeList
    }))
    const openShelvesDialog = () => {
        data.hanleGround(changeList)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const onGrounding = () => {
        if (!time) {
            _toast($t('qxzsj'))
            return
        }
        if (!price) {
            _toast($t('qsrjg'))
            return
        }
        let exp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
        if (!exp.test(price)) {
            _toast($t('qsrzzshxs'))
            return
        }
        data.hanleGround(changeList)
    }
    const onExpand = () => {
        setIsExpand(!isExpand)
    }
    const onRemove = (item) => {
        setChangeList(current => current.filter(v => v.id !== item.id))
    }
    const onChangePrice = (e) => {
        setPrice(e.target.value)
        setRate(computedRate(changeList[0], e.target.value))
        data.onPrice(e.target.value)
    }
    const onChangeGoods = (item, e) => {
        if (data.tab == 0 && item.status == 0 && (!changeList[0] || changeList[0].series_id == item.series_id)) {
            let index = changeList.findIndex(val => item.id === val.id)
            if (index >= 0) {
                //    删除
                setChangeList(current => current.filter(v => v.id !== item.id))
            } else {
                //    新增
                setChangeList(current => [...current, item])
            }
            if (changeList.length == 1) {
                setCurSeriesId(changeList[0].series_id)
            }
        } else {
            _toast($t('znsjtyhjxdcp'))
        }
    }
    const onTransfer = () => {
        data.onTransfer(changeList)
    }
    const onscroll = (e) => {
        let scrollTop = e.nativeEvent.srcElement.scrollTop
        if (scrollTop > 200) {
        }
    }
    const onOffShelf = () => {
        data.onOffShelf()
    }
    const comItem = data.list && data.list.length ? data.list[0] : {}
    console.log('data.tab==', data.tab)
    return (
        <div style={{ width: '100%' }}>
            {data?.list?.length > 0 && <Grid xs={12} item sx={{ ...{ margin: '10px 0' }, ...theme.typobox.flexWarpWrap, ...theme.typobox.flexCenterSpaceBetween }} >
                <Grid className={style.mineListCont} style={{ overflowY: 'hidden' }} onScroll={onscroll}
                    sx={{
                        ...{ margin: '0 0 15px' },
                        ...theme.typobox.flexWarpWrap,
                        justifyContent: 'space-between',
                    }}>
                    {(data.tab == 0 || data.tab == 4) && data.list.map((pro, index) => <ListItem item={pro} key={index} changeList={changeList}
                        onChangeGoods={onChangeGoods.bind(event, pro)} tab={data.tab} />)}
                    {(data.tab == 3 || data.tab == 2 || data.tab == 1) && <TableItem onOffShelf={onOffShelf} list={data.list} tab={data.tab}></TableItem>}
                    {(data.tab == 5) && data.list.map((v, i) => <Chujia {...v} props={data} key={i} />)}
                    {(data.tab == 6) && data.list.map((v, i) => <HarvestChujia {...v} props={data} key={i} />)}
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v) => (
                        <Box key={v + 1000} sx={{ width: { xs: 220 } }} />
                    ))}
                </Grid>
            </Grid>}
            {data?.list?.length == 0 && <Empty></Empty>}
            {/*上架底部抽屉*/}
            <ShelvesDrawer list={changeList} openShelvesDialog={openShelvesDialog}
                onRemove={onRemove} onTransfer={onTransfer}></ShelvesDrawer>
            {/*    上架-价格弹窗*/}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div style={{ fontSize: '20px', fontWeight: 600, color: '#333333', textAlign: 'center' }}>{$t('xgjg')}</div>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className={style.mineGoodsInfo} style={{ display: "flex", alignItems: "center" }}>
                        <img src="https://i.seadn.io/gcs/files/c65e7b88d5551ce349239841aa3aeec2.png?auto=format&w=512"
                            width={78} height={78} />
                        <div style={{ marginLeft: '15px' }}>
                            <div className={style.mineGoodsName}>{$t('cpmc')}</div>
                            <div className={style.mineGoodsLabel}>{$t('ssxmmc')}</div>
                            <div className={style.flex} style={{ marginTop: '15px' }}>
                                <div style={{ color: '#888888', fontSize: '14px', marginRight: '5vw' }}>{$t('public.ddgqsj')}</div>
                                <div style={{ color: '#888888', fontSize: '14px' }}>{data.expireTime}</div>
                            </div>
                        </div>
                    </div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={style.mineDialogSelect}
                        value={time}
                        label={$t('collection.endtime')}
                        onChange={selectChange}
                    >
                        {expireTimeOption.map(item => {
                            return <MenuItem value={item} key={item.value}>{item.name}</MenuItem>
                        })}
                    </Select>
                    <OutlinedInput
                        id="outlined-adornment-weight" className={style.mineDialogInput}
                        endAdornment={<InputAdornment position="end">CFX</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{ 'aria-label': 'weight', }}
                        onChange={onChangePrice}
                    />
                    <div className={style.mineGrayText} style={{ marginTop: '15px' }}>
                        {$t('home.dbj')}：{comItem && comItem.floor_price ? comItem.floor_price : 0} {comItem && comItem.pay_coin ? comItem.pay_coin : 0}</div>
                    <div className={style.flex} style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={style.mineGrayText} style={{ display: 'flex', alignItems: 'center', marginRight: '0.3vw' }}>
                                <div style={{ marginRight: '0.5vh' }}>{$t('public.ptfwf')}</div>
                                <img src={doubt} style={{ width: '1.6vh', height: '1.6vh' }} />
                            </div>
                            <div className={style.mineGrayText}>{omit(rate.ptfwf)}
                                {/*<span style={{margin:'0 8px'}}>{comItem.pay_coin}</span>*/}
                                {rate.platform_rate ? `${omit(rate.platform_rate)}%` : '--'}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={style.mineGrayText} style={{ display: 'flex', alignItems: 'center', marginRight: '0.3vw' }}>
                                <div style={{ marginRight: '0.5vh' }}>{$t('public.bs')}</div>
                                <img src={doubt} style={{ width: '1.6vh', height: '1.6vh' }} />
                            </div>
                            <div className={style.mineGrayText}>{omit(rate.bs)}
                                {/*<span style={{margin:'0 8px'}}>{comItem.pay_coin}</span>*/}
                                {rate.copyright_rate ? `${omit(rate.copyright_rate)}%` : '--'}</div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ display: 'block' }}>
                    {isExpand && <div>
                        <div className={style.mineBlackText}>{$t('public.zl')}</div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '0.3vh 0' }}>
                            <div className={style.mineGrayText}>{$t('public.ptfwf')}：</div>
                            <div className={style.mineBlackText}>
                                {price ? +(omit(comItem.platform_rate * price * payNum)) : '--'}
                                {/*{comItem.pay_coin}*/}
                            </div>
                            <div className={style.mineGrayText} style={{ marginLeft: '10%' }}>{$t('public.bs')}：</div>
                            <div className={style.mineBlackText}>
                                {price ? +(omit(comItem.copyright_rate * price * payNum)) : '--'}
                                {/*{comItem.pay_coin}*/}
                            </div>
                        </div>
                    </div>}
                    <div className={style.flex} style={{ marginLeft: 0 }}>
                        <div className={style.mineBlackText}>{$t('public.ygsy')}</div>
                        <div className={style.mineBlackText} style={{ display: 'flex', alignItems: 'center' }}
                            onClick={onExpand}>
                            <div style={{ marginRight: '10px' }}>
                                {price ? +(omit((1 - comItem.copyright_rate - comItem.platform_rate) * price * payNum)) : '--'}
                                {/*{comItem.pay_coin}*/}
                            </div>
                            <div className={isExpand ? style.dialogUpTriangle : style.dialogDownTriangle}></div>
                        </div>
                    </div>
                    {/*<div className={style.mineGrayText} style={{textAlign:'right'}}>≈￥102</div>*/}
                    {!data.loading && <Button variant="outlined" className={`${style.groundSubmitBtn} ${style.handleBtn}`} onClick={onGrounding}>{$t('public.sj')}</Button>}
                    {data.loading && <LoadingButton loading loadingIndicator={$t('sjz')} variant="outlined" className={style.loadBtn}>
                    </LoadingButton>}
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
})

export default List