import {Box, Grid, Hidden, InputAdornment, useTheme} from "@mui/material";
import defaultAvatar from '@/assets/icon/mineIcon/defaultAvatar.png'
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import {Copy} from "@/components/Mine/index"
import infoIcon from "@/assets/icon/mineIcon/gantan.png"
import {invNewColumns,h5url,visibilityChange,createPoster} from "@/components/Mine/data"
import { QRCode } from 'antd';
import inviteZhHants from "@/assets/icon/mineIcon/invitebg-zh-Hans.png"
import {useEffect,useState} from "react";
import {globalApiSlice} from "@/features/global/globalSlice";
import logo from "@/assets/icon/mineIcon/logodownload.png"
import { Empty } from 'antd';

function Poster(props){
    const theme = useTheme()
    return(
        <Box style={{position:"fixed",top:'0',width:300,height:300,display:"none"}} id={'posterHtml'}>
            <Box sx={{width: '100%', height: '100%', backgroundImage: `url(${inviteZhHants})`,
                ...theme.components.backgroundIcon, backgroundSize: { xs: '100%', sm: '100% 100%' },
                backgroundColor:'#00F6D0'
            }} style={{display:"flex",alignItems:"end",justifyContent:"center"}}>
                <QRCode size={100} bordered={false} iconSize={20} errorLevel="H" value={props.link} icon={logo} bgColor={'white'}/>
            </Box>
        </Box>
    )
}
function InviteNew (){
    const theme = useTheme()
    const [invite, setInvite] = useState({})
    const [link,setLink] = useState('')
    const getinviteList = () =>{
        globalApiSlice.getinviteListApi({}).then(res=>{
            // let data = [
            //     {email:"zhangsanaaaaa@xmetash.com",inviter_status:'1',createtime:"1695004070"},
            //     {email:"zhangsanaaaaaaaaaaa@xmetash.com",inviter_status:'0',createtime:"1695004070"},
            //     {email:"zhangsanaaaaaaaaa@xmetash.com",inviter_status:'1',createtime:"1695004070"}
            // ]
            res.list.data = res?.list?.data || []
            setInvite(res)
            setLink(`${h5url}#/pages/public/register?invite_id=${res.inviter_code}`)
        })
    }
    const onSave = () =>{
        const canvas = document.getElementById('qrCodeBox')?.querySelector('canvas');
        createPoster(canvas)
    }
    useEffect(() => {
        getinviteList()
    }, [])
    return(
        <Box className="global-position-relative" style={{margin:'30px auto'}} sx={{width: { xs: '100%', sm:'70%' }, ...theme.components.layoutMainBox, ...theme.palette.warp, overflowX: 'hidden' }}>
            <Grid container item xs={12} sm={10} style={{maxWidth:'100%'}}>
                <Grid item xs={12} md={6} sx={{height: { xs: 400, sm: 'auto' },maxWidth:{sm:'48%'}}}>
                    <Box sx={{width: { xs: '100%', sm: '100%' }, height: { xs: '100%', sm: '100%' },
                        ...theme.components.backgroundIcon,borderRadius:'10px',overflow:'hidden'}}>
                        <Box sx={{width: '100%', height: '100%', backgroundImage: `url(${inviteZhHants})`,
                            ...theme.components.backgroundIcon, backgroundSize: { xs: '100%', sm: '100% 100%' },
                            backgroundColor:'#00F6D0'
                        }}></Box>
                    </Box>
                </Grid>
                <Grid item xs={11} sx={{ maxWidth:{sm:'48%'},margin: '0 auto', borderRadius:'10px',overflow:'hidden',p: '2px 4px'}} md={6}
                style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                    <Grid sx={{backgroundColor:'#F8F8F8',padding:'15px 20px 30px 20px'}} style={{borderRadius:10}}>
                        <Box sx={{fontSize:{ xs: 16, sm: 22, md: 24 }}}>{$t('invite.wdyqm')}：{invite.inviter_code}</Box>
                        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',
                            width: '100%',border: '1px solid #888888',borderRadius: '10px',margin:'20px 0' }}>
                            <Grid container sx={{ ml: 1, flex: 1,height: { xs: 40, sm: 50 },alignItems:'center',
                                justifyContent: { xs: 'space-between', sm: 'space-between' },color:'#888888',
                                fontSize:{ xs: 14, sm: 16, md: 18 }}}>
                                <Grid>{visibilityChange(link,[26,4])}</Grid>
                                <Copy walletCode={link}></Copy>
                            </Grid>
                        </Paper>
                        <Grid sx={{...theme.typobox.flexCenterCenter}} id='qrCodeBox'>
                            <QRCode size={280} errorLevel="H" value={link} icon={logo}/>
                        </Grid>
                        <Box sx={{fontSize:{xs:16,sm:18},...theme.typobox.flexCenterCenter}} style={{width:'100%'}}>
                            <Box className="global-button" onClick={onSave} style={{borderRadius:'10px'}} sx={{...theme.palette.btn.login, height: { xs: 40, sm:60 },color:'#070809',marginTop:'30px',fontWeight:600 }}>{$t('invite.ca')}</Box>
                        </Box>
                    </Grid>
                    <Box sx={{fontSize: { xs: 22, sm: 24 },margin:'25px 0 15px 0'}}>{$t('sjzl')}</Box>
                    <Grid container sx={{justifyContent:'space-between'}}>
                        <Grid item xs={5} sx={{backgroundColor:'#F8F8F8'}} style={{padding:15,borderRadius:10}}>
                            <Box sx={{fontSize:{xs:16,sm:18},color:'#888888'}}>{$t('yqhyzs')}<img src={infoIcon} style={{width:15,height:15,marginLeft:6}}/></Box>
                            <Box sx={{fontSize:{xs:16,sm:23}}} style={{fontWeight:540}}>{invite?.list?.total || 0}</Box>
                        </Grid>
                        <Grid item xs={5} sx={{backgroundColor:'#F8F8F8'}} style={{padding:15,borderRadius:10}}>
                            <Box sx={{fontSize:{xs:16,sm:18},color:'#888888'}}>{$t('bdqbzs')}<img src={infoIcon} style={{width:15,height:15,marginLeft:6}}/></Box>
                            <Box sx={{fontSize:{xs:16,sm:23}}} style={{fontWeight:540}}>{invite?.address_total || 0}</Box>
                        </Grid>
                    </Grid>
                </Grid >
                <Grid item xs={11} sx={{ margin: '0 auto', overflow:'hidden',p: '2px 4px'}} md={12}>
                    <Box sx={{...theme.typobox.flexCenterLeft}} style={{margin:'15px 0'}}>
                        <Box sx={{fontSize: { xs: 16, sm: 24 }}} style={{fontWeight:'Medium',color:'#333333'}}>{$t('yqjl')}</Box>
                        <Box sx={{fontSize: { xs: 14, sm: 16 }}} style={{color:'#555555'}}>（{$t('g')}{invite?.list?.total||0}{$t('r')}）</Box>
                    </Box>
                    <Grid container>
                        {invNewColumns.map((v,i)=>
                            <Grid key={i} item xs={3} sx={{fontSize: { xs: 16, sm: 18 },...theme.typobox[i==0?'flexCenterLeft':'flexCenterCenter']}}
                        style={{backgroundColor:'#F8F8F8',padding:'10px 6px',color:'#888888'}}>
                            {v.label}
                        </Grid>)}
                        {invite?.list?.data.map((v,i)=><Grid container key={i} sx={{width:'100%'}}>
                            {invNewColumns.map((n,idx)=><Grid key={n.prop+idx} xs={3} sx={{fontSize: { xs: 16, sm: 18 },...theme.typobox[idx==0?'flexCenterLeft':'flexCenterCenter']}}
                                                        style={{padding:'10px 6px',color:'#888888',whiteSpace:"nowrap"}}>
                                {n.prop=='index'&&<Grid>{i+1}</Grid>}
                                {n.prop=='email'&&<Grid>{visibilityChange(v.email,[6,4])}</Grid>}
                                {n.prop=='inviter_status'&&<Grid>{v.inviter_status==1?$t('iswallet'):$t('nowallet')}</Grid>}
                                {n.prop=='createtime'&&<Grid>{v.createtime}</Grid>}
                            </Grid>)}
                        </Grid>)}
                        {invite?.list?.data.length==0&&<Grid container sx={{...theme.typobox.flexCenterCenter}}>
                            <Empty description="{$t('nodata')}"></Empty>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            <Poster link={link}></Poster>
        </Box>
    )
}

export default InviteNew