import { Box, Grid, useTheme, Avatar, Select, FormControl, MenuItem, Snackbar } from '@mui/material'
import React, { useState, useEffect } from 'react'
// import IMG1 from '@/assets/img/home1.png'
import TOP from '@/assets/icon/top.png'
import './publish.css'
import { useNavigate } from 'react-router-dom'
import AppContext from '@/hooks/useAppContext'
import { globalApiSlice } from '@/features/global/globalSlice'
// import { makeStyles } from '@mui/styles';
// import { makeStyles } from '@mui/system';

// const useStyles = makeStyles((theme) => ({
//   centerSnackbar: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// })); 

//倒计时
function CountdownTimer({ initialTime }) {
  console.log(initialTime, 11111111)
  const [timeRemaining, setTimeRemaining] = useState(initialTime)

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1)
      }
    }, 1000)

    return () => clearInterval(timer) // 清除定时器以避免内存泄漏

  }, [timeRemaining])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  return (
    <span>
      {formatTime(timeRemaining)}
    </span>
  )
}
function Title(props) {
  const theme = useTheme()
  const WIDTH = props.width || 103
  return (
    <Grid container item xs={12} sm={12} md={12}>
      <Grid item>
        <Box
          sx={{
            fontSize: 30,
            '@media (max-width: 600px)': {
              fontSize: 20,
            },
          }}
        >
          {props.name}
        </Box>
        <Box
          sx={{
            height: { xs: 2, sm: 4, md: 6 },
            background: 'linear-gradient(90deg, #00F6D0, #0077F5);',
            width: WIDTH,
            marginTop: 1,
          }}
        ></Box>
      </Grid>
      {props.children}
    </Grid>
  )
}
// 热门首发
function HotPublish(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Box
      onClick={() => navigate('/publish-info?id=' + props.id)}
      className="global-border-radius global-overflow-hidden"
      sx={{
        // width: { xs: 220 },
        // height: { xs: 300 },
        width: 220,
        // height: 300,
        '@media (max-width: 600px)': {
          width: '48%',
        },
        ...theme.palette.boxShadow,
        ...theme.palette.warp,
        marginTop: 2,
        pb: 2,
        cursor: 'pointer',
        // mr: 3,
      }}
    >
      <Box sx={{
        width: 1, height: 160, position: 'relative',
        '@media (max-width: 600px)': {
          height: '140px',
        },
      }}>
        <img
          className="global-img"
          style={{ background: '#24D5BA', width: '100%', height: 'auto' }}
          src={props.image}
        />
        <Box
          sx={{
            // width: 110,
            // height: 20,
            padding: '1px 10px',
            fontSize: 10,
            position: 'absolute',
            top: '12px',
            left: '15px',
            ...theme.typobox.flexCenterCenter,
            bgcolor: 'rgba(0,0,0,.2)',
            borderRadius: 10,
            color: '#00F1CC',
          }}
        >
          {/* { props.now<(props.first_at || props.start_at) && ((props.first_at||props.start_at)-props.now)>24*60*60 ? `即将开始` : <></>} */}
          {props.now < (props.first_at || props.start_at) ? $t('issue.jjks') : <></>}
          {(props.now >= (props.first_at || props.start_at)) && props.now < props.end_at ? $t('issue.zzz') : <></>}
          {props.now > props.end_at ? $t('mas.yjs') : <></>}
        </Box>
      </Box>
      <Box sx={{ ...{ padding: '0 10px', fontSize: 16 } }}>
        <Box
          className="global-font-weight-bold"
          sx={{
            ...theme.palette.mainText,
            ...{ fontSize: 20 },
            '@media (max-width: 600px)': {
              fontSize: 16
            },
            whiteSpace: 'nowrap', //不换行
            overflow: 'hidden',      //超出部分隐藏
            textOverflow: ' ellipsis',    //文本溢出显示省略号
          }}
        >
          {props.publish_name}
        </Box>
        {/* <Box><Avatar src="/static/images/avatar/1.jpg" />叛逆鸭</Box> */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          <Avatar sx={{ width: 22, height: 22, mr: '10px' }} src={props.avatar} />
          <Box className=" media-title14" sx={{
            whiteSpace: 'nowrap', //不换行
            overflow: 'hidden',      //超出部分隐藏
            textOverflow: ' ellipsis',    //文本溢出显示省略号
          }}>{props.publisher}</Box>
        </Box>
        <Box
          className=" media-title14"
          sx={{
            ...theme.typobox.flexCenterSpaceBetween,
            ...theme.palette.text,
          }}
        >
          <Box>{$t('num')}</Box>
          <Box>{$t('price')}</Box>
        </Box>
        <Box
          className="global-font-weight-bold"
          sx={{
            ...theme.typobox.flexCenterSpaceBetween,
            ...theme.palette.mainText,
            ...{ marginTop: '5px', fontSize: 14 },
          }}
        >
          <Box>{props.sold}个</Box>
          <Box>{formatNumberWithFixedDecimals(props.price)}{props.pay_coin}</Box>
        </Box>
      </Box>
    </Box>
  )
}
// 只保留2位小数
function formatNumberWithFixedDecimals(number) {
  if (isNaN(number)) {
    return '' // 如果输入的不是有效数字，返回空字符串
  }

  const parsedNumber = parseFloat(number)
  return parsedNumber.toFixed(2)
}
// 关于发行市场
function PublishMarket() {
  // const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const doClick = () => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 1000)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          color: theme.palette.text,
          fontSize: 24,
          mt: 2,
          mb: 2,
          width: 1,
          '@media (max-width: 600px)': {
            fontSize: 16,
          },
        }}
      >
        {$t('issue.hyy')}
        {/* 欢迎各位创作者、数字艺术家及优质项目方在X-Meta.HK发行高质量数字资产 */}
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box
          onClick={doClick}
          sx={{
            color: '#fff',
            background: 'linear-gradient(90deg, #248AE8, #35F4D2)',
            width: 460,
            height: 70,
            '@media (max-width: 600px)': {
              width: '100%',
              height: 45,
            },

            borderRadius: 30,
            ...theme.typobox.flexCenterCenter,
            cursor: 'pointer'
          }}
        >
          <span>{$t('issue.cwfxf')}</span>
        </Box>
      </Box>
      {/* 复制提示语 */}
      <Box sx={{
        // width:300,
        padding: '10px 40px',
        borderRadius: '5px',
        backgroundColor: theme.palette.grey.bgblack,
        color: theme.palette.grey.bgff,
        display: open ? 'block' : 'none',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}>
        <span>{$t('waitfk')}</span>
        {/* <Snackbar
          anchorOrigin={{ vertical:'top', horizontal:'center' }}
          open={open}
          onClose={handleClose}
          message="等待开放"
          // className={classes.centerSnackbar} 
          
        /> */}

      </Box>
    </Box>
  )
}
// 全部发行item
function AllPublishItem(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Box
      onClick={() => navigate('/publish-info?id=' + props.id)}
      className="global-border-radius global-overflow-hidden"
      sx={{
        // width: { xs: 220 },
        // height: { xs: 300 },
        width: 220,
        // height: 300,
        '@media (max-width: 600px)': {
          width: '48%',
        },
        ...theme.palette.boxShadow,
        ...theme.palette.warp,
        marginTop: 2,
        pb: 2,
        cursor: 'pointer',
        // mr: 3,
      }}
    >
      <Box sx={{
        width: 1, height: 160, position: 'relative',
        '@media (max-width: 600px)': {
          height: '140px',
        },
      }}>
        <img
          className="global-img"
          style={{ background: '#24D5BA', width: '100%', height: 'auto' }}
          src={props.image}
        />
        <Box
          sx={{
            // width: 110,
            // height: 20,
            padding: '1px 10px',
            fontSize: 10,
            position: 'absolute',
            top: '12px',
            left: '15px',
            ...theme.typobox.flexCenterCenter,
            bgcolor: 'rgba(0,0,0,.2)',
            borderRadius: 10,
            color: '#00F1CC',
          }}
        >
          {/* {props.now<(props.first_at || props.start_at) && ((props.first_at||props.start_at)-props.now)>24*60*60 ? `即将开始${<CountdownTimer initialTime={((props.first_at||props.start_at)-props.now) * 1000}/>}` : <></>} */}
          {props.now < (props.first_at || props.start_at) ? $t('issue.jjks') : <></>}
          {(props.now >= (props.first_at || props.start_at)) && props.now < props.end_at ? $t('issue.zzz') : <></>}
          {props.now > props.end_at ? $t('issue.yjs') : <></>}
        </Box>
      </Box>
      <Box sx={{ ...{ padding: '0 10px', fontSize: 16 } }}>
        <Box
          className="global-font-weight-bold"
          sx={{
            ...theme.palette.mainText,
            ...{ fontSize: 20 },
            '@media (max-width: 600px)': {
              fontSize: 16
            },
            whiteSpace: 'nowrap', //不换行
            overflow: 'hidden',      //超出部分隐藏
            textOverflow: ' ellipsis',    //文本溢出显示省略号
          }}
        >
          {props.publish_name}
        </Box>
        {/* <Box><Avatar src="/static/images/avatar/1.jpg" />叛逆鸭</Box> */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          <Avatar sx={{ width: 22, height: 22, mr: '10px' }} src={props.avatar} />
          <Box className="media-title14" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: ' ellipsis' }}>{props.publisher}</Box>
        </Box>
        <Box
          className="media-title14"
          sx={{
            ...theme.typobox.flexCenterSpaceBetween,
            ...theme.palette.text,

          }}
        >
          <Box>{$t('issue.zghb')}</Box>
          <Box>{$t('issue.jye')}</Box>
        </Box>
        <Box
          className="global-font-weight-bold"
          sx={{
            ...theme.typobox.flexCenterSpaceBetween,
            ...theme.palette.mainText,
            ...{ marginTop: '5px', fontSize: 14 },
          }}
        >
          <Box>
            {props.max_return_rate * 100}%{' '}
            <img
              src={TOP}
              style={{
                marginLeft: '3px', verticalAlign: 'middle',
                width: '15px', height: '15px'
              }}
            />
          </Box>
          <Box>{formatNumberWithFixedDecimals(props.sell_amount)}{props.pay_coin}</Box>
          {/* <Box>1.000000{props.pay_coin}</Box> */}
        </Box>
      </Box>
    </Box>
  )
}
// 全部发行
function AllPublish(props) {
  // console.log(props);
  // console.log(props.data);
  const { onSortChange } = props // 从props中提取onSortChange函数
  const theme = useTheme()
  const [sort, setSort] = React.useState(1)
  const handleChangeSort = (event) => {
    const newSortValue = event.target.value
    onSortChange(newSortValue) // 调用传递的函数来更新父组件的sort状态
    setSort(newSortValue)
  }
  // const sorts = [
  //   { value: 1, label: '发行时间：近到远' },
  //   { value: 2, label: '交易额：高到低' },
  //   { value: 3, label: '24H交易额涨幅：快到慢' },
  //   { value: 4, label: '地板价：高到低' },
  //   { value: 5, label: '24H地板价涨幅：快到慢' }
  // ]
  const sorts = $t('issue.sortType').map(v => ({
    label: v.name,
    value: v.index + 1
  }))
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          // height: 156,
          '@media (max-width: 600px)': {
            p: 2
          },
          p: 3,
          // bgcolor: '#F8F8F8',
          backgroundColor: theme.palette.grey.bg,
          borderRadius: '20px',
          display: 'flex',
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            ...theme.typobox.flexCenterCenterByColumn,
            borderRight: '2px solid #DEE1E1 ',
            '@media (max-width: 600px)': {
              borderRight: 'none',
            },
          }}
        >
          <span className="card-num">{props.sell_amount}</span>
          <span className="card-title">{$t('issue.scze')}</span>
        </Box>
        <Box
          sx={{
            flex: 1,
            ...theme.typobox.flexCenterCenterByColumn,
            borderRight: '2px solid #DEE1E1 ',
            '@media (max-width: 600px)': {
              borderRight: 'none',
            },
          }}
        >
          <span className="card-num">{props.genesis_number}</span>
          <span className="card-title">{$t('issue.fxxms')}</span>
        </Box>
        <Box sx={{ flex: 1, ...theme.typobox.flexCenterCenterByColumn }}>
          <span className="card-num">{props.participation}</span>
          <span className="card-title">{$t('issue.cyrs')}</span>
        </Box>
      </Box>
      {/* <Box sx={{ display: 'flex' }}>
        <Box className="all-select select-active">发行时间：近到远</Box>
        <Box className="all-select">交易额：高到低</Box>
        <Box className="all-select">交易额涨幅：快到慢</Box>
        <Box className="all-select">地板价：高到低</Box>
        <Box className="all-select">地板价涨幅：快到慢</Box>
      </Box> */}
      <FormControl sx={{ minWidth: 120 }} size="small">
        <Select
          value={sort}
          onChange={handleChangeSort}
          displayEmpty

        >
          {sorts.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
          {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem> */}
        </Select>
      </FormControl>
      <Box
        sx={{
          // ...{ margin: '15px 0' },
          ...theme.typobox.flexWarpWrap,
          justifyContent: 'space-between',
        }}
      >

        {props.data ? props.data.map((v) => (
          <AllPublishItem key={v.id} {...v} />
        )) : <p>--{$t('nodata')}--</p>}

        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v) => (
          <Box key={v + 1000} sx={{ width: { xs: 220 } }} />
        ))}
      </Box>
    </Box>
  )
}
function Publish() {
  const theme = useTheme()
  const currency = React.useContext(AppContext)
  const [state, setState] = useState({
    publishHot: [],
    publishTotal: {},
    publishList: {},
    publishBanner: [],
    sort: 1

  })

  const {
    usePublishHotQuery,
    usePublishTotalQuery,
    usePublishListQuery
  } = globalApiSlice

  const handleSortChange = newSortValue => {
    setState(prevState => ({ ...prevState, sort: newSortValue }))
  }
  const publishHot = usePublishHotQuery({})
  const publishTotal = usePublishTotalQuery({})
  const publishList = usePublishListQuery({ "sort": state.sort, "page": 1 })
  // const collectionTop = useCollectionTopQuery({ "coin": "ETH", "chain": 15, "time_type": 2 })
  // const newsHot = useNewsHotQuery({ page: 1, pageSize: 9999 })
  // const changeTop = useChangeTopQuery({})
  // const castHot = useCastHotQuery({})

  useEffect(() => {
    if (publishHot.data) setState(v => ({ ...v, publishHot: publishHot.data }))
    if (publishTotal.data) setState(v => ({ ...v, publishTotal: publishTotal.data }))
    //   setState(v => ({ ...v, publishHot:[
    //     {
    //         "id": 13,
    //         "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //         "series_id": 15, 
    //         "quantity": 100,
    //         "first_at": 1687251512, //优先购时间
    //         "start_at": 1684573113, //开始时间
    //         "end_at": 1687251512, //结束时间
    //         "pay_coin": "CFX", //支付币种
    //         "publisher": "XMETA测试", //发行者
    //         "avatar": "", //发行者头像
    //         "publish_name": "055D战舰ZH首发",//发行名称
    //         "publish_describe": "战舰ZH",//发行描述
    //         "price": "1.0000",//价格
    //         "now": 1684738270,//当前时间
    //         "sold":10 //已售数量
    //     },
    //     {
    //       "id": 11,
    //       "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //       "series_id": 15, 
    //       "quantity": 100,
    //       "first_at": 1687251512, //优先购时间
    //       "start_at": 1684573113, //开始时间
    //       "end_at": 1687251512, //结束时间
    //       "pay_coin": "CFX", //支付币种
    //       "publisher": "XMETA测试", //发行者
    //       "avatar": "", //发行者头像
    //       "publish_name": "055D战舰ZH首发",//发行名称
    //       "publish_describe": "战舰ZH",//发行描述
    //       "price": "1.0000",//价格
    //       "now": 1684738270,//当前时间
    //       "sold":10 //已售数量
    //   },
    //   {
    //     "id": 12,
    //     "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //     "series_id": 15, 
    //     "quantity": 100,
    //     "first_at": 1687251512, //优先购时间
    //     "start_at": 1684573113, //开始时间
    //     "end_at": 1687251512, //结束时间
    //     "pay_coin": "CFX", //支付币种
    //     "publisher": "XMETA测试", //发行者
    //     "avatar": "", //发行者头像
    //     "publish_name": "055D战舰ZH首发",//发行名称
    //     "publish_describe": "战舰ZH",//发行描述
    //     "price": "1.0000",//价格
    //     "now": 1684738270,//当前时间
    //     "sold":10 //已售数量
    // }
    // ] }))
    // if (newsHot.data) setState(v => ({ ...v, newsHot: state.newsHot.concat(newsHot.data.data) }))
    // if (changeTop.data) setState(v => ({ ...v, changeTop: changeTop.data }))
    // if (castHot.data) setState(v => ({ ...v, castHot: castHot.data }))
    if (publishList.data) setState(v => {
      // console.log(publishList.data);
      // console.log(state.publishList.data);
      return { ...v, publishList: publishList.data }
    })
    // setState(v => ({ ...v, publishList:{data:
    //   [
    //     {
    //         "id": 13,
    //         "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //         "series_id": 15, 
    //         "quantity": 100,
    //         "first_at": 1687251512, //优先购时间
    //         "start_at": 1684573113, //开始时间
    //         "end_at": 1687251512, //结束时间
    //         "pay_coin": "CFX", //支付币种
    //         "publisher": "XMETA测试", //发行者
    //         "avatar": "", //发行者头像
    //         "publish_name": "055D战舰ZH首发",//发行名称
    //         "publish_describe": "战舰ZH",//发行描述
    //         "price": "1.0000",//价格
    //         "now": 1684738270,//当前时间
    //         "sold":10 //已售数量
    //     },
    //     {
    //       "id": 11,
    //       "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //       "series_id": 15, 
    //       "quantity": 100,
    //       "first_at": 1687251512, //优先购时间
    //       "start_at": 1684573113, //开始时间
    //       "end_at": 1687251512, //结束时间
    //       "pay_coin": "CFX", //支付币种
    //       "publisher": "XMETA测试", //发行者
    //       "avatar": "", //发行者头像
    //       "publish_name": "055D战舰ZH首发",//发行名称
    //       "publish_describe": "战舰ZH",//发行描述
    //       "price": "1.0000",//价格
    //       "now": 1684738270,//当前时间
    //       "sold":10 //已售数量
    //   },
    //   {
    //     "id": 12,
    //     "image": "https://cdn.higer.club/water_margin/nft/lc.jpg", //封面图片
    //     "series_id": 15, 
    //     "quantity": 100,
    //     "first_at": 1687251512, //优先购时间
    //     "start_at": 1684573113, //开始时间
    //     "end_at": 1687251512, //结束时间
    //     "pay_coin": "CFX", //支付币种
    //     "publisher": "XMETA测试", //发行者
    //     "avatar": "", //发行者头像
    //     "publish_name": "055D战舰ZH首发",//发行名称
    //     "publish_describe": "战舰ZH",//发行描述
    //     "price": "1.0000",//价格
    //     "now": 1684738270,//当前时间
    //     "sold":10 //已售数量
    // }
    // ]} }))
    // if (publishList.data) setState(v => ({ ...v, publishList: publishList.data }))
  }, [publishHot.data, publishTotal.data, publishList.data])
  return (
    <Box className="container">
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '30px auto' }}
      >
        {state.publishHot?.length > 0 ? <Title {...{ name: $t('issue.hot') }} /> : <></>}
        <Box
          sx={{

            ...theme.typobox.flexWarpWrap,
            justifyContent: 'space-between',
          }}
        >
          {state.publishHot.map((v) => (
            <HotPublish key={v.id}  {...v} />
          ))}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v) => (
            <Box key={v + 1000} sx={{ width: { xs: 220 } }} />
          ))}
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '30px auto' }}
      >
        <Title {...{ name: $t('issue.about') }} />
        <PublishMarket />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        sx={{ margin: '40px auto' }}
      >
        <Title {...{ name: $t('issue.lsxm') }} />
        <AllPublish {...{ ...state.publishList, ...state.publishTotal }} onSortChange={handleSortChange} />
      </Grid>

      {/* <Box sx={{ height: 800 }}></Box> */}
    </Box>
  )
}

// function Publish() {
//   return <p>11111</p>;
// }

export default Publish
