import { TreeSelect } from 'antd';
import {useState} from "react";
import {AreaJson} from './addressJson'
import style from "@/components/Mine/mine.module.css"
import {useTheme,Grid} from "@mui/material";
const valueMap = {};
function loops(list, parent) {
    return (list || []).map(({ districts, adcode,name }) => {
        const node = (valueMap[adcode] = {
            parent,
            name
        });
        node.districts = loops(districts, node);
        return node;
    });
}

loops(AreaJson);
function VTreeSelect(props){
    const theme = useTheme()
    const [value,setValue] = useState(undefined)
    const [address,setAddress] = useState(undefined)
    const  onChange = (val, node, extra) => {
        console.log('val222=-===',val)

    };
    const onSelect = (value, labelNode, extra) => {
        let current = valueMap[value];
        let province,city
        if (current.parent){
            city=current.parent.name
            if (current.parent.parent){
                province=current.parent.parent.name
            }
        }
        let path=`${province}-${city}-${current.name}`
        setAddress(path)
        props.getAddress(path)
        setValue({label:path,value:value})
    }
    return(
        <Grid style={{position:"relative",width:'100%'}}>
            <TreeSelect
                showSearch
                labelInValue={true}
                maxTagPlaceholder={'1231231'}
                bordered={false}
                rootClassName={'addressTreeSelect'}
                popupClassName={style.addressSelect}
                style={{
                    width: '100%',
                    zIndex:2,
                    opacity:0
                }}
                // value={value}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    zIndex:9999
                }}
                sx={{...theme.palette.mainText}}
                fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
                treeNodeFilterProp='name'
                // placeholder="请选择区域"
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                onSelect={onSelect}
                treeData={AreaJson}
            />
            <Grid className={style.treeSelectPlaceholder} style={{color:address?'#333333':'#A9A9A9'}}
            sx={{fontSize:{xs:14,sm:16}}}>{address?address:$t('qxzqy')}</Grid>
        </Grid>
    )
}
export default VTreeSelect