import apiSlice, { service } from '@/app/api/apiSlice'
import { createSlice } from '@reduxjs/toolkit'
import { offShelf } from "@/components/Mine/ground"

const initialState = {
  history: [],
  referrer: '/',
  shoppingCart: []
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setHistory: (state, action) => {
      const history = state.history
      history.push(action.payload)
      state.referrer = history.length > 1 ? history[history.length - 2] : '/'
      state.history = history
    },
    addShoppingCart: (state, action) => {
      const { shoppingCart } = state
      shoppingCart.push(action.payload)
      state.shoppingCart = shoppingCart
    },
  },
})

const createApi = builder => ({
  blockchain: builder.query({
    query: (body) => ({ url: '/home/coin-list', method: 'POST', body }),
  }),
  collectionHot: builder.query({
    query: (body) => ({ url: '/home/collection-hot', method: 'POST', body }),
  }),
  newsHot: builder.query({
    query: (body) => ({ url: '/home/news-hot', method: 'POST', body })
  }),
  castHot: builder.query({
    query: (body) => ({ url: '/home/cast-hot', method: 'POST', body })
  }),
  collectionTop: builder.query({
    query: (body) => ({ url: '/home/collection-top', method: 'POST', body })
  }),
  changeTop: builder.query({
    query: (body) => ({ url: '/home/change-top', method: 'POST', body })
  }),
  productShow: builder.query({
    query: (body) => ({ url: '/product/show', method: 'POST', body })
  }),
  fileIp2region: builder.query({
    query: (body) => ({ url: '/file/ip2region', method: 'POST', body })
  }),
  productSellOrder: builder.query({
    query: (body) => ({ url: '/product/sell-order', method: 'POST', body })
  }),
  productDealLine: builder.query({
    query: (body) => ({ url: '/product/deal_line', method: 'POST', body })
  }),
  shoppingCartAdd: builder.query({
    query: (body) => ({ url: '/shoppingCart/add', method: 'POST', body })
  }),
  collectionDynamic: builder.query({
    query: (body) => ({ url: '/collection/dynamic', method: 'POST', body })
  }),
  marketProductList: builder.query({
    query: (body) => ({ url: '/market/product-list', method: 'POST', body })
  }),
  marketCollection: builder.query({
    query: (body) => ({ url: '/market/collection', method: 'POST', body })
  }),
  login: builder.query({
    query: (body) => ({ url: '/member/login', method: 'POST', body: { email: body.e, password: body.p, ckR: body.ckR } })
  }),
  activityBanner: builder.query({
    query: (body) => ({ url: '/activity/banner', method: 'POST', body })
  }),
  memberRegister: builder.query({
    query: (body) => ({ url: '/member/register', method: 'POST', body })
  }),
  memberFindPassword: builder.query({
    query: (body) => ({ url: 'member/findPassword', method: 'POST', body })
  }),
  commonSendCode: builder.query({
    query: (body) => ({ url: '/common/sendCode', method: 'POST', body })
  }),
  publishHot: builder.query({
    query: (body) => ({ url: '/publish/hot', method: 'POST', body })
  }),
  mergeEncodeMessage: builder.query({
    query: (body) => ({ url: '/merge/encodeMessage', method: 'POST', body })
  }),
  publishTotal: builder.query({
    query: (body) => ({ url: '/publish/total', method: 'POST', body })
  }),
  publishList: builder.query({
    query: (body) => ({ url: '/publish/list', method: 'POST', body })
  }),
  lotteryDraw: builder.query({
    query: (body) => ({ url: 'lottery/draw', method: 'POST', body })
  }),
  publishShow: builder.query({
    query: (body) => ({ url: '/publish/show', method: 'POST', body })
  }),
  merge: builder.query({
    query: (body) => ({ url: '/merge', method: 'POST', body }),
  }),
  //我的NFT
  myProduct: builder.query({
    query: (body) => ({ url: '/user/product', method: 'POST', body }),
  }),
  //上架中
  shelves: builder.query({
    query: (body) => ({ url: '/user/user_order', method: 'POST', body }),
  }),
  //获取合集
  searchSeries: builder.query({
    query: (body) => ({ url: '/user/search_series', method: 'POST', body }),
  }),
  //  获取交易流水
  tradeLog: builder.query({
    query: (body) => ({ url: '/user/trade_log', method: 'POST', body }),
  }),
  offerReceive: builder.query({
    query: (body) => ({ url: '/offer/receive', method: 'POST', body }),
  }),
  offerSelfQuotation: builder.query({
    query: (body) => ({ url: '/offer/self-quotation', method: 'POST', body }),
  }),
  //  获取用户信息
  userInfo: builder.query({
    query: (body) => ({ url: '/member/info', method: 'POST', body }),
  }),
  authLoginMessage: builder.query({
    query: (body) => ({ url: '/auth/loginMessage', method: 'POST', body }),
  }),
  authLogin: builder.query({
    query: (body) => ({ url: '/auth/login', method: 'POST', body }),
  }),
  shoppingCartRemove: builder.query({
    query: (body) => ({ url: '/shoppingCart/remove', method: 'POST', body }),
  }),
  userHashReturn: builder.query({
    query: (body) => ({ url: '/user/hash_return', method: 'POST', body }),
  }),
  getUserInfo: builder.query({
    query: (body) => ({ url: '/user/info', method: 'POST', body }),
  }),
  netIndex: builder.query({
    query: (body) => ({ url: '/net/index', method: 'POST', body }),
  }),
  productBuyMessage: builder.query({
    query: (body) => ({ url: '/product/buy-message', method: 'POST', body }),
  }),
  productBulkBuyMessage: builder.query({
    query: (body) => ({ url: '/product/bulkBuy-message', method: 'POST', body }),
  }),
  lotteryInfo: builder.query({
    query: (body) => ({ url: '/lottery/info', method: 'POST', body }),
  }),
  medalShow: builder.query({
    query: (body) => ({ url: '/medal/show', method: 'POST', body }),
  }),
  shoppingCartList: builder.query({
    query: (body) => ({ url: '/shoppingCart/list', method: 'POST', body }),
  }),
  shoppingCartGroup: builder.query({
    query: (body) => ({ url: '/shoppingCart/group', method: 'POST', body }),
  }),
  articleContent: builder.query({
    query: (body) => ({ url: '/article/content', method: 'POST', body }),
  }),
  articleNotice: builder.query({
    query: (body) => ({ url: '/article/notice', method: 'POST', body }),
  }),
  medalIndex: builder.query({
    query: (body) => ({ url: '/medal/index', method: 'POST', body }),
  }),
  lotteryList: builder.query({
    query: (body) => ({ url: '/lottery/list', method: 'POST', body }),
  }),
  lotteryLog: builder.query({
    query: (body) => ({ url: '/lottery/log', method: 'POST', body }),
  }),
  medalReceive: builder.query({
    query: (body) => ({ url: '/medal/receive', method: 'POST', body }),
  }),
  lotterySetAddress: builder.query({
    query: (body) => ({ url: '/lottery/set_address', method: 'POST', body }),
  }),
  addressList: builder.query({
    query: (body) => ({ url: '/address/list', method: 'POST', body }),
  }),
  productTransferMessage: builder.query({
    query: (body) => ({ url: '/product/transfer-message', method: 'POST', body }),
  }),
  lotteryScoreLog: builder.query({
    query: (body) => ({ url: '/lottery/score_log', method: 'POST', body }),
  }),
  //合集类型
  collectionType: builder.query({
    query: (body) => ({ url: '/collection/type', method: 'POST', body }),
  }),
  //总排行榜
  totalTop: builder.query({
    query: (body) => ({ url: '/home/total-top', method: 'POST', body }),
  }),
  //  end
  collectionShow: builder.query({
    query: (body) => ({ url: '/collection/show', method: 'POST', body })
  }),
  attributeList: builder.query({
    query: (body) => ({ url: '/collection/attribute_list', method: 'POST', body })
  }),
  collectionProductList: builder.query({
    query: (body) => ({ url: '/collection/product-list', method: 'POST', body })
  }),
  //合集价格分布
  collectionPriceLine: builder.query({
    query: (body) => ({ url: '/collection/price_line', method: 'POST', body })
  }),
  // 合集价格交易额
  collectionTradeLine: builder.query({
    query: (body) => ({ url: '/collection/trade_line', method: 'POST', body })
  }),
  // 合集拥有者分布
  collectionHolderLine: builder.query({
    query: (body) => ({ url: '/collection/holder_line', method: 'POST', body })
  }),
  // 巨鲸钱包列表
  collectionWhale: builder.query({
    query: (body) => ({ url: '/collection/whale', method: 'POST', body })
  }),
  // 立即铸造-折扣
  publishDiscount: builder.query({
    query: (body) => ({ url: '/publish/discount', method: 'POST', body })
  }),
  // 立即铸造-获取铸造事务编码
  publishBuyRespCastMessage: builder.query({
    query: (body) => ({ url: '/publish/buy-cast-message', method: 'POST', body })
  }),
  // 立即铸造-交易状态
  publishBuyResp: builder.query({
    query: (body) => ({ url: '/publish/buy-resp', method: 'POST', body })
  }),
  //  NFT授权编码
  netNftSetApproval: builder.query({
    query: (body) => ({ url: '/net/nft-set-approval', method: 'POST', body })
  }),
  //  批量上架签名信息
  batchUpSignMsg: builder.query({
    query: (body) => ({ url: '/product/bulk-message', method: 'POST', body })
  }),
  //  批量上架
  batchUp: builder.query({
    query: (body) => ({ url: '/product/bulk-up', method: 'POST', body })
  }),
  //  上架网络注册
  netregister: builder.query({
    query: (body) => ({ url: '/net/register', method: 'POST', body })
  }),
  //  上架网络注册
  netRegisterStatus: builder.query({
    query: (body) => ({ url: '/net/register-status', method: 'POST', body })
  }),
  netNftApprovalStatus: builder.query({
    query: (body) => ({ url: '/net/nft-approval-status', method: 'POST', body })
  }),
  //  下架
  offShelf: builder.query({
    query: (body) => ({ url: '/product/down-message', method: 'POST', body })
  }),
  //  下架
  hashReturn: builder.query({
    query: (body) => ({ url: '/user/hash_return', method: 'POST', body })
  }),
  //  删除钱包地址
  removeAddress: builder.query({
    query: (body) => ({ url: '/member/removeAddress', method: 'POST', body })
  }),
  upload: builder.query({
    query: (body) => ({ url: '/file/upload', method: 'POST', body })
  }),
  editUserInfo: builder.query({
    query: (body) => ({ url: '/user/modify', method: 'POST', body })
  }),
  addAddress: builder.query({
    query: (body) => ({ url: '/address/add', method: 'POST', body })
  }),
  modifyAddress: builder.query({
    query: (body) => ({ url: '/address/modify', method: 'POST', body })
  }),
  delAddress: builder.query({
    query: (body) => ({ url: '/address/del', method: 'POST', body })
  }),
  //  获取版本信息
  getVersion: builder.query({
    query: (body) => ({ url: '/file/version', method: 'POST', body })
  }),
  //  邀请拉新
  getinviteList: builder.query({
    query: (body) => ({ url: '/user/invite_list', method: 'POST', body })
  }),
  loginMessage: builder.query({
    query: (body) => ({ url: '/auth/loginMessage', method: 'POST', body })
  }),
  commonSymbol: builder.query({
    query: (body) => ({ url: '/common/symbol', method: 'POST', body })
  }),
  offerQuotation: builder.query({
    query: (body) => ({ url: '/offer/quotation', method: 'POST', body })
  }),
  offerOrders: builder.query({
    query: (body) => ({ url: '/offer/orders', method: 'POST', body })
  }),
  offerCancelQuotation: builder.query({
    query: (body) => ({ url: '/offer/cancel-quotation', method: 'POST', body })
  }),
  offerReject: builder.query({
    query: (body) => ({ url: '/offer/reject', method: 'POST', body })
  }),
  offerReceiveInfo: builder.query({
    query: (body) => ({ url: '/offer/receive-info', method: 'POST', body })
  }),
  offerIsNftApprove: builder.query({
    query: (body) => ({ url: '/offer/isNftApprove', method: 'POST', body })
  }),
  offerSetApproveAll: builder.query({
    query: (body) => ({ url: '/offer/setApproveAll', method: 'POST', body })
  }),
  offerAccept: builder.query({
    query: (body) => ({ url: '/offer/accept', method: 'POST', body })
  }),
  homeProjectHot: builder.query({
    query: (body) => ({ url: '/home/project-hot', method: 'POST', body })
  }),
  projectDetail: builder.query({
    query: (body) => ({ url: '/project/detail', method: 'POST', body })
  }),
  projectSeries: builder.query({
    query: (body) => ({ url: '/project/series', method: 'POST', body })
  }),
  projectList: builder.query({
    query: (body) => ({ url: '/project/list', method: 'POST', body })
  }),
  
})

const assignApi = createApi => {
  const temp = createApi.toString()
  const map = {}

  temp.replace(/[a-z|A-Z]*:[\s|\S]{1,10}.query/g, (a, b, c) => {
    map[a.substring(0, a.indexOf(':')) + 'Api'] = null
  })

  Object.keys(map).forEach((k, i) => {
    const m = k.substring(k, k.length - 3)
    const r = new RegExp(`${m}:[\\s|\\S]{0,100}body`)
    const cache = r.exec(temp)[0]
    const url = cache.match(/url:[.+\s]?['"](.*?)['"]/)[1]
    const method = cache.match(/method:[.+\s]?['"](.*?)['"]/)[1]
    map[k] = body => service({ url, method, ...method === 'GET' ? { params: body } : { data: body } })
  })
  return map
}

export const globalApiSlice = Object.assign(apiSlice.injectEndpoints({
  endpoints: builder => createApi(builder),
}), assignApi(createApi))

export const { setHistory, setReferrer } = globalSlice.actions
export const { useBlockchainQuery } = globalApiSlice

export default globalSlice.reducer
