import { globalApiSlice } from '@/features/global/globalSlice'


let chain_id = null
function connectWallet() {
    return new Promise(async resolve => {
        const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
        if (accounts.length > 0) {
            if (!chain_id) {
                chain_id = await window.ethereum.networkVersion
            }
            let res = await globalApiSlice.loginMessageApi({
                address: accounts[0],
                chain: chain_id
            })
            if (res.login_message) {
                let login_message = res.login_message
                let sign = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [login_message, accounts[0]],
                    // from: that.walletConnect.account,
                })
                let res2 = await globalApiSlice.authLoginApi({
                    address: accounts[0],
                    sign,
                    chain: chain_id,
                })
                if (res2) {
                    _toast($t('tjqbcg'))
                    resolve(true)
                }
            }
        }
    })
}
export default connectWallet