import { useContext, useReducer, useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useTheme, Box, Grid, Drawer } from '@mui/material'
import { useDispatch } from 'react-redux'
import { changeMode } from '@/features/user/userSlice'
import useAppContext from '@/hooks/useAppContext'
import Meru from './Meru/index.jsx'
import { globalApiSlice } from '@/features/global/globalSlice.js'
import Home from '@/components/Home/index.jsx'
import AppContext from '@/hooks/useAppContext.js'
import Search from '../Search/index.jsx'
import contentEthereum from '@/hooks/contentEthereum.js'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ethereumSendTransaction from '@/hooks/ethereumSendTransaction'

function ShoppingCart({ rd }) {
  const { store, store: { shoppingOpen }, setStore } = useContext(AppContext)
  const { useShoppingCartListQuery, useShoppingCartGroupQuery } = globalApiSlice
  const t = useTheme()
  const [shopping_card, setShopping_card] = useState(0)
  const [shopping, setShopping] = useState([])
  const [group, setGroup] = useState([])
  const [index, setIndex] = useState(0)
  const [item, setItem] = useState({})
  const [body, setBody] = useState({ ckR: !!group.length })
  // const SCG = useShoppingCartGroupQuery({ ckR: shoppingOpen, rd })
  // const SCL = useShoppingCartListQuery({ ..._.clone(body), index })
  useEffect(() => {
    new Promise((resolve) => {
      globalApiSlice.shoppingCartGroupApi().then(res => {
        if (res.list.length) {
          const item = res.list[index]
          setShopping_card(res.shopping_card)
          setGroup(res.list)
          setItem(item)
          globalApiSlice.shoppingCartListApi({ chain_id: item?.main_net_id, rd, page: 1, per_page: 9999 }).then(data => {
            data.data?.map(v => {
              if (v.order === null) {
                v.order = {
                  currency: '',
                  price: '0'
                }
              }
              return v
            })
            setStore(v => ({ ...v, shoppingNum: data?.data?.length || 0 }))
            setShopping(() => data.data || [])
          })
        } else {
          setShopping(() => [])
        }
      })
    })
  }, [index, shopping_card])

  const handle = async () => {
    let eth = null
    if (store.ethereum?.address) {
      eth = store.ethereum
    } else {
      eth = await contentEthereum()
    }

    if (shopping.filter(v => v.order.id).length !== shopping.length) {
      return _toast($t('qqdgwczsxdcp'))
    }

    const PBM = await globalApiSlice.productBulkBuyMessageApi({ orders: shopping.filter(v => v.order.id).map(v => ({ order_id: v.order.id, number: 1 })) })
    const sign = await ethereumSendTransaction({
      ...PBM,
      id: PBM.trade_ids,
      type: 1,
      to: PBM.exchange_address,
      chain_id: PBM.chain_id,
      money: Number(eval(shopping.filter(v => v.order.id).map(v => v.order.price).join('+')))
    })
    if (sign) {
      _toast($t('buySuccess'))
      setStore(v => ({ ...v, shoppingOpen: false }))
    }
  }

  const rm = async (v, i) => {
    const data = await globalApiSlice.shoppingCartRemoveApi({ ids: [v.id] })
    setShopping_card(() => data.shopping_cart)
  }

  return (
    <Box sx={{ width: { xs: 220, sm: 370 }, height: '100%' }}>
      <Grid container item xs={12} sx={{ height: '100%' }}>
        <Grid item sx={{ m: 2, pb: 1, mt: 2, borderBottom: `1px solid ${t.palette.theme.color}` }} xs={12} sm={12}>
          <Box className="global-font-weight-bold " sx={{ fontSize: { xs: 20 } }}>{$t('cart')} ({shopping_card || 0})</Box>
        </Grid>
        <Grid container item xs={12} sm={12} sx={{ m: 2, mt: 0, ...t.typobox.flexCenterLeft }}>
          {group.map((v, i) => <Box
            key={i}
            onClick={() => setIndex(i) & setShopping(() => []) & setBody(data => ({ ...data, chain_id: v.main_net_id, rd: Math.random() }))}
            className="global-border-radius"
            sx={{
              mb: 1,
              p: 1, pt: .5, pb: .5, ...t.palette.border, fontSize: { xs: 14, sm: 16 },
              background: index == i ? '#00EFCA' : '',
              color: index == i ? '#fff' : '',
              borderColor: index == i ? '#00EFCA' : '',
              mr: 1
            }}>{v.network.name}</Box>)}
        </Grid>
        <Grid className='global-position-relative' item sx={{ m: 2, mt: 1, mb: 0, lineHeight: 1, height: { xs: 'calc(100% - 400px)', sm: 'calc(100% - 350px)' }, overflow: 'auto' }} xs={10} sm={11}>
          {
            shopping?.map((v, i) => <Grid className='global-position-relative' key={i} container item sx={{
              ...t.typobox.flexRevertSpaceBetween, pb: 2, mb: 2,
              filter: v.order.id ? '' : 'grayscale(1)',
              borderBottom: `1px solid ${t.palette.theme.color}`, fontSize: { xs: 12, sm: 18 }
            }}>
              <Box sx={{ width: { xs: 40, sm: 60 }, height: { xs: 40, sm: 60 } }}>
                <img className='global-img' src={v.product.sourcefile_url} />
              </Box>
              <Box sx={{ width: { xs: 'calc(100% - 50px)', sm: 'calc(100% - 80px)' } }}>
                <Box sx={{ ...t.typobox.flexRevertSpaceBetween, mb: .5, width: '100%' }}>
                  <Box className="global-text-nowrap" sx={{ width: { xs: 'calc(100% - 20px)', sm: 'calc(100% - 40px)' } }}>
                    {/* {v.product.name} #{v.token_id} */}
                    {v.product.name.replace(`#${v.product.token_id}`, '') === v.series ? '#' + v.product.token_id :
                      v.product.name
                    }
                  </Box>
                  <Box onClick={() => rm(v, i)}><RemoveCircleOutlineIcon sx={{ fontSize: { xs: 16, sm: 20 } }} /></Box>
                </Box>
                <Box fontSize={{ xs: 8, sm: 14 }}>{v.series.name}</Box>
                <Box sx={{ fontSize: { xs: 14, sm: 18 }, mt: .5 }} >{v?.order?.price} {v.order?.currency}</Box>
              </Box>
            </Grid>)
          }
        </Grid>
        <Grid item sx={{ m: 2, mb: 0, lineHeight: 1 }} xs={10} sm={11}>
          <Grid item xs={12} sx={{ p: 2, fontSize: { xs: 14, sm: 16 }, background: t.palette.mode == 'dark' ? '#282D2D' : '#F8F8F8' }}>
            <Box sx={{ ...t.typobox.flexRevertSpaceBetween, ...t.palette.mainText }}>
              <Box>{$t('njzf')}</Box>
              <Box className="global-font-weight-bold">
                {eval(shopping.map(v => v.order?.price).join('+'))} {shopping.length ? shopping[0].order.currency : '-'}
                {/* {shopping.length ? shopping?.reduce((a, b) => a.order.price + b.order.price) : '-'} {shopping.length ? shopping[0].order.currency : '-'} */}
              </Box>
            </Box>
            <Box sx={{ mt: 2, ...t.palette.text }}>{shopping.length} 件藏品</Box>
          </Grid>
        </Grid>
        <Grid container item sx={{ m: 2, lineHeight: 1 }} xs={10} sm={11}>
          <Grid item xs={12}
            onClick={handle}
            className='global-border-radius'
            sx={{
              ...t.palette.btn.login,
              ...t.typobox.flexCenterCenter,
              height: { xs: 40, sm: 60 },
              fontSize: { xs: 14, sm: 20 }
            }}>
            {$t('qrzf')}
          </Grid>
        </Grid>
      </Grid>
    </Box >
  )
}

function Layout(props) {
  const l = useLocation()
  const n = useNavigate()
  const { store: { shoppingOpen }, setStore } = useContext(AppContext)
  const [v, setV] = useState('')
  if (l.pathname === '/') n('/home')
  return (
    <Box sx={{ height: '100%' }}>
      <Meru s={setV} />
      <Box sx={{ height: { xs: 'calc(100% - 60px)', sm: 'calc(100% - 80px)' } }}>
        {v && (l.pathname.indexOf('search') > -1) ? <Search {...{ ...props, v, setV }} /> : <Outlet />}
      </Box>
      <Drawer anchor={'right'} open={shoppingOpen} onClose={() => setStore(v => ({ ...v, shoppingOpen: false }))}>
        {shoppingOpen ? <ShoppingCart {...{ rd: Math.random() }} /> : ''}
      </Drawer>
      {/* <Grid xs={12} sm={12} container item style={{ background: theme.palette.background.default }}>333</Grid > */}
    </Box>
  )
}

export default Layout
