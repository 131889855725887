import React, { useState, useEffect, useMemo } from 'react'
import { globalApiSlice } from '@/features/global/globalSlice'
import {
    Box,
    Grid,
    useTheme,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Hidden,
    TableHead,
    TableRow, TableCell, TableBody
} from "@mui/material"
import AppContext from "@/hooks/useAppContext"
import BasicTable from "./table/index"
import style from '@/components/Mine/mine.module.css'
import { expireTimeOption, omit, rankingData } from '@/components/Mine/data'
import axios from 'axios'

const {
    useCollectionTypeQuery,
    useTotalTopQuery
} = globalApiSlice

const getList = async (form) => {
    let url = `${import.meta.env.VITE_API_URL}/home/total-top`
    let response = await axios.post(url, form)
    let res = response.data.data.data
    return res
}
function Ranking() {
    const theme = useTheme()
    const [state, setState] = useState({
        collectionType: [],
        totalTop: []
    })
    const collectionType = useCollectionTypeQuery({})
    const [type, setType] = React.useState(0)
    const [time, setTime] = React.useState(2)
    const [cost, setCost] = React.useState('ARB')
    const [costId, setCoseId] = React.useState(undefined)
    const [chain, setChain] = React.useState(0)
    let form = {
        type: type,//类型ID, 0表示所有
        chain: chain,//主网ID, 0表示所有
        coin: cost,//币标识
        time_type: time,//时间类型(1:1小时、2:24小时、3:7天、4:30天、5: 所有时间)
        sort: 2,//排序（1: 交易量跌幅desc, 2: 交易量涨幅asc, 3:交易量desc, 4:交易量asc）
    }
    let totalTop = useTotalTopQuery(form)
    let { store: { blockchain, lang } } = React.useContext(AppContext)
    if (!blockchain) blockchain = []
    if (!form.chain) {
        if (JSON.stringify(blockchain) != '[]') {
            setChain(blockchain[0].main_network_id)
            setCoseId(blockchain[0].id)
            setCost(blockchain[0].identifier)
        }
    }
    useEffect(() => {
        showLoad($t('qqz'))
        if (collectionType.data) setState(v => ({ ...v, collectionType: collectionType.data }))
        if (totalTop.data) {
            closeLoad()
            setState(v => ({ ...v, totalTop: totalTop.data }))
        }

    }, [collectionType.data, totalTop.data])
    const handleChange = (event) => {
        setType(event.target.value)
    }
    const timeChange = async (event) => {
        setTime(event.target.value)
    }
    function blockChange(v, event) {
        setCost(v.identifier)
        setChain(v.main_network_id)
        setCoseId(v.id)
    }
    const updateList = async () => {
        let arr = await getList(form)
        setState(v => {
            v.totalTop = arr
            return v
        })
    }
    const onSort = async (event) => {
        form.sort = form.sort == 1 ? 2 : 1
        let res = await updateList()
    }
    return (
        <Box className={style.rankingBox} sx={{ width: { xs: '95%', sm: '80%' } }}>
            <div className={style.rankQueryBox}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">{$t('market.type')}</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={type} label={$t('market.type')} onChange={handleChange}>
                        <MenuItem value={0}>{$t('public.all')}</MenuItem>
                        {state.collectionType.map(item => {
                            return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={time} label="Age" onChange={timeChange}>
                        {expireTimeOption.map(item => {
                            return <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Hidden smDown>
                    <Grid item xs={6} sm={2}>
                        <Box className={style.rankBlockSelect}
                            sx={{
                                ...theme.typobox.flexCenterSpaceBetween,
                                ...theme.palette.border,
                                ...{ padding: '1px' }
                            }}>
                            {blockchain.map((v, i) => <Box
                                className={'global-pointer ' + 'global-border-radius ' + style.rankCostItem} value={v.id}
                                style={{ color: v.id == costId ? '#070809' : '#7E8181', fontWeight: 500 }}
                                sx={{
                                    width: { xs: 40, sm: 45, md: 60 },
                                    height: 'auto',
                                    padding: '8.5px 14px',
                                    background: v.id == costId ? '#00EFCA' : '',
                                    fontSize: { xs: 12, sm: 16 },
                                    ...theme.typobox.flexCenterCenter,
                                    ...theme.palette.text,
                                    ...i === 0 ? theme.palette.currentText : {}
                                }} key={v.id} onClick={blockChange.bind(event, v)}>{v.name}</Box>)}
                        </Box>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                            value={cost}>
                            {blockchain.map(item => {
                                return <MenuItem value={item.name} key={item.id} onClick={blockChange.bind(event, item)}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Hidden>
            </div>
            <BasicTable list={state.totalTop && state.totalTop.data ? state.totalTop.data : []} click={onSort}></BasicTable>
        </Box>
    )
}
export default Ranking