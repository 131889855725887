import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import history from '@/routes/history'
// import { setCredentials, logOut } from '../../features/user/userSlice';

const formatting = data => {
  let json = JSON.stringify(data)
  json = json.replace(/[0-9]*\.[0-9]+/g, a => isNaN(Number(a)) ? a : Number(a))
  json = json.replace(/https:[^"]*\.(gif|png|jpg)/g, (a, b, c) => {
    return a + '?x-oss-process=image/resize,w_400,m_lfit'
  })
  return JSON.parse(json)
}

const resultFilter = result => {
  if (!result.data && result.error) { _toast(result.error.error); return Promise.reject({ error: result.error.error }) }
  if (result.data.code == 2) { _toast($t('pljqb')); return Promise.reject({ error: result.data.message }) }
  if (result.data.code == 4) { _toast(result.data.message); window.location.href = window.location.origin + '/#/login'; return Promise.reject({ error: result.data.message }) }
  if (result.data.code !== 1 && result.data.message) { _toast(result.data.message); return Promise.reject({ error: result.data.message }) }
  if (result.data.code == 1) return { ...result, ...{ data: formatting(result.data.data) } }
  return Promise.reject(result.data)
}

const queryFilter = headers => {
  // const { token } = getState().auth;
  if (window.TOKEN || sessionStorage.getItem('TOKEN')) {
    headers.set('Authorization', `${sessionStorage.getItem('Q_TOKEN_TYPE') || 'bearer'} ${sessionStorage.getItem('Q_TOKEN')}`)
    headers.set('User-Token', sessionStorage.getItem('TOKEN'))
    headers.set('Accept', 'application/json')
  } else {
    headers.set('Authorization', `bearer`)
    headers.set('User-Token', ``)
  }
  const lang = sessionStorage.getItem('lang') || 'ZH'
  headers.set('Accept-Language', lang === 'ZH' ? 'zh_CN' : lang === 'TW' ? 'zh_HK' : 'en')
  return headers
}

export const service = axios.create({
  baseUrl: import.meta.env.VITE_API_URL,
  timeout: 5000
})

service.interceptors.request.use(config => {
  config.headers = queryFilter(config.headers)
  config.url = import.meta.env.VITE_API_URL + config.url
  return config
}, error => Promise.reject(error)
)

service.interceptors.response.use(async response => {
  const result = await resultFilter(response)
  return result.data
}, error => {
  if (error.message) _toast(error.message)
  return Promise.reject(error)
})

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    return queryFilter(headers)
  },
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (args?.body?.ckR !== undefined) {
    if (!args.body.ckR) { return { error: '' } } else { args = _.clone({ ...args, body: { ...args.body, ckR: undefined } }) }
  }

  const result = await baseQuery(args, api, extraOptions)
  // ====================== TODO: Implement 401 unauthorized re-authentication ======================
  // if (result?.error?.status === 401) {
  //   const refreshResult = await baseQuery('/refresh', api, extraOptions);
  //   if (refreshResult?.data) {
  //     const { user } = api.getState().auth;
  //     api.dispatch(setCredentials({ user, ...refreshResult.data }));
  //     result = await baseQuery(args, api, extraOptions);
  //   } else {
  //     api.dispatch(logOut());
  //   }
  // }
  return resultFilter(result)
}

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})

export default apiSlice
