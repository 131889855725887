import utils from 'web3-utils'

export const ERR = {
  "STE": "订单异常",
  "OTA": "剩余数量不足",
  "NYT": "没到购买时间",
  "PCE": "购买金额错误",
  "OAE": "购买数量错误",
  "OWE": "nft拥有者错误",
  "TSE": "转移失败",
  "FTE": "转账失败",
  "TKE": "nft tokenid 错误",
  "ODE": "卖单异常",
  "SOE": "卖单异常",
  "NFTE": "合约地址错误",
  "SGE": "卖家签名错误",
  "OC": "卖单已取消",
  "OVF": "验证卖单错误",
  "OCE": "卖单已取消",
  "BSE": "批量购买验证错误",
  "OVF": "验证卖单错误",
  "WPE": "购买金额错误",
  "NTSE": "转移错误",
  "FDSE": "转账失败",
  "MTE": "铸造失败",
  "AME": "卖单数量不足",
  "CLS": "首发已结束",
  "TAE": "购买数量错误",
  "ODC": "已取消或订单已下架",
  "ETS": "超出购买数量",
  "TO": "订单超时",
  "OTE": "超出卖单数量",
  "ST": "转移失败",
  "SA": "未授权"
}

export const handleMsg = (e) => {
  closeLoad()
  let msg = ''
  if (typeof e == 'object') {
    msg = e && e.message
    if (e.code == -32603) {
      try {
        msg = ERR[Object.keys(ERR).filter(k => e.data.message?.indexOf(k) > 0)[0]]
        if (!msg) msg = e.data.message
        if (e.data.code == -32000) msg = '余额不足'
      } catch (err) {
        msg = e.data.message
      }
    }
  }
  _toast(msg)
}

// 藏品上架
function ethereumSendTransaction(data) {
  // 参数参照H5源码
  data = Object.assign({
    data: '',
    to: '',
    chain_id: '',
    type: '',
    id: '',
  }, data)

  return new Promise((resolve, reject) => {
    let chain_id = window?.ethereum?.networkVersion
    console.log('ethereum======', window?.ethereum)
    if (data.chain_id && (data.chain_id != chain_id)) {
      window?.ethereum?.request({
        "method": "wallet_switchEthereumChain",
        "params": [{
          "chainId": utils.numberToHex(data.chain_id)
        }]
      }).then(switchRes => {
        if (switchRes) {
          window.ethereum.request({
            "method": "wallet_addEthereumChain",
            "params": [this.chainsList[data.chain_id]]
          })
        }
      })
      // 4902	无法识别的链 ID。首先尝试使用“wallet_addEthereumChain”添加链。
    }

    try {
      showLoad('请打开钱包进行操作')
      window?.ethereum?.request({
        "method": "eth_estimateGas",
        "params": [{
          "from": window.ethereum.selectedAddress,
          "to": data.to,
          "data": data.data,
          "chainId": utils.numberToHex(data.chain_id),
          "value": data.value ? utils.numberToHex(+data.value) : '0x0',
        }, null]
      }).then(estimateGas => {
        window?.ethereum?.request({
          method: 'eth_sendTransaction',
          params: [{
            from: window.ethereum.selectedAddress,
            to: data.to,
            value: data.value ? utils.numberToHex(data.value) : 0,
            data: data.data,
            gas: estimateGas
          }],
        }).then(result => {
          closeLoad()
          resolve(result)
        }).catch(e => {
          handleMsg(e)
          reject(e)
        })
      }).catch(e => {
        handleMsg(e)
        reject(e)
      })
    } catch (e) {
      handleMsg(e)
      reject(e)
    }
  })

}

export default ethereumSendTransaction
