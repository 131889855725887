import React, { useState, useEffect, useMemo, useRef } from "react"
import * as ReactDOM from "react-dom"
import { componentMap } from './routes/index.jsx'
import { CssBaseline, Box, Dialog, DialogTitle, DialogContent, Button, Grid } from '@mui/material'
import {
  createHashRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from "react-router-dom"

import ZH from '@/lang/zh.json'
import EN from '@/lang/en.json'
import TW from '@/lang/tw.json'
import { globalApiSlice } from '@/features/global/globalSlice.js'
import AppContext from '@/hooks/useAppContext.js'
import { useSnackbar } from 'notistack'
import contentEthereum from '@/hooks/contentEthereum'
import { useTheme } from "@emotion/react"
import Loading from "@/features/loading/index"
import $ from 'jquery'
const router = createHashRouter(componentMap)
let num = 0

class DialogSerive {
  constructor(options = {}) {
    this.title = options.title || ''
    this.message = options.message || ''
    this.temp = options.temp || ''
    this.cacleShow = options.cacleShow
    this.style = options.style || {
      width: { xs: 320, sm: 500 },
      p: { xs: 2, sm: 4 }
    }
    this.status = 0
    this.open = true
    this.id = (Math.random() * 10000).toFixed(0)
    this.render()
  }
  async then(callback) {
    return new Promise((resolve, rejected) => {
      const i = setInterval(() => {
        console.log('this.status', this.status)
        if (this.status == 1) resolve(callback($t('success'))) & clearInterval(i)
        if (this.status == 2) rejected($t('cancel')) & clearInterval(i)

      }, 200)
    })
  }
  commit() {
    this.open = false
    this.status = 1
    $(`#${this.id}`).remove()
    $(`.${this.id}`).remove()
  }
  close() {
    this.status = 2
    this.open = false
    $(`#${this.id}`).remove()
    $(`.${this.id}`).remove()
  }
  render() {
    const div = document.createElement('div')
    div.className = this.id
    document.body.appendChild(div)
    window.open = true
    ReactDOM.render(<Dialog id={this.id} open={this.open}>
      <Box sx={this.style}>
        <DialogTitle className="global-font-weight-bold" sx={{ p: 0, mb: 2, color: '#24D5BA', textAlign: 'center' }}>{this.title}</DialogTitle>
        {this.message ? <DialogContent>{this.message}</DialogContent> : ''}
        {this.temp ? <DialogContent>{this.temp}</DialogContent> : ''}
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button onClick={() => this.commit()} sx={{ background: '#24D5BA', color: '#fff' }}>{$t('market.determine')}</Button>
          {this.cacleShow ?
            <Button onClick={() => this.close()} sx={{ color: '#24D5BA', mr: 2 }}>{$t('cancel')}</Button>
            : ''}
        </Box>
      </Box>
    </Dialog>, div)
  }
}

window._alert = (...age) => new DialogSerive(...age)

export default function App(props) {
  const globalRef = useRef(null)
  const theme = useTheme()
  const lang = sessionStorage.getItem('lang') || 'ZH'
  if (!sessionStorage.getItem('lang')) sessionStorage.setItem('lang', 'ZH')
  const [store, setStore] = useState({
    lang,
    langData: null,
    blockchain: null,
    ethereum: void 0,
    shoppingNum: 0,
    shoppingOpen: false,
    shelfLoad: {
      loading: false,
      text: ''
    },
    authContent: {
      status: 1,
      name: '',
      num: 0
    }, // 授权弹窗内容
  })
  if (store.lang !== lang) {
    sessionStorage.setItem('lang', store.lang)
    window.location.reload()
  }

  const [ipS, setIpS] = useState(false)
  const { useBlockchainQuery, useShoppingCartGroupQuery, useFileIp2regionQuery } = globalApiSlice
  const blockchain = useBlockchainQuery({})
  const SCG = useShoppingCartGroupQuery({ ckR: !!sessionStorage.getItem('TOKEN') })
  const F2R = useFileIp2regionQuery({})
  const { enqueueSnackbar: ES } = useSnackbar()
  window.$t = s => {
    let t = ''
    switch (store.lang) {
      case 'ZH':
        t = (!Array.isArray(s)
          ? s.replace(/\[/g, '.').replace(/\]/g, '').split('.')
          : s
        ).reduce((o, k) => (o || {})[k], ZH)
        if (!t) t = ZH[s]
        break
      case 'YW':
        t = (!Array.isArray(s)
          ? s.replace(/\[/g, '.').replace(/\]/g, '').split('.')
          : s
        ).reduce((o, k) => (o || {})[k], EN)
        if (!t) t = EN[s]
        break
      case 'TW':
        t = (!Array.isArray(s)
          ? s.replace(/\[/g, '.').replace(/\]/g, '').split('.')
          : s
        ).reduce((o, k) => (o || {})[k], TW)
        if (!t) t = TW[s]
        break
    }
    return t
  }

  useEffect(() => {
    window._toast = (m) => ES(m, {
      vertical: 'top',
      horizontal: 'center'
    })

    if (F2R.data && F2R.data.status && !sessionStorage.getItem('IPS')) setIpS(true)
    if (SCG.data) setStore(v => ({ ...v, shoppingNum: SCG.data.shopping_card }))
    if (blockchain.data) setStore(v => _.clone(Object.assign(v, { blockchain: blockchain.data ? blockchain.data.map(v => ({ ...v, coin: v.identifier, chain: v.id })) : null })))
  }, [blockchain.data, SCG.data])
  useEffect(() => {

    let USER = sessionStorage.getItem('USER') || {}
    try {
      USER = JSON.parse(USER)
    } catch (err) {
      USER = {}
    }

    if (USER.address) setStore(v => ({ ...v, ethereum: USER }))
    console.log('numfsafas', num)
    if (num == 0) {
      num++
      window.ethereum?.on('accountsChanged', async (accounts) => {
        console.log('xxx', num)
        setStore(v => ({ ...v, ethereum: void 0 }))
        let USER = sessionStorage.getItem('USER') || {}
        try {
          USER = JSON.parse(USER)
        } catch (err) {
          USER = {}
        }
        delete USER.address
        delete USER.token
        sessionStorage.setItem('USER', JSON.stringify(USER))
        sessionStorage.removeItem('Q_TOKEN')
        sessionStorage.removeItem('Q_TOKEN_TYPE')
        console.log('accountsChanged===')
        const eth = await contentEthereum()
        setStore(v => ({ ...v, ethereum: eth }))
      })
    }
  }, [])

  return (
    <AppContext.Provider value={{ store, setStore }}>
      <Dialog open={ipS}>
        <Box sx={{ borderRadius: '20px', m: 6, ...theme.typobox.flexCenterCenterByColumn }}>
          <DialogTitle className="global-font-weight-bold" sx={{ p: 0, mb: 2, color: '#24D5BA' }}>{$t('lzsxgjdqdyh')}</DialogTitle>
          <DialogContent sx={{ p: 0, mb: 2 }}>{F2R.data?.message}</DialogContent>
          <Box sx={{ width: '100%', mb: { xs: 2, sm: 6 } }}>
            {/* href={`https://file.x-meta.hk/user/fwtk-zh-Hans.html`} */}
            <a target="_bank" href={window.location.origin + `/#/link?value=8&url=${encodeURIComponent('https://file.x-meta.hk/user/fwtk-zh-Hans.html')}`} ><Box sx={{ color: '#24D5BA' }}>{`《${$t('yhxy')}》`}</Box></a>
          </Box>
          <Button onClick={() => sessionStorage.setItem('IPS', 1) & setIpS(false)} sx={{ ...theme.palette.btn.login, width: '100%' }}>{$t('market.determine')}</Button>
        </Box>
      </Dialog>
      <CssBaseline />
      <RouterProvider router={router} >
      </RouterProvider>
      <Loading></Loading>
    </AppContext.Provider>
  )
}
